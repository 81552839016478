import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseTaskList } from 'src/app/common/baseClasses/baseTaskList';
import { EnumTaskTypes } from 'src/app/enums/tasks.enums';
import { SecurityService } from 'src/app/services/security.service';
import { TaskService } from 'src/app/services/tasks/task.service';
import { UrlParamService } from 'src/app/services/urlParam.service';
import { TaskUtil } from 'src/app/utils/task.util';

@Component({
  selector: 'app-tasks-all',
  templateUrl: './tasks-all.component.html',
  styleUrls: ['./tasks-all.component.scss'],
  providers: [UrlParamService]
})
export class TasksAllComponent extends BaseTaskList implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['importance', 'type', 'name', 'assignee', 'organization'];

  taskList$;

  constructor(
    private taskService: TaskService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService,
    public ss: SecurityService
  ) {  
    super(null, null, ss);

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit() {
    if (this.hasAccessProjects) {
      this.displayedColumns.push('project')
    }
      this.doGetInitalOptions();
  }

  doGetInitalOptions() {
    let defaultParams = {
      open: this.state
    }

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      }

      this.taskList$ = this.taskService.list(options).subscribe(data => {
        this.setListResponseData(data);
      });

      if ('create' in options.params) {
        // if the create param send (independently for the value we open the project creation)
        this.openTaskEdit();
      }
    })
  }
}

