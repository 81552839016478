<div class="section">
    <div class="header">
        <div class="title">
            <span>Module Hozzáférés</span>
        </div>
    </div>

    <div class="alert alert-info">
        <ul>
            <li>
                Felhasználó rendelkezik ADMIN hozzáféréssel, bármelyik Modulhoz van teljes hozzáférése.
            </li>
            <li>
                Telefonkönyv és a Termekek: Alap Modulok, ami azt jelenti hogy minden Felhasználónak van  teljes hozzáférése.
            </li>
            <li>
                Szervezet és a Konfiguració minden felhasznaló számára olvahato, de csak Admin hozzáféréssel lehet szerkeszteni
            </li>
            <li>
                Mindenki csak a saját Profil adatait tudja szerkesztni (+ Admin)
            </li>
            <li>
                Fentieken kívül ha egy Modul nincs az alábbi listában, akkor ahhoz nincs hozzáférése a Felhasználónak.
            </li>
        </ul>
    </div>

    <h4 class="text-grey text-center padding-20-0">
        A alábbi táblázat tartamlazza a Modulokat amelyek hozzáférése testreszabható:
    </h4>

     <!-- add new modules-->
     <div *ngIf="availableModuels">
        <mat-form-field appearance="outline" class="w300">
            <mat-label>Új Module Hozzáadása:</mat-label>
            <mat-select [(value)]="defaultSelect" (selectionChange)="add($event)">
                <mat-option [value]="defaultSelect">-Válassz-</mat-option>
                <ng-container *ngFor="let module of availableModuels">
                    <mat-option [value]="module">
                        <ng-container *ngTemplateOutlet="modulNames; context:{module: module}"></ng-container>
                    </mat-option>
                </ng-container >
            </mat-select>
        </mat-form-field>
    </div> 

    <ng-container *ngIf="userAccesses">
        <form  #f="ngForm">
        <div class="table-responsive">   
            <table class="table">
                <thead class="bg-light-grey">
                    <tr>
                        <td>Module</td>
                        <td>Hozzáférés</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let access of userAccesses">
                        <tr>
                            <td>
                                <app-static-true-false-icon [value]="access.level > 0"></app-static-true-false-icon> 

                                <ng-container *ngTemplateOutlet="modulNames; context: {module: access.module}"></ng-container>
                                <ng-container *ngIf="access.module == 'taskservices'">
                                    <div class="helper-text">
                                        Hozzáférés 3 Feladathoz es a "Szervíz és Tennivalók" részhez.<br>
                                        Feladatok: Szervízek, Tevékenységek és Kisszálítás a Vevőnek
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="access.module == 'taskclients'">
                                    <div class="helper-text">
                                        Hozzáférés a vevőkhöz kapcsolodó 5 Feladathoz <br>
                                        Feladatok: Ajánlatkérés Vevőtől, Ajánlat a Vevőnek, Megrendelés a Vevőtől, Visszaigazolás a Vevőnek, Átadás/Átvétel
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="access.module == 'taskvendors'">
                                    <div class="helper-text">
                                        Hozzáférés a beszállítókhoz kapcsolodó 2 Feladathoz <br>
                                        Feladatok: Ajánlatkérés Beszállítótól, Megrendelés a Beszállítótól
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="access.module == 'inventory'">
                                    <div class="helper-text">
                                        Hozzáférése és módosítása a raktár adatoknok (Kivétélezés, Bevételezés és Raktárkészlet)<br>
                                        Feladatok: Bevételezés a Raktárba, Kivételezés a Raktárból
                                        <br>
                                        <span class="text-red">Saját és Mások Adatának nincs értelme, bármilyen hozzáférési szint az adatok szerkesztését is jelenti</span>
                                    </div>
                                </ng-container>
                            </td>
                            <td class="w300">
                                <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="access.level" name="level{{access.id}}">
                                    <mat-option  [value]="0">Nincs Hozzáférés</mat-option>
                                    <mat-option  [value]="1">Hozzáférés a saját adatokhoz</mat-option>
                                    <mat-option  [value]="2">Hozzáférés minden adathoz</mat-option>
                                </mat-select>
                            </mat-form-field>
                            </td>
                           
                            <td>
                                <p class="flex">
                                    <span class="padding-l-10">
                                        <button mat-mini-fab  color="primary" (click)="save(access)"  [disabled]="f.invalid" matTooltip="Mentés">
                                            <mat-icon>save</mat-icon>
                                        </button>
                                    </span>
                                    <span class="padding-l-10">
                                        <button mat-icon-button  color="warn"  matTooltip="Törlés" (confirm-click)="delete(access)" confirm>
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </span>
                                    <span class="padding-l-10">
                                        <app-quick-log-info [data]="access"></app-quick-log-info>
                                    </span>
                                </p>              
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div> 
    </form>
    </ng-container>
</div>


<ng-template #modulNames let-module="module">
    <!--projekt-->
    <ng-container *ngIf="module == 'projects'">Projektek</ng-container>
    <ng-container *ngIf="module == 'templateing'">PDF/Email Sablon Beállítások</ng-container>
    <ng-container *ngIf="module == 'projectplanning'">Projekt Sablon Beállítások</ng-container>
    <!--grouped task-->
    <ng-container *ngIf="module == 'taskservices'">Szervíz Feladatok</ng-container>
    <ng-container *ngIf="module == 'taskclients'">Vevői  Feladatok</ng-container>
    <ng-container *ngIf="module == 'taskvendors'">Beszállítói Feladatok</ng-container>
    <ng-container *ngIf="module == 'inventory'">Raktár</ng-container>
</ng-template>


