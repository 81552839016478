import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DlgConfirmComponent } from '../shared/dialogs/dlg-confirm/dlg-confirm.component';
import { AutoUnsub } from '../utils/autoUnsubscribe';

@AutoUnsub()
@Directive({
    selector: '[confirm]'
})
export class ConfirmDirective {
    @Output('confirm-click') click: any = new EventEmitter();

    dlgConfirm$;

    constructor(
        private dialog: MatDialog
    ) {}

    @HostListener('click', ['$event']) clicked(e) {
        // open the dialog
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.dialog.open(DlgConfirmComponent, {width: '300px'});

        this.dlgConfirm$ = dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.click.emit();
            }
          });
    }
}