import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Category } from 'src/app/models/category.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';

@Component({
  selector: 'app-dlg-edit-category',
  templateUrl: './dlg-edit-category.component.html',
  styleUrls: ['./dlg-edit-category.component.scss']
})
export class DlgEditCategoryComponent implements OnInit {
  category: Category = null;

  constructor(
    private modelFactory: ModelFactory,
    public dialogRef: MatDialogRef<DlgEditCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.category = this.modelFactory.getNewModel(Category)
    if (data) {
      this.category = data;
    }
  }

  ngOnInit() {}

  created(event) {
    this.closeDlg(event)
  }

  updated(event) {
    this.closeDlg(event);
  }

  deleted(event) {
    this.closeDlg(event);
  }

  closeDlg(event) {
    this.dialogRef.close(event);
  }

}
