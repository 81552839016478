<ul class="breadcrumb"> 
    <li>Kontaktok</li>  
</ul>

<div class="flex"> 
    <button (click)="lookup()" mat-mini-fab color="primary" [disabled]="txtSearch === ''">
        <mat-icon matSuffix>search</mat-icon>
    </button>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Keresés</mat-label>
            <input [(ngModel)]="txtSearch" matInput placeholder="?" (keyup.enter)="lookup()">
            <mat-icon  class="pointer" matTooltip="Keresés Törlése" (click)="noSearch()" matSuffix>close</mat-icon>
        </mat-form-field>
    </div>
    
    <div class="full text-right">
        <button mat-mini-fab  color="accent" [routerLink]="['person']" matTooltip="Új Kontakt"> 
            <mat-icon>add</mat-icon>
        </button>
    </div> 
</div>

<mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Név</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-bottom-people [data]="row"></app-bottom-people>
            <span  (click)="open(row)" class="link">{{row.fullName}}</span>        
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="organisation">
        <mat-header-cell *matHeaderCellDef>Cég</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row._organization">
                <app-bottom-organisation [data]="row.organization"></app-bottom-organisation>      
                <span  (click)="openOrg(row)" class="link" *ngIf="row._organization">{{row._organization.name}}</span>
            </ng-container>        
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef>Beosztás</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.role}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="updated">
        <mat-header-cell *matHeaderCellDef>Frissítve</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.updated | date: 'yyyy-MM-dd'}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
    [showFirstLastButtons]="true"
></mat-paginator>
