import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Action } from 'src/app/models/projects/action.model';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';
import { BottomActionDetailComponent } from './bottom-action-detail/bottom-action-detail.component';

@AutoUnsub()
@Component({
  selector: 'app-bottom-action',
  templateUrl: './bottom-action.component.html',
  styleUrls: ['./bottom-action.component.scss']
})
export class BottomActionComponent implements OnInit {
  @Input() action: Action;
  @Output() onChange: EventEmitter<Action> = new EventEmitter;

  bottom$;

  constructor(
    private bottomSheet: MatBottomSheet,
  ) {
  }

  ngOnInit(): void {
  }

  open(): void {
    let bottomSheetRef = this.bottomSheet.open(BottomActionDetailComponent, {data: this.action});
    this.bottom$ = bottomSheetRef.instance.onChange.subscribe((action) => {
      this.action = action;
      this.onChange.emit(this.action);
    });
  }

}
