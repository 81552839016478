<div class="dlg">
    <div class="dlg-header">
        <div class="title font-red flex">
            <mat-icon class="text-red">pest_control</mat-icon>
            <div class="text-bold">A kutyafáját, valami történt ...</div>
        </div>
        <div class="close">
            <i class="material-icons" (click)="closeDialog()">close</i> 
        </div>
    </div>
    <div *ngIf="error.error" class="dlg-body">
        <!--TODO: replace this section based on the error code -->
        <div *ngIf="error.error.detail" class="alert alert-danger">{{error.error.detail}}</div>
        <mat-list>
            <ng-container *ngFor="let msgs of error.error | keyvalue">
                <ng-container *ngIf="isArray(msgs.value)">
                    <div class="text-help">{{msgs.key}}</div>
                </ng-container>
                <ng-container *ngFor="let msg of msgs.value">
                    <mat-list-item>
                        <mat-icon mat-list-icon class="text-secondary">navigate_next</mat-icon>
                        {{msg}}
                    </mat-list-item>
                </ng-container>
            </ng-container>
        </mat-list>
        
    </div>
    <div *ngIf="error.error" class="dlg-footer">
        <div class="text-help">{{now | date: 'yyyy-MM-dd hh:mm'}}</div>
        <div class="helper-text">{{error.url}}</div>
        <div class="helper-text">{{error.message}}</div>
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div>Hiba Kód: {{error.error.code}}</div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>{{error | json}}</p>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

