
export function downloadFile(data: any, file_name) {
    // based on a blob (response from backend, makes the file to appear as a download in the browser)
    const url= window.URL.createObjectURL(data);

    const a = document.createElement('a');
    a.href = url;
    a.download = file_name;
    a.click();

    setTimeout(() => {
        window.URL.revokeObjectURL(url);
        a.remove();
    }, 100)
  }