import { Component, Input, OnInit } from '@angular/core';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { OrganisationEmail } from 'src/app/models/organisation';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-organisation-email',
  templateUrl: './organisation-email.component.html',
  styleUrls: ['./organisation-email.component.scss']
})
export class OrganisationEmailComponent extends BaseEntity implements OnInit {
  @Input() organisationId: number;

  emails: OrganisationEmail[] = [];

  emailList$;
  emailCreate$;
  emailUpdate$;
  emailDelte$;

  constructor(
    private organisationService: OrganisationService,
    private modelFactory: ModelFactory
  ) {
    super();
  }

  ngOnInit() {
    let params = {organisation: this.organisationId};
    this.emailList$ = this.organisationService.listEmails(params).subscribe(resp => {
      this.emails = resp;
    })
  }

  save(email) {
    if (typeof email.id === 'string') {
      // create
      this.create(email);
    } else {
      this.update(email);
    }
  }

  create(email) {
    this.emailCreate$ = this.organisationService.createEmail(email).subscribe(res => {
      // fitler out the dummy email
      this.emails = this.emails.filter(e => e.email != res.email);
      // add the newly created to the list
      this.emails.push(res)
    })
  }

  update(email) {
    this.emailUpdate$ = this.organisationService.updateEmail(email).subscribe()
  }

  add() {
    // add new element to the list
    let email = this.modelFactory.getNewModel(OrganisationEmail);
    email.setOrganisationId(this.organisationId);
    email.setId(this.getNextId());
    this.emails.push(email);
  }

  delete(email) {
    if (typeof email.id === 'string') {
      // delete only from the list
      this.emails = this.emails.filter(e => e.id !== email.id);
    } else {
      // delete from the server
      this.emailDelte$ = this.organisationService.deleteEmail(email.id).subscribe(res => {
        this.emails = this.emails.filter(e => e.id !== email.id);
      });
    }
  }
}
