import { Component, OnInit, Input } from '@angular/core';
import { EnumTaskStatus } from 'src/app/enums/tasks/taskStatus.enum';

@Component({
  selector: 'app-status-container',
  templateUrl: './status-container.component.html',
  styleUrls: ['./status-container.component.scss']
})
export class StatusContainerComponent implements OnInit {
  @Input() status: EnumTaskStatus | string;
  // as defualt show the text beside of the dot
  @Input() showLabel: boolean = true;

  enumTaskStatus = EnumTaskStatus;

  constructor() { }

  ngOnInit() {}

}
