import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isLoggedIn = false;

  login$;

  constructor(
    private router: Router,
    private authService: AuthService
    ) { }
  
  username: string;
  password: string;

  grecaptcha: any = (window as any).grecaptcha;
  
  ngOnInit() {
    // if already logged in redirect to the dash
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['app/dash']);
      return;
    }
  }

  // submit the form for enter
  keyDownFunction(event) {
    if(event.keyCode == 13) {
      this.login();
    }
  }
  
  login() : void {
    let user = {
      email: this.username,
      password: this.password
    }

    this.grecaptcha.ready(() => {
      this.grecaptcha.execute(environment.reCaptchaSecret, {action: 'submit'}).then((token) => {
          
        user['reCaptcha'] = token;

        this.login$ = this.authService.login(user).subscribe((resp) => {
          if (resp) {
            this.router.navigate(['/app/dash']);
          } else {
           // not loged in
          }
        });
      });
    });
  }

}
