import {NgModule} from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule, MatList } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSliderModule }  from '@angular/material/slider';

@NgModule({
  imports: [
  CommonModule, 
  MatToolbarModule,
  MatButtonModule, 
  MatCardModule,
  MatInputModule,
  MatDialogModule,
  MatTableModule,
  MatMenuModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatPaginatorModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatSortModule,
  MatCheckboxModule,
  MatSelectModule,
  MatBottomSheetModule,
  MatBadgeModule,
  MatExpansionModule,
  MatDividerModule,
  MatAutocompleteModule,
  MatRadioModule,
  MatTabsModule,
  MatStepperModule,
  MatListModule,
  MatChipsModule,
  MatTreeModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSliderModule
  ],
  exports: [
  CommonModule,
   MatToolbarModule, 
   MatButtonModule, 
   MatCardModule, 
   MatInputModule, 
   MatDialogModule, 
   MatTableModule, 
   MatMenuModule,
   MatIconModule,
   MatProgressSpinnerModule,
   MatSidenavModule,
   MatPaginatorModule,
   MatSnackBarModule,
   MatTooltipModule,
   MatSortModule,
   MatCheckboxModule,
   MatSelectModule,
   MatBottomSheetModule,
   MatBadgeModule,
   MatExpansionModule,
   MatDividerModule,
   MatAutocompleteModule,
   MatRadioModule,
   MatTabsModule,
   MatStepperModule,
   MatListModule,
   MatChipsModule,
   MatTreeModule,
   MatDatepickerModule,
   MatNativeDateModule,
   MatSliderModule
   ],
})

export class CustomMaterialModule { }