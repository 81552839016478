import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Task } from 'src/app/models/tasks/task.model';
import { TaskNote } from 'src/app/models/tasks/taskNote.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { TaskNoteService } from 'src/app/services/tasks/taskNote.service';
import { DlgWysiwygComponent } from 'src/app/shared/dialogs/dlg-wysiwyg/dlg-wysiwyg.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-task-notes',
  templateUrl: './task-notes.component.html',
  styleUrls: ['./task-notes.component.scss']
})
export class TaskNotesComponent implements OnInit {
  @Input() task: Task;

  taskNotes: TaskNote[];

  // if true fetch by projects (all notes related to the project)
  fetchByProject: boolean = true;

  noteList$;
  noteCreate$;
  noteUpdate$;
  noteDel$;
  dlgW$;

  constructor(
    private taskNoteService: TaskNoteService,
    private modelFacotry: ModelFactory,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.fetch();
  }

  fetch() {
    let params = {};
    
    if (this.fetchByProject && this.task._action?.project) {
      params['project'] = this.task._action?.project;
    } else {
      this.fetchByProject = false;
      params['task'] = this.task.id
    }
    
    this.noteList$ = this.taskNoteService.list({params}).subscribe(res => this.taskNotes=res)
  }

  create() {
    let taskNote = this.modelFacotry.getNewModel(TaskNote);
    taskNote.task = this.task.id;

    this.editNote('', (note) => {
        taskNote.note = note;

        this.noteCreate$ = this.taskNoteService.create(taskNote).subscribe(res => {
          this.taskNotes.unshift(res);
         
        })
    });
  }

  update(taskNote) {
     this.editNote(taskNote.note, (note) => {
        taskNote.note = note;

        this.noteUpdate$ = this.taskNoteService.update(taskNote).subscribe(res => {
          let _taskNote = this.taskNotes.find(tN => tN.id === res.id);
          if (_taskNote) {
            _taskNote = res;
          }
        })
    });
  }

  delete(taskNote) {
    this.noteDel$ = this.taskNoteService.delete(taskNote).subscribe(() => {
      this.taskNotes = this.taskNotes.filter(tN => tN.id != taskNote.id);
    })
  }

  editNote(note, callback) {
    const dialogConfig = new MatDialogConfig();
    
    dialogConfig.data = (note) ? note : '';
    dialogConfig.minWidth = 450;
    dialogConfig.minHeight = 350;
    dialogConfig.disableClose =  true;
    
    const dialogRef = this.dialog.open(DlgWysiwygComponent, dialogConfig);

    this.dlgW$ = dialogRef.componentInstance.onSave.subscribe(content => {
        callback(content);
    });
  }



}
