<ul class="breadcrumb"> 
    <div class="flex">
        <span>Raktár Mozgások</span> 
        <span>
            <mat-icon matTooltip="Keresés Raktári Termék vagy Időintervallum szerint, egyszerre nem lehetséges!">info</mat-icon>
        </span>   
    </div>  
</ul>

<div class="flex flex-right">
    <span class="margin-5">
        <button mat-mini-fab color="accent"  matTooltip="Bevételezés" (click)="openInventoryTransactionEdit('in')"> 
            <mat-icon>vertical_align_bottom</mat-icon>
        </button>
    </span>
    <span class="margin-5">
        <button mat-mini-fab color="warn"  matTooltip="Kivételezés" (click)="openInventoryTransactionEdit('out')"> 
            <mat-icon>vertical_align_top</mat-icon>
        </button>
    </span>
    <span class="margin-5">
        <button mat-mini-fab color="primary"  matTooltip="CSV exportálás szűrés szerint: Raktári Termék vagy Időintervallum. Előugró ablakokat engedélyezni kell a Chrome-ban!" (click)="csvExport()"> 
            <mat-icon>file_present</mat-icon>
        </button>
    </span>
</div>

<div class="flex block-md padding-5-0"> 
    <div  class="flex full">
        <!--date range filters-->
        <mat-form-field appearance="outline" class="w300">
        <mat-label>Időintervallum</mat-label>
        <mat-date-range-input [formGroup]="searchRange" [rangePicker]="rangePicker" (click)="rangePicker.open()">
        <input matStartDate  formControlName="start" placeholder="Tól">
        <input matEndDate formControlName="end" placeholder="Ig">
        </mat-date-range-input>
        <mat-datepicker-toggle matPrefix [for]="rangePicker"></mat-datepicker-toggle>
        <mat-icon class="pointer text-red" matTooltip="összes eseménye, dátum szűrés nélkül." (click)="removeSelectedRange()" matSuffix>close</mat-icon>
        <mat-date-range-picker #rangePicker>
        <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>Vissza</button>
            <button mat-raised-button color="primary" matDateRangePickerApply (click)="searchByDate()">Keresés</button>
        </mat-date-range-picker-actions>
        </mat-date-range-picker>
        </mat-form-field>
   </div>
   <div class="w300 flex"> 
        <!--item filter-->
        <app-item-selector (onChange)="searchItem($event)" [item]="item"></app-item-selector>
        <ng-container *ngIf="item">
            <mat-icon class="margin-l-20 text-red pointer" (click)="removeItem()">close</mat-icon>
        </ng-container>
    </div>
</div>


<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef>Dátum</mat-header-cell>
        <mat-cell *matCellDef="let row">
           <span class="helper-text">{{row.updated | dateTime}}</span>
        </mat-cell>
    </ng-container>


    <ng-container matColumnDef="direction">
        <mat-header-cell *matHeaderCellDef>Betét/Kivét</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span  class="text-green flex" *ngIf="row.direction == 'in'">
                <mat-icon>vertical_align_bottom</mat-icon>
                <span>Betét</span>
            </span>
            <span class="text-red flex" *ngIf="row.direction == 'out'">
                <mat-icon>vertical_align_top</mat-icon>
                <span>Kivét</span>
            </span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="item">
        <mat-header-cell *matHeaderCellDef>Raktári Termék</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-item-container [item]="row._item"></app-item-container>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef>Mennyiség</mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{row.quantity}} {{row._item._product.unitHu}}
            &nbsp;
            <ng-container *ngIf="row.foreignItem">
                <span  matTooltip="Idegen Termék">
                    <app-static-true-false-icon [value]="!row.foreignItem"></app-static-true-false-icon>
                </span>
            </ng-container>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef>Egység Ár</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-currency-container [value]="row.price" [currency]="'eur'"></app-currency-container>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="reason">
        <mat-header-cell *matHeaderCellDef>Mozgás oka</mat-header-cell>
        <mat-cell *matCellDef="let row">
                <ng-container *ngIf="row.reason=='builtin'">
                    <ng-container *ngIf="row.builtin" class="helper-text">
                        <app-item-container [item]="row._builtin"></app-item-container>
                    </ng-container>
                </ng-container>
            <ng-container *ngIf="row.reason=='company'">
                <ng-container *ngIf="row.organization">
                    <a href="#" class="link"  routerLink="/app/contacts/organisations/organisation"  [queryParams]="{id: row.organization}">{{row._organization.name}}</a>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="row.reason=='broken'"><mat-icon>heart_broken</mat-icon> Eltört</ng-container>
            <ng-container *ngIf="row.reason=='sent_back'"><mat-icon>outbox</mat-icon> Visszaküldve</ng-container>
            <ng-container *ngIf="row.reason=='correction'"><mat-icon>auto_fix_high</mat-icon> Korrekció</ng-container>        
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="log">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-quick-log-info [data]="row"></app-quick-log-info>&nbsp;
        </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
></mat-paginator>





