<p class="p-10-0">
    <mat-checkbox name="product-image" [(ngModel)]="defaultConfig.showProductImage">Kép  Beillesztése a Termékről</mat-checkbox>
    <mat-icon matTooltip="Az Árucikk Csoportnál feltöltött képek beillesztése a PDF-be">info</mat-icon>
</p>
<p class="p-10-0">
    <mat-checkbox name="product-marketing" [(ngModel)]="defaultConfig.showProductMarketing">Marketing Leírás beillesztése a Termékről</mat-checkbox>
    <mat-icon matTooltip="Gép Ajánlatnál csak a gép adatai kerülnek beillesztésra. Alkatrész Ajánlatnál az összes listában lévő alkatrész leírása bekerül.">info</mat-icon>
</p>
<p class="p-10-0">
    <mat-checkbox name="cateogry-marketing" [(ngModel)]="defaultConfig.showCategoryMarketing">Marketing Leírás beillesztése a Kategóriáról</mat-checkbox>
    <mat-icon matTooltip="Gép Ajánlat kérésnél a géphez tartozó kategoria Marketing lLeírása is bekerül a PDF fájlba">info</mat-icon>
</p>

