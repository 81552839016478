export enum EnumTemplateCategory {
  // similar to task types but can be more extensive
  TaskCustomerOffer = 'taskcustomeroffer',
  TaskVendorInterest = 'taskvendorinterest',
  TaskVendorOrder = 'taskvendororder',
  TaskCustomerConfirmation = 'taskcustomerconfirmation',

  // not allowed to create a template in the UI
  // (these are the implicit templates)
  BreakDownReprot = 'breakdownreport',
  ServiceReport = 'servicereport',
  TaskCustomerDeliver = 'taskcustomerdeliver',
  TaskCustomerHandover = 'taskcustomerhandover'
  }