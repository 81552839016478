import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  color = '#c4c4c4';
  mode = 'indeterminate';
  value = 50;

  isLoading: Subject<boolean>;

  constructor(private loaderService: LoaderService){}

  ngOnInit() {
    this.isLoading = this.loaderService.isLoading;
  }

}
