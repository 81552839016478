<ul class="breadcrumb"> 
    <li>Raktár</li>  
</ul>

<div class="flex block-sm padding-10-0"> 
    <div class="flex full block-md"> 
        <div class="flex">
            <button (click)="lookup()" mat-mini-fab color="primary" [disabled]="txtSearch === ''">
                <mat-icon matSuffix>search</mat-icon>
            </button>
            <div class="padding-l-10">
                <mat-form-field appearance="standard">
                    <mat-label>Keresés</mat-label>
                    <input [(ngModel)]="txtSearch" matInput placeholder="?" (keyup.enter)="lookup()">
                    <mat-icon  class="pointer" matTooltip="Keresés Törlése" (click)="noSearch()" matSuffix>close</mat-icon>
                </mat-form-field>
            </div>
        </div>
        
        <div class="padding-l-10">
            <mat-radio-group (change)="changeType($event)">
                <mat-radio-button [checked]="productType === 'machine'" value="machine">Gép</mat-radio-button>
                <mat-radio-button [checked]="productType === 'part'" value="part">Alkatrész</mat-radio-button>
              </mat-radio-group>
        </div>
    </div>
    <div>
        <span class="margin-5">
            <button mat-mini-fab color="accent"  matTooltip="Bevételezés" (click)="openInventoryTransactionEdit('in')"> 
                <mat-icon>vertical_align_bottom</mat-icon>
            </button>
        </span>
        <span  class="margin-5">
            <button mat-mini-fab color="warn"  matTooltip="Kivételezés" (click)="openInventoryTransactionEdit('out')"> 
                <mat-icon>vertical_align_top</mat-icon>
            </button>
        </span>
        <span class="margin-5">
            <button mat-mini-fab color="primary"  matTooltip="CSV Export: Teljes Raktárkészlet exportálása. Előugró ablakokat engedélyezni kell a Chrome-ban!" (click)="csvExport()"> 
                <mat-icon>file_present</mat-icon>
            </button>
        </span>
    </div>
</div>

<div class="flex helper-text block-sm">
    <div class="padding-10"><mat-icon matTooltip="Sorba Rendezés">sort_by_alpha</mat-icon></div>
    <mat-radio-group (change)="changeType($event)">
        <mat-radio-button [checked]="ordering === 'updated'" value="updated" (click)="changeSortBy('updated')">Frissítve</mat-radio-button>
        <mat-radio-button [checked]="ordering === 'name'" value="name" (click)="changeSortBy('name')">Név</mat-radio-button>
        <mat-radio-button [checked]="ordering === 'location'" value="location" (click)="changeSortBy('location')">Polc Szám</mat-radio-button>
      </mat-radio-group>
</div>

<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="item">
        <mat-header-cell *matHeaderCellDef>Raktári Termék</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="link" (click)="open(row)">
                <app-item-container [item]="row._item"></app-item-container>
            </span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="stock">
        <mat-header-cell *matHeaderCellDef>Mennyiség</mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{row.stock}} {{row._item._product.unitHu}}
            &nbsp;
            <ng-container *ngIf="row.foreignItem">
                <span  matTooltip="Idegen Termék">
                    <app-static-true-false-icon [value]="!row.foreignItem"></app-static-true-false-icon>
                </span>
            </ng-container>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef>Egység Ár</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-currency-container [value]="row.price" [currency]="'eur'"></app-currency-container>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef>Polc szám</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="helper-text">
                {{row._item.location}}
            </span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="transaction">
        <mat-header-cell *matHeaderCellDef>Bevét/Kivét</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <div>
                <span class="margin-r-10">
                    <button mat-icon-button color="accent"  matTooltip="Bevételezés" (click)="openInventoryTransactionEdit('in', row._item)"> 
                        <mat-icon>vertical_align_bottom</mat-icon>
                    </button>
                </span>
                <button mat-icon-button color="warn"  matTooltip="Kivételezés" (click)="openInventoryTransactionEdit('out', row._item)"> 
                    <mat-icon>vertical_align_top</mat-icon>
                </button>
            </div>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
    [showFirstLastButtons]="true"
></mat-paginator>





