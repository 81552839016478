/**
 * List a specific type of Generated PDF 
 * can be more tha none type per Task
 *  */

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { EnumTaskPdfGenerationStatus } from 'src/app/enums/tasks/taskPdfGenerationStatus.enum';
import { Organisation } from 'src/app/models/organisation';
import { Task } from 'src/app/models/tasks/task.model';
import { TaskPdfService } from 'src/app/services/tasks/taskPdf.service';
import { People } from 'src/app/models/people.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EnumTemplateCategory } from 'src/app/enums/templates/templateCategory.enum';
import { EnumTemplateType } from 'src/app/enums/templates/templateType.enum';
import { EnumLanguage } from 'src/app/enums/languages.enum';
import { EnumCurrency } from 'src/app/enums/currencies.enum';
import { TaskProduct } from 'src/app/models/tasks/taskProduct.model';
import { FileUploadService } from 'src/app/services/fileUpload.service';
import { EmailSendingComponent } from 'src/app/shared/dialogs/email-sending/email-sending.component';
import { TemplateSection } from 'src/app/models/template.model';
import { EmailService } from 'src/app/services/email.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-task-pdfs',
  templateUrl: './task-pdfs.component.html'
})
export class TaskInterestPdfsComponent extends BaseEntity implements OnInit {
  @Input() task: Task
  @Input() templateCategory: EnumTemplateCategory | string;
  // disabel email sendign option
  @Input() allowedEmailSending = true;
  // allow generate pdf from another pdf
  @Input() allowGenerateNewPdf = true;

  // create a new PDF based on the the already created one
  @Output() onCreate: EventEmitter<any> = new EventEmitter();

  pdfs: any = [];
  // set to true if the pdf creation is clicked to load all of the data what need to setup the PDF}}uu
  createPdf = false;
  enumTaskPdfGenerationSatus = EnumTaskPdfGenerationStatus;
  organizastion: Organisation;
  products: TaskProduct[] =[];
  contacts: People[];
  EnumLanguage = EnumLanguage;
  EnumCurrency = EnumCurrency;
  EnumTemplateCategory = EnumTemplateCategory;
  EnumTemplateType = EnumTemplateType;
  templateSections: TemplateSection[];

  // contains the data to generate the pdf

  list$;
  fileDown$;
  emailSend$;
  pdfDelete$;
  emailList$;

  constructor(
    private taskPdfService: TaskPdfService,
    private dialog: MatDialog,
    private fileService: FileUploadService,
    private emailService: EmailService,
  ) {
    super()
  }

  ngOnInit() {
    this.loadPdfs();
  }

  loadPdfs() {
    let params = {
      task: this.task.id, 
      category: this.templateCategory
    };

    this.list$ = this.taskPdfService.list({params}).subscribe(resp => {
      this.pdfs = resp;
    })
  }

  reLoadPdfs() {
    this.loadPdfs();
  }

  sendOffer(pdf) {
    //1. get the file from the server
    this.fileDown$ = this.fileService.download(pdf.file).subscribe(file => {
      // 2. open the Email sending dialog and pass the file
      let options = {
        files: [file],
        language: this.task.language,
        templateCategory: this.templateCategory,
        taskPdf: pdf // taskPDf
      };
      this.openEmailSendingDlg(options);
    });
  }

  openEmailSendingDlg(options) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.data = options;
    dialogConfig.minHeight = 300;
    dialogConfig.disableClose =  true;

    const dialogRef = this.dialog.open(EmailSendingComponent, dialogConfig);

    this.emailSend$ = dialogRef.componentInstance.onSent.subscribe(() => {
      // set the task pdf to sent
      options.taskPdf.sent = true;
      options.taskPdf.emailLogs = null;
    });

  }

  deletePdf(pdf) {
    this.pdfDelete$ = this.taskPdfService.delete(pdf).subscribe(() => {
      this.pdfs = this.pdfs.filter(p => p.id !== pdf.id);
    });
  }

  checkEmailLog(taskPdf) {
    let params = {
      taskPdf: taskPdf.id,
      limit: 1000
    }
      // fetch task Pdf emails and stamp on the object
      this.emailList$ = this.emailService.list({params}).subscribe(emailLogs => {
          taskPdf.emailLogs = emailLogs.results;
      });
  }

  createNewPdf(taskPdf) {
      this.onCreate.emit(taskPdf)
  }
}
