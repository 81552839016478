<ng-container *ngIf="data == enumNotificationType.Updated">
    <span>Frissítve</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.Created">
    <span>Létrehozva</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.Deleted">
    <span>Törölve</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.Success">
    <span>Mentve</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.Error">
    <span>Hiba történt</span>
</ng-container>


<ng-container *ngIf="data == enumNotificationType.Dirty">
    <span>Ne felejts el menteni!</span>
</ng-container>

<!--password-->
<ng-container *ngIf="data == enumNotificationType.PassswordNotEqual">
    <span>A két jelszó nem egyforma!</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.PasswordMinLength">
    <span>Minimum hossz 8!</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.PasswordLowerCase">
    <span>Nem tartalmaz kisbetűt</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.PasswordUpperCase">
    <span>Nem tartalmaz nagybetűt</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.PasswordNumber">
    <span>Nem tartalmaz számot!</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.PasswordSpecialChar">
    <span>Nem tartalmaz különleges karaktert!</span>
</ng-container>
<!-- Email-->
<ng-container *ngIf="data == enumNotificationType.EmailInvalid">
    <span>Hibás Email</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.EmailSent">
    <span>Email Elküldve</span>
</ng-container>
<!--product-->
<ng-container *ngIf="data == enumNotificationType.ProductInList">
    <span>Árucikk már szerepel a listában</span>
</ng-container>
<!--Item-->
<ng-container *ngIf="data == enumNotificationType.ItemMissing">
    <span>Termék Hiányzik</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.ItemInList">
    <span>Termék már szerepel a listában</span>
</ng-container>
<!-- Org -->
<ng-container *ngIf="data == enumNotificationType.OrgMissing">
    <span>Cég Hiányzik</span>
</ng-container>
<!-- Template -->
<ng-container *ngIf="data == enumNotificationType.TemplateCategoryMissing">
    <span>Kategória Hiányzik</span>
</ng-container>
<!--Task-->
<ng-container *ngIf="data == enumNotificationType.TaskInactiveMachineOnly">
    <span>Minta Termék csak Gép lehet</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.NoTaskRelatedOffers">
    <span>Nincs Kiajánlható Termék</span>
</ng-container>
<ng-container *ngIf="data == enumNotificationType.ItemInTaskProduct">
    <span>A Termék már szereple a listában</span>
</ng-container>
<!--Inventory Transaction-->
<ng-container *ngIf="data == enumNotificationType.InventoryTransactionCsvExportNoFilter">
    <span>CSV exportáláshoz Dátum vagy Raktári Termék szűrés szükséges</span>
</ng-container>

<!--security-->
<ng-container *ngIf="data == enumNotificationType.SecurityModuleNoAccess">
    <span>Nincs Hozzáférésed a Modulhoz</span>
</ng-container>