<div class="flex flex-align-item-start padding-b-20 datetime-range-selector">
    <div>
        <input type="date" id="date" [(ngModel)]="strDate" name="date" (change)="change()">
    </div>
    <div class="padding-l-10" *ngIf="!dateOnly">
        <input [disabled]="!strDate" type="time" id="start" [(ngModel)]="strStartTime" name="start" (focusout)="change()" step="900">
        <div  class="text-secondary text-tiny">Kezdés</div>
    </div>
    <div class="padding-l-10" *ngIf="!dateOnly">
        <input [disabled]="!strDate" type="time" id="end" [(ngModel)]="strEndTime" name="end" (focusout)="change()" step="900">
        <div  class="text-secondary text-tiny">Vége</div>
    </div>
    <mat-icon class="font-grey pointer margin-l-10" (click)="setNow()" matTooltip="Most">flag_circle</mat-icon>
</div>
