<ul class="breadcrumb"> 
    <li>Esemenyék és Tennivalók</li>  
</ul>

<!--filters-->
<mat-form-field appearance="outline" class="w350 m-10-0">
    <mat-label>Keresés Időintervallum Szerint</mat-label>
    <mat-date-range-input [formGroup]="searchRange" [rangePicker]="rangePicker" (click)="rangePicker.open()">
      <input matStartDate  formControlName="start" placeholder="Tól">
      <input matEndDate formControlName="end" placeholder="Ig">
    </mat-date-range-input>
    <mat-hint class="link" (click)="defaultDateRange()">Alapértelmezett (+/- 14 nap)</mat-hint>
    <mat-datepicker-toggle matPrefix [for]="rangePicker"></mat-datepicker-toggle>
    <mat-icon class="pointer text-red" matTooltip="összes eseménye, dátum szűrés nélkül." (click)="removeSelectedRange()" matSuffix>close</mat-icon>
    <mat-date-range-picker #rangePicker>
      <mat-date-range-picker-actions>
        <button mat-button matDateRangePickerCancel>Vissza</button>
        <button mat-raised-button color="primary" matDateRangePickerApply (click)="searchByDate()">Keresés</button>
      </mat-date-range-picker-actions>
    </mat-date-range-picker>
  </mat-form-field>




<!--table-->
<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="done">
        <mat-header-cell *matHeaderCellDef>Lezárva</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <app-static-true-false-icon [value]="row.done"></app-static-true-false-icon>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="datetime">
        <mat-header-cell *matHeaderCellDef>
            <div class="h5">
                <span matTooltip="Csökkenő sorrendben a tervezett időpont szerint" class="badge badge-pill badge-bg badge-warning">
                    {{count}} darab
                </span>
            </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.done">
                <div class="text-bold">{{row.actualStart | dateDate}}</div>
                <div class="helper-text">
                     <div>Kezdés: {{row.actualStart | dateTime}}</div>
                     <div>Befejezés: {{row.actualEnd | dateTime}}</div>
                </div>
            </ng-container>
            <ng-container *ngIf="!row.done">
                <div class="text-bold">{{row.start | dateDate}}</div>
                <div class="helper-text">
                     <div>Kezdés: {{row.start | dateTime}}</div>
                     <div>Befejezés: {{row.end | dateTime}}</div>
                </div>
            </ng-container>
         
        </mat-cell>
    </ng-container>

   
    <ng-container matColumnDef="assignee">
        <mat-header-cell *matHeaderCellDef>Felhasználó</mat-header-cell>
        <mat-cell *matCellDef="let row">
           <app-avatar [user]="row._assignee"></app-avatar>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="task">
        <mat-header-cell *matHeaderCellDef>Feladat</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <div class="text-yellow text-small">
                <app-task-type-container [type]="row._task.taskType"></app-task-type-container>
            </div>
            <div matTooltip="{{row.problem}}">{{row.name}}</div>
            <div class="flex">
                <app-status-container [status]="row._task.status" [showLabel]="false"></app-status-container>
                <span class="link" (click)="openTask(row._task)" matTooltip="{{row._task.name}}">{{row._task.name | truncate: 5}} </span>
            </div>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
            <button mat-mini-fab  color="primary" (click)="openEdtiTaskEventDlg(row)" matTooltip="Szerkesztés">
                <mat-icon>edit</mat-icon>
            </button>
        
        </mat-cell>
    </ng-container>



    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>


<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
></mat-paginator>


