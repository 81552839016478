import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnumTaskTypes } from 'src/app/enums/tasks.enums';
import { Task } from 'src/app/models/tasks/task.model';
import { TaskEvent } from 'src/app/models/tasks/taskEvent.model';
import { TaskEventService } from 'src/app/services/tasks/taskEvent.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-dlg-edit-task-event',
  templateUrl: './dlg-edit-task-event.component.html',
  styleUrls: ['./dlg-edit-task-event.component.scss']
})
export class DlgEditTaskEventComponent implements OnInit {
  taskEvent: TaskEvent;
  task: Task;

  // predefined Activity types for TaskActivity
  activityTypes = [
    'Anyagbeszerzés',
    'Megbeszélés',
    'Raktártakarítás, Rendezés',
    'Gépmozgatás',
    'Vásár, Kiállítás',
    'Tanfolyam',
    'Vevőlátogatás',
    'Telefon Marketing'
  ]

  enumsTaskTypes = EnumTaskTypes;

  showTabIndex = 0;

  dayEvent: boolean = false; // event is a full day event the hours doesn't count 

  @Output() onChange: EventEmitter<TaskEvent> = new EventEmitter();
  @Output() onDelete: EventEmitter<TaskEvent> = new EventEmitter();

  eventCreate$;
  eventUpdate$;
  eventDel$;

  constructor(
    private taskEventService: TaskEventService,
    public dialogRef: MatDialogRef<DlgEditTaskEventComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.taskEvent = data.taskEvent;
    this.task = this.taskEvent._task;

    if (this.task.taskType == EnumTaskTypes.TaskCustomerDeliver) {
      this.dayEvent = true;
    }

    if (this.taskEvent.done) {
      // show second tab to finalize the Event
      this.showTabIndex = 1;
    }
  }

  ngOnInit(): void {
  }

  save() {
    (this.taskEvent.id) ? this.update() : this.create();
  }

  create() {
    this.eventCreate$ = this.taskEventService.create(this.taskEvent).subscribe(taskEvent => {
      this.taskEvent = taskEvent;
      this.onChange.emit(this.taskEvent);
      this.dialogRef.close();
    })
  }

  update() {
    this.eventUpdate$ = this.taskEventService.update(this.taskEvent).subscribe(taskEvent => {
      this.taskEvent = taskEvent;
      this.onChange.emit(this.taskEvent);
      this.dialogRef.close();
    })
  }

  delete() {
    this.eventDel$ = this.taskEventService.delete(this.taskEvent).subscribe(() => {
      this.onDelete.emit(this.taskEvent);
      this.dialogRef.close();
    })
  }

  setPlannedStartEnd(data: any) {
    this.taskEvent.start = data.start;
    this.taskEvent.end = data.end;
  }

  setActualStartEnd(data: any) {
    this.taskEvent.actualStart = data.start;
    this.taskEvent.actualEnd = data.end;
  }

  setAssignedUser(user) {
    this.taskEvent.assignee = user.id;
    this.taskEvent._assignee = user;
  }

  setPlannedForActual() {
    this.taskEvent.actualStart = this.taskEvent.start;
    this.taskEvent.actualEnd = this.taskEvent.end;
  }

}
