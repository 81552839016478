import { Pipe, PipeTransform } from '@angular/core';

// full formatted date time
@Pipe({name: 'dateTime'})
export class DateTime implements PipeTransform {
  transform(dt: Date): string {
    if (!dt) return null;
    
    let newStr: string = dt.toLocaleString('hu-HU', { 
      year: 'numeric', 
      month: 'numeric', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'});
    
    return newStr;
  }
}