import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import { TaskCustomerInterest } from 'src/app/models/tasks/taskCustomerInterest.model';
import { TaskCustomerOrder } from 'src/app/models/tasks/taskCustomerOrder.model';
import { SecurityService } from 'src/app/services/security.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';
import { environment } from 'src/environments/environment';

@AutoUnsub()
@Component({
  selector: 'app-quick-log-info-details',
  templateUrl: './quick-log-info-details.component.html',
  styleUrls: ['./quick-log-info-details.component.scss']
})
export class QuickLogInfoDetailsComponent implements OnInit {
  baseUrl = environment.baseUrl;
  isAdmin = false;
  audits: any = null;

  http$;

  constructor(
    private securityService: SecurityService,
    private http: HttpClient,
    private ref: ChangeDetectorRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) { }

  URL_AUDIT = '/config/audits/';

  ngOnInit() {
    this.isAdmin = this.securityService.user.admin;
  }

  loadAudit() {
    let model = this.getModelString()
    let id = this.data.id;
    if (this.data instanceof TaskCustomerInterest) {
      id = this.data.task;
    } else if (this.data instanceof TaskCustomerOrder) {
      id = this.data.task;
    }

    let params = {
      model: model,
      id: id
    }

    let url = this.baseUrl + this.URL_AUDIT;

    this.http$ = this.http.get<any>(url, {params}).subscribe((res) => {
        this.audits = res.logs;
        this.formatAudit();
        this.ref.markForCheck();
    });
  }

  formatAudit() {
    this.audits.forEach(audit => {
      audit.when = new Date(audit.when)
    })
  }

  getModelString() {
    let modelType = this.data.constructor.name;
    return modelType.toLowerCase();
  }

}
