import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnumTaskImportance } from 'src/app/enums/tasks/taskImportance.enums';

@Component({
  selector: 'app-importance-selector',
  templateUrl: './importance-selector.component.html',
  styleUrls: ['./importance-selector.component.scss']
})
export class ImportanceSelectorComponent implements OnInit {
  @Input() importance: EnumTaskImportance | number = null;
  // importance selector show all options or not
  @Input() showAll: boolean = false;

  @Output() onChange: EventEmitter<number> = new EventEmitter();

  enumTaskImportance = EnumTaskImportance;

  constructor() { }

  ngOnInit() {
  }

  update() {
    this.onChange.emit(this.importance);
  }

}
