import { Component, OnInit } from '@angular/core';
import { EnumLanguage } from 'src/app/enums/languages.enum';
import { AuthService } from 'src/app/services/auth.service';
import { FileUploadService } from 'src/app/services/fileUpload.service';
import { ProfileService } from 'src/app/services/profile.service';
import { UsersService } from 'src/app/services/users.service';
import { EnumFileUploadType } from 'src/app/enums/fileUploadType.enum';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  data:any = {};
  user: any = null;
  profile: any = null;
  enumLanguage = EnumLanguage;

  profile$;
  profileUpdate$;
  profileFileUpload$;

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private profileService: ProfileService,
    private fileUploadService: FileUploadService,
  ) {
    //get the user 
    this.user = this.authService.user;
    // get the profile from special endpoint
    this.fetchProfile();
  }

  ngOnInit() {
  }

  fetchProfile() {
    this.profile$ = this.profileService.getByUser(this.user.id).subscribe(res => {
      this.profile = res;
      this.buildModel();
    });
  }

  buildModel() {
    // building a fake model with the editable User and Profile fields
    this.data = {
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      language: this.user.language,
      phonePersonal: this.profile.phonePersonal,
      phoneCompany: this.profile.phoneCompany,
      address: this.profile.address,
      timezone: this.profile.timezone,
      signature: this.profile.signature
    }
  }

  save() {
    this.profileUpdate$ = this.profileService.updateMyProfile(this.data).subscribe();
  }

  onSignatureUpload(formData) {
    this.profileFileUpload$ = this.fileUploadService.upload(formData, EnumFileUploadType.ProfileSignature, this.profile).subscribe(res => {
      this.fetchProfile();
    });
  }

}
