import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseList } from '../common/baseClasses/baseList';
import { EnumProductType } from '../enums/productType.enum';
import { InventoryService } from '../services/inventory.service';
import { UrlParamService } from '../services/urlParam.service';
import { DlgEditInventoryTransactionComponent } from '../shared/dialogs/edit/dlg-edit-inventory-transaction/dlg-edit-inventory-transaction.component';
import { AutoUnsub } from '../utils/autoUnsubscribe';
import { downloadFile } from '../utils/file.util';

@AutoUnsub()
@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
  providers: [UrlParamService]
})
export class InventoryComponent extends BaseList implements OnInit  {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['item', 'stock', 'price', 'location', 'transaction'];

  productType = EnumProductType.Machine;

  ordering = 'updated' // name vs location vs updated

  invList$;
  dlgInvEdit$;

  constructor(
    private inventoryService: InventoryService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService,
    private dialog: MatDialog
  ) {
    super();

     // set data for the base service
     this.setUrlParamService(this.urlParamService);
     this.setActivatedRoute(this.route);
  }

  ngOnInit() {
    this.doGetInitalOptions();
}

doGetInitalOptions() {
  let defaultParams = {
    productType: this.productType,
    ordering: this.ordering
  }

  this.getInitialOptions(defaultParams, (options) => {
    // if the default page setup changed based on the url we have to set them
    for (const key in options.params) {
      this[key] = options.params[key];
    }

    this.invList$ = this.inventoryService.list(options).subscribe(data => {
      this.setListResponseData(data);
     });

     if ('create' in options.params) {
      // if the create param send (independently for the value we open the project creation)
      this.openInventoryTransactionEdit('in');
    }
  })
}

open(inventory) {
    this.router.navigate(['transactions'],  {
      queryParams: {item: inventory.item}, 
      relativeTo: this.route  
    });
  }

  openInventoryTransactionEdit(direction, item=null) {
    // @direction of the transaction
    // @item if we want to work with a particular item 
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '450px';
      
    dialogConfig.data = {
      direction: direction,
      item: item
    };

    const dialogRef = this.dialog.open(DlgEditInventoryTransactionComponent, dialogConfig);

    this.dlgInvEdit$ = dialogRef.componentInstance.onChange.subscribe(inventoryTransaction => {
      // let's reaload the page with the params in the url
      this.doGetInitalOptions();

    });
  }

  lookup() {
    this.doSearch();
  }

  changeType(event) {
    // reset
    this.pageIndex = 0;
    this.txtSearch = '';
    this.urlParamService.firstPage();

    // set
    this.productType = event.value;
    let param = {productType: this.productType};
    this.urlParamService.update(param);
    this.urlParamService.set();
  }

  changeSortBy(sortBy) {
    // reset
    this.pageIndex = 0;
    this.txtSearch = '';
    this.urlParamService.firstPage();

    // set
    this.ordering = sortBy;

    let param = {
      productType: this.productType,
      ordering: this.ordering
    };

    this.urlParamService.update(param);
    this.urlParamService.set();
  }

  csvExport() {
    this.inventoryService.csvExport().subscribe(res => {
      let fileName = 'ich_raktarkeszlet_' + new Date().toISOString() + '.csv';
      downloadFile(res, fileName)
    })
  }
}
