import { Component, OnInit } from '@angular/core';
import { Organisation } from 'src/app/models/organisation';
import { ActivatedRoute, Router } from "@angular/router";
import { OrganisationService } from 'src/app/services/organisation.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PeopleService } from 'src/app/services/people.service';
import { People } from 'src/app/models/people.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { EnumCountry } from 'src/app/enums/countries.enums';
import { DlgSearchPeopleComponent } from 'src/app/shared/dialogs/search/dlg-search-people/dlg-search-people.component';
import { DlgEditPersonComponent } from 'src/app/shared/dialogs/edit/dlg-edit-person/dlg-edit-person.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-page-organisation',
  templateUrl: './page-organisation.component.html',
  styleUrls: ['./page-organisation.component.scss']
})
export class PageOrganisationComponent implements OnInit {
  organisation: Organisation = null;
  persons: People[];

  listPerson$;
  dlgSearchPeople$;
  dlgEditPerson$;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private organisationService: OrganisationService,
    private personService: PeopleService,
    private dialog: MatDialog,
    private modelFactory: ModelFactory
  ) { }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id')

    if (id) {
      this.organisationService.get(id).subscribe(i => {
        this.organisation = i;
      });

      this.loadPersons(id);
    } else {
      this.organisation = this.modelFactory.getNewModel(Organisation);
      // default country is Hungary
      this.organisation.addressCountry = EnumCountry.Hungary;
    }
  }

  created(entity) {
    this.organisation = entity;
     //  navigate to the page
     this.router.navigate(['./'],  {
      queryParams: {id: this.organisation.id}, 
      relativeTo: this.route  
    });
  }

  deleted(event) {
       //  navigate to the page
       this.router.navigate(['../../organisations'],  {
        relativeTo: this.route  
      });
  }

  updated(event) {
    //
  }

  loadPersons(orgId) {
      let options = {params: {organisation: orgId, limit: 1000}};
      this.listPerson$ = this.personService.list(options).subscribe(listResponse => {
          this.persons = listResponse.results;
      });
  }

  openPerson(person) {
     //  navigate to the page
     this.router.navigate(['../../persons/person'],  {
      queryParams: {id: person.id}, 
      relativeTo: this.route  
    });
  }

  addPersonDlg() {
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.data = {
      name: '',
      organisation: this.organisation
    };

    const dialogRef = this.dialog.open(DlgSearchPeopleComponent, dialogConfig);

    this.dlgSearchPeople$ = dialogRef.componentInstance.onChange.subscribe(people => {
      let peopleInTheList = this.persons.filter(p => p.id == people.id);

      if (peopleInTheList.length == 0) {
        // only save it if the people is not in the list
        this.savePerson(people); 
      }
 
    })
  }

  savePerson(people) {
    if (!people) {
      return
    }
    people.organization = this.organisation.id;
    people._organization = this.organisation;
    this.personService.update(people).subscribe(res => {
      this.persons.push(res); 
    })
  }

  deleteOrgPerson(people) {
    // delete person org connection
    people.organization = null;
    this.personService.update(people).subscribe(res => {
      this.persons = this.persons.filter(p => p.id != people.id) 
    })
  }

  editOrgPerson(people) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose =  true;
  
    dialogConfig.data = {
      organisation: this.organisation,
      people: people
    };
  
    const dialogRef = this.dialog.open(DlgEditPersonComponent, dialogConfig);
  
    this.dlgEditPerson$ = dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => { this.loadPersons(this.organisation.id);}, 300)
     
    })
  }

}
