<div class="section">
    <div class="header">
        <div class="title"><span>AjanlatKérés a Vevőtöl</span></div>
    </div>

 <form #f="ngForm" *ngIf="taskCustomerInterest">
        <mat-form-field appearance="outline">
            <mat-label>Státusz</mat-label>
            <mat-select name="status" [(ngModel)]="taskCustomerInterest.status" (selectionChange)="save()">
                <mat-option [value]="enumTaskInterestStatus.WaitingOffer">Ajánlatra Vár</mat-option>
                <mat-option [value]="enumTaskInterestStatus.WaitingCallback">Visszahívásra Vár</mat-option>
                <mat-option [value]="enumTaskInterestStatus.WaitingDecision">Döntésre Várunk</mat-option>
                <mat-option [value]="enumTaskInterestStatus.PersonalMeeting">Személyes Találozó</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Állapot</mat-label>
            <mat-select name="condition" [(ngModel)]="taskCustomerInterest.condition" (selectionChange)="save()">
                <mat-option [value]="enumTaskCustomerInterestCondition.New">Új</mat-option>
                <mat-option [value]="enumTaskCustomerInterestCondition.Used">Használt</mat-option>
                <mat-option [value]="enumTaskCustomerInterestCondition.Both">Új vagy Használt</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="padding-20-0">
                <!--<button mat-mini-fab  color="primary" (click)="save()" matTooltip="Mentés">
                    <mat-icon>save</mat-icon>
                </button>-->
                <span class="padding-l-10">
                    <app-quick-log-info [data]="taskCustomerInterest"></app-quick-log-info>
                </span> 
        </div>
    </form>
</div>
