import { Component, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { ItemService } from 'src/app/services/item.service';
import { environment } from '../../environments/environment';
import { UrlParamService } from '../services/urlParam.service';
import { BaseList } from '../common/baseClasses/baseList';
import { EnumProductType } from '../enums/productType.enum';
import { AutoUnsub } from '../utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss'],
  providers: [UrlParamService]
})
export class ItemsComponent extends BaseList implements OnInit  {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['manufacturer', 'nameHu'];

  productType = EnumProductType.Machine;

  itemList$;

  constructor(
    private itemService: ItemService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService
  ) {
    super()

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit() {
    let defaultParams = {productType: this.productType}

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      }
      
      this.itemList$ = this.itemService.list(options).subscribe(data => {
        this.setListResponseData(data);
      });
    })
  }

  open(entity) {
    this.router.navigate(['item'],  {
      queryParams: {id: entity.id}, 
      relativeTo: this.route  
    });
  }

  lookup() {
    this.doSearch();
  }

  changeType(event) {
    this.resetFilters();

    this.productType = event.value;
    let param = {productType: this.productType};
   
    this.urlParamService.update(param);
    this.urlParamService.set();
  }

}
