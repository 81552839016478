import { Component, Input, OnInit } from '@angular/core';
import { EnumLanguage } from 'src/app/enums/languages.enum';
import { EnumTemplateCategory } from 'src/app/enums/templates/templateCategory.enum';
import { OrganisationAddress } from 'src/app/models/organisation';
import { Task } from 'src/app/models/tasks/task.model';
import { TemplateSection } from 'src/app/models/template.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { NotificationService } from 'src/app/services/notification';
import { OrganisationService } from 'src/app/services/organisation.service';
import { TaskPdfService } from 'src/app/services/tasks/taskPdf.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-default-section-handover-pdf',
  templateUrl: './default-section-handover-pdf.component.html',
  styleUrls: ['./default-section-handover-pdf.component.scss']
})
export class DefaultSectionHandoverPdfComponent implements OnInit {
  @Input() task: Task;
  
  TEMPLATE_CATEGORY = EnumTemplateCategory.TaskCustomerHandover;

  // pdf list vs create view
  createPdfView = false;
  isPdfGenerated = false;  // set to true if the pdf generated

  // this is the only section in the pdf
  section: TemplateSection;
  sections: TemplateSection[] = [];

  orgAddresses: OrganisationAddress[];

  addresses = [];

  taskPdfCreate$;
  addresses$;

  constructor(
    private taskPdfService: TaskPdfService,
    private modelFactory: ModelFactory,
    private notificationService: NotificationService,
    private orgService: OrganisationService
  ) { }

  ngOnInit(): void {
    this.createSection();

    this.fetchAddresses();
  }

  fetchAddresses() {
    let params = {limit: 1000, organisation: this.task.organization}
    this.addresses$ = this.orgService.listAddresses(params).subscribe(res => {
      this.orgAddresses = res;

      this.setAddresses();
    })
  }

  createSection() {
    this.section = this.modelFactory.getNewModel(TemplateSection);
    this.section.defaultSection = this.TEMPLATE_CATEGORY;
    this.section.defaultConfig = {
      userId: this.task.assignee,
      peopleId: null,
      location: {
        country: null,
        postcode: null,
        city: null,
        line: null
      },
      comment: null
    }
  }

  generate() {
    let data = {
      fileName: (this.task.language == EnumLanguage.En) ? 'delivery_note' : 'szallitolevel',
      sections: [this.section]
    };

    let payload = {
      taskId: this.task.id,
      data: data,
      templateCategory: this.TEMPLATE_CATEGORY
    }

    this.taskPdfCreate$ = this.taskPdfService.create(payload).subscribe(res => {
      this.notificationService.success();
    });
  }

  setUser(user) {
    this.section.defaultConfig['userId'] = user.id;
  }

  setPeople(people) {
    this.section.defaultConfig['peopleId'] = people.id
  }

  setAddresses() {
    // create a list of addresses of the company with the same porperties
    if (this.task.organization) {
      let line = '';
      if (this.task._organization.addressLine1) { line += this.task._organization.addressLine1;}
      if (this.task._organization.addressLine2) { line += ' ' + this.task._organization.addressLine2;}

      let orgAddress = {
        country: this.task._organization.addressCountry,
        postcode: this.task._organization.addressPostcode,
        city: this.task._organization.addressCity,
        line: line
       }

      this.addresses.push(orgAddress); 
    }

    if (!this.orgAddresses) return;

    this.orgAddresses.forEach(address => {
      let line = '';
      if (address.addressLine1) { line += address.addressLine1;}
      if (address.addressLine2) { line += ' ' + address.addressLine2;}

      let a = {
        country: address.country,
        postcode: address.postcode,
        city: address.city,
        line: line
       }

      this.addresses.push(a); 
    });
  }

  copyToAddress(address) {
    this.section.defaultConfig.location.country = address.country
    this.section.defaultConfig.location.city = address.city;
    this.section.defaultConfig.location.postcode = address.postcode;
    this.section.defaultConfig.location.line = address.line;
  }

}
