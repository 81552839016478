import { Component, Inject, OnInit, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Item } from 'src/app/models/item.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';

@Component({
  selector: 'app-dlg-edit-item',
  templateUrl: './dlg-edit-item.component.html',
  styleUrls: ['./dlg-edit-item.component.scss']
})
export class DlgEditItemComponent implements OnInit {
  item: Item = null;
  onChange = new EventEmitter();
  preventItemCreation: false;

  constructor(
    private modelFactory: ModelFactory,
    public dialogRef: MatDialogRef<DlgEditItemComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.item = this.modelFactory.getNewModel(Item);

    if (data.item) {
      this.item = data.item;
    }

    if (data.preventItemCreation) {
      this.preventItemCreation = data.preventItemCreation;
    }
  }

  ngOnInit() {}

  created(event) {
    this.onChange.emit(event);
    this.closeDlg()
  }

  updated(event) {
    this.onChange.emit(event);
    this.closeDlg();
  }

  deleted(event) {
    this.onChange.emit(event);
    this.closeDlg();
  }

  closeDlg() {
    this.dialogRef.close();
  }

}
