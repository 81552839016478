import { Component, Input, OnInit } from '@angular/core';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'app-default-config-marketing',
  templateUrl: './default-config-marketing.component.html',
  styleUrls: ['./default-config-marketing.component.scss']
})
export class DefaultConfigMarketingComponent implements OnInit {
  @Input() defaultConfig: any;

  constructor() {
    this.defaultConfig = {
      showProductImage: true,
      showProductMarketing: true,
      showCategoryMarketing: true
    }
  }

  ngOnInit(): void {
    if (!this.defaultConfig || isEmpty(this.defaultConfig)) {
      // provide default if empty
      this.defaultConfig['showProductImage'] = true;
      this.defaultConfig['showProductMarketing'] = true;
      this.defaultConfig['showCategoryMarketing'] = true;
    }
  }

}
