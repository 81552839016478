<ul class="breadcrumb"> 
    <li>Kivételezés a Raktárból</li>  
</ul>

<div class="flex"> 
    <app-task-list-filter-container
        [textSearch]="txtSearch" (onChangeSearch)="changeSearch($event)"
        [open]="state" (onChangeOpen)="changeState($event)"
        [status]="status" (onChangeStatus)="changeStatus($event)"
        [importance]="importance" (onChangeImportance)="changeImportance($event)"
        [assignee]="assignee" (onChangeAssignee)="changeUser($event)"
    ></app-task-list-filter-container>

    <div class="full text-right">
        <button mat-mini-fab color="accent"  matTooltip="Új Feladat Létrhozása" (click)="openTaskEdit()"> 
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>

<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="importance">
        <mat-header-cell *matHeaderCellDef>Fonotsság</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-importance-container [importance]="row.importance"></app-importance-container>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Név</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="link" (click)="openEntity(row)" matTooltip="{{row.name}}">{{row.name | truncate: 5}} </span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="assignee">
        <mat-header-cell *matHeaderCellDef>Felelős</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-avatar [user]="row._assignee"></app-avatar>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>Státusz</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-status-container [status]="row.status"></app-status-container>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="project" *ngIf="hasAccessProjects">
        <mat-header-cell *matHeaderCellDef>Projekt</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.action">
                <div>
                    <div class="link" (click)="openProject(row)" matTooltip="row._action._project.name" matTooltip="{{row._action._project.name}}">{{row._action._project.name | truncate:4}}</div>
                    <div class="text-small text-grey" matTooltip="row._action.name" matTooltip="{{row._action.name}}">{{row._action.name | truncate:4}}</div>
                </div>
            </ng-container>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="organization">
        <mat-header-cell *matHeaderCellDef>Cég</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span *ngIf="row.organization" matTooltip="{{row._organization.name}}">{{row._organization.name | truncate:4}}</span>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
    [showFirstLastButtons]="true"
></mat-paginator>





