<ng-container *ngIf="type == enumTaskTypes.TaskCustomerInterest">Ajánlatkérés Vevőtől</ng-container>
<ng-container *ngIf="type == enumTaskTypes.TaskVendorInterest">Ajánlatkérés Beszállítótól</ng-container>
<ng-container *ngIf="type == enumTaskTypes.TaskCustomerOffer">Ajánlat a Vevőnek</ng-container>
<ng-container *ngIf="type == enumTaskTypes.TaskCustomerOrder">Megrendelés a Vevőtől</ng-container>
<ng-container *ngIf="type == enumTaskTypes.TaskCustomerConfirmation">Visszaigazolás a Vevőnek</ng-container>
<ng-container *ngIf="type == enumTaskTypes.TaskVendorOrder">Megrendelés a Beszállítótól</ng-container>
<ng-container *ngIf="type == enumTaskTypes.TaskCustomerDeliver">Kiszállítás a Vevőnek</ng-container>
<ng-container *ngIf="type == enumTaskTypes.TaskCustomerHandover">Átadás/Átvétel</ng-container>
<ng-container *ngIf="type == enumTaskTypes.TaskInventoryIn">Bevételezés</ng-container>
<ng-container *ngIf="type == enumTaskTypes.TaskInventoryOut">Kivételezés</ng-container>
<ng-container *ngIf="type == enumTaskTypes.TaskService">Szervíz</ng-container>
<ng-container *ngIf="type == enumTaskTypes.TaskActivity">Tevéknység</ng-container>
