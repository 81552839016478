<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Árucikk Csoportok</a></li>  
    <li>{{product.nameHu}}</li>  
</ul>

<h5  class="text-center">
    {{product.nameHu}}
    <mat-icon matTooltip="Migrált adat" class="m-l-10 text-yellow" *ngIf="product && product.migrated">move_down</mat-icon>
</h5>

<mat-tab-group animationDuration="0ms" dynamicHeight>
    <mat-tab label="Árucikk Csoport">
        <div class="section">
            <app-product 
            (eventCreated)="created($event)"
            (eventDeleted)="deleted($event)" 
            (eventUpdated)="updated($event)"
            [product]="product" class="flex"></app-product>
        </div>
    </mat-tab>
    <mat-tab label="Kiajánlható Termék" *ngIf="product.id">
        <ng-template matTabContent>
            <div class="section">
                <div class="header">
                    <div class="title"><span>Kiajánlható Termékek</span></div>
                </div>
                <app-product-related-offer [product]="product"></app-product-related-offer>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="Műszaki Adatok" *ngIf="product.id">
        <ng-template matTabContent>
            <app-product-attributes [product]="product"></app-product-attributes>
        </ng-template>
    </mat-tab>
    <mat-tab label="Képek" *ngIf="product.id">
        <ng-template matTabContent>
           <app-product-images [product]="product"></app-product-images>
        </ng-template>
    </mat-tab>
    <mat-tab label="Documentumok" *ngIf="product.id">
        <ng-template matTabContent>
           <app-product-documents [product]="product"></app-product-documents>
        </ng-template>
    </mat-tab>
    <mat-tab label="Marketing Leírás" *ngIf="product.id">
        <div class="section">
            <div class="header">
                <div class="title"><span>Marketing Leírás</span></div>
            </div>
            <div class="alert alert-warning">
                Az itt megadott szöveg látható lehet ügyfelek számára!
            </div>

            <mat-tab-group>
                <mat-tab label="Magyar">
                    <app-html-container [content]="product.marketingHu" (onChange)="updateMarketingHu($event)"></app-html-container>
                </mat-tab>
                <mat-tab label="Angol">
                    <app-html-container [content]="product.marketingEn" (onChange)="updateMarketingEn($event)"></app-html-container>
                </mat-tab>
            </mat-tab-group>
        </div>
    </mat-tab>
  </mat-tab-group>




