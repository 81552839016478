import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { NotificationService } from './notification';
import { Utils } from '../shared/utils';
import { Inventory } from '../models/inventory.model';


@Injectable({
    providedIn: 'root'
  })
export class InventoryService {
    baseUrl = environment.baseUrl;

    URL = '/inventory';

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {}

    list(options=null) {
        // @params: obj of params
        // @url: full url with params (next and prev for pagination)
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL;
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }

        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(i => new Inventory(i));
                return res;
            })
        )
    }

    getByItem(itemId) {
        let params = { item: itemId }
        let url = this.baseUrl + this.URL + '/';
        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(i => new Inventory(i));
                if (res.results.length > 0) {
                    return res.results[0];
                }
               return null;
            })
        )
    }

    getByProduct(productId) {
        let params = { product: productId }
        let url = this.baseUrl + this.URL + '/';
        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(i => new Inventory(i));
                if (res.results.length > 0) {
                    return res.results;
                }
               return null;
            })
        )
    }

    csvExport(): any {
        // return csv with all the items in the inventory
        let url = this.baseUrl + this.URL  + '/csv_export/';
        return this.http.get(url,  { responseType: 'blob'});
    }
}
