export class Product {
    id: number = null;
    nameHu: string = null;
    nameEn: string = null;
    unitHu: string = 'db';
    unitEn: string = 'pc';
    articleNumber: string = null;
    manufacturer: number = null;
    _manufacturer?: any = null;
    category: number = null;
    _category: any = null;
    type: string = 'machine'; // default type is machine
    marketingHu: string = null;
    marketingEn: string = null;
    descriptionHu: string = null;
    descriptionEn: string = null;
    automaticItemCreation: boolean = false;
    
    migrated?: boolean = false;
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.nameHu  = data.nameHu;
            this.nameEn  = data.nameEn;
            this.unitHu = data.unitHu;
            this.unitEn = data.unitEn;
            this.type  = data.type;
            this.descriptionHu = data.descriptionHu;
            this.descriptionEn = data.descriptionEn;
            this.marketingHu = data.marketingHu;
            this.marketingEn = data.marketingEn;
            this.articleNumber = data.articleNumber;
            this.automaticItemCreation = data.automaticItemCreation;
            
            this.manufacturer = data.manufacturer;
            if ('_manufacturer' in data) {
                this._manufacturer = data._manufacturer;
            }

            this.category = data.category;
            if ('_category' in data) {
                this._category = data._category;
            }

            this.migrated = data.migrated;
            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }  
    }
  }

  export class ProductRelatedOffers {
    id: number = null;
    product: number = null;
    _product: any = null;
    offer: number = null;
    _offer: number = null;
    
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;

            this.product = data.product;
            if ('_product' in data) {
                this._product = data._product;
            }

            this.offer = data.offer;
            if ('_offer' in data) {
                this._offer = data._offer;
            }

            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }  
    }
  }


  export class ProductAttribute {
    id: number = null;
    product: number = null;
    _product: any = null;
    attribute: number = null;
    _attribute: any = null;
    valueHu: any = null;
    valueEn: any = null;
    
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.valueHu = data.valueHu;
            this.valueEn = data.valueEn;

            this.product = data.product;
            if ('_product' in data) {
                this._product = data._product;
            }

            this.attribute = data.attribute;
            if ('_attribute' in data) {
                this._attribute = data._attribute;
            }

            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }  
    }
  }

  export class ProductImage {
    id: number = null;
    product: number = null;
    _product: any = null;
    file: number = null;
    default: boolean = false;
    
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.file = data.file;
            this.default = data.default;

            this.product = data.product;
            if ('_product' in data) {
                this._product = data._product;
            }

            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }  
    }
  }

  export class ProductDocument {
    id: number = null;
    product: number = null;
    _product: any = null;
    file: number = null;
    
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.file = data.file;

            this.product = data.product;
            if ('_product' in data) {
                this._product = data._product;
            }

            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }  
    }
  }
