import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumLanguage } from 'src/app/enums/languages.enum';
import { EnumTaskTypes } from 'src/app/enums/tasks.enums';
import { EnumTaskImportance } from 'src/app/enums/tasks/taskImportance.enums';
import { EnumTaskStatus } from 'src/app/enums/tasks/taskStatus.enum';
import { Task } from 'src/app/models/tasks/task.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { TaskService } from 'src/app/services/tasks/task.service';
import { DlgSearchActionComponent } from '../../../search/dlg-search-action/dlg-search-action.component';
import { cloneDeep } from 'lodash-es';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

/**
 * Create/Edit/Delete task
 * @data.task - if update
 * @data.taskType - if create
 */

@AutoUnsub()
@Component({
  selector: 'app-dlg-edit-task',
  templateUrl: './dlg-edit-task.component.html',
  styleUrls: ['./dlg-edit-task.component.scss']
})
export class DlgEditTaskComponent implements OnInit {
  task: Task = null;

  enumLanguage = EnumLanguage;
  enumTaskImportance = EnumTaskImportance;
  enumTaskStatus = EnumTaskStatus;

  isVendor: boolean = false;

  @Output() onChange: EventEmitter<Task> = new EventEmitter();

  taskCreate$;
  taskUpdate$;
  taskDel$;
  dlgActSearch$;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialogRef: MatDialogRef<DlgEditTaskComponent>,
    private taskService: TaskService,
    private modelFactory: ModelFactory,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data

  ) {

    if (!data.task) {
      // creating a new task
      if (!data.taskType) {
        throw 'Task Type is mandaotry for new Tasks';
      }

      this.task = this.modelFactory.getNewModel(Task);
      this.task.taskType = data.taskType;
      this.task.importance = EnumTaskImportance.Normal;
      this.task.status = EnumTaskStatus.NotStarted;

      // default language different for different tasks
      if (this.task.taskType == EnumTaskTypes.TaskVendorInterest) {
        this.task.language = EnumLanguage.En;
      } else if (this.task.taskType == EnumTaskTypes.TaskVendorOrder) {
        this.task.language = EnumLanguage.En;
      } else {
        this.task.language = EnumLanguage.Hu;
      }
    } else {
      // update task
      this.task = cloneDeep(data.task);

      if ([EnumTaskTypes.TaskVendorInterest, EnumTaskTypes.TaskVendorOrder].includes(this.task.taskType as EnumTaskTypes)) {
        // the org is a vendor not a client
        this.isVendor = true;
      }
    }
  }

  ngOnInit() {
  } 

  setAssignedUser(user) {
    if (user) {
      this.task.assignee = user.id;
      this.task._assignee = user;
    } else {
      this.task.assignee = null;
      this.task._assignee = null;
    }
  }

  setOrg(org) {
    if (org) {
      this.task.organization = org.id;
      this.task._organization = org;

      if (org.language) {
        // set task language as same for the company
        this.task.language = org.language;
      }
    } else {
      this.task.organization = null;
      this.task._organization = null;

      // set default to HU if no company
      this.task.language = 'hu';
    }
  }

  save() {
    (this.task.id) ? this.update() : this.create();
  }

  create() {
    this.taskCreate$ = this.taskService.create(this.task).subscribe(task => {
      this.task = task;

      this.onChange.emit(this.task);
      
      this.dialogRef.close(true);
    })
  }

  update() {
    this.taskUpdate$ = this.taskService.update(this.task).subscribe(res => {
      this.task = res;

      this.onChange.emit(this.task);
      
      this.dialogRef.close(true);
    })
  }

  delete() {
   this.taskDel$ = this.taskService.delete(this.task).subscribe(res => {
     // angular things _routerState doesn't exist 
     // has to cast to any type the router to avoid comilation error
    let r = this.router as any;
    let url = r.routerState.root._routerState.snapshot.url;
    url = url.substring(0, url.lastIndexOf('/') + 1);
    this.router.navigate([url]);

      this.dialogRef.close(true);
    });
  }

  openActionSearchDlg() {
    const dialogConfig = new MatDialogConfig();

    let name = (this.task.action) ? this.task._action.name : '';
    
    dialogConfig.data = {
      name: name
    };

    const dialogRef = this.dialog.open(DlgSearchActionComponent, dialogConfig);

    this.dlgActSearch$ = dialogRef.afterClosed().subscribe(action => {
      if (action) {
        this.task.action = action.id;
        this.task._action = action;
      } else {
        this.task.action = null;
        this.task._action = null;
      }
    });
  }

}
