import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Task } from 'src/app/models/tasks/task.model';
import { TaskDoc } from 'src/app/models/tasks/taskDoc';
import { TaskDocService } from 'src/app/services/tasks/taskDoc.service';

/**
 * Allow to search or provide in a project for a partner ref number
 * - search in the TaskDoc Partner Ref number field
 */

@Component({
  selector: 'app-task-partner-ref-number-container',
  templateUrl: './task-partner-ref-number-container.component.html',
  styleUrls: ['./task-partner-ref-number-container.component.scss']
})
export class TaskPartnerRefNumberContainerComponent implements OnInit {
  @Input() task: Task;

  @Output() onSelect: EventEmitter<string> = new EventEmitter();

  partnerRefNumber: string;

  taskDocs: TaskDoc[];

  taskDocs$;

  constructor(
    private taskDocService: TaskDocService
  ) { }

  ngOnInit(): void {
    this.fetchTasks();
  }

  fetchTasks() {
    if (!this.task) return;

    if (!this.task.action) return;

    let params = {
      limit: 1000,
      project: this.task._action.project 
    }
    
    this.taskDocs$ = this.taskDocService.list({params}).subscribe(res => {
      this.taskDocs = res.results;
    })
  }

  select(tasDoc) {
    this.partnerRefNumber = tasDoc.docRefNumber;
    this.onSelect.emit(this.partnerRefNumber);
  }

  sendChange() {
    this.onSelect.emit(this.partnerRefNumber);
  }

}
