<div class="flex padding-10-0">
    <div class="full">
        Árucikk Csoport Keresés
    </div>
    <button mat-mini-fab color="success" (click)="createProductDlg()" matTooltip="új Árucikk Csoport létrehozása">
        <mat-icon>add</mat-icon>
    </button>
</div>
<ng-container *ngIf="product">
    <div class="text-secondary text-small padding-10-0">
        <span>{{product.nameHu}}</span>
    </div>
</ng-container>
<form>
    <div>
        <mat-radio-group (change)="changeType($event)" [disabled]="disableTypeSelection">
            <mat-radio-button [checked]="productType === 'machine'" value="machine">Gép</mat-radio-button>
            <mat-radio-button [checked]="productType === 'part'" value="part">Alkatrész</mat-radio-button>
            <mat-radio-button [checked]="productType === 'service'" value="service">Szolgáltatás</mat-radio-button>
        </mat-radio-group>
    </div>
    <mat-form-field appearance="outline">
        <mat-icon class="pointer text-red"  (click)="delete()" matSuffix matTooltip="Töröl">delete</mat-icon>
        <input #focus matInput name="txtSearch" (keyup)="search()" [(ngModel)]="txtSearch" placeholder="Keresés (min 3 karakter"  [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
        <mat-option (click)="select(option)" *ngFor="let option of options" [value]="option">
            <div class="flex">
                <app-icon-item-type [itemType]="option.type"></app-icon-item-type>
                <div>     
                    {{option.nameHu | truncate:6}}
                    <div class="text-secondary text-small">
                        {{option.articleNumber}}
                    </div>      
                </div>
            </div>
        </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>
