import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Product } from 'src/app/models/product.model';
import { TaskProduct } from 'src/app/models/tasks/taskProduct.model';
import { EnumTemplateDefaultSection } from 'src/app/enums/templates/templateDefaultSection.enum';
import { EnumLanguage } from 'src/app/enums/languages.enum';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { cloneDeep } from 'lodash-es';
import { Template, TemplateSection } from 'src/app/models/template.model';
import { EnumModule } from 'src/app/enums/modules.enum';
import { Task } from 'src/app/models/tasks/task.model';
import { EnumCurrency } from 'src/app/enums/currencies.enum';

@Component({
  selector: 'app-template-builder',
  templateUrl: './template-builder.component.html',
  styleUrls: ['./template-builder.component.scss']
})
export class TemplateBuilderComponent extends BaseEntity implements OnInit, OnChanges {
  @Input() task: Task;
  @Input() templateSections: TemplateSection[];
  @Input() products: Product | TaskProduct; // always need to add the different type of product list!
  @Input() template: Template;
  @Input() language: EnumLanguage | string = 'hu';
  @Input() isLanguageOption:boolean = true;
  @Input() isDlg: boolean = false; // set a class to modify the style if it is true

  @Output() onGeneratePdf: EventEmitter<any> = new EventEmitter;
  @Output() onReset: EventEmitter<any> = new EventEmitter;

  EnumLanguage = EnumLanguage;
  EnumTemplateDefaultSection = EnumTemplateDefaultSection;
  module: EnumModule;

  templateType = 'pdf';

  pdfData: any; // generated pdfData strucutre to render the pdf

  constructor() {
    super();

    this.pdfData = this.getDefaultPdfData();
  }

  ngOnInit() {
    if (this.template) {
      this.templateType = this.template.type;
    }
    
    if (this.pdfData.langauge != this.language) {
      this.changeLanguage(this.language);
    }

    if(this.task) {
      this.pdfData.currency = this.task.currency;
    }
    
  }

  ngOnChanges() {
    // we need to detect that an object change in the parent;
    this.setPdfDataSections();

    this.setPdfFileName();
  }

  getDefaultPdfData() {
    let defaultPdfData = {
      fileName: '_',
      language: this.language,
      currency: 'eur',
      sections: []
    };

    return cloneDeep(defaultPdfData);
  }

  changeLanguage(language) {
    // when the language is changed we have to reset the default data on the selected langauge
    this.language = language;
    this.pdfData.language = this.language;
    this.setPdfDataSections();
    this.setPdfFileName();
  }

  setPdfFileName() {
    if (!this.template) {
      return;
    }

    this.pdfData.fileName = (this.language == EnumLanguage.En) ? this.template.fileNameEn : this.template.fileNameHu;
  }

  setPdfDataSections() {
    // based on the template and the language build the pdfData sections
    let pdfSections = [];

    this.templateSections.forEach(templateSection => {
      // default data based on the TemplateSection
      let pdfSection = {
        defaultSection: templateSection.defaultSection,
        defaultConfig: templateSection.defaultConfig,
        id: templateSection.id,
        order: templateSection.order,
        columns: templateSection.columns,
        title: (this.pdfData.language == EnumLanguage.Hu) ? templateSection.titleHu : templateSection.titleEn,
        body: (this.pdfData.language == EnumLanguage.Hu) ? templateSection.bodyHu : templateSection.bodyEn,
      }

      // extend the data with the PdfData.sections


      pdfSections.push(pdfSection);
    });

    this.pdfData.sections = pdfSections;
  }

  deleteSection(section) {
    this.pdfData.sections = this.pdfData.sections.filter(s => s.order != section.order);
    this.setOrderNumbers(this.pdfData.sections);
  }
  
  moveUp(section) {
    this.moveElementUp(this.pdfData.sections, section);
  }

  moveDown(section) {
    this.moveElementDown(this.pdfData.sections, section);
  }

  addNewSection(section?) {
    let newSection = {
      order: null,
      columns: false,
      title: '',
      body: '',
      id: this.getNextId(),
      defaultSection: false
    }

    if (section) {
      // which section below should be the new section added
      this.pdfData.sections.splice(section.order + 1, 0, newSection);
    } else {
      // add the section to the end
      this.pdfData.sections.push(newSection);
    }

    this.setOrderNumbers(this.pdfData.sections);
  }

  setNewSectionData(templateSectionVersion) {
    // set the new data with the selected templateSectionVersion
    let section = this.pdfData.sections.find(s => s.id === templateSectionVersion.section);
    
    if (section) {
      if (this.pdfData.language == EnumLanguage.Hu) {
        section.title = templateSectionVersion.titleHu
        section.body = templateSectionVersion.bodyHu;
      } else {
        section.title = templateSectionVersion.titleEn;
        section.body = templateSectionVersion.bodyEn;
      }      
    }
  }

  generatePdf() {
    this.onGeneratePdf.emit(this.pdfData);
  }

  resetPdfGeneration() {
    this.onReset.emit();
  }


}
