<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Felhasználók</a></li>  
    <li> {{!!editMode ? user.fullName : 'Létrehoz'}}</li>  
</ul>

<ng-container *ngIf="user.id">
    <div class="flex flex-right">
        <p class="padding-10-0 ">
         <button mat-stroked-button color="warn"  (click)="newPassoword()"  
         matTooltip="A kiküldött email segítségével a felhasználó új jelszót állíthat be">
              Új Jelszó <mat-icon>send</mat-icon>
         </button>
        </p>
       <p class="padding-10-0 padding-l-10">
         <button mat-stroked-button color="accent"  (click)="verifyEmail()"
         matTooltip="Ha az email cím még nem lett megerősítve, az elküldütt email segítségével ez lehetséges">
             Emailcím Visszaigzolás <mat-icon>send</mat-icon>
         </button>
       </p>
       
    </div>
 </ng-container>

<mat-tab-group animationDuration="0ms">
    <mat-tab label="Alapadatok">
        <ng-container *ngTemplateOutlet="defaultDataTab"></ng-container>
    </mat-tab>
    <ng-container *ngIf="user.id">
        <mat-tab label="Hozzáférés">
            <ng-template matTabContent>
                <app-user-access [userId]="user.id"></app-user-access>
            </ng-template>
        </mat-tab>
        <mat-tab label="Profil Adatok">
            <ng-template matTabContent>
                <app-profile [userId]="user.id"></app-profile>
            </ng-template>
        </mat-tab>
    </ng-container>
</mat-tab-group>

<!-- defaultDataTab -->
<ng-template #defaultDataTab class="padding-20-0">
    <form #f="ngForm" class="form-group">
        <div class="section">
            <div class="header">
                <div class="title"><span>Alap Adatok</span></div>
            </div>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Vezetéknev</mat-label>
                    <input matInput name="lastName" [(ngModel)]="user.lastName" autocomplete="off" required>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Keresztnév</mat-label>
                    <input matInput name="firstName" [(ngModel)]="user.firstName" autocomplete="off" required>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput name="email" [(ngModel)]="user.email" autocomplete="off" required>
                </mat-form-field>
            </p>
            <p>
                <mat-checkbox name="isActive" [(ngModel)]="user.isActive">Aktív</mat-checkbox>
                <mat-icon matTooltip="Inaktív felhasználók nem tudnak belépni a rendszerbe">info</mat-icon>
            </p>
            <p>
                <mat-checkbox name="isWorker" [(ngModel)]="user.isWorker">Dolgozó</mat-checkbox>
                <mat-icon matTooltip="Dolgozók azok akikhez Feladatot, Szervíz eseményt vagy Projektet lehet hozzárendelni. A Dolgozó megjelnnek mint választás a listában.">info</mat-icon>
            </p>
            <p>
                <mat-checkbox name="verified" [(ngModel)]="user.verified">Email Visszaigazolva</mat-checkbox>
                <mat-icon matTooltip="Kétféle képpen lehet egy Emailcímet validálni: 1. Checkbox segítségével. 2. Kiküldött Email segítségével, a felhaszáló validálhatja a saját emailcímét.">info</mat-icon>
            </p>
            <p>
                <mat-checkbox name="admin"  [(ngModel)]="user.admin">Admin</mat-checkbox>
                <mat-icon matTooltip="A legmagasabb hozzáférési szint a rendszerben.">info</mat-icon>
            </p>
        </div>
        <div class="section">
            <div class="header">
                <div class="title"><span>Beosztás</span></div>
            </div>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Munkakör megnevezése</mat-label>
                    <input matInput name="jobtitle" [(ngModel)]="user.jobTitle" autocomplete="off" required>
                    <mat-icon matSuffix matTooltip="Beosztás látható lehet ügyfelek számára!">info</mat-icon>
                </mat-form-field>
            </p>
    </div>
        <div class="section">
                <div class="header">
                    <div class="title"><span>Szín</span></div>
                </div>
                <p>
                    <mat-form-field appearance="outline">
                        <mat-label>Szín</mat-label>
                        <input matInput type="color" name="color" [(ngModel)]="user.color" autocomplete="off">
                    </mat-form-field>
                </p>
        </div>            
        <div class="flex">
                <p class="full">
                    <button mat-mini-fab  color="primary" [disabled]="f.invalid"  (click)="save()">
                        <mat-icon>save</mat-icon>
                    </button>
                </p>
                <button mat-icon-button class=" mat-danger" [disabled]="f.invalid"  (confirm-click)="delete()" confirm>
                    <mat-icon>delete</mat-icon>
                </button>
        </div>
    </form>
</ng-template>