export enum EnumTaskTypes {
  // Egyeb Tevekenységek
  TaskActivity = 'taskactivity',
  // Servizek
  TaskService = 'taskservice',
  // customer
  TaskCustomerInterest = 'taskcustomerinterest',
  TaskCustomerOffer = 'taskcustomeroffer',
  TaskCustomerOrder = 'taskcustomerorder',
  TaskCustomerConfirmation = 'taskcustomerconfirmation',
  TaskCustomerDeliver = 'taskcustomerdeliver',
  TaskCustomerHandover = 'taskcustomerhandover',
  // vendor
  TaskVendorInterest = 'taskvendorinterest',
  TaskVendorOrder = 'taskvendororder',

  TaskInventoryIn = 'taskinventoryin',
  TaskInventoryOut = 'taskinventoryout',
}