import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import { TaskCustomerInterest } from 'src/app/models/tasks/taskCustomerInterest.model';
import { TaskCustomerOrder } from 'src/app/models/tasks/taskCustomerOrder.model';
import { QuickLogInfoDetailsComponent } from './quick-log-info-details/quick-log-info-details.component';

/**
 * Bottomsheet to show the Quick Log information (Creator, Modifier, Last Modifier.....)
 */

@Component({
  selector: 'app-quick-log-info',
  templateUrl: './quick-log-info.component.html',
  styleUrls: ['./quick-log-info.component.scss']
})
export class QuickLogInfoComponent implements OnInit, OnChanges {
  @Input() data: any = null;

  showIcon = false;

  constructor(
    private _bottomSheet: MatBottomSheet
    ) { }

  openBottomSheet(): void {
    // passing the data to the component which displayed in the bootm sheet
    this._bottomSheet.open(QuickLogInfoDetailsComponent, {data: this.data});
  }

  ngOnInit() {
    this.isShowIcon();
  }

  ngOnChanges() {
    // data could load after the component initiated, we have to listen to that.
   this.isShowIcon();
  }

  isShowIcon() {
    // TODO add every differnt type of SubTask to this list!
    if (this.data instanceof TaskCustomerInterest) {
      this.showIcon = true;
    } else if (this.data instanceof TaskCustomerOrder) {
      this.showIcon = true;
    } else if (!this.data.id || typeof this.data.id === 'string') {
      // new item, no audit so far
      this.showIcon = false;
    } else if (this.data.creator) {
      this.showIcon = true;
    } else if (this.data.lastModifier) {
      this.showIcon = true;
    } else if (this.data.created) {
      this.showIcon = true;
    } else if (this.data.updated) {
      this.showIcon = true;
    }
  }

}
