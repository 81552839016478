import { Component, Input, OnInit, Output } from '@angular/core';
import { EnumCurrency } from 'src/app/enums/currencies.enum';

@Component({
  selector: 'app-currency-container',
  templateUrl: './currency-container.component.html',
  styleUrls: ['./currency-container.component.scss']
})
export class CurrencyContainerComponent implements OnInit {
  @Input() currency: EnumCurrency | string = 'eur';
  @Input() value: number = null;
  @Input() isColor: boolean = false; // color the text based on it is positive or negative value

  formattedValue: string; // formatted value of the currency

  enumCurrency = EnumCurrency;

  constructor() { 
    
  }

  ngOnInit() {
    this.formatCurrency();
  }

  ngOnChanges() {
    // because in the parent component the data is nested (obj) the change detection has to be done manually
    // - the object change detection not working for child componenets
    this.formatCurrency();
  }

  formatCurrency() {
    // format the value based on the currency
    if (this.value === null || this.value === undefined) {
      return;
    }
    let jsLocal = 'hu-HU';

    let decimal = 0;
    if (this.currency == EnumCurrency.EUR) {
      decimal = 2;
    }

    this.formattedValue = this.value.toLocaleString(jsLocal, {maximumFractionDigits: decimal});

    // add prefix or postfix to the currency
    if (this.currency == EnumCurrency.EUR) {
      this.formattedValue = '€ ' + this.formattedValue;
    } else {
     this.formattedValue += ' Ft'
    }
  }
}
