import { Component, OnInit } from '@angular/core';
import { EnumModule } from '../enums/modules.enum';
import { SecurityService } from '../services/security.service';

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.scss']
})
export class DashComponent implements OnInit {
  hasAccessServices = false;

  constructor(
    private ss: SecurityService
  ) { }

  ngOnInit() {
    this.hasAccessServices =  this.ss.hasAccess(EnumModule.TaskServices);
  }

}
