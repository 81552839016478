import { Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseList } from 'src/app/common/baseClasses/baseList';
import { EmailService } from 'src/app/services/email.service';
import { UrlParamService } from 'src/app/services/urlParam.service';

@Component({
  selector: 'app-email-logs',
  templateUrl: './email-logs.component.html',
  styleUrls: ['./email-logs.component.scss'],
  providers: [UrlParamService]
})
export class EmailLogsComponent  extends BaseList implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['datetime', 'created', 'from', 'to', 'subject'];

  constructor(
    private emailLogService: EmailService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService,
  ) {
    super();

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit(): void {
    let defaultParams = {}

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      }

      this.emailLogService.list(options).subscribe(data => {
        this.setListResponseData(data);
      });
    })
  }

}
