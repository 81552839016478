import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseTaskList } from 'src/app/common/baseClasses/baseTaskList';
import { EnumTaskTypes } from 'src/app/enums/tasks.enums';
import { SecurityService } from 'src/app/services/security.service';
import { TaskService } from 'src/app/services/tasks/task.service';
import { UrlParamService } from 'src/app/services/urlParam.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-task-customer-confirmations',
  templateUrl: './task-customer-confirmations.component.html',
  styleUrls: ['./task-customer-confirmations.component.scss'],
  providers: [UrlParamService]
})
export class TaskCustomerConfirmationsComponent  extends BaseTaskList implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['importance', 'name', 'assignee', 'status'];

  taskList$;

  constructor(
    private taskService: TaskService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService,
    public dialog: MatDialog,
    public ss: SecurityService
  ) {
    super(EnumTaskTypes.TaskCustomerConfirmation, dialog, ss);

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit() {
    if (this.hasAccessProjects) {
      this.displayedColumns.push('project')
    }
   

    this.doGetInitalOptions();
}

 doGetInitalOptions() {
  let defaultParams = {
    open: this.state,
    taskType: this.taskType
  }

  this.getInitialOptions(defaultParams, (options) => {
    // if the default page setup changed based on the url we have to set them
    for (const key in options.params) {
      this[key] = options.params[key];
    }

    this.taskList$ = this.taskService.list(options).subscribe(data => {
      this.setListResponseData(data);
     });

     if ('create' in options.params) {
      // if the create param send (independently for the value we open the project creation)
      this.openTaskEdit();
    }
  })
 }
}
