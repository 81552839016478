export class Exchange {
    rate: number = null;
    currency: string = null;
    date: Date = null;

    constructor(data: any) {
       if (data) {
            this.rate = +data.rate;
            this.currency = data.currency;
            this.date = new Date(data.date);
        }  
    }
  }
