import { Component, Input, OnInit } from '@angular/core';
import { EnumTaskActivity } from 'src/app/enums/tasks/taskActivityenum';
import { Task } from 'src/app/models/tasks/task.model';
import { TaskActivity } from 'src/app/models/tasks/taskActivity.model';
import { TaskActivityService } from 'src/app/services/tasks/taskActivity.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-task-activity-detail',
  templateUrl: './task-activity-detail.component.html',
  styleUrls: ['./task-activity-detail.component.scss']
})
export class TaskActivityDetailComponent implements OnInit {
  @Input() task: Task;

  taskActivity: TaskActivity;
  enumTaskActivity = EnumTaskActivity;

  taskGet$;
  taskUpdate$;

  constructor(
    private taskActivityService: TaskActivityService
  ) { }

  ngOnInit(): void {
    this.taskGet$ = this.taskActivityService.get(this.task.id).subscribe(resp => {
      this.taskActivity = resp;
    })
  }

  save() {
    this.taskUpdate$ = this.taskActivityService.update(this.taskActivity).subscribe(resp => {
      this.taskActivity = resp;
    })
  }

}
