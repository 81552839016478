import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { NotificationService } from './../notification';
import { Utils } from '../../shared/utils';
import { TaskProduct } from 'src/app/models/tasks/taskProduct.model';
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
  })
export class TaskProductService {
    baseUrl = environment.baseUrl;

    URL = '/tasks/products';

    taskInterestProducts$ = new BehaviorSubject<TaskProduct[]>(null);

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {}

    list(options=null) {
        // list all the contacts
        // @params: obj of params
        // @url: full url with params (next and prev for pagination)
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL + '/';
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }

        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.map(i => new TaskProduct(i));
                return res;
            })
        )
    }

    get(id) {
        let url = this.baseUrl + this.URL + '/' + id + '/';
        return this.http.get<TaskProduct>(url).pipe(
            map(res => new TaskProduct(res))
        )
    }

    getByTask(task_id) {
        let params = { task: task_id }
        let url = this.baseUrl + this.URL + '/';
        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res = res.map(i => new TaskProduct(i));
                return res;
            })
        )
    }

    getByProject(project_id) {
        let params = { task: project_id }
        let url = this.baseUrl + this.URL + '/';
        return this.http.get<TaskProduct>(url, {params}).pipe(
            map(res => new TaskProduct(res))
        )
    }

    create(payload) {
        let url = this.baseUrl + this.URL  + '/';
        return this.http.post(url, payload).pipe(
            map(result => new TaskProduct(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    update(payload) {
        let url = this.baseUrl + this.URL + '/' + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new TaskProduct(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    delete(payload) {
        let url = this.baseUrl + this.URL + '/' + payload.id + '/';
        return this.http.delete(url).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

    onProductsChange(products) {
        // if somebody interested for the new Products of the Task lets send it...
        this.taskInterestProducts$.next(products);
    }

    getStocks(taskId) {
        // for the given task return the stock for the TaskProducts
        // structure: [{taskProductId: [inventory]}, .....]
        let params = { task: taskId }
        let url = this.baseUrl + this.URL + '/stock/';
        return this.http.get<any>(url, {params})
    }

    /*
    Special endpoint to add TaskProducts (bulk)
    */
    setUp(destinationTaskId, taskProducts) {
        // based on the task id copy the products from it
        let _taskProducts = [];
        taskProducts.forEach(tp => {
            _taskProducts.push({id: tp.id, amount:tp.amount})
        })
        
        let payload = {
            task: destinationTaskId, // to this task
            taskProducts: _taskProducts // adding these taskProducts with the amount
        }
        
        let url = this.baseUrl + this.URL + '/setup/';
        return this.http.post<any>(url, payload).pipe(
            map(res => {
                res.taskProducts = res.taskProducts.map(i => new TaskProduct(i));
                return res.taskProducts;
            }),
            tap(res => { this.notificationService.updated(); })
        )
    }

     /*
    Special endpoint to add RelatedProduct (offer) (bulk)
    */
    addRelatedProducts(taskId, productId) {
        // add realted offers of the Product to the Task
        // notification in the caller, becuse maybe nothing happend if the original and the new list are the same
        let payload = {
            taskId: taskId,
            productId: productId
        }
        
        let url = this.baseUrl + this.URL + '/add_related_products/';
        return this.http.post<any>(url, payload).pipe(
            map(res => {
                res.taskProducts = res.taskProducts.map(i => new TaskProduct(i));
                return res.taskProducts;
            })
        )
    }
}