import { environment } from '../../../environments/environment';

/**
 * Serve as a Base class with a bunch of helper method to handle comman task for comoponenets which handle the
 * list based queries with paramater options
 */

export abstract class BaseList {
    dataSource = null;
    count = 0;
    pageSize = environment.pageSize;
    urlNext = null;
    urlPrev = null;
    txtSearch: string = '';
    pageIndex = 0;

    router = null;
    route = null;
    urlParamService = null;

    constructor() {}

    setUrlParamService(urlParamService) {
        this.urlParamService = urlParamService;
    }

    setActivatedRoute(route) {
        this.route = route;
    }

    getInitialOptions(defaultParams, callback) {
        /**
         * When the page load we check the params in the URL and load the page accordingly
         * this means we may override the defaultParams of the page
         */
        this.route.queryParams.subscribe(queryParams => {
            if ('search' in queryParams) {
                this.txtSearch = queryParams['search'];
            }

            let params = {};
            if (queryParams) {
                params = {...defaultParams, ...queryParams};
            }
            
            this.pageIndex = this.urlParamService.getPageIndex(params)

            // if a param is a number or boolean let's transform it from stirng
            for (const prop in params) {
                if (Number(params[prop])) {
                    params[prop] = Number(params[prop])
                }
                else if((params[prop] == "true")) {
                    params[prop] = true;
                } else if((params[prop] == "false")) {
                    params[prop] = false;
                }
            }

            let options = {};
            options['params'] = params;

            callback(options)
        });
    }

    setListResponseData(data) {
        // set the information from the list which require for the pagination
        this.dataSource = data.results;
        this.count = data.count
        this.urlNext = data.next;
        this.urlPrev = data.previous;
    }

    onPaginatorClick(e) {
        let url = null;
        
        if (e.previousPageIndex < e.pageIndex) {
          url = this.urlNext;
        } else {
          url = this.urlPrev;
        }
    
        this.urlParamService.setFromUrl(url, 'merge')
    }

    doSearch() {
        let params = {search: null};

        if (this.txtSearch.length > 0) {
            params.search = this.txtSearch
        }

        this.urlParamService.update(params);
        this.urlParamService.set('merge');
    }
    
    noSearch() {
        this.txtSearch = '';
    
        this.urlParamService.delete('search');
        this.urlParamService.set();
    
        this.pageIndex = 0
    }

    resetFilters() {
        // page filtering changed - we have to reset base filters
        // the pagination
        this.pageIndex = 0;
        this.txtSearch = '';
        this.urlParamService.firstPage();
        this.urlParamService.set();
    }

}