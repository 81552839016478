<div mat-dialog-title>
    <div class="flex">
        <div class="full">Előrejelzés</div>
        <span [mat-dialog-close]>
            <mat-icon class="text-red">close</mat-icon>
        </span>
    </div>
</div>

<mat-dialog-content>
    <mat-tab-group *ngIf="projectForecast.id; else tmpForcast" animationDuration="0ms">
        <mat-tab label="Gép">
            <ng-container *ngTemplateOutlet="tmpForcast"></ng-container>
        </mat-tab>
        <mat-tab label="Alkatrészek">
            <ng-template matTabContent>
                <ng-container *ngTemplateOutlet="tmpForcastParts"></ng-container>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>

<ng-template #tmpForcast>
    <form #f="ngForm">
     <p>
        <mat-checkbox name="active" [(ngModel)]="projectForecast.active">Aktív</mat-checkbox>
     </p>
     <div class="flex padding-10 margin-10-0 bg-light-grey" *ngIf="projectForecast.project">
        <span class="full">{{projectForecast._product.nameHu | truncate:4}}</span>
        <span><mat-icon (click)="editForecastProduct()" class="pointer" matTooltip="Árucikk Csoport választás">edit</mat-icon></span>
    </div> 
    <p>
        <mat-form-field appearance="outline">
            <mat-label>EK Vetel</mat-label>
            <input type="number" pattern="^[0-9]\d*$" matInput name="ek_vetel" [(ngModel)]="projectForecast.ek_vetel" autocomplete="off" required>
        </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="outline">
            <mat-label>VK Eladas</mat-label>
            <input type="number" pattern="^[0-9]\d*$" matInput name="vk_eladas" [(ngModel)]="projectForecast.vk_eladas" autocomplete="off" required>
        </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="outline">
            <mat-label>Inbetr.</mat-label>
            <input type="number" pattern="^[0-9]\d*$" matInput name="inbetr" [(ngModel)]="projectForecast.inbetr" autocomplete="off" required>
        </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="outline">
            <mat-label>Transport</mat-label>
            <input type="number" pattern="^[0-9]\d*$" matInput name="transport" [(ngModel)]="projectForecast.transport" autocomplete="off" required>
        </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="outline">
            <mat-label>Schulung</mat-label>
            <input type="number" pattern="^[0-9]\d*$" matInput name="schulung" [(ngModel)]="projectForecast.schulung" autocomplete="off" required>
        </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="outline">
            <mat-label>Garantie Rückstellung</mat-label>
            <input type="number" pattern="^[0-9]\d*$" matInput name="garancia" [(ngModel)]="projectForecast.garancia" autocomplete="off" required>
        </mat-form-field>
    </p>
    <p class="flex">
        <mat-form-field appearance="outline" class="margin-r-10 w100">
            <mat-label>Év</mat-label>
            <mat-select name="ev" [ngModel]="projectForecast.ev"  (selectionChange)="setYear($event)">
                <mat-option [value]="">Válassz</mat-option>
                <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w100">
            <mat-label>Negyedév</mat-label>
            <mat-select name="negyedev" [ngModel]="projectForecast.negyedev"   (selectionChange)="setQuarter($event)">
                <mat-option [value]="">Válassz</mat-option>
                <mat-option *ngFor="let quarter of [1,2,3,4]" [value]="quarter">
                    {{quarter}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </p>

    <hr>

    <p>
        <mat-form-field appearance="outline">
            <mat-label>Gemeinkosten</mat-label>
            <input type="number" pattern="^[1-9][0-9]?$|^100$" matInput name="gemeinpercent" [(ngModel)]="projectForecast.gemeinpercent" autocomplete="off" required>
            <mat-icon matSuffix>percent</mat-icon>
        </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="outline">
            <mat-label>Vorab Gewinn</mat-label>
            <input type="number" pattern="^[1-9][0-9]?$|^100$" matInput name="vorabgewinn" [(ngModel)]="projectForecast.vorabgewinn" autocomplete="off" required>
            <mat-icon matSuffix>percent</mat-icon>
        </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="outline">
            <mat-label>Odds</mat-label>
            <input type="number" pattern="^[1-9][0-9]?$|^100$" matInput name="esely" [(ngModel)]="projectForecast.esely" autocomplete="off" required>
            <mat-icon matSuffix>percent</mat-icon>
        </mat-form-field>
    </p>

    <p>
        <span class="padding-10">
            <button mat-mini-fab  color="primary" (click)="saveForecast()" [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
    </p>
</form>
</ng-template>

<ng-template #tmpForcastParts>
    <ng-container *ngIf="projectForecastParts.length > 0;">
        <table class="table">
            <tbody>
                <tr *ngFor="let p of projectForecastParts">
                    <td><app-product-container [product]="p._product"></app-product-container>
                        <mat-form-field appearance="outline" class="w150">
                            <input type="number" pattern="^[0-9]\d*$" matInput name="koltseg" [(ngModel)]="p.cost" autocomplete="off" required>
                            <mat-icon matPrefix>euro</mat-icon>
                        </mat-form-field>
                    </td>
                    <td>
                        <div class="flex">
                            <span class="padding-l-10">
                                <button mat-mini-fab  color="primary" (click)="updatePart(p)" matTooltip="Mentés">
                                    <mat-icon>save</mat-icon>
                                </button>
                            </span>
                            <span class="padding-l-10">
                                <button mat-icon-button  color="warn" matTooltip="Töröl" (confirm-click)="deletePart(p)" confirm>
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </span>
                            <ng-container *ngIf="p.id">
                                <span class="padding-l-10">
                                    <app-quick-log-info [data]="p"></app-quick-log-info>           
                                </span>
                            </ng-container>
                        </div>   
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>

    <div class="padding-10-0 margin-10-0">
        <button mat-mini-fab  color="accent" (click)="addPart()"> 
            <mat-icon>add</mat-icon>
        </button>
    </div>
</ng-template>
