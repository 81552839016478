/**
 * 
 */
import { EnumLanguage } from "src/app/enums/languages.enum";
import { EnumModule } from "src/app/enums/modules.enum";
import { EnumTemplateCategory } from "src/app/enums/templates/templateCategory.enum";
import { EnumTemplateType } from "src/app/enums/templates/templateType.enum";
import { Project } from "src/app/models/projects/project.model";
import { Task } from "src/app/models/tasks/task.model";
import { TaskProduct } from "src/app/models/tasks/taskProduct.model";
import { User } from "src/app/models/user";
import { ProjectService } from "src/app/services/projects/project.service";
import { SecurityService } from "src/app/services/security.service";
import { TaskService } from "src/app/services/tasks/task.service";
import { TaskPdfService } from "src/app/services/tasks/taskPdf.service";
import { TaskProductService } from "src/app/services/tasks/taskProduct.service";
import { AutoUnsub } from "src/app/utils/autoUnsubscribe";
import { BaseEntity } from "./baseEntity";


@AutoUnsub()
export abstract class BaseTaskEntity extends BaseEntity {
    task: Task = null;
    project: Project = null;

    taskService: TaskService;
    taskProductService: TaskProductService;
    projectService: ProjectService;
    ss: SecurityService;

    enumTemplateCategory = EnumTemplateCategory;
    enumTemplateType = EnumTemplateType;
    
    products: TaskProduct[];
    
    language: EnumLanguage | string = 'hu';
   
    // common filters for the tasks
    state: boolean = true;
    importance = null;
    status = null;
    assignee: User = null;

    // access
    hasAccessProjects = false;

    taskServiceGet$;
    projectGet$;

    constructor(taskService, taskProductService, projectService, securityService) {
        super(); 

        this.taskService = taskService;
        this.taskProductService = taskProductService;
        this.projectService = projectService;
        this.ss = securityService;

        this.hasAccessProjects = this.ss.hasAccess(EnumModule.Projects);
    }

    fetchTaskById(id:number) {
        this.taskServiceGet$ = this.taskService.get(id).subscribe(resp => {
            this.task = resp;

            this.taskProductsUpdated()
        });
    }

    fetchProject(project: Project) {
        this.projectGet$ = this.projectService.get(project).subscribe(res => {
            this.project = res;
        })
    }

    onTabClick(event) {
        if (event.tab.textLabel.toLowerCase().startsWith('pro')) {
            // if the tab text start with 'pro' should be the project tab and try to fetch it
            if (this.project) return;

            this.fetchProject(this.task._action.project);
        } else if (event.tab.textLabel.toLowerCase().startsWith('ajánlatok')) {
            this.taskProductsUpdated();
        }  else if (event.tab.textLabel.toLowerCase().startsWith('ajánlatk')) {

         } else if (event.tab.textLabel.toLowerCase().startsWith('megrend')) {
 
         } else if (event.tab.textLabel.toLowerCase().startsWith('jav')) {

         }  
    }

    taskProductsUpdated() {
        this.taskProductService.getByTask(this.task.id).subscribe(products => {
            this.products = products;
            this.resetPdfGeneration();
        })
    }
    
    taskUpdated(task) {
        // because the task update we have to refetch the project becuase it may caused changed with it
        this.project = null;
        this.task = task;

        if (this.task.action && this.task._action.project) {
            setTimeout(() => {
            // need a delay to keep consisteny between Task and Project
            this.fetchProject(this.task._action.project);
            }, 500);
        }   
    }
    
    projectUpdated(project) {
        this.project = project;

        setTimeout(() => {
            // need a delay to keep consisteny between Task and Project
            this.fetchTaskById(this.task.id);
        }, 500);
    }

    actionsUpdated(actions) {
       let taskAction = actions.filter(a => a.id == this.task.action);
       
       if (!taskAction) {
        // the Action which was deleted to the Task is deleted
        this.task.action = null;
        this.task._action = null;

        this.project = null;
       }
    }

    resetPdfGeneration() {
        //ABSTRACT method:  to force overwrite with the child calss
        // we only need to unset the sections for the pdf to forece to restart the process
    }
    
}