import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { Template, TemplateSection } from 'src/app/models/template.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { NotificationService } from 'src/app/services/notification';
import { TemplateService } from 'src/app/services/template.service';
import { EnumNotificationType } from 'src/app/enums/notificationType.enum';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'] 
})
export class TemplateComponent extends BaseEntity implements OnInit {
  template: Template = new Template(null);
  sections: TemplateSection[] = [];

  tempGet$;
  tempSecGet$;
  tempCreate$;
  tempUpdate$;
  tempDel$;
  tempSecSave$;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private templateService: TemplateService,
    private modelFactory: ModelFactory,
    private notificationService: NotificationService,
  ) {
    super()
  }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id');

    if (id) {
     this.fetchTemplate(id);
    } else {
      this.template = this.modelFactory.getNewModel(Template)
      this.template.type = 'pdf';
    }
  }

  fetchTemplate(id) {
   this.tempGet$ = this.templateService.get(id).subscribe(i => {
      this.template = i;
      this.fetchSections();
    });
  }

  fetchSections() {
    this.tempSecGet$ = this.templateService.getTemplateSections(this.template.id).subscribe(res => {
      this.sections = res;
      this.setOrderNumbers(this.sections);
    });
  }

  createNewSection() {
    let newSection = this.modelFactory.getNewModel(TemplateSection);
    newSection.id = this.getNextId();
    newSection.template = this.template.id;
    newSection._template = this.template;

    this.sections.push(newSection);
    this.setOrderNumbers(this.sections);

    this.notificationService.dirty();
  }


  changeType(event) {
    if (event.value) {
      this.template.type = event.value;
    }
  }

  save() {
    if (!this.validateTemplate()) {
      return;
    }

    (this.template.id) ? this.update() : this.create();
  }

  validateTemplate() {
    // becuse we have sub component with selectors we have to validate their values
    if (!this.template.category) {
      this.notificationService.notify(EnumNotificationType.TemplateCategoryMissing)
      return false;
    }

    return true;
  }

  create() {
    this.tempCreate$ = this.templateService.create(this.template).subscribe(t => {
      this.template = t;
      this.fetchSections();
    });
  }

  update() {
    this.tempUpdate$ = this.templateService.update(this.template).subscribe(t => {
      this.template = t;
    });
  }


  delete() {
    this.tempDel$ = this.templateService.delete(this.template).subscribe(t => {
      this.router.navigate(['../'], {
        relativeTo: this.route  
      });
    });
  }

  deleteSection(section) {
    // remove an idividual section from the list and after call save
    this.sections = this.sections.filter(s => s.id != section.id);
    this.notificationService.dirty();
  }

  saveSections() {
    // save the sections, send all the sections at once to the server
    let payload = {
      templateId: this.template.id,
      sections: this.sections
    }

    this.tempSecSave$ = this.templateService.saveTemplateSections(payload).subscribe(s => {
      this.sections = s;
      this.notificationService.success();
    });
  }
  
  moveUp(section) {
    this.moveElementUp(this.sections, section);
    this.notificationService.dirty();
  }

  moveDown(section) {
    this.moveElementDown(this.sections, section);
    this.notificationService.dirty();
  }

  updateSectionBodyHu(content, section) {
    section.bodyHu = content;
  }

  updateSectionBodyEn(content, section) {
    section.bodyEn = content;
  }

  updateSectionTitleHu(content, section) {
    section.titleHu = content;
  }

  updateSectionTitleEn(content, section) {
    section.titleEn = content;
  }

}
