<div  [class.in-dlg]="isDlg">

    <div class="padding-10-0" *ngIf="pdfData.sections.length > 0">
    <app-language-selector [language]="language" (onChange)="changeLanguage($event)" *ngIf="isLanguageOption"></app-language-selector>
    </div>

    <ng-container *ngFor="let section of pdfData.sections; let first = first; let last = last">
        <div class="section" [attr.data-section]="section.id">
            <ng-container *ngIf="section.defaultSection; else customSection">
                
                <!-- Default section header -->
                <div class="flex">
                    <div class="full">
                        <ng-container *ngIf="!first">
                            <mat-icon class="cursor text-green" (click)="moveUp(section)" click-stop-propagation>arrow_circle_up</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="!last">
                            <mat-icon class="cursor text-red" (click)="moveDown(section)" click-stop-propagation>arrow_circle_down</mat-icon>
                        </ng-container>
                    </div>
                    <button mat-icon-button  color="warn"  matTooltip="Eltávolítás" (confirm-click)="deleteSection(section)" confirm>
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <!-- Default Section Options -->
                <ng-container *ngIf="section.defaultSection == EnumTemplateDefaultSection.PdfHeader">
                    <app-default-section-pdf-header [task]="task" [defaultConfig]="section.defaultConfig"></app-default-section-pdf-header>
                </ng-container>
                <ng-container *ngIf="section.defaultSection == EnumTemplateDefaultSection.ProductsMarketing">
                    <app-default-section-products-marketing ></app-default-section-products-marketing>
                    <app-default-config-marketing [defaultConfig]="section.defaultConfig"></app-default-config-marketing>
                </ng-container>
                <ng-container *ngIf="section.defaultSection == EnumTemplateDefaultSection.ProductList">
                   <app-default-section-product-list  
                   [pdfSection]="section"
                   [taskProducts]="products" 
                   [language]="pdfData.language">
                </app-default-section-product-list>
                </ng-container>
                <ng-container *ngIf="section.defaultSection == EnumTemplateDefaultSection.PriceTable">
                    <app-default-section-price-table  
                    [pdfSection]="section"
                    [taskProducts]="products" 
                    [language]="pdfData.language"
                    [currency]="pdfData.currency">
                </app-default-section-price-table>
                </ng-container>
                <ng-container *ngIf="section.defaultSection == EnumTemplateDefaultSection.SignitureAndStamp">
                    <div class="text-blue padding-10-0">Aláírás</div> 
                    <app-default-config-signature [defaultConfig]="section.defaultConfig"></app-default-config-signature>
                </ng-container>
                <ng-container *ngIf="section.defaultSection == EnumTemplateDefaultSection.ProductTable">
                    <div class="text-blue padding-10-0">Termék Táblázat</div>
                    <app-default-config-product-table [defaultConfig]="section.defaultConfig"></app-default-config-product-table>
                </ng-container>
                <ng-container *ngIf="section.defaultSection == EnumTemplateDefaultSection.InvoiceAndDeliveryAddress">
                    <div class="text-blue padding-10-0">Számlázási és szállítási adatok</div>
                    <app-default-config-invoice-and-delivery-address [defaultConfig]="section.defaultConfig"></app-default-config-invoice-and-delivery-address>
                </ng-container>
                <ng-container *ngIf="section.defaultSection == EnumTemplateDefaultSection.VatNumber">
                    <div class="text-blue padding-10-0">Adószám</div>
                </ng-container>
                <ng-container *ngIf="section.defaultSection == EnumTemplateDefaultSection.CustomerConfirmation">
                    <div class="text-blue padding-10-0">Visszaigazolás</div>
                    <app-default-config-customer-confirmation [task]="task" [defaultConfig]="section.defaultConfig"></app-default-config-customer-confirmation>
                </ng-container>
            </ng-container>

            <!--header-->
            <ng-template #customSection>
                <div class="flex">
                    <div class="full">
                        <ng-container *ngIf="!first">
                            <mat-icon class="cursor text-green" (click)="moveUp(section)" click-stop-propagation>arrow_circle_up</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="!last">
                            <mat-icon class="cursor text-red" (click)="moveDown(section)" click-stop-propagation>arrow_circle_down</mat-icon>
                        </ng-container>
                    </div>
                    <div>
                        <div class="flex">
                            <ng-container *ngIf="templateType == 'pdf'">
                                <mat-checkbox name="columns" [(ngModel)]="section.columns">2 oszlop</mat-checkbox>
                            </ng-container>
                            
                            <ng-container *ngIf="(section.id | typeof) === 'number'">
                                <app-template-section-versions [sectionId]="section.id" (onSelect)="setNewSectionData($event)"></app-template-section-versions>
                            </ng-container>

                            <button mat-icon-button class="text-green" (click)="addNewSection(section)" matTooltip="Új Bekezdés hozzáadása ezen bekezdés után"> 
                                <mat-icon>add</mat-icon>
                            </button>

                            <button mat-icon-button  color="warn"  matTooltip="Eltávolítás" (confirm-click)="deleteSection(section)" confirm>
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <!--content-->
                <div class="padding-10" [class.row]="section.columns">
                    <div class="textTitle margin-10-0" [class.col]="section.columns">
                        <app-html-container [content]="section.title" (onChange)="section.title=$event"></app-html-container>
                    </div>
                    <div class="margin-10-0 txtBody"  [class.col]="section.columns">
                        <app-html-container [content]="section.body" (onChange)="section.body=$event"></app-html-container>
                    </div>
                </div>
            </ng-template>
        </div>        
    </ng-container>

    <div class="flex  padding-10" *ngIf="pdfData.sections.length > 0">
        <div class="full">
            <button mat-mini-fab  color="accent" (click)="addNewSection()" matTooltip="Új Bekezdés hozzáadása"> 
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div>
            <button mat-icon-button  color="warn" (click)="resetPdfGeneration()" matTooltip="Újrakezdés"> 
                <mat-icon>loop</mat-icon>
            </button>
        </div>
    </div>

    <div class="padding-10-0 text-center" *ngIf="pdfData.sections.length > 0">
        <button class="w300" mat-flat-button  color="primary" (click)="generatePdf()">
            Generálás
        </button>
    </div>

</div>