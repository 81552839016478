export class Profile {
    id: number = null;
    user = {
        id: null,
        email: null, 
        firstName: null, 
        lastName: null, 
        color: null
    };
    phoneCompany?: string = null;
    phonePersonal?: string = null;
    address?: string = null;
    timezone?: string = null;
    language?: string = null;
    hourlyRate?: number = null;
    signature: string;
    created?: Date = null;
    updated?: Date = null;

   constructor(data: any) {
       if (data) {
           this.id = data.id;
           this.user = data.user;
           this.phoneCompany = data.phoneCompany;
           this.phonePersonal = data.phonePersonal;
           this.address = data.address;
           this.language = data.language;
           this.timezone = data.timezone;
           this.hourlyRate = data.hourlyRate;
           this.signature = data.signature;
           this.created =  new Date(data.created);
           this.updated = new Date(data.updated);
       }
   }
}