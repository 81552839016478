<div class="section">
    <div class="header">
        <div class="title"><span>Tevékenységek</span></div>
    </div>

 <form #f="ngForm" *ngIf="taskActivity">
        <mat-form-field appearance="outline">
            <mat-label>Válassz</mat-label>
            <mat-select name="activity" [(ngModel)]="taskActivity.activity" required (selectionChange)="save()">
                <mat-option [value]="enumTaskActivity.Procurement">Anyagbeszerzés</mat-option>
                <mat-option [value]="enumTaskActivity.Meeting">Megbeszélés</mat-option>
                <mat-option [value]="enumTaskActivity.Warehousing">Raktártakarítás, Rendezés</mat-option>
                <mat-option [value]="enumTaskActivity.MachineMovement">Gépmozgatás</mat-option>
                <mat-option [value]="enumTaskActivity.Exhibition">Vásár, kiállítás</mat-option>
                <mat-option [value]="enumTaskActivity.Course">Tanfolyam</mat-option>
                <mat-option [value]="enumTaskActivity.CustomerVisit">Vevőlátogatás</mat-option>
                <mat-option [value]="enumTaskActivity.TelephoneMarketing">Telefonmarketing</mat-option>
                <mat-option [value]="enumTaskActivity.Office">Iroda</mat-option>
                <mat-option [value]="enumTaskActivity.Other">Egyéb</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="padding-20-0">
                <!--<button [disabled]="f.invalid" mat-mini-fab  color="primary" (click)="save()" matTooltip="Mentés">
                    <mat-icon>save</mat-icon>
                </button>-->
                <span class="padding-l-10">
                    <app-quick-log-info [data]="taskActivity"></app-quick-log-info>
                </span>  
        </div>
    </form>
</div>

