export class TaskProduct {
    id: number = null;
    amount: number = 1;
    active: boolean = true;
    task: number = null;
    _task: any = null;
    
    product: number = null;
    _product: any = null;
    item: number = null;
    _item: any = null;
   
    project: number = null;
    _project: any = null;

    costPrice: number;
    sellingPrice: number;

    inventory_transaction: number;
    _inventoryTransaction: any;

    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.active = data.active;
            this.costPrice = data.costPrice;
            this.sellingPrice = data.sellingPrice;

            this.inventory_transaction = data.inventory_transaction;
            if (data._inventoryTransaction) {
                this._inventoryTransaction = data._inventoryTransaction;
            }

            if (data.amount) {
                this.amount = data.amount;
            }
           
            this.task = data.task;
            if ('_task' in data) {
                this._task = data._task;
            }

            this.product  = data.product;
            if ('_product' in data) {
                this._product = data._product;
            }

            this.item  = data.item;
            if ('_item' in data) {
                this._item = data._item;
            }

            this.project  = data.project;
            if ('_project' in data) {
                this._project = data._project;
            }
            
            if (data.creator) {
                this.creator = data.creator;
            }
           
            if (data.lastModifier) {
                this.lastModifier = data.lastModifier;
            }
           
            if (data.created) {
                this.created = new Date(data.created);
            }
            
            if (data.updated) {
                this.updated = new Date(data.updated);
            }
        }  
    }
  }
