
export enum EnumCountry {
    Albania = 'albania',
    Andorra = 'andorra',
    Austria = 'austria',
    Belarus = 'belarus',
    Belgium = 'belgium',
    Bosnia = 'bosnia',
    Bulgaria = 'bulgaria',
    Croatia = 'croatia',
    CzechRepublic = 'czech_republic',
    Denmark = 'denmark',
    Estonia = 'estonia',
    Finland = 'finland',
    France = 'france',
    Germany = 'germany',
    Greece = 'greece',
    Hungary = 'hungary',
    Iceland = 'iceland',
    Ireland = 'ireland',
    Italy = 'italy',
    Latvia = 'latvia',
    Liechtenstein = 'liechtenstein',
    Lithuania = 'lithuania',
    Luxembourg = 'luxembourg',
    Malta = 'malta',
    Moldova = 'moldova',
    Monaco = 'monaco',
    Montenegro = 'montenegro',
    Netherlands = 'netherlands',
    Macedonia = 'macedonia',
    Norway = 'norway',
    Poland = 'poland',
    Portugal = 'portugal',
    Romania = 'romania',
    Russia = 'russia',
    SanMarino = 'san_marino',
    Serbia = 'serbia',
    Slovakia = 'slovakia',
    Slovenia = 'slovenia',
    Spain = 'spain',
    Sweden = 'sweden',
    Switzerland = 'switzerland',
    Ukraine = 'ukraine',
    UnitedKingdom = 'united_kingdom',
  }