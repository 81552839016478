import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from './../notification';
import { TaskCustomerOrder } from 'src/app/models/tasks/taskCustomerOrder.model';

@Injectable({
    providedIn: 'root'
  })
export class TaskCustomerOrderService {
    baseUrl = environment.baseUrl;

    URL = '/tasks/customer-orders';

    taskInterest$ = new BehaviorSubject<TaskCustomerOrder>(null);

    // list items
    dataSource$ = new BehaviorSubject<any[]>([])
    data = this.dataSource$.asObservable();

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {}


    get(task_id) {
        let url = this.baseUrl + this.URL + '/' + task_id + '/';
        return this.http.get<TaskCustomerOrder>(url).pipe(
            map(res => new TaskCustomerOrder(res))
        )
    }

    update(payload) {
        // task is the id!
        let url = this.baseUrl + this.URL + '/' + payload.task + '/';
        return this.http.put(url, payload).pipe(
            map(res => new TaskCustomerOrder(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }
    
    onChange(taskInterest) {
        this.taskInterest$.next(taskInterest);
    }
}