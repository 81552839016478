<div class="flex padding-10-0">
    <div class="helper-text padding-r-10">Küldő</div>
    <div class="w300">
        <app-user-selector [user]="sender" (onChange)="setSender($event)"></app-user-selector>
    </div>
</div>

<div>
    <mat-checkbox [ngModel]="includeStamp" (change)="setIncludeStamp()">Bélyegző beillesztése</mat-checkbox>
</div>

<div>
    <mat-checkbox [ngModel]="includeSignature" (change)="setIncludeSignature()">Küldő aláírásának beillesztése</mat-checkbox>
</div>