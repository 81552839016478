<div mat-dialog-title>
    <div class="flex">
        <div class="full">
            <ng-container *ngIf="isVendor; else tmpClient">Beszállító</ng-container>
            <ng-template #tmpClient>Cég</ng-template>
        </div>
        <button mat-mini-fab color="accent" (click)="createOrganisation()" matTooltip="Létrehozása">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
<div mat-dialog-content>
    <div class="padding-l-10 text-small text-light-grey">
        <mat-checkbox name="isvendor" [(ngModel)]="isVendor" (change)="search()">Keresés a Beszállítók között</mat-checkbox>
    </div>
    <form>
        <mat-form-field appearance="outline">
            <mat-icon class="pointer text-red"  (click)="delete()" matSuffix matTooltip="Töröl">delete</mat-icon>
            <input matInput name="txtSearch" 
                (keyup)="search()" 
                [(ngModel)]="txtSearch" 
                placeholder="Keresés (min 2 karakter)"
                [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
            <mat-option (click)="select(option)" *ngFor="let option of options" [value]="option">
                {{option.name}}
            </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form>
</div>