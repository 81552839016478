import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskEvent } from 'src/app/models/tasks/taskEvent.model';
import { TaskEventService } from 'src/app/services/tasks/taskEvent.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';
import { TaskUtil } from 'src/app/utils/task.util';

@AutoUnsub()
@Component({
  selector: 'app-bottom-task-event-search',
  templateUrl: './bottom-task-event-search.component.html',
  styleUrls: ['./bottom-task-event-search.component.scss']
})
export class BottomTaskEventSearchComponent implements OnInit, AfterViewInit  {
  @ViewChild("searchInput") private _inputElement: ElementRef;

  txtSearch: string;
  taskEvents: TaskEvent[] = [];

  eventList$;

  constructor(
    private taskEventService: TaskEventService,
    private el: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private _bottomSheetRef: MatBottomSheetRef<BottomTaskEventSearchComponent>,
  ) { }

  ngOnInit(): void {
  }

  search() {
    if (!this.txtSearch) {
      return
    }

    let params = {
      limit: 500,
      search: this.txtSearch
    }

    this.eventList$ = this.taskEventService.list({params}).subscribe(res => {
        this.taskEvents = res.results;

        // add abs path to the entity to navigate to the Task
        this.taskEvents.forEach(tE => {
          tE['path'] = TaskUtil.getTaskPath(tE._task.taskType);
        })
    })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._inputElement.nativeElement.focus();
    }, 100)
  }


}
