<ul class="breadcrumb"> 
    <li>Cégek</li>  
</ul>

<div class="flex"> 
    <button (click)="lookup()" mat-mini-fab color="primary" [disabled]="txtSearch === ''">
        <mat-icon matSuffix>search</mat-icon>
    </button>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Keresés</mat-label>
            <input [(ngModel)]="txtSearch" matInput placeholder="?" (keyup.enter)="lookup()">
            <mat-icon  class="pointer" matTooltip="Keresés Törlése" (click)="noSearch()" matSuffix>close</mat-icon>
        </mat-form-field>
    </div>

    <div class="padding-l-10">
        <mat-checkbox name="isvendor" [(ngModel)]="isVendor" (change)="filterVendors()">Beszállítók</mat-checkbox>
    </div>

    <div class="full text-right">
        <button mat-mini-fab  color="accent" [routerLink]="['organisation']" matTooltip="Új Cég"> 
            <mat-icon>add</mat-icon>
        </button>
    </div> 
</div>

<mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Név</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-bottom-organisation [data]="row"></app-bottom-organisation>
            <span class="link" (click)="open(row)">{{row.name}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="industry">
        <mat-header-cell *matHeaderCellDef>Iparág</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.industry}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="updated">
        <mat-header-cell *matHeaderCellDef>Beszállító</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-static-true-false-icon [value]="row.isVendor" *ngIf="row.isVendor"></app-static-true-false-icon>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row class="cursor" *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="0"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
    [showFirstLastButtons]="true"
></mat-paginator>