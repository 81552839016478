import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlanningProjectService } from 'src/app/services/projects/planningProject.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-dlg-search-project-template',
  templateUrl: './dlg-search-project-template.component.html',
  styleUrls: ['./dlg-search-project-template.component.scss']
})
export class DlgSearchProjectTemplateComponent implements OnInit {
  txtSearch: string = '';
  options = [];
  timeout = null;
  context = null;

  list$;
  
  constructor(
    private planningProjectService: PlanningProjectService,
    public dialogRef: MatDialogRef<DlgSearchProjectTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.txtSearch = data.name;

    if (data.context) {
      this.context = data.context
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.search();
    }, 0)
  }

  search() {
    this._doSearch();
  }

  _doSearch() {
    let params = {
      search: this.txtSearch,
      limit: 25,
      archived: false
    };

    if (this.context) {
      params['context'] = this.context
    }

    this.list$ = this.planningProjectService.list({params}).subscribe(data => {
      this.options = data.results
     });
  }

  select(projectTemplate) {
    if (!projectTemplate) {
      return;
    }
    this.txtSearch = projectTemplate.name;

    setTimeout(() => {
      this.dialogRef.close(projectTemplate);
    }, 200);
     
  }

  delete() {
    this.txtSearch = '';

    setTimeout(() => {
      this.dialogRef.close('');
    }, 300);
  }



}
