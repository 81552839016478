import { Component, Input,  OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OrganisationService } from 'src/app/services/organisation.service';
import { Organisation, OrganisationItem } from 'src/app/models/organisation';
import { DlgEditOrganisationItemComponent } from 'src/app/shared/dialogs/edit/dlg-edit-organisation-item/dlg-edit-organisation-item.component';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';
import { ItemService } from 'src/app/services/item.service';

@AutoUnsub()
@Component({
  selector: 'app-organisation-item',
  templateUrl: './organisation-item.component.html',
  styleUrls: ['./organisation-item.component.scss']
})
export class OrganisationItemComponent extends BaseEntity implements OnInit {
  @Input() organization: Organisation;

  orgItems = null;

  itemList$;
  dlgEdit$;
  itemUpdate$;
  itemDelete$;
  
  constructor(
    private organisationService: OrganisationService,
    private itemService: ItemService,
    private dialog: MatDialog,
    private modelFactory: ModelFactory
  ) {
    super();
  }

  ngOnInit() {
   this.loadItems();
  }

  loadItems() {
    let params = {organization: this.organization.id, limit:1000};
    this.itemList$ = this.organisationService.listItems({params}).subscribe(resp => {
      this.orgItems = resp.results;

      this.sortByType();
    })
  }

  sortByType() {
    // sort by product type (machines first)
   this.orgItems.sort(function(a, b) {
     let textA = a._item._product.type.toUpperCase();
     let textB = b._item._product.type.toUpperCase();
     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
 });
 }

  add() {
    let orgItem = this.modelFactory.getNewModel(OrganisationItem);
    orgItem.setOrganisation(this.organization.id, this.organization)
    this.editOrgItemDlg(orgItem);
  }
 
  editOrgItemDlg(orgItem) {
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.data = {orgItem: orgItem};

    const dialogRef = this.dialog.open(DlgEditOrganisationItemComponent, dialogConfig);

    this.dlgEdit$ = dialogRef.afterClosed().subscribe(orgItem => {
        this.loadItems();
    });
  }

  updateOrgItem(orgItem) {
    this.itemUpdate$ = this.organisationService.updateItem(orgItem).subscribe(() => {
     // item in-memory already right
    })
  }

  deleteOrgItemDlg(orgItem) {
    this.itemDelete$ = this.organisationService.deleteItem(orgItem).subscribe(() => {
      this.orgItems = this.orgItems.filter(oI => oI.id != orgItem.id);
    })
  }

  fetchBuiltInItems(orgItem) {
    this.itemService.getBuiltInItems(orgItem.item).subscribe(res => {
      orgItem['builtInItems'] = res.results;
    })
  }

}

