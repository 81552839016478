import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { BuiltInItem } from 'src/app/models/builtInItem.model';
import { Item } from 'src/app/models/item.model';
import { ItemService } from 'src/app/services/item.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { DlgSearchItemComponent } from 'src/app/shared/dialogs/search/dlg-search-item/dlg-search-item.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-item-built-into',
  templateUrl: './item-built-into.component.html',
  styleUrls: ['./item-built-into.component.scss']
})
export class ItemBuiltIntoComponent extends BaseEntity implements OnInit {
  @Input() item: Item;
  builtIns: BuiltInItem[];

  itemBuiltList$;
  itemCreate$;
  itemUpdate$;
  itemDelete$;
  dlgSearchItem$;

  constructor(
    private itemService: ItemService,
    public router: Router,
    public route: ActivatedRoute,
    private modelFactory: ModelFactory,
    private dialog: MatDialog,
  ) {
    super()
  }

  ngOnInit() {
    let params = {
      limit: 1000,
      parent: this.item.id
    }
    this.itemBuiltList$ = this.itemService.getBuiltInItems(this.item.id).subscribe(res => {
        this.builtIns = res.results;
    })
  }

  openItem(itemId: number) {
    // Need Angular to reaload an already loaded componenet
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
  };

    this.router.navigate(['./../item'],  {
      queryParams: {id: itemId}, 
      relativeTo: this.route  
    });
  }

  add() {
    let builtIn = this.modelFactory.getNewModel(BuiltInItem);
    // fake id
    builtIn.id = this.getNextId();
    builtIn.built_in = this.item.id;
    builtIn._builtIn = this.item;
    // default amount 1
    builtIn.amount = 1;
    
    this.openItemSearchDlg(builtIn);

  }

  save(builtIn) {
    if (typeof builtIn.id === 'string') {
      // create
      this.create(builtIn);
    } else {
      this.update(builtIn);
    }
  }

  create(builtIn) {
    this.itemCreate$ = this.itemService.createBuiltIn(builtIn).subscribe(res => {
      // fitler out the dummy email
      this.builtIns = this.builtIns.filter(e => e.id != builtIn.id);
      // add the newly created to the list
      this.builtIns.push(res)
    })
  }

  update(builtIn) {
    this.itemUpdate$ = this.itemService.updateBuiltIn(builtIn).subscribe();
  }

  delete(builtIn) {
    if (typeof builtIn.id === 'string') {
      this.builtIns = this.builtIns.filter(e => e.id != builtIn.id);
    } else {
      this.itemDelete$ = this.itemService.deleteBuiltIn(builtIn).subscribe(() => {
        this.builtIns = this.builtIns.filter(e => e.id != builtIn.id);
      });
    }
   
  }

  openItemSearchDlg(builtIn) {
    let dialogConfig = new MatDialogConfig(); 
    
    let item = (builtIn.item) ? builtIn._item : null;
    
    dialogConfig.data = {
      item: item
    };

    const dialogRef2 = this.dialog.open(DlgSearchItemComponent, dialogConfig);

    this.dlgSearchItem$ = dialogRef2.componentInstance.onSave.subscribe(item => {
      if (item && item.id) {
        builtIn.item = item.id;
        builtIn._item = item;

        this.builtIns.push(builtIn);
      } else {
        builtIn.item = null;
        builtIn._item = null;

        // TODO: Remove
      } 
    })
  }

}
