<div class="section">
    <div class="header">
        <div class="title">
            <span>Telefon ({{phones.length}})</span>
        </div>
    </div>

    <div *ngFor="let obj of phones">
            <form class="from-group-inline" #f="ngForm">
                <p>
                    <mat-form-field appearance="outline">
                        <mat-label>Telefon</mat-label>
                        <input matInput type="tel" name="name" [(ngModel)]="obj.phone" autocomplete="off" required>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline">
                        <mat-label>Megjegyzés</mat-label>
                        <input matInput name="industry" [(ngModel)]="obj.name" autocomplete="off">
                    </mat-form-field>
                </p>
                <p>
                    <button mat-mini-fab  color="primary" (click)="save(obj)"  [disabled]="f.invalid" matTooltip="Mentés">
                        <mat-icon>save</mat-icon>
                    </button>
                </p>
                <p>
                    <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete(obj)" confirm>
                        <mat-icon>delete</mat-icon>
                    </button>
                </p>
                <p>
                    <app-quick-log-info [data]="obj"></app-quick-log-info>
                </p>
            </form>          
    </div>   
    <div class="padding-20-0">
        <button mat-mini-fab color="accent" (click)="add()" matTooltip="Új Szám">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
