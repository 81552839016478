import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseList } from 'src/app/common/baseClasses/baseList';
import { UrlParamService } from 'src/app/services/urlParam.service';
import { UsersService } from 'src/app/services/users.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [UrlParamService]
})
export class UsersComponent extends BaseList implements OnInit  {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  displayedColumns = ['name', 'status', 'active', 'admin', 'verified', 'worker'];

  users$;

  constructor(
    private usersService: UsersService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService
  ) {
    super()

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit() {
    let defaultParams = {}

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      }

      this.users$ = this.usersService.list().subscribe(data => {
        this.setListResponseData(data);
      });
    })
  }  
    
  open(user) {
    this.router.navigate(['user'],  {
      queryParams: {id: user.id}, 
      relativeTo: this.route  
    });
  }

}
