import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// pipes
import { IsoDate } from './pipes/isodate.pipe';
import { DateTime } from './pipes/dateTime.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { TypeofPipe } from './pipes/typeof.pipe';
import { FileNameFromPath } from './pipes/fileNameFromPath.pipe';
import { StripHtmlPipe } from './pipes/stripeHtml.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { DateDate } from './pipes/dateDate.pipe';

import { CustomMaterialModule } from './material.module';
//notification
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

// BottomSheet
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA, MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';


import { DashComponent } from './dash/dash.component';
import { LoginComponent } from './public/login/login.component';
// accounts
import { AccountsComponent } from './accounts/accounts.component';
import { CompaniesComponent } from './accounts/companies/companies.component';

import { LoaderComponent } from './shared/loader/loader.component';
import { HttpErrorInterceptor } from './services/httpError.interceptor';

// Guards
import { AuthGuard } from './services/auth.guard';
import { ModuleGuard } from './services/module.guard';
// services
import { AuthService } from './services/auth.service';
import { LoaderService } from './services/loader.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenService } from './services/token.interceptor';
import { DlgErrorComponent } from './shared/dialogs/dlg-error/dlg-error.component';
import { ErrorMsgService } from './services/errorMsg.service';
import { ContactsComponent } from './contacts/contacts.component';
import { OrganisationsComponent } from './contacts/organisations/organisations.component';
import { OrganisationComponent } from './contacts/organisations/page-organisation/organisation/organisation.component';
import { OrganisationAddressComponent } from './contacts/organisations/page-organisation/organisation/organisation-address/organisation-address.component';
import { OrganisationEmailComponent } from './contacts/organisations/page-organisation/organisation/organisation-email/organisation-email.component';
import { OrganisationPhoneComponent } from './contacts/organisations/page-organisation/organisation/organisation-phone/organisation-phone.component';
import { NotificationService } from './services/notification';
import { DlgConfirmComponent } from './shared/dialogs/dlg-confirm/dlg-confirm.component';
import { ConfirmDirective } from './directives/confirm.directive';
import { DndDirective } from './directives/dnd.directive';
import { ClickStopPropagation } from './directives/click-stop-propagation.directive';
import { UsersComponent } from './accounts/users/users.component';
import { UserComponent } from './accounts/users/user/user.component';
import { StaticTrueFalseIconComponent } from './shared/boolean/static-true-false-icon/static-true-false-icon.component';
import { ProfileComponent } from './accounts/users/user/profile/profile.component';
import { ProfileService } from './services/profile.service';
import { QuickLogInfoComponent } from './shared/bottomSheets/quick-log-info/quick-log-info.component';
import { QuickLogInfoDetailsComponent } from './shared/bottomSheets/quick-log-info/quick-log-info-details/quick-log-info-details.component';
import { PersonsComponent } from './contacts/persons/persons.component';
import { PersonComponent } from './contacts/persons/person/person.component';
import { BottomOrganisationComponent } from './shared/bottomSheets/bottom-organisation/bottom-organisation.component';
import { BottomOrganisatonDetailsComponent } from './shared/bottomSheets/bottom-organisation/bottom-organisaton-details/bottom-organisaton-details.component';
import { BottomPeopleComponent } from './shared/bottomSheets/bottom-people/bottom-people.component';
import { BottomPeopleDetailComponent } from './shared/bottomSheets/bottom-people/bottom-people-detail/bottom-people-detail.component';
import { PersonPhoneComponent } from './contacts/persons/person/person-phone/person-phone.component';
import { PersonEmailComponent } from './contacts/persons/person/person-email/person-email.component';
import { DlgSearchOrganisationComponent } from './shared/dialogs/search/dlg-search-organisation/dlg-search-organisation.component';
import { ProjectsComponent } from './projects/projects.component';
import { OrganisationItemComponent } from './contacts/organisations/page-organisation/organisation/organisation-item/organisation-item.component';
import { ItemsComponent } from './items/items.component';
import { ItemComponent } from './items/page-item/item/item.component';
import { ProductComponent } from './products/page-product/product/product.component';
import { ProductsComponent } from './products/products.component';
import { ManufacturersComponent } from './manufacturers/manufacturers.component';
import { CategoriesComponent } from './categories/categories.component';
import { UrlParamService } from './services/urlParam.service';
import { ManufacturerComponent } from './manufacturers/page-manufacturer/manufacturer/manufacturer.component';
import { CategoryComponent } from './categories/page-category/category/category.component';
import { NullValueService } from './services/nullValue.service';
import { DlgSearchCategoryComponent } from './shared/dialogs/search/dlg-search-category/dlg-search-category.component';
import { DlgSearchManufacturerComponent } from './shared/dialogs/search/dlg-search-manufacturer/dlg-search-manufacturer.component';
import { PageManufacturerComponent } from './manufacturers/page-manufacturer/page-manufacturer.component';
import { DlgEditManufacturerComponent } from './shared/dialogs/edit/dlg-edit-manufacturer/dlg-edit-manufacturer.component';
import { ManufacturerService } from './services/manufacturer.service';
import { PageCategoryComponent } from './categories/page-category/page-category.component';
import { DlgEditCategoryComponent } from './shared/dialogs/edit/dlg-edit-category/dlg-edit-category.component';
import { PageItemComponent } from './items/page-item/page-item.component';
import { DlgSearchProductComponent } from './shared/dialogs/search/dlg-search-product/dlg-search-product.component';
import { DlgSearchItemComponent } from './shared/dialogs/search/dlg-search-item/dlg-search-item.component';
import { DlgEditProductComponent } from './shared/dialogs/edit/dlg-edit-product/dlg-edit-product.component';
import { PageProductComponent } from './products/page-product/page-product.component';
import { PageOrganisationComponent } from './contacts/organisations/page-organisation/page-organisation.component';
import { DlgEditOrganisationComponent } from './shared/dialogs/edit/dlg-edit-organisation/dlg-edit-organisation.component';
import { PagePersonComponent } from './contacts/persons/page-person/page-person.component';
import { DlgEditPersonComponent } from './shared/dialogs/edit/dlg-edit-person/dlg-edit-person.component';
import { DlgEditOrganisationItemComponent } from './shared/dialogs/edit/dlg-edit-organisation-item/dlg-edit-organisation-item.component';
import { DlgEditItemComponent } from './shared/dialogs/edit/dlg-edit-item/dlg-edit-item.component';
import { IconItemTypeComponent } from './shared/snippets/icons/icon-item-type/icon-item-type.component';
import { RequestForgottenPassowrdComponent } from './public/request-forgotten-passowrd/request-forgotten-passowrd.component';
import { SecurityService } from './services/security.service';
import { initApp } from './services/initApp.service';
import { SidemenuComponent } from './common/sidemenu/sidemenu.component';
import { ProjectPlansComponent } from './project-plans/project-plans.component';
import { ProjectPlanComponent } from './project-plans/project-plan/project-plan.component';
import { ActionsPlansComponent } from './project-plans/actions-plans/actions-plans.component';
import { ActionPlanComponent } from './project-plans/actions-plans/page-action-plans/action-plan/action-plan.component';
import { ProjectActionsPlanComponent } from './project-plans/project-plan/project-actions-plan/project-actions-plan.component';
import { DlgEditPlanActionComponent } from './shared/dialogs/edit/dlg-edit-plan-action/dlg-edit-plan-action.component';
import { PageActionPlansComponent } from './project-plans/actions-plans/page-action-plans/page-action-plans.component';
import { DlgSearchPlanActionComponent } from './shared/dialogs/search/dlg-search-plan-action/dlg-search-plan-action.component';
import { ProjectComponent } from './projects/project/project.component';
import { ProjectImportanceComponent } from './shared/snippets/projects/project-importance/project-importance.component';
import { ProjectStatusComponent } from './shared/snippets/projects/project-status/project-status.component';
import { DlgSearchProjectTemplateComponent } from './shared/dialogs/search/dlg-search-project-template/dlg-search-project-template.component';
import { ProjectActionsTreesComponent } from './shared/snippets/projects/project-actions-tree/project-actions-tree.component';
import { ActionComponent } from './projects/project/action/action.component';
import { EventsComponent } from './projects/project/action/events/events.component';
import { DlgCreateCommentComponent } from './shared/dialogs/dlg-create-comment/dlg-create-comment.component';
import { AvatarComponent } from './shared/snippets/avatar/avatar.component';
import { DlgSearchUserComponent } from './shared/dialogs/search/dlg-search-user/dlg-search-user.component';
import { ProjectTypesComponent } from './project-plans/project-types/project-types.component';
import { ProjectTypeComponent } from './project-plans/project-types/project-type/project-type.component';
import { DlgSearchActionComponent } from './shared/dialogs/search/dlg-search-action/dlg-search-action.component';
import { TaskInterestPdfsComponent } from './shared/snippets/tasks/task-pdfs/task-pdfs.component';
import { PublicComponent } from './public/public.component';
import { FileUploadComponent } from './shared/snippets/file-upload/file-upload.component';
import { DlgSearchOrgContactComponent } from './shared/dialogs/search/dlg-search-org-contact/dlg-search-org-contact.component';
import { SetNewPasswordComponent } from './public/set-new-password/set-new-password.component';
import { NotificationContentComponent } from './shared/snippets/notification-content/notification-content.component';
import { ItemOrgComponent } from './items/page-item/item-org/item-org.component';
import { ItemBuiltIntoComponent } from './items/page-item/item-built-into/item-built-into.component';
import { CountrySelectorComponent } from './shared/snippets/selectors/country-selector/country-selector.component';
import { DlgSearchPeopleComponent } from './shared/dialogs/search/dlg-search-people/dlg-search-people.component';
import { EmailSendingComponent } from './shared/dialogs/email-sending/email-sending.component';
import { ProductRelatedOfferComponent } from './products/page-product/product-related-offer/product-related-offer.component';
import { AttributeOptionsComponent } from './attribute-options/attribute-options.component';
import { AttributesComponent } from './attribute-options/attributes/attributes.component';
import { AttributeGroupsComponent } from './attribute-options/attribute-groups/attribute-groups.component';
import { ProductsubmenuComponent } from './common/productsubmenu/productsubmenu.component';
import { PageAttributeGroupsComponent } from './attribute-options/attribute-groups/page-attribute-groups/page-attribute-groups.component';
import { AttributeGroupComponent } from './attribute-options/attribute-groups/page-attribute-groups/attribute-group/attribute-group.component';
import { PageAttributesComponent } from './attribute-options/attributes/page-attributes/page-attributes.component';
import { AttributeComponent } from './attribute-options/attributes/page-attributes/attribute/attribute.component';
import { DlgSearchAttributeGroupComponent } from './shared/dialogs/search/dlg-search-attribute-group/dlg-search-attribute-group.component';
import { DlgEditAttributeGroupComponent } from './shared/dialogs/edit/dlg-edit-attribute-group/dlg-edit-attribute-group.component';
import { ProductAttributesComponent } from './products/page-product/product-attributes/product-attributes.component';
import { DlgSearchAttributeComponent } from './shared/dialogs/search/dlg-search-attribute/dlg-search-attribute.component';
import { DlgEditAttributeComponent } from './shared/dialogs/edit/dlg-edit-attribute/dlg-edit-attribute.component';
import { DlgWysiwygComponent } from './shared/dialogs/dlg-wysiwyg/dlg-wysiwyg.component';
import { ProductImagesComponent } from './products/page-product/product-images/product-images.component';
import { ProductDocumentsComponent } from './products/page-product/product-documents/product-documents.component';
import { TemplatesComponent } from './templates/templates.component';
import { TemplateComponent } from './templates/template/template.component';
import { IconTemplateTypeComponent } from './shared/snippets/icons/icon-template-type/icon-template-type.component';
import { OrderNumber } from './pipes/orderNumber.pipe';
import { EurPipe } from './pipes/eur.pipe';
import { HufPipe } from './pipes/huf.pipe';
import { FormatPercentPipe } from './pipes/formatPercent.pipe';
import { TemplateSelectorComponent } from './shared/snippets/selectors/template-selector/template-selector.component';
import { UserSelectorComponent } from './shared/snippets/selectors/user-selector/user-selector.component';
import { HtmlContainerComponent } from './shared/snippets/html-container/html-container.component';
import { TextContainerComponent } from './shared/snippets/text-container/text-container.component';
import { DlgEditTextComponent } from './shared/dialogs/edit/dlg-edit-text/dlg-edit-text.component';
import { TemplateSectionVersionsComponent } from './shared/snippets/templates/template-section-versions/template-section-versions.component';
import { DlgTemplateSectionVersionsComponent } from './shared/dialogs/edit/dlg-template-section-versions/dlg-template-section-versions.component';
import { DefaultSectionPriceTableComponent } from './shared/snippets/templates/template-default-sections/default-section-price-table/default-section-price-table.component';
import { MyProfileComponent } from './accounts/my-profile/my-profile.component';
import { ConfigurationComponent } from './accounts/configuration/configuration.component';
import { DefaultSectionProductListComponent } from './shared/snippets/templates/template-default-sections/default-section-product-list/default-section-product-list.component';
import { CurrencySelectorComponent } from './shared/snippets/selectors/currency-selector/currency-selector.component';
import { CurrencyContainerComponent } from './shared/snippets/currency-container/currency-container.component';
import { CurrencyUtil } from './utils/currency.util';
import { TemplateBuilderComponent } from './shared/snippets/templates/template-builder/template-builder.component';
import { DefaultSectionPdfHeaderComponent } from './shared/snippets/templates/template-default-sections/default-section-pdf-header/default-section-pdf-header.component';
import { DefaultSectionProductsMarketingComponent } from './shared/snippets/templates/template-default-sections/default-section-products-marketing/default-section-products-marketing.component';
import { OrgSelectorComponent } from './shared/snippets/selectors/org-selector/org-selector.component';
import { LanguageSelectorComponent } from './shared/snippets/selectors/language-selector/language-selector.component';
import { TaskCustomerOfferComponent } from './tasks/task-customer-offers/task-customer-offer/task-customer-offer.component';
import { TaskVendorInterestsComponent } from './tasks/task-vendor-interests/task-vendor-interests.component';
import { TaskCustomerOffersComponent } from './tasks/task-customer-offers/task-customer-offers.component';
import { TaskCustomerOrdersComponent } from './tasks/task-customer-orders/task-customer-orders.component';
import { TaskVendorOrdersComponent } from './tasks/task-vendor-orders/task-vendor-orders.component';
import { TaskCustomerDeliveryComponent } from './tasks/task-customer-deliveries/task-customer-delivery/task-customer-delivery.component';
import { TaskCustomerInterestsComponent } from './tasks/task-customer-interests/task-customer-interests.component';
import { DlgEditTaskComponent } from './shared/dialogs/edit/tasks/dlg-edit-task/dlg-edit-task.component';
import { TaskStatusSelectorComponent } from './shared/snippets/selectors/task-status-selector/task-status-selector.component';
import { ImportanceSelectorComponent } from './shared/snippets/selectors/importance-selector/importance-selector.component';
import { StatusContainerComponent } from './shared/snippets/status-container/status-container.component';
import { ImportanceContainerComponent } from './shared/snippets/tasks/importance-container/importance-container.component';
import { TaskListFilterContainerComponent } from './shared/snippets/tasks/task-list-filter-container/task-list-filter-container.component';
import { TaskDetailComponent } from './shared/snippets/tasks/task-detail/task-detail.component';
import { TaskCustomerInterestComponent } from './tasks/task-customer-interests/task-customer-interest/task-customer-interest.component';
import { TaskProductsComponent } from './shared/snippets/tasks/task-products/task-products.component';
import { TaskCustomerInterestDetailComponent } from './shared/snippets/tasks/task-customer-interest-detail/task-customer-interest-detail.component';
import { ProjectDetailsComponent } from './shared/snippets/projects/project-details/project-details.component';
import { DlgEditProjectComponent } from './shared/dialogs/edit/dlg-edit-project/dlg-edit-project.component';
import { DlgSearchProjectProductsComponent } from './shared/dialogs/search/dlg-search-project-products/dlg-search-project-products.component';
import { TemplateCategorySelectorComponent } from './shared/snippets/selectors/template-category-selector/template-category-selector.component';
import { EditorModule, TINYMCE_SCRIPT_SRC  } from '@tinymce/tinymce-angular';
import { VerifyEmailComponent } from './public/verify-email/verify-email.component';
import { TaskVendorInterestComponent } from './tasks/task-vendor-interests/task-vendor-interest/task-vendor-interest.component';
import { TaskVendorOrderComponent } from './tasks/task-vendor-orders/task-vendor-order/task-vendor-order.component';
import { TaskCustomerOrderComponent } from './tasks/task-customer-orders/task-customer-order/task-customer-order.component';
import { TaskCustomerDeliveriesComponent } from './tasks/task-customer-deliveries/task-customer-deliveries.component';
import { TaskServicesComponent } from './tasks/task-services/task-services.component';
import { TaskActivitiesComponent } from './tasks/task-activities/task-activities.component';
import { TaskActivityComponent } from './tasks/task-activities/task-activity/task-activity.component';
import { TaskServiceComponent } from './tasks/task-services/task-service/task-service.component';
import { TaskTypeSelectorComponent } from './shared/snippets/selectors/task-type-selector/task-type-selector.component';
import { TaskCustomerOrderDetailComponent } from './shared/snippets/tasks/task-customer-order-detail/task-customer-order-detail.component';

import { NgxEchartsModule } from 'ngx-echarts';
import { BottomActionComponent } from './shared/bottomSheets/bottom-action/bottom-action.component';
import { BottomActionDetailComponent } from './shared/bottomSheets/bottom-action/bottom-action-detail/bottom-action-detail.component';
import { TaskEventsComponent } from './shared/snippets/tasks/task-events/task-events.component';
import { DlgEditTaskEventComponent } from './shared/dialogs/edit/tasks/dlg-edit-task-event/dlg-edit-task-event.component';
import { TaskActivitySelectorComponent } from './shared/snippets/selectors/task-activity-selector/task-activity-selector.component';
import { DatetimeRangeSelectorComponent } from './shared/snippets/selectors/datetime-range-selector/datetime-range-selector.component';
import { TaskActivityContainerComponent } from './shared/snippets/tasks/task-activity-container/task-activity-container.component';
import { TaskActivityDetailComponent } from './shared/snippets/tasks/task-activity-detail/task-activity-detail.component';
import { ProjectForecastComponent } from './shared/snippets/projects/project-forecast/project-forecast.component';
import { DlgEditProjectForecastComponent } from './shared/dialogs/edit/dlg-edit-project-forecast/dlg-edit-project-forecast.component';
import { ForecastComponent } from './reports/forecast/forecast.component';
import { TaskNotesComponent } from './shared/snippets/tasks/task-notes/task-notes.component';
import { InventoryComponent } from './inventory/inventory.component';
import { DlgEditInventoryTransactionComponent } from './shared/dialogs/edit/dlg-edit-inventory-transaction/dlg-edit-inventory-transaction.component';
import { ItemSelectorComponent } from './shared/snippets/selectors/item-selector/item-selector.component';
import { ItemContainerComponent } from './shared/snippets/item-container/item-container.component';
import { InventoryTransactionsComponent } from './inventory/inventory-transactions/inventory-transactions.component';
import { BottomTaskEventSearchComponent } from './shared/bottomSheets/bottom-task-event-search/bottom-task-event-search.component';
import { TaskInventoriesInComponent } from './tasks/task-inventories-in/task-inventories-in.component';
import { TaskInventoriesOutComponent } from './tasks/task-inventories-out/task-inventories-out.component';
import { TaskInventoryOutComponent } from './tasks/task-inventories-out/task-inventory-out/task-inventory-out.component';
import { TaskInventoryInComponent } from './tasks/task-inventories-in/task-inventory-out/task-inventory-in.component';
import { TemplateCategoryContainerComponent } from './shared/snippets/templates/template-category-container/template-category-container.component';
import { PeopleSelectorComponent } from './shared/snippets/selectors/people-selector/people-selector.component';
import { ProductContainerComponent } from './shared/snippets/product-container/product-container.component';
import { DefaultConfigSignatureComponent } from './shared/snippets/templates/template-default-configs/default-config-signature/default-config-signature.component';
import { DefaultConfigProductListComponent } from './shared/snippets/templates/template-default-configs/default-config-product-list/default-config-product-list.component';
import { DefaultSectionBreakdownReportComponent } from './shared/snippets/templates/template-default-sections/default-section-breakdown-report/default-section-breakdown-report.component';
import { DlgOrgItemComponent } from './shared/dialogs/search/dlg-org-item/dlg-org-item.component';
import { TaskTypeContainerComponent } from './shared/snippets/tasks/task-type-container/task-type-container.component';
import { TaskCustomerConfirmationComponent } from './tasks/task-customer-confirmations/task-customer-confirmation/task-customer-confirmation.component';
import { TaskCustomerConfirmationsComponent } from './tasks/task-customer-confirmations/task-customer-confirmations.component';
import { DefaultConfigMarketingComponent } from './shared/snippets/templates/template-default-configs/default-config-marketing/default-config-marketing.component';
import { DefaultPriceTableComponent } from './shared/snippets/templates/template-default-configs/default-price-table/default-price-table.component';
import { DefaultSectionServiceReportComponent } from './shared/snippets/templates/template-default-sections/default-section-service-report/default-section-service-report.component';
import { TaskCustomerDeliveryDetailComponent } from './shared/snippets/tasks/task-customer-delivery-detail/task-customer-delivery-detail.component';
import { DefaultSectionDeliveryPdfComponent } from './shared/snippets/templates/template-default-sections/default-section-delivery-pdf/default-section-delivery-pdf.component';
import { DefaultConfigProductTableComponent } from './shared/snippets/templates/template-default-configs/default-config-product-table/default-config-product-table.component';
import { DefaultConfigInvoiceAndDeliveryAddressComponent } from './shared/snippets/templates/template-default-configs/default-config-invoice-and-delivery-address/default-config-invoice-and-delivery-address.component';
import { TaskCustomerHandoversComponent } from './tasks/task-customer-handovers/task-customer-handovers.component';
import { TaskCustomerHandoverComponent } from './tasks/task-customer-handovers/task-customer-handover/task-customer-handover.component';
import { TaskDocsComponent } from './shared/snippets/tasks/task-docs/task-docs.component';
import { DefaultConfigCustomerConfirmationComponent } from './shared/snippets/templates/template-default-configs/default-config-customer-confirmation/default-config-customer-confirmation.component';
import { TaskRefNumberContainerComponent } from './shared/snippets/tasks/task-ref-number-container/task-ref-number-container.component';
import { TaskPartnerRefNumberContainerComponent } from './shared/snippets/tasks/task-partner-ref-number-container/task-partner-ref-number-container.component';
import { DefaultSectionHandoverPdfComponent } from './shared/snippets/templates/template-default-sections/default-section-handover-pdf/default-section-handover-pdf.component';
import { AuditsComponent } from './dash/audits/audits.component';
import { MyTaskEventsComponent } from './dash/myTaskEvents/myTaskEvents.component';
import { ProjectContainerComponent } from './shared/snippets/projects/project-container/project-container.component';
import { StrToDate } from './pipes/strToDate.pipe';
import { EmailLogsComponent } from './accounts/email-logs/email-logs.component';
import { ServiceMgmtComponent } from './service-mgmt/service-mgmt.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MyTasksComponent } from './dash/my-tasks/my-tasks.component';
import { TasksAllComponent } from './tasks/tasks-all/tasks-all.component';
import { UserAccessComponent } from './accounts/users/user/user-access/user-access.component';

@NgModule({
    declarations: [
        AppComponent,
        DashComponent,
        LoginComponent,
        CompaniesComponent,
        IsoDate,
        DateTime,
        StrToDate,
        DateDate,
        TruncatePipe,
        TypeofPipe,
        FormatPercentPipe,
        FileNameFromPath,
        LoaderComponent,
        AccountsComponent,
        DlgErrorComponent,
        ContactsComponent,
        OrganisationsComponent,
        OrganisationComponent,
        OrganisationAddressComponent,
        OrganisationEmailComponent,
        OrganisationPhoneComponent,
        DlgConfirmComponent,
        ConfirmDirective,
        DndDirective,
        ClickStopPropagation,
        UsersComponent,
        UserComponent,
        StaticTrueFalseIconComponent,
        ProfileComponent,
        QuickLogInfoComponent,
        QuickLogInfoDetailsComponent,
        PersonsComponent,
        PersonComponent,
        BottomOrganisationComponent,
        BottomOrganisatonDetailsComponent,
        BottomPeopleComponent,
        BottomPeopleDetailComponent,
        PersonPhoneComponent,
        PersonEmailComponent,
        DlgSearchOrganisationComponent,
        ProjectsComponent,
        OrganisationItemComponent,
        ItemsComponent,
        ItemComponent,
        ProductComponent,
        ProductsComponent,
        ManufacturersComponent,
        CategoriesComponent,
        ManufacturerComponent,
        CategoryComponent,
        DlgSearchCategoryComponent,
        DlgSearchManufacturerComponent,
        PageManufacturerComponent,
        DlgEditManufacturerComponent,
        PageCategoryComponent,
        DlgEditCategoryComponent,
        PageItemComponent,
        DlgSearchProductComponent,
        DlgSearchItemComponent,
        DlgEditProductComponent,
        PageProductComponent,
        PageOrganisationComponent,
        DlgEditOrganisationComponent,
        PagePersonComponent,
        DlgEditPersonComponent,
        DlgEditOrganisationItemComponent,
        DlgEditItemComponent,
        IconItemTypeComponent,
        RequestForgottenPassowrdComponent,
        SidemenuComponent,
        ProjectPlansComponent,
        ProjectPlanComponent,
        ActionsPlansComponent,
        ActionPlanComponent,
        ProjectActionsPlanComponent,
        DlgEditPlanActionComponent,
        PageActionPlansComponent,
        DlgSearchPlanActionComponent,
        ProjectComponent,
        ProjectImportanceComponent,
        ProjectStatusComponent,
        DlgSearchProjectTemplateComponent,
        ProjectActionsTreesComponent,
        ActionComponent,
        EventsComponent,
        DlgCreateCommentComponent,
        AvatarComponent,
        DlgSearchUserComponent,
        ProjectTypesComponent,
        ProjectTypeComponent,
        DlgSearchActionComponent,
        TaskInterestPdfsComponent,
        PublicComponent,
        FileUploadComponent,
        DlgSearchOrgContactComponent,
        SetNewPasswordComponent,
        NotificationContentComponent,
        ItemOrgComponent,
        ItemBuiltIntoComponent,
        CountrySelectorComponent,
        DlgSearchPeopleComponent,
        EmailSendingComponent,
        ProductRelatedOfferComponent,
        AttributeOptionsComponent,
        AttributesComponent,
        ProductsubmenuComponent,
        AttributeGroupsComponent,
        PageAttributeGroupsComponent,
        AttributeGroupComponent,
        PageAttributesComponent,
        AttributeComponent,
        DlgSearchAttributeGroupComponent,
        DlgEditAttributeGroupComponent,
        ProductAttributesComponent,
        DlgSearchAttributeComponent,
        DlgEditAttributeComponent,
        DlgWysiwygComponent,
        ProductImagesComponent,
        ProductDocumentsComponent,
        TemplatesComponent,
        TemplateComponent,
        IconTemplateTypeComponent,
        OrderNumber,
        EurPipe,
        HufPipe,
        TemplateSelectorComponent,
        UserSelectorComponent,
        HtmlContainerComponent,
        TextContainerComponent,
        DlgEditTextComponent,
        TemplateSectionVersionsComponent,
        DlgTemplateSectionVersionsComponent,
        StripHtmlPipe,
        SafeHtmlPipe,
        DefaultSectionPriceTableComponent,
        MyProfileComponent,
        ConfigurationComponent,
        DefaultSectionProductListComponent,
        CurrencySelectorComponent,
        CurrencyContainerComponent,
        TemplateBuilderComponent,
        DefaultSectionPdfHeaderComponent,
        DefaultSectionProductsMarketingComponent,
        OrgSelectorComponent,
        LanguageSelectorComponent,
        TaskCustomerOfferComponent,
        TaskVendorInterestsComponent,
        TaskCustomerOffersComponent,
        TaskCustomerOrdersComponent,
        TaskVendorOrdersComponent,
        TaskCustomerDeliveryComponent,
        TaskCustomerInterestsComponent,
        DlgEditTaskComponent,
        TaskStatusSelectorComponent,
        ImportanceSelectorComponent,
        StatusContainerComponent,
        ImportanceContainerComponent,
        TaskListFilterContainerComponent,
        TaskDetailComponent,
        TaskCustomerInterestComponent,
        TaskProductsComponent,
        TaskCustomerInterestDetailComponent,
        ProjectDetailsComponent,
        DlgEditProjectComponent,
        DlgSearchProjectProductsComponent,
        TemplateCategorySelectorComponent,
        VerifyEmailComponent,
        TaskVendorInterestComponent,
        TaskVendorOrderComponent,
        TaskCustomerOrderComponent,
        TaskCustomerDeliveriesComponent,
        TaskServicesComponent,
        TaskActivitiesComponent,
        TaskActivityComponent,
        TaskServiceComponent,
        TaskTypeSelectorComponent,
        TaskCustomerOrderDetailComponent,
        BottomActionComponent,
        BottomActionDetailComponent,
        TaskEventsComponent,
        DlgEditTaskEventComponent,
        TaskActivitySelectorComponent,
        DatetimeRangeSelectorComponent,
        TaskActivityContainerComponent,
        TaskActivityDetailComponent,
        ProjectForecastComponent,
        DlgEditProjectForecastComponent,
        ForecastComponent,
        TaskNotesComponent,
        InventoryComponent,
        DlgEditInventoryTransactionComponent,
        ItemSelectorComponent,
        ItemContainerComponent,
        InventoryTransactionsComponent,
        BottomTaskEventSearchComponent,
        TaskInventoriesInComponent,
        TaskInventoriesOutComponent,
        TaskInventoryOutComponent,
        TaskInventoryInComponent,
        TemplateCategoryContainerComponent,
        PeopleSelectorComponent,
        ProductContainerComponent,
        DefaultConfigSignatureComponent,
        DefaultConfigProductListComponent,
        DefaultSectionBreakdownReportComponent,
        DlgOrgItemComponent,
        TaskTypeContainerComponent,
        TaskCustomerConfirmationComponent,
        TaskCustomerConfirmationsComponent,
        DefaultConfigMarketingComponent,
        DefaultPriceTableComponent,
        DefaultSectionServiceReportComponent,
        TaskCustomerDeliveryDetailComponent,
        DefaultSectionDeliveryPdfComponent,
        DefaultConfigProductTableComponent,
        DefaultConfigInvoiceAndDeliveryAddressComponent,
        TaskCustomerHandoversComponent,
        TaskCustomerHandoverComponent,
        TaskDocsComponent,
        DefaultConfigCustomerConfirmationComponent,
        TaskRefNumberContainerComponent,
        TaskPartnerRefNumberContainerComponent,
        DefaultSectionHandoverPdfComponent,
        AuditsComponent,
        MyTaskEventsComponent,
        ProjectContainerComponent,
        EmailLogsComponent,
        ServiceMgmtComponent,
        MyTasksComponent,
        TasksAllComponent,
        UserAccessComponent
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        CustomMaterialModule,
        EditorModule,
        NgxEchartsModule.forRoot({
            /**
             * This will import all modules from echarts.
             * If you only need custom modules,
             * please refer to [Custom Build] section.
             */
            echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
          }),
    ],
    providers: [
        AuthService,
        AuthGuard,
        LoaderService,
        ErrorMsgService,
        TokenService,
        NotificationService,
        NullValueService,
        SecurityService,
        MatDatepickerModule,
        MatNativeDateModule,
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [AuthService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
            deps: [TokenService, ErrorMsgService, LoaderService, NullValueService, AuthService]
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
                duration: 4000,
                verticalPosition: 'bottom',
                horizontalPosition: 'right',
                panelClass: 'snackbar-success'
            }
        },
        { provide: MatBottomSheetRef, useValue: {} },
        { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
        { provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: { panelClass: 'bottom-sheet', hasBackdrop: true } },
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

