import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManufacturerService } from 'src/app/services/manufacturer.service';
import { DlgEditManufacturerComponent } from 'src/app/shared/dialogs/edit/dlg-edit-manufacturer/dlg-edit-manufacturer.component';

@Component({
  selector: 'app-dlg-search-manufacturer',
  templateUrl: './dlg-search-manufacturer.component.html',
  styleUrls: ['./dlg-search-manufacturer.component.scss']
})
export class DlgSearchManufacturerComponent implements OnInit {
  txtSearch: string = '';
  options = [];
  timeout = null;

  constructor(
    private service: ManufacturerService,
    public dialogRef: MatDialogRef<DlgSearchManufacturerComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog
  ) {
    this.txtSearch = data.name;
  }

  ngOnInit() {
    // help to avoid: ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.search();
    }, 0)
  }

  search() {
      this._doSearch();
  }

  _doSearch() {
    let options = {params: {
      search: this.txtSearch,
      limit: 50
    }};

    this.service.list(options).subscribe(data => {
      this.options = data.results
     });
  }

  select(entity) {
      this.txtSearch = entity.nameHu;

      setTimeout(() => {
        this.dialogRef.close(entity);
      }, 300);
     
  }

  delete() {
    this.txtSearch = '';

    setTimeout(() => {
      this.dialogRef.close('');
    }, 500);
  }

  createManufactureDlg() {
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.data = null;

    const dialogRef = this.dialog.open(DlgEditManufacturerComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(manufacturer => {
      if (manufacturer) {
        this.select(manufacturer)
      }
    });
  }

}

