import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EnumTaskStatus } from 'src/app/enums/tasks/taskStatus.enum';

@Component({
  selector: 'app-task-status-selector',
  templateUrl: './task-status-selector.component.html',
  styleUrls: ['./task-status-selector.component.scss']
})
export class TaskStatusSelectorComponent implements OnInit {
  @Input() status: EnumTaskStatus | string = null;
  @Input() showAll: boolean = false;

  @Output() onChange: EventEmitter<string> = new EventEmitter();

  enumTaskStatus = EnumTaskStatus;

  constructor() { }

  ngOnInit() {
  }

  update() {
    this.onChange.emit(this.status);
  }

}
