
<div class="input-group mb-4 margin-10-0">
        <input type="text" class="form-control"  (keyup.enter)="search()" placeholder="Mit keresel?" [(ngModel)]="txtSearch" #searchInput>
        <div class="input-group-append">
            <button class="btn btn-outline-secondary bg-blue text-white" (click)="search()"  type="button">Keresés</button>
        </div>
</div>

<div style="min-height: 200px;"> 
<ng-container *ngIf="taskEvents.length > 0">
    <div class="table-responsive">   
      <table class="table">
          <thead>
              <tr>
                  <th>Tennivaló</th>
                  <th>Részletek</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let taskEvent of taskEvents">
                  <td>
                    <div matTooltip="{{taskEvent.name}}">
                        <a href="#" class="link" routerLink="{{taskEvent.path}}"  [queryParams]="{id: taskEvent._task.id}">{{taskEvent.name |truncate:6}}</a>
                    </div>
                     
                    <div class="flex helper-text">
                        <span *ngIf="taskEvent.actualStart; else tmpDate">{{taskEvent.actualStart | date:'longDate'}}</span>
                        <ng-template #tmpDate>{{taskEvent.start  | date:'longDate'}}</ng-template>
                    </div>
                
                    <div>
                        <app-avatar [user]="taskEvent._assignee"></app-avatar>
                    </div>
                  </td>
                  <td>
                      <div class="text-red">{{taskEvent.problem}}</div>
                      <div class="text-green">{{taskEvent.comment}}</div>
                  </td>
              </tr>
          </tbody>
      </table>
    </div>
</ng-container>
</div>
  