import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseList } from '../common/baseClasses/baseList';
import { TaskEventService } from '../services/tasks/taskEvent.service';
import { UrlParamService } from '../services/urlParam.service';
import { DlgEditTaskEventComponent } from '../shared/dialogs/edit/tasks/dlg-edit-task-event/dlg-edit-task-event.component';
import { AutoUnsub } from '../utils/autoUnsubscribe';
import { TaskUtil } from '../utils/task.util';
import {FormGroup, FormControl} from '@angular/forms';

@AutoUnsub()
@Component({
  selector: 'app-service-mgmt',
  templateUrl: './service-mgmt.component.html',
  styleUrls: ['./service-mgmt.component.scss'],
  providers: [UrlParamService]
})
export class ServiceMgmtComponent extends BaseList implements OnInit  {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  searchRange = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  displayedColumns = ['datetime', 'assignee', 'task', 'done', 'edit'];

  constructor(
    private taskEventService: TaskEventService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService,
    private dialog: MatDialog,
  ) {
    super()

    this.setDefaultDateRange();

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  setDefaultDateRange() {
    // default search +/- 14 days (this is in milliseconds)
    this.searchRange.get('start').setValue(new Date(Date.now() - 12096e5));
    this.searchRange.get('end').setValue(new Date(Date.now() + 12096e5));
  }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    let defaultParams = {}

    if (this.searchRange.value.start && this.searchRange.value.end) {
      defaultParams['dates'] = this.searchRange.value.start.toISOString() + '&' + this.searchRange.value.end.toISOString()
    }

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      }
      
      this.taskEventService.list(options).subscribe(data => {
        this.setListResponseData(data);
       });
    })
  }

  openTask(task){
    let path = TaskUtil.getTaskPath(task.taskType);
        
    this.router.navigate([path],  {
      queryParams: {id: task.id}, 
    });
  }

  openEdtiTaskEventDlg(taskEvent) {
    const dialogConfig = new MatDialogConfig();
        
    dialogConfig.data = {
      taskEvent: taskEvent,
      autoFocus: false,
      restoreFocus: false
    };

    const dialogRef = this.dialog.open(DlgEditTaskEventComponent, dialogConfig);
    dialogRef.componentInstance.onChange.subscribe(taskEvent => {
      this.fetchData();
    });
  }

  searchByDate() {
    // search by the selected date range
    this.fetchData();
  }

  removeSelectedRange() {
    this.pageIndex = 0;
    this.urlParamService.truncate();
    this.urlParamService.set();

    setTimeout(() => {
      // have to wait until the params are removed from the url
      // remove the selected range in the input field
      this.searchRange.get('start').setValue(null);
      this.searchRange.get('end').setValue(null);
      this.fetchData();
    }, 1100);
   
  }

  defaultDateRange() {
    this.setDefaultDateRange();
    this.fetchData();
  }

}
