import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { NotificationService } from '../notification';
import { ProjectForecast, ProjectForecastPart } from 'src/app/models/projects/projectForecast.model';
import { Utils } from '../../shared/utils';


@Injectable({
    providedIn: 'root'
  })
export class ProjectForecastService {
    baseUrl = environment.baseUrl;

    URL_FORECAST = '/projects/forecast';
    URL_FORECAST_PARTS = '/projects/forecast/parts';
    URL_FORECAST_REPORT = '/projects/forecast/report';

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {}

    list(options=null) {
        // list all the contacts
        // @params: obj of params
        // @url: full url with params (next and prev for pagination)
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL_FORECAST + '/';
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }

        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map((i) => {
                    let p = new ProjectForecast(i);
                    this.updateCalcualtedFields(p);
                    return p;
                });
                return res;
            })
        )
    }

    get(id) {
        let url = this.baseUrl + this.URL_FORECAST + '/' + id + '/';
        return this.http.get<ProjectForecast>(url).pipe(
            map((res) => {
                let p = new ProjectForecast(res);
                this.updateCalcualtedFields(p);
                return p;
            })
        )
    }

    create(payload) {
        let url = this.baseUrl + this.URL_FORECAST  + '/';
        return this.http.post(url, payload).pipe(
            map(res => {
                let p = new ProjectForecast(res);
                this.updateCalcualtedFields(p);
                return p;
            }),
            tap(res => { this.notificationService.created(); })
        )
    }

    update(payload) {
        let url = this.baseUrl + this.URL_FORECAST + '/' + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => {
                let p = new ProjectForecast(res);
                this.updateCalcualtedFields(p);
                return p;
            }),
            tap(res => { this.notificationService.updated(); })
        )
    }

    delete(payload) {
        let url = this.baseUrl + this.URL_FORECAST + '/' + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }


    // PARTS

    listParts(options=null) {
        // list all the contacts
        // @params: obj of params
        // @url: full url with params (next and prev for pagination)
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL_FORECAST_PARTS + '/';
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }

        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(i => new ProjectForecastPart(i));
                return res;
            })
        )
    }

    getPart(id) {
        let url = this.baseUrl + this.URL_FORECAST_PARTS + '/' + id + '/';
        return this.http.get<ProjectForecastPart>(url).pipe(
            map(res => new ProjectForecastPart(res))
        )
    }

    createPart(payload) {
        let url = this.baseUrl + this.URL_FORECAST_PARTS  + '/';
        return this.http.post(url, payload).pipe(
            map(result => new ProjectForecastPart(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    updatePart(payload) {
        let url = this.baseUrl + this.URL_FORECAST_PARTS + '/' + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new ProjectForecastPart(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    deletePart(payload) {
        let url = this.baseUrl + this.URL_FORECAST_PARTS + '/' + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

    /**
     * add the calcualted fields to ProjectForecast
     */
     updateCalcualtedFields(p) {
        let sumCostParts = 0;
        p.parts.forEach(part => {
            sumCostParts += part.cost
        });

        p.sumKosten = p.ek_vetel + p.transport + sumCostParts;
        
        p.gemeinKosten = (p.vk_eladas * (p.gemeinpercent/100)) + p.schulung + p.inbetr + p.garancia;

        p.provision = (p.vk_eladas * (p.vorabgewinn/100));

        p.gewinnAuf = p.vk_eladas - p.sumKosten - p.gemeinKosten - p.provision;
        
        p.gweinnAufPerc = (p.gewinnAuf / p.vk_eladas) * 100;
        
        p.gewinnBrutto = p.vk_eladas - p.sumKosten;

        p.gewinnBurttoPerc = (p.gewinnBrutto / p.vk_eladas) * 100;
    }

    /**
     * REPORT
     */
    report(params) {
        let url = this.baseUrl + this.URL_FORECAST_REPORT;
        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res = res.map(i => {
                    let p = new ProjectForecast(i);
                    this.updateCalcualtedFields(p);
                    return p;
                });
                return res;
            })
        )
    }
     
}