import { Component, Input, OnInit } from '@angular/core';
import { EnumTaskTypes } from 'src/app/enums/tasks.enums';

@Component({
  selector: 'app-task-type-container',
  templateUrl: './task-type-container.component.html',
  styleUrls: ['./task-type-container.component.scss']
})
export class TaskTypeContainerComponent implements OnInit {
  @Input() type: EnumTaskTypes | string;

  enumTaskTypes = EnumTaskTypes

  constructor() { }

  ngOnInit(): void {
  }

}
