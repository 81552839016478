export enum EnumNotificationType {
    Created = 'created',
    Updated = 'updated',
    Deleted = 'deleted',
    Success = 'success',
    Error = 'error',
    EmailSent = 'emailSent',
    PassswordNotEqual = 'passwordNotEqual',
    PasswordMinLength = 'passwordMinLength',
    PasswordLowerCase = 'passwordLowerCase',
    PasswordUpperCase = 'passwordUppercase',
    PasswordNumber = 'passwordNumber',
    PasswordSpecialChar = 'passwordSpecialChar',
    EmailInvalid = 'emailinvalid',
    Dirty = 'dirty', // data dirty on the page, save

    // specials 

    // company
    OrgMissing = 'orgMissing',

    // item
    ItemMissing = 'itemMissing',
    ItemInList = 'itemInList',

    // product
    ProductInList = 'productInList',

    // template
    TemplateCategoryMissing = 'templatecategoryMissing',

    // task
    TaskInactiveMachineOnly = 'taskinactivemachineonly',
    NoTaskRelatedOffers = 'notaskrelatedoffers',
    ItemInTaskProduct = 'itemintaskproduct',

    // inventroy transaction
    InventoryTransactionCsvExportNoFilter = 'inventorytransactioncsvexportnofilter',

    // security
    SecurityModuleNoAccess = 'securitymodulenoaccess'
  }