import { Component, Input, OnInit } from '@angular/core';
import { EnumTemplateCategory } from 'src/app/enums/templates/templateCategory.enum';

@Component({
  selector: 'app-template-category-container',
  templateUrl: './template-category-container.component.html',
  styleUrls: ['./template-category-container.component.scss']
})
export class TemplateCategoryContainerComponent implements OnInit {
  @Input() category: EnumTemplateCategory | string = null;

  constructor() { }

  ngOnInit(): void {
  }

}
