import { Injectable } from "@angular/core";
import {HttpRequest } from '@angular/common/http'

// TODO: Rename filename to simple service

@Injectable()
export class TokenService {
    // hold decoded jwt token information
    accesssToken = null;
    refreshToken = null;

    constructor()  {     
    }

    getCurrentUserId() {
      // return the parsed valid token information
      if (this.accesssToken) {
        return this.accesssToken.user_id;
      } 

      return;
    }

    parseJwt (token) {
      // parse the token to make it readable
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  
      return JSON.parse(jsonPayload);
  };

    public addTokenToRequest(request: HttpRequest<any>, token: string) {
      /* add the access token to the current request */
      // token: string
      if (!token) {
        // if no token - nothing to add
        return request;
      }

      return request.clone({
          setHeaders: {
              'Authorization': 'JWT ' + token
          }
      })
    };

    public setAccessToken(token) {
      this.accesssToken = this.parseJwt(token);
    }

    public setRefreshTOken(token) {
      this.refreshToken = this.parseJwt(token);
    }
}