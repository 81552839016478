<div class="flex">
    <div class="full helper-text">Email Küldés</div>
    <span [mat-dialog-close]>
        <mat-icon class="text-red">close</mat-icon>
    </span>
</div>
<mat-dialog-content>
 
    <mat-horizontal-stepper #stepper>
        <mat-step label="Sablon">
            <ng-container *ngTemplateOutlet="templateSetup"></ng-container>
        </mat-step>
        <mat-step label="Email">
            <ng-container *ngTemplateOutlet="emailSending"></ng-container>
        </mat-step>
        <mat-step  label="Csatolmányok">
            <ng-container *ngTemplateOutlet="attachments"></ng-container>
        </mat-step>
    </mat-horizontal-stepper>


    <!-- template setup-->
    <ng-template #templateSetup>
        <div class="flex ">
            <div class="full">
                <app-template-selector
                    [category]="templateCategory"
                    [type]="EnumTemplateType.Email"
                    (eventTemplate)="setTemplate($event)"
                    (eventSections)="setTemplateSections($event)">
                </app-template-selector>
            </div>
            <div class="padding-l-10 padding-r-10">
                <mat-icon>compare_arrows</mat-icon>
            </div>
            <div>
                <button mat-flat-button color="accent" (click)="continueWithoutTemplate(stepper)"
                    matTooltip="Kattints a gombra ha nem kell sablon az emailhez">
                    Folytatás Sablon Nélkül
                </button>
            </div>
        </div>
        

        <app-template-builder 
            [template]="template"
            [templateSections]="templateSections"
            [language] = "language" 
            (onGeneratePdf)="generate($event, stepper)"
            (onReset)="resetPdfGeneration()"
            isDlg="true">
        </app-template-builder>
    </ng-template>

    <!-- Email Setup -->
    <ng-template #emailSending>
        
        <section class="flex">
            <div class="padding-r-10" *ngIf="orgId">
                <button mat-stroked-button color="primary"  (click)="openSearchOrgContactDialog('recipient')" matTooltip="Címzett hozzáadása">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div class="full">
                <mat-form-field style="width: 100%;" appearance="standard">
                    <mat-label>Címzettek</mat-label>
                    <mat-chip-list #chipList aria-label="Címzett Hozzádása">
                        <mat-chip color="primary" [removable]="removable" *ngFor="let _to of to">
                            {{_to}}
                            <mat-icon matChipRemove (click)="removeTo(_to)">cancel</mat-icon>
                        </mat-chip>
                    <input name="input_recipient"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="addTypedTo($event)">
                    </mat-chip-list>
                </mat-form-field>
                </div>
        </section>
        <div class="flex flex-right">
            <div class="helper-text link" (click)="showCC = !showCC">CC</div>
            <span class="padding-l-10 padding-r-10">|</span>
            <div class="helper-text link" (click)="showBCC = !showBCC">BCC</div>
        </div>
        
        <section class="flex" *ngIf="showCC">
            <div class="padding-r-10" *ngIf="orgId">
                <button mat-stroked-button color="accent"  (click)="openSearchOrgContactDialog('cc')" matTooltip="CC hozzáadása">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div class="full">
                <mat-form-field style="width: 100%;" appearance="standard">
                    <mat-label>CC</mat-label>
                    <mat-chip-list #chipListBcc aria-label="Címzettek Hozzádása">
                        <mat-chip color="primary" [removable]="removable" *ngFor="let _cc of cc">
                            {{_cc}}
                            <mat-icon matChipRemove (click)="removeCC(_cc)">cancel</mat-icon>
                        </mat-chip>
                    <input name="input__bcc"
                            [matChipInputFor]="chipListBcc"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="addTypedCC($event)">
                    </mat-chip-list>
                </mat-form-field>
                </div>
        </section>
        
        <section class="flex" *ngIf="showBCC">
            <div class="padding-r-10" *ngIf="orgId">
                <button mat-stroked-button color="primary"  (click)="openSearchOrgContactDialog('recipient')" matTooltip="BCC hozzáadása">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div class="full">

                <mat-form-field style="width: 100%;" appearance="standard">
                    <mat-label>BCC</mat-label>
                    <mat-chip-list #chipList aria-label="BCC Hozzádása">
                        <mat-chip color="primary" [removable]="removable" *ngFor="let _bcc of bcc">
                            {{_bcc}}
                            <mat-icon matChipRemove (click)="removeBcc(_bcc)">cancel</mat-icon>
                        </mat-chip>
                    <input name="input_recipient"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="addTypedBCC($event)">
                    </mat-chip-list>
                </mat-form-field>
                </div>
        </section>
        
        <section>
            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Tárgy</mat-label>
                <input matInput name="name" [(ngModel)]="subject" autocomplete="off" required>
            </mat-form-field>
        </section>


        <div class="flex sender-selector">
            <div class="full helper-text text-small">Email Tartalma</div>
            <div *ngIf="user" class="flex"> 
                <div class="padding-r-10 helper-text">Küldő:</div>
                <select name="sendByMyself" (change)="sendByMyself=$event.target.value">
                    <option value="false">IC-Hungary</option>
                    <option value="true">{{user.lastName}} {{user.firstName}}</option>
                </select>
            </div>
        </div>

        <app-html-container [content]="body" (onChange)="manuallyEdited($event)"></app-html-container>

        <div class="padding-10-0">
            <button mat-flat-button color="accent" matTooltip="Következő lépés" (click)="goForward(stepper)" [disabled]="!subject || !body">
                Tovább
            </button>
        </div>

    </ng-template>

    <!--Attechements-->
    <ng-template #attachments>

        <table class="table">
            <tr *ngIf="taskPdf">
                <td class="text-small" collspan="2">
                    <div class="flex">
                        <mat-icon class="text-yellow">attachment</mat-icon>
                        <a target="_blank" href="{{taskPdf.file}}">{{taskPdf.file | fileNameFromPath}}</a>
                    </div>
                </td>
            </tr>
            <tr *ngFor="let file of files">
                <td class="text-small">
                    <div class="flex">
                        <mat-icon  class="text-yellow">attachment</mat-icon> 
                        <span>{{file.name}}</span>
                    </div>
                </td>
                <td class="w50 text-right">
                    <mat-icon class="text-red" (click)="removeFile(file)">delete</mat-icon>
                </td>
            </tr>
        </table>

        <div class="helper-text">Csatolmány hozzáadása</div>
        <app-file-upload (onFileSelect)="addAttachement($event)"></app-file-upload>
       
        <div class="padding-20-0">
            <button class="padding-r-10" mat-flat-button color="accent" matTooltip="Email Küldés" (click)="sendEmail()">
                Email Küldés
            </button>
            <button mat-flat-button  matTooltip="Vissza" (click)="goBack(stepper)">
                Vissza
            </button>
        </div>
    </ng-template>

</mat-dialog-content> 
