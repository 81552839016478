<mat-form-field appearance="outline">
    <mat-label>Feladat</mat-label>
    <mat-select [(value)]="taskType" (selectionChange)="update()">
        <ng-container *ngIf="showSelect">
            <mat-option [value]="">-Válassz-</mat-option>
        </ng-container>
        <mat-option [value]="enumTaskTypes.TaskCustomerInterest">Ajánlatkérés Vevőtől</mat-option>
        <mat-option [value]="enumTaskTypes.TaskVendorInterest">Ajánlatkérés Beszállítótól</mat-option>
        <mat-option [value]="enumTaskTypes.TaskCustomerOffer">Ajánlat a Vevőnek</mat-option>
        <mat-option [value]="enumTaskTypes.TaskCustomerConfirmation">Visszaigazolás a Vevőnek</mat-option>
        <mat-option [value]="enumTaskTypes.TaskCustomerOrder">Megrendelés a Vevőtől</mat-option>
        <mat-option [value]="enumTaskTypes.TaskVendorOrder">Megrendelés a Beszállítótól</mat-option>
        <mat-option [value]="enumTaskTypes.TaskInventoryIn">Bevételezés</mat-option>
        <mat-option [value]="enumTaskTypes.TaskInventoryOut">Kivételezés</mat-option>
        <mat-option [value]="enumTaskTypes.TaskCustomerDeliver">Kiszállítás a Vevőnek</mat-option>
        <mat-option [value]="enumTaskTypes.TaskCustomerHandover">Átadás/Átvétel</mat-option>
        <mat-option [value]="enumTaskTypes.TaskService">Szervíz</mat-option>
        <mat-option [value]="enumTaskTypes.TaskActivity">Tevékenység</mat-option>
    </mat-select>
</mat-form-field>
