import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseList } from 'src/app/common/baseClasses/baseList';
import { EnumActionStatus } from 'src/app/enums/actionStatus.enum';
import { Action } from 'src/app/models/projects/action.model';
import { ActionEventService } from 'src/app/services/actionEvent.service';
import { ActionEvent } from 'src/app/models/projects/actionEvent.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { UrlParamService } from 'src/app/services/urlParam.service';
import { DlgCreateCommentComponent } from 'src/app/shared/dialogs/dlg-create-comment/dlg-create-comment.component';
import { EnumActionEventType } from 'src/app/enums/actionEventType.enum';
import { AuthService } from 'src/app/services/auth.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  providers: [UrlParamService]
})
export class EventsComponent extends BaseList implements OnInit, OnChanges {
  @Input() action: Action

  enumActionEventType = EnumActionEventType;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['date', 'user', 'actionType'];

  actList$;
  dlgCom$;
  eventCreate$;

  constructor(
    private authService: AuthService,
    private modelFactory: ModelFactory,
    private actionEventService: ActionEventService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService,
    private dialog: MatDialog
  ) {
    super()

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit() {
   this.fetchEvents();
  }

  fetchEvents() {
    // baseList cannot be used becuse it fetch params from UI
    // and it reserved for Project, to solove it use 200 element in the item
    let options = {
    params: {
      limit: 200,
      action: this.action.id
      }
    }

    this.actList$ = this.actionEventService.list(options).subscribe(data => {
      this.setListResponseData(data);
    });
  }
  
  ngOnChanges(changes) {
    if ('action' in changes) {
      if (changes.action.firstChange) {
        return;
      }

      if (changes.action.currentValue.id != changes.action.previousValue.id) {
        this.fetchEvents();
      }
    }
  }

  openCommentCreateDlg() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = null;
    dialogConfig.width = '500px';

    const dialogRef = this.dialog.open(DlgCreateCommentComponent, dialogConfig);
    this.dlgCom$ = dialogRef.componentInstance.onCreate.subscribe(comment => {
        this.saveComment(comment)
    });
  }

  saveComment(note) {
    // create an ActionEvent with the details
    let actionEvent = this.modelFactory.getNewModel(ActionEvent);
    actionEvent.note = note;
    actionEvent.eventType = EnumActionEventType.Note;
    actionEvent.action = this.action.id;
    actionEvent.employee = this.authService.user.id;
    
    this.eventCreate$ = this.actionEventService.create(actionEvent).subscribe(event => {
      this.fetchEvents();
    });
  }

}
