<div class="text-blue">Pdf Címe és Cég adatok</div> 

<ng-container *ngIf="!org; else tmpOrg">
    <div class="text-red padding-10-0">A Feladathoz nincs cég kiválasztva!</div>
</ng-container>

<ng-template #tmpOrg>
    <div class="text-help">[A pdf készítésekor beilleszti a Sablonnak megfelelő adatokat a pdf tetejere]</div>
    <div class="padding-10-0">
        <div class="text-bold">{{org.name}}</div>
        <div class="helper-text">Email: {{org.email}}</div>
        <div class="helper-text">Telefon: {{org.phone}}</div>
        <div class="helper-text">Cím: {{org.addressPostcode}} {{org.addressCity}} {{org.addressLine1}}</div>
    </div>
    <div class="padding-10-0 w400 flex">
        <div class="helper-text flex padding-r-10">
            <mat-icon matTooltip="Ha a PDf egy konkrét személynek lesz küldve itt lehet megadni. Ha címzett nem ismert hagyd üresen.">info</mat-icon>
            Címzett
        </div>
        <app-people-selector [org]="org" [people]="recipient" (onChange)="setRecipient($event)"></app-people-selector>
    </div>
    <div class="padding-10-0">
        <app-task-partner-ref-number-container [task]="task" (onSelect)="updatePartnerSelect($event)"></app-task-partner-ref-number-container>
    </div>
</ng-template>
