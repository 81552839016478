import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from '../notification';
import { Project } from '../../models/projects/project.model';
import { Utils } from '../../shared/utils';
import { cloneDeep } from 'lodash-es';


@Injectable({
    providedIn: 'root'
  })
export class ProjectService {
    baseUrl = environment.baseUrl;

    URL_PROJECT = '/projects';

    // list items
    dataSource$ = new BehaviorSubject<any[]>([])
    data = this.dataSource$.asObservable();

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {}

    list(options=null) {
        // list all the contacts
        // @params: obj of params
        // @url: full url with params (next and prev for pagination)
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL_PROJECT + '/';
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }

        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(i => new Project(i));
                return res;
            })
        )
    }

    get(id) {
        let url = this.baseUrl + this.URL_PROJECT + '/' + id + '/';
        return this.http.get<Project>(url).pipe(
            map(res => new Project(res))
        )
    }

    create(payload) {
        let url = this.baseUrl + this.URL_PROJECT  + '/';
        return this.http.post(url, payload).pipe(
            map(result => new Project(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    update(payload) {
        let url = this.baseUrl + this.URL_PROJECT + '/' + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new Project(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    delete(payload) {
        let url = this.baseUrl + this.URL_PROJECT  + '/' + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

    getActionTree(_actions: any[]) {
        // helper method to build a tree structure from a list of Actions of a Project
        // used for Action and ProjectActionPlan as well!
        // https://typeofnan.dev/an-easy-way-to-build-a-tree-with-object-references/
        let actions = cloneDeep(_actions);

        const idMapping = actions.reduce((acc, action, i) => {
            acc[action.id] = i;
            return acc;
        }, {});

        let root;

        actions.forEach((action) => {
            // used to track if the action is currenlty edited or not
            action['edit'] = false;
            
            // Handle the root element
            if (action.parent === null) {
                root = action;
                return;
            }

            // Use our mapping to locate the parent element in our data array
            const parentEl = actions[idMapping[action.parent]];

            if (!('children' in parentEl)) {
                parentEl['children'] = [];
            }

            // Add our current el to its parent's `children` array
            parentEl['children'] = [...(parentEl['children'] || []), action];
        });

        return root;
    }
}