import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Allow to to create a comment,
 * and pass the comment back to the caller
 */

@Component({
  selector: 'app-dlg-create-comment',
  templateUrl: './dlg-create-comment.component.html',
  styleUrls: ['./dlg-create-comment.component.scss']
})
export class DlgCreateCommentComponent implements OnInit {
  comment: string = null;
  onCreate = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DlgCreateCommentComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { }

  ngOnInit() {
  }

  create() {
    this.onCreate.emit(this.comment);
    this.dialogRef.close();
  }

}
