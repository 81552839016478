
<div class="section">
    <div class="header">
        <div class="title" matTooltip="">
            <span>Feladathoz Tartozó Dokumentumok</span>
        </div>
    </div>
    
    <app-file-upload (onFileSelect)="saveFile($event)"></app-file-upload>

    <ng-container *ngIf="taskDocs.length > 0; else tmpNoDocs">
        <div class="row padding-20-0" *ngFor="let taskDoc of taskDocs">
            <div class="col-lg-6">
                <div class="helper-text"><span>Feltöltve: </span>{{taskDoc.created | isoDate }}</div>
                <a href="{{taskDoc.file}}" target="_blank" class="link text-small">{{taskDoc.file | fileNameFromPath | slice:0:60}}</a>
            </div>
            <div class="col-lg-4 p-10-0">
                <div class="input-group mb-2">
                    <input [(ngModel)]="taskDoc.docRefNumber" type="text" class="form-control " placeholder="Partner Referencia Száma">
                    <div class="input-group-append">
                    <button class="btn btn-primary" 
                    [ngClass]="{'btn-success': taskDoc.docRefNumber, 'btn-primary' : !taskDoc.docRefNumber }"
                    type="button" 
                    (click)="update(taskDoc)" 
                    matTooltip="A dockumentumban található referencia szám amit a partner használ a dockumentum azonosítására. Nem a mienk!">
                        Ment
                    </button>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 p-10-0">
                <button mat-icon-button color="warn"  (confirm-click)="deleteDoc(taskDoc)" confirm>
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </ng-container>

    <ng-template #tmpNoDocs>
        <div class="helper-text text-center">Nincs feltöltött dokumentum</div>
    </ng-template>

</div>