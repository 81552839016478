import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttributeGroupService } from 'src/app/services/attributeGroup.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';
import { DlgEditAttributeGroupComponent } from '../../edit/dlg-edit-attribute-group/dlg-edit-attribute-group.component';

@AutoUnsub()
@Component({
  selector: 'app-dlg-search-attribute-group',
  templateUrl: './dlg-search-attribute-group.component.html',
  styleUrls: ['./dlg-search-attribute-group.component.scss']
})
export class DlgSearchAttributeGroupComponent implements OnInit {
  //text which we searching 
  txtSearch: string = '';
  options = [];
  timeout = null;

  list$;
  dlgAttrGEdit$;

  constructor(
    private service: AttributeGroupService,
    public dialogRef: MatDialogRef<DlgSearchAttributeGroupComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog
  ) {
    this.txtSearch = data.name;
  }

  ngOnInit() {
     // help to avoid: ExpressionChangedAfterItHasBeenCheckedError
     setTimeout(() => {
      this.search();
    }, 0)
  }

  search() {
    this._doSearch();
  }

  _doSearch() {
    let options = {params: {
      search: this.txtSearch,
      limit: 100
    }};

    this.list$ = this.service.list(options).subscribe(data => {
      this.options = data.results
     });
  }

  select(entity) {
      this.txtSearch = entity.nameHu;

      setTimeout(() => {
        this.dialogRef.close(entity);
      }, 500);
     
  }

  delete() {
    this.txtSearch = '';

    setTimeout(() => {
      this.dialogRef.close('');
    }, 500);
  }

  openCreateDlg() {
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.data = null;

    const dialogRef = this.dialog.open(DlgEditAttributeGroupComponent, dialogConfig);

    this.dlgAttrGEdit$ = dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.select(res);
      }
    });
  }


}
