<div class="password-page">
    <mat-card class="login-card">
      <p class="padding-10-0">
        <img src="assets/logo/ich-logo-small.png" alt="IC-Hungary">
      </p>
      <ng-container *ngIf="!showOnlyLogin; else showLogin">
          <mat-card-content>
            <form class="form">
              <table cellspacing="0">
                  <tr>
                  <td>
                      <mat-form-field class="example-full-width">
                          <input matInput placeholder="Jelszó" [(ngModel)]="password" type="password" name="password" required>
                      </mat-form-field>
                  </td>
                  </tr>
                  <tr>
                      <td>
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Jelszó újra" [(ngModel)]="password2" type="password" name="password2" required>
                          </mat-form-field>
                      </td>
                      </tr>
              </table>
            </form>
          </mat-card-content>
          <mat-card-actions>
            <div class="flex">
              <div class="full">
                <button mat-raised-button (click)="updatePassword()" color="primary"  [disabled]="!password || !password2">Mentés</button>
              </div> 
              <div>
                <button mat-button [routerLink]="['./../login']" color="secondary">Belepés</button>
              </div>
            </div>
          </mat-card-actions>
        </ng-container> 
        <ng-template #showLogin>
          <mat-card-content>
            <p>
              A jelszó megváltozott. 
            </p>
          </mat-card-content>
          <mat-card-actions>
            <div class="flex">
              <div class="full">
                <button mat-button [routerLink]="['./../login']" color="primary">Belepés</button>
              </div> 
            </div>
          </mat-card-actions>
        </ng-template> 
      </mat-card>
</div>


