<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Műszaki Adatok</a></li>  
    <li>
        <ng-container *ngIf="attribute">
            {{attribute.nameHu}}
        </ng-container>    
    </li>  
</ul>

<div class="section">
    <div class="header">
        <div class="title"><span>Műszaki Adat</span></div>
    </div>
    <ng-container *ngIf="attribute">
        <app-attribute [attribute]="attribute"
        (eventCreated)="created($event)"
        (eventDeleted)="deleted($event)" 
        (eventUpdated)="updated($event)"
        ></app-attribute>
    </ng-container>
</div>
