<form #f="ngForm">
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Név</mat-label>
            <input matInput name="name" [(ngModel)]="manufacturer.name" required autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <span class="padding-20-0">
            <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
        <ng-container *ngIf="manufacturer.id">
            <span class="padding-20-0 padding-l-10">
                <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                    <mat-icon>delete</mat-icon>
                </button>
            </span>
            <span class="padding-l-10 padding-10-0">
                <app-quick-log-info [data]="manufacturer"></app-quick-log-info>           
            </span>
        </ng-container>  
    </p>

</form>