
export function isTruthy(value) {
    // return true or false based on the value is truty or falsy
    // main purpose is to decide based on a string like :'None' or 'null' etc
    // - consider python values as well if they come as a string
   if (value === true) return true;
   if (value === false) return false;

   // false strings
   if (value === 'false') return false;
   if (value === 'False') return false;
   if (value === 'null') return false;
   if (value === 'none') return false;
   if (value === 'None') return false;

   // true strings
   if (value === 'true') return true;
   if (value === 'True') return true;

   if (value) {
    return true;
   } else {
    return false;
   }
  }