
<form #f="ngForm" class="full">
    <p class="padding-20-0">
        <mat-radio-group (change)="changeType($event)" >
            <mat-radio-button [checked]="product.type === 'machine'" value="machine">Gép</mat-radio-button>
            <mat-radio-button [checked]="product.type === 'part'" value="part">Alkatrész</mat-radio-button>
            <mat-radio-button [checked]="product.type === 'service'" value="service">Szolgáltatás</mat-radio-button>
        </mat-radio-group>
    </p>
    
    <div class="row padding-20-0">
        <div class="col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Név - HU</mat-label>
                <input matInput name="nameHu" [(ngModel)]="product.nameHu" required autocomplete="off" required>
                <mat-icon class="pointer" matSuffix (click)="product.nameEn=product.nameHu" matTooltip="Másolás az Angolhoz">content_copy</mat-icon>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Név - EN</mat-label>
                <input matInput name="nameEn" [(ngModel)]="product.nameEn" required autocomplete="off">
                <mat-icon class="pointer" matSuffix (click)="product.nameHu=product.nameEn" matTooltip="Másolás a Magyarhoz">content_copy</mat-icon>
            </mat-form-field>
        </div>
    </div>

    <div class="row padding-20-0" *ngIf="product.type === 'part'">
        <div class="col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Cikkszám</mat-label>
                <input matInput name="articleNumber" [(ngModel)]="product.articleNumber" autocomplete="off">
            </mat-form-field>
        </div>
    </div>


    <div class="row padding-20-0">
        <div class="col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Kiszerelés - HU</mat-label>
                <input matInput name="unitHu" [(ngModel)]="product.unitHu" required autocomplete="off" required>
                <mat-icon matSuffix matTooltip="Kiszerelés vagy Mértekegysége az Árucikknek">info</mat-icon>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Kiszerelés - EN</mat-label>
                <input matInput name="unitEn" [(ngModel)]="product.unitEn" required autocomplete="off">
            </mat-form-field>
        </div>
    </div>

    <div class="row padding-20-0">
        <div class="col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Leírás - HU</mat-label>
                <textarea rows=3 matInput name="descriptionHu" [(ngModel)]="product.descriptionHu"  autocomplete="off"></textarea>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Leírás - EN</mat-label>
                <textarea rows=3 matInput name="descriptionEn" [(ngModel)]="product.descriptionEn"  autocomplete="off"></textarea>
            </mat-form-field>
        </div>
    </div>


    <div class="row" *ngIf="product.type != 'service'">
        <div class="col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Gyártó</mat-label>
                <ng-container *ngIf="product.manufacturer; else noManufacturer">
                    <input matInput name="manufacturer" disabled [(ngModel)]="product._manufacturer.name"  autocomplete="off" required>
                </ng-container>
                <ng-template #noManufacturer>
                    <input matInput name="manufacturer" disabled [(ngModel)]="product.manufacturer" autocomplete="off" required>
                </ng-template>
                <mat-icon class="pointer" (click)="openManufacturerSearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>  
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="product.type != 'service'">
        <div class="col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Kategória</mat-label>
                <ng-container *ngIf="product.category; else noCategory">
                    <input matInput name="category" disabled [(ngModel)]="product._category.nameHu"  autocomplete="off">
                </ng-container>
                <ng-template #noCategory>
                    <input matInput name="category" disabled [(ngModel)]="product.category" autocomplete="off">
                </ng-template>
                <mat-icon class="pointer" (click)="openCategorySearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>
            </mat-form-field>
        </div>
    </div>

    <p>
        <span class="padding-20-0">
            <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
        <ng-container *ngIf="!preventItemCreation && !product.id && product.type != 'machine'">
            <mat-checkbox name="autoCreate" [(ngModel)]="product.automaticItemCreation" class="padding-l-20">
                <span class="helper-text">Automatikus Ratári Termék generálás</span>
                <mat-icon matTooltip="Alkatrészek és Szolgáltatások esetében lehetőség van a Raktári Termék automatikus generálására. Akkor érdemes alkalmazni ha az Árucikk Csoportnak nincsenek különböző Raktári Termék változatai.">info</mat-icon>
            </mat-checkbox>
        </ng-container> 
        <ng-container *ngIf="product.id">
            <span class="padding-20-0 padding-l-10">
                <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                    <mat-icon>delete</mat-icon>
                </button>
            </span>
            <span class="padding-l-10 padding-10-0">
                <app-quick-log-info [data]="product"></app-quick-log-info>           
            </span>
        </ng-container> 
    </p>
</form>  

