<mat-form-field appearance="outline">
    <mat-label>Kategória</mat-label>
    <mat-select [(value)]="category" [disabled]="disabled" (selectionChange)="select($event)" [required]="required">
        <mat-optgroup  [label]="'Beszállítóknak'">
            <mat-option [value]="'taskvendorinterest'">Ajánlatkérés Beszállítótól</mat-option>
            <mat-option [value]="'taskvendororder'">Megrendelés a Beszállítótól</mat-option>
        </mat-optgroup>
        <mat-optgroup  [label]="'Vevőnek'">
            <mat-option [value]="'taskcustomeroffer'">Ajánlat a Vevőnek</mat-option>
            <mat-option [value]="'taskcustomerconfirmation'">Visszaigazolás a Vevőnek</mat-option>
        </mat-optgroup>
    </mat-select>
</mat-form-field>
