import { Pipe, PipeTransform } from '@angular/core';

/**
 * Remove any HTML tag from a given string
 */

@Pipe({name: 'stripHtml'})
export class StripHtmlPipe implements PipeTransform {
    transform(value: string): any {
        value = value.replace(/<.*?>/g, ''); // replace tags
        value = value.replace('&nbsp', ' '); // replace space

        return value;
    }
}