import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Profile } from '../models/profile.model';
import { map, tap } from 'rxjs/operators';
import { NotificationService } from './notification';
import { ListResponse } from '../interfaces/httpResponse.interface';
import { Utils } from '../shared/utils';


@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    baseUrl = environment.baseUrl;

    URL = '/accounts/profiles/';
    URL_MY_PROFILE = '/accounts/profiles/update_my_profile';

    constructor(
        private httpClient: HttpClient,
        private notificationService: NotificationService
        ) {}

    list() {
        let url = this.baseUrl  + this.URL;

        return this.httpClient.get<ListResponse>(url).pipe(
            map(res => res.results.map(profile => new Profile(profile)))
            )
    }

    getByUser(userId: number) {
        // the response is going to be a list bacuse we use the filter option to get the profile
        let url = this.baseUrl  + this.URL;
        
        //TODO:  fake dict. This maybe shouldn't be a separate function - just part of list
        let params = Utils.getParams({'user': userId});
       
        return this.httpClient.get<ListResponse>(url, {params}).pipe(
            // select the first result
            // cannot be more than one!
            map(res => res.results[0]),
            map(res => new Profile(res))
            )
    }


    get(id: number) {
        // get profile by id
        let url = this.baseUrl + this.URL + id + '/';
        return this.httpClient.put(url, id).pipe(
            map(res => new Profile(res))
        )
    }

    update(payload) {
         // remove files to avoid issue with the validation. On the object the fiels are represetned with URL 
        // but the backend expect Files. Thisi service is only handle GET.
        // if you want to upload file use FileService
        delete payload.signature;
        
        let url = this.baseUrl + this.URL + payload.id + '/';
        return this.httpClient.put(url, payload).pipe(
            map(res => new Profile(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    updateMyProfile(payload) {
        let url = this.baseUrl + this.URL_MY_PROFILE + '/';
        return this.httpClient.post(url, payload).pipe(
            tap(res => { this.notificationService.updated(); })
        )
    }

}