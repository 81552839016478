import { Component, Input, OnInit } from '@angular/core';
import { TaskCustomerOrder } from 'src/app/models/tasks/taskCustomerOrder.model';
import { EnumTaskCustomerOrderStatus } from 'src/app/enums/tasks/taskCustomerOrderStatus.enum';
import { TaskCustomerOrderService } from 'src/app/services/tasks/taskCustomerOrder.service';
import { Task } from 'src/app/models/tasks/task.model';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-task-customer-order-detail',
  templateUrl: './task-customer-order-detail.component.html',
  styleUrls: ['./task-customer-order-detail.component.scss']
})
export class TaskCustomerOrderDetailComponent implements OnInit {
  @Input() task: Task;

  taskCustomerOrder: TaskCustomerOrder;

  enumTaskOrderStatus = EnumTaskCustomerOrderStatus;

  taskGet$;
  taskUpdate$;

  constructor(
    private taskCustomerOrderService: TaskCustomerOrderService
  ) { }

  ngOnInit(): void {
    this.taskGet$ = this.taskCustomerOrderService.get(this.task.id).subscribe(resp => {
      this.taskCustomerOrder = resp;

      this.taskCustomerOrderService.onChange(this.taskCustomerOrder);
    })
  }

  save() {
    this.taskUpdate$ = this.taskCustomerOrderService.update(this.taskCustomerOrder).subscribe(resp => {
      this.taskCustomerOrder = resp;

      this.taskCustomerOrderService.onChange(this.taskCustomerOrder);
    })
  }


}
