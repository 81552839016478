import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DlgWysiwygComponent } from 'src/app/shared/dialogs/dlg-wysiwyg/dlg-wysiwyg.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-html-container',
  templateUrl: './html-container.component.html',
  styleUrls: ['./html-container.component.scss']
})
export class HtmlContainerComponent implements OnInit, OnChanges {
  @Input() content: any;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  dlgW$;

  constructor(
    private dialog: MatDialog
  ) {
    if (!this.content) {
      this.content = '';
    }
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.ngOnInit();
  }

  editContent() {
    const dialogConfig = new MatDialogConfig();
    
    dialogConfig.data = (this.content) ? this.content : '';
    dialogConfig.minHeight = 300;
    dialogConfig.disableClose =  true;
    
    const dialogRef = this.dialog.open(DlgWysiwygComponent, dialogConfig);

    this.dlgW$ = dialogRef.componentInstance.onSave.subscribe(content => {
      this.content = content;
      this.onChange.emit(this.content);
    });
  }


}
