<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Műszaki Adat Csoportok</a></li>  
    <li>
        <ng-container *ngIf="attributeGroup">
            {{attributeGroup.nameHu}}
        </ng-container>    
    </li>  
</ul>

<div class="section">
    <div class="header">
        <div class="title"><span>Műszaki Adat Csoportok</span></div>
    </div>
    <ng-container *ngIf="attributeGroup">
        <app-attribute-group [attributeGroup]="attributeGroup"
        (eventCreated)="created($event)"
        (eventDeleted)="deleted($event)" 
        (eventUpdated)="updated($event)"
        ></app-attribute-group>
    </ng-container>
</div>
