import { Component, OnInit } from '@angular/core';
import { TaskEvent } from 'src/app/models/tasks/taskEvent.model';
import { TaskEventService } from 'src/app/services/tasks/taskEvent.service';
import { SecurityService } from 'src/app/services/security.service';
import { User } from 'src/app/models/user';
import { TaskUtil } from 'src/app/utils/task.util';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-task-events',
  templateUrl: './myTaskEvents.component.html',
  styleUrls: ['./myTaskEvents.component.scss']
})
export class MyTaskEventsComponent implements OnInit {
  taskEvents: TaskEvent[];
  user: User;

  constructor(
    private taskEventService: TaskEventService,
    private ss: SecurityService,
    private router: Router
  ) {}

  ngOnInit() {
    this.fetchEvents()
  }

  fetchEvents() {
    let params = {
      limit: 500,
      assignee: this.ss.user.id,
      done: false
    }
    this.taskEventService.list({params}).subscribe(res => {
      this.taskEvents = res.results
    })
  }

  openTask(task) {
    let path = TaskUtil.getTaskPath(task.taskType);
        
    this.router.navigate([path],  {
      queryParams: {id: task.id}, 
    });
  }

}
