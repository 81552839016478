import { Component, OnInit, Input } from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import { BottomPeopleDetailComponent } from './bottom-people-detail/bottom-people-detail.component';


@Component({
  selector: 'app-bottom-people',
  templateUrl: './bottom-people.component.html',
  styleUrls: ['./bottom-people.component.scss']
})
export class BottomPeopleComponent implements OnInit {
  @Input() data: any = null;

  constructor(private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {
  }

  openBottomSheet(): void {
    // passing the data to the component which displayed in the bootm sheet
    this._bottomSheet.open(BottomPeopleDetailComponent, {data: this.data});
  }

}
