<div mat-dialog-title>
    <div class="flex">
        <div class="full">
                <ng-container *ngIf="project && project.id; else tmpUj">{{project.name | truncate:5}}</ng-container>
                <ng-template #tmpUj>Új Projekt</ng-template>
        </div>
        <div>
            <span [mat-dialog-close]>
                <mat-icon class="text-red">close</mat-icon>
            </span>
        </div>
    </div>
</div>
<mat-dialog-content>
    
    <mat-tab-group *ngIf="project && project.id; else projectTab" animationDuration="0ms"  (selectedTabChange)="onTabClick($event)">
        <mat-tab label="Projekt">
            <ng-container *ngTemplateOutlet="projectTab"></ng-container>
        </mat-tab>
        <mat-tab label="Lépések" *ngIf="project.id">
            <ng-template matTabContent>
                <ng-container *ngTemplateOutlet="actionsTab"></ng-container>
            </ng-template>
        </mat-tab>
    </mat-tab-group>

</mat-dialog-content>

<!--project tempalte-->
<ng-template #projectTab>
    <form #f="ngForm">
        <p *ngIf="!project.id">
            <mat-form-field appearance="outline">
                <mat-label>Projekt Terv</mat-label>
                <ng-container *ngIf="project.project_template; else noResult">
                    <input matInput name="template" disabled [(ngModel)]="project._projectTemplate.name"  autocomplete="off">
                </ng-container>
                <ng-template #noResult>
                    <input matInput name="template" disabled [(ngModel)]="project.project_template" autocomplete="off">
                </ng-template>
                <mat-icon class="pointer" (click)="openTemplateSearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Project Név</mat-label>
                <input matInput name="nev" [(ngModel)]="project.name"  autocomplete="off" required>
            </mat-form-field>
            
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Típus</mat-label>
                <mat-select name="project_type" [(ngModel)]="project.project_type" required>
                    <mat-option *ngFor="let option of projectTypes" [value]="option.id">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Leírás</mat-label>
                <textarea rows=3 matInput name="description" [(ngModel)]="project.description"  autocomplete="off"></textarea>
            </mat-form-field>
            
        </p>
        <div>
            <mat-label class="helper-text">Fontosság</mat-label>
            <app-importance-selector [importance]="project.importance" (onChange)="project.importance=$event"></app-importance-selector>
        </div>
        <div>
            <div class="helper-text">Felelős</div>
            <app-user-selector [user]="project._assignee" (onChange)="setAssginedUser($event)"></app-user-selector>
        </div>
        <div>
            <div>Cég</div>
            <app-org-selector [org]="project._organization" (onChange)="setOrg($event)"></app-org-selector>
        </div>
        <div>
            <div>Partner</div>
            <app-org-selector [isVendor]="true" [org]="project._partner" (onChange)="setPartner($event)"></app-org-selector>
        </div>
        <p class="padding-20-0 flex">
            <span class="padding-20-0 full">
                <button mat-flat-button  color="primary" (click)="saveProject()" [disabled]="f.invalid" matTooltip="A Projektet és a Lépéseket külön kell menteni'">
                    <mat-icon>save</mat-icon> Projekt Mentése
                </button>
                <ng-container *ngIf="project.id">
                    <span class="padding-l-10 padding-10-0">
                        <app-quick-log-info [data]="project"></app-quick-log-info>           
                    </span>
                </ng-container>
            </span>
            <ng-container *ngIf="project.id">
                <span class="padding-20-0 padding-l-10">
                    <button mat-icon-button  color="warn"  matTooltip="Törlés" (confirm-click)="deleteProject()" confirm>
                        <mat-icon>delete</mat-icon>
                    </button>
                </span>
            </ng-container>
        </p>
    </form>
</ng-template>



<!--action tempate-->
<ng-template #actionsTab matTabContent>
    <div style="min-height: 150px;" *ngIf="dataSource.data; else tmpAddFirstAction">  
    <mat-tree  #tree [dataSource]="dataSource" [treeControl]="treeControl" class="action-tree">
        <!-- This is the tree node template for leaf nodes -->
        <!-- There is inline padding applied to this node using styles.
          This padding value depends on the mat-icon-button width. -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <div class="action flex">
                <div class="full">
                    <app-status-container [status]="node.status" [showLabel]="false"></app-status-container> 
                    <span *ngIf="!node.edit; else tmpEditActionname" matTooltip="{{node.name}}">{{node.name | truncate:5}}</span>
                    <ng-template #tmpEditActionname>
                        <input type="text" [(ngModel)]="node.name" (ngModelChange)="setActionName(node, $event)" id="node{{node.id}}" name="node{{node.id}}">
                    </ng-template>
                </div>
                <mat-icon class="text-red pointer" (click)="deleteAction(node)" matTooltip="Lépés törlése">delete</mat-icon>
                <mat-icon *ngIf="node.parent && node._parent.parent" class="text-grey pointer" (click)="moveLeftAction(node)" matTooltip="Mozgatás oldalra">subdirectory_arrow_left</mat-icon>
                <mat-icon *ngIf="node.parent" class="text-yellow pointer" (click)="moveUpAction(node)" matTooltip="Mozgatás felfele">arrow_upward</mat-icon>
                <mat-icon class="text-green pointer" (click)="addChildAction(node)" matTooltip="Következő lépés hozzáadása">add</mat-icon>
                <mat-icon class="text-blue pointer" (click)="node.edit=!node.edit" matTooltip="Név változtatás">edit</mat-icon>
            </div>  
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <div class="mat-tree-node">
              <button mat-icon-button matTreeNodeToggle>
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <div class="flex action">
                <div class="full flex" matTooltip="{{node.name}}">
                    <app-status-container [status]="node.status" [showLabel]="false"></app-status-container>
                    <span *ngIf="!node.edit; else tmpEditActionname" matTooltip="{{node.name}}">{{node.name | truncate:5}}</span>
                    <ng-template #tmpEditActionname>
                        <input type="text" [(ngModel)]="node.name" (ngModelChange)="setActionName(node, $event)" id="node{{node.id}}" name="node{{node.id}}">
                    </ng-template>
                </div>
                <mat-icon *ngIf="node.parent && node._parent.parent" class="text-grey pointer" (click)="moveLeftAction(node)" matTooltip="Mozgatás oldalra">subdirectory_arrow_left</mat-icon>
                <mat-icon *ngIf="node.parent" class="text-yellow pointer" (click)="moveUpAction(node)" matTooltip="Mozgatás felfele">arrow_upward</mat-icon>
                <mat-icon class="text-green pointer" (click)="addChildAction(node)" matTooltip="Következő lépés hozzáadása">add</mat-icon>
                <mat-icon class="text-blue pointer" (click)="node.edit=!node.edit" matTooltip="Név változtatás">edit</mat-icon>
            </div>
             
            </div>
            <!-- There is inline padding applied to this div using styles.
                This padding value depends on the mat-icon-button width.  -->
            <div [class.action-tree-invisible]="!treeControl.isExpanded(node)" role="group">
              <ng-container matTreeNodeOutlet></ng-container>
          </div>
        </mat-nested-tree-node>
      </mat-tree>
    </div>

    <ng-template #tmpAddFirstAction>
        <div class="text-center">
            <button mat-flat-button color="accent" (click)="addFirstAction()" matTooltip="Lépés létrhozása">
                <mat-icon>add</mat-icon> Első Lépés
            </button>
        </div>
    </ng-template>

    <div class="padding-20-0">
        <button mat-flat-button color="primary" (click)="saveActions()" matTooltip="A Projektet és a Lépéseket külön kell menteni'">
            <mat-icon>save</mat-icon> Lépések Mentése
        </button>
    </div>

</ng-template>