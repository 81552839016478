import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { EnumCountry } from 'src/app/enums/countries.enums';

@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss']
})
export class CountrySelectorComponent implements OnInit {
  @Output() eventSelect: EventEmitter<string> = new EventEmitter();
  @Input() country: EnumCountry;

  enumsCountry = EnumCountry;

  constructor() { }

  ngOnInit() {
  }

  selected(country: string) {
    this.eventSelect.emit(country)
  }

}
