<div class="flex">
    <div class="full">
        <div *ngIf="organisation; else tmpTitle" class="helper-text flex">
            <span class="margin-r-10">{{organisation.name}}</span>
            <mat-icon (click)="removeOrg()" class="cursor text-red" matTooltip="Cég filter eltávolítása. Keresés az összes kontakt között">close</mat-icon>
        </div>
        <ng-template #tmpTitle>Kontaktok</ng-template>
    </div>
    <button mat-mini-fab color="accent" (click)="createPeople()" matTooltip="Új Kontakt Létrehozása">
        <mat-icon>add</mat-icon>
    </button>
</div>
<form>
    <mat-form-field  appearance="outline" class="w300">
        <mat-icon class="pointer text-red"  (click)="delete()" matSuffix matTooltip="Töröl">delete</mat-icon>
        <input matInput name="txtSearch" (keyup)="search()" [(ngModel)]="txtSearch" placeholder="Keresés.."  [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
        <mat-option (click)="select(option)" *ngFor="let option of options" [value]="option">
            {{option.fullName}}
            <span *ngIf="option.organization" class="text-secondary text-tiny">
                ({{option._organization.name}})
            </span>
        </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>

