import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CategoryService } from 'src/app/services/category.service';
import { DlgEditCategoryComponent } from 'src/app/shared/dialogs/edit/dlg-edit-category/dlg-edit-category.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-dlg-search-category',
  templateUrl: './dlg-search-category.component.html',
  styleUrls: ['./dlg-search-category.component.scss']
})
export class DlgSearchCategoryComponent implements OnInit {
  //text which we searching 
  txtSearch: string = '';
  options = [];
  timeout = null;
  
  list$;
  dlgCatEdit$;

  constructor(
    private service: CategoryService,
    public dialogRef: MatDialogRef<DlgSearchCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog
  ) {
    this.txtSearch = data.name;
  }

  ngOnInit() {
    // help to avoid: ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.search();
    }, 0)
  }

  search() {
    this._doSearch();
  }

  _doSearch() {
    let options = {params: {
      search: this.txtSearch,
      limit: 50
    }};

    this.list$ = this.service.list(options).subscribe(data => {
      this.options = data.results
     });
  }

  select(entity) {
      this.txtSearch = entity.nameHu;

      setTimeout(() => {
        this.dialogRef.close(entity);
      }, 500);
     
  }

  delete() {
    this.txtSearch = '';

    setTimeout(() => {
      this.dialogRef.close('');
    }, 500);
  }

  openCreateDlg() {
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.data = null;

    const dialogRef = this.dialog.open(DlgEditCategoryComponent, dialogConfig);

    this.dlgCatEdit$ = dialogRef.afterClosed().subscribe(category => {
      if (category) {
        this.select(category)
      }
    });
  }

}

