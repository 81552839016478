import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseList } from '../common/baseClasses/baseList';
import { EnumProjectImportance } from '../enums/projectImportance.enum';
import { ProjectType } from '../models/projects/projectType.model';
import { User } from '../models/user';
import { ProjectService } from '../services/projects/project.service';
import { ProjectTypeService } from '../services/projects/projectType.service';
import { UrlParamService } from '../services/urlParam.service';
import { UsersService } from '../services/users.service';
import { DlgEditProjectComponent } from '../shared/dialogs/edit/dlg-edit-project/dlg-edit-project.component';
import { forkJoin } from 'rxjs';
import { AutoUnsub } from '../utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  providers: [UrlParamService]
})
export class ProjectsComponent  extends BaseList implements OnInit  {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['importance', 'name', 'assignee', 'organization', 'partner', 'status'];

  status_open = true;

  project_type = null;
  projectTypes: ProjectType[] = null;

  assignee: User = null;
  users: User[] = [];

  importance = null;

  dlgProjEdit$;

  constructor(
    private projectService: ProjectService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService,
    private projectTypeService: ProjectTypeService,
    private usersService: UsersService,
    private dialog: MatDialog
  ){
    super()

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit() {
    let defaultParams = {open: this.status_open}

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      }

      let users$ = this.usersService.getActiveUsers();
      let projectTypes$ =  this.projectTypeService.list({params:{limit: 1000}});
      let projects$ = this.projectService.list(options);

      forkJoin([ users$, projectTypes$, projects$]).subscribe(res => {
        this.users = res[0];
        this.projectTypes = res[1].results;
        // projects
        this.setListResponseData(res[2]);
      });

      if ('create' in options.params) {
        // if the create param send (independently for the value we open the project creation)
        this.openEditDlg();
      }
    })
  }

  openProject(entity) {
    this.router.navigate(['project'],  {
      queryParams: {id: entity.id}, 
      relativeTo: this.route  
    });
  }

  changeStatus(event) {
    this.resetFilters();

    // active or archived
    this.status_open = (event.value == 'open') ? true : false;
    
    let param = {open: this.status_open};   
    this.urlParamService.update(param);
    this.urlParamService.set();
  }

  changeType(projectType) {
    this.resetFilters();

    let param = {project_type: null}; 
    
    this.project_type = null;
    
    if (projectType) {
      this.project_type = projectType;
      param = {project_type: projectType.id};   
    }
 
    this.urlParamService.update(param);
    this.urlParamService.set();
  }

  changeImportance(importance) {
    this.resetFilters();

    let param = {importance: null}; 
    
    this.importance = null;
    
    if (importance) {
      this.importance = importance;
      param = {importance: +importance};   
    }
 
    this.urlParamService.update(param);
    this.urlParamService.set();
  }

  changeUser(user) {
    this.resetFilters();

    let param = {assignee: null}; 
    
    this.assignee = null;
    
    if (user) {
      this.assignee = user;
      param = {assignee: this.assignee.id};   
    }
 
    this.urlParamService.update(param);
    this.urlParamService.set();
  }

  openEditDlg() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "600px";
      
    dialogConfig.data = {
      project: null
    };

    const dialogRef = this.dialog.open(DlgEditProjectComponent, dialogConfig);

    this.dlgProjEdit$ = dialogRef.componentInstance.onChangeProject.subscribe(project => {
      this.openProject(project);
    });
  }
}
