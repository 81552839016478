<div class="padding-20-0 text-center">Keresés</div>
<form class="padding-10-0">
    <mat-form-field appearance="outline">
        <mat-icon class="pointer text-red"   (click)="delete()" matSuffix matTooltip="Töröl">close</mat-icon>
        <input matInput name="txtSearch" (keyup)="search()" [(ngModel)]="txtSearch" placeholder="Keresés"  [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
        <mat-option (click)="select(option)" *ngFor="let option of options" [value]="option">
            {{option.name}} 
        </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <div class="flex padding-l-10">
        <div class="full">
            <button mat-mini-fab  color="primary" (click)="save()"  matTooltip="Kiválaszt">
                <mat-icon>check</mat-icon>
            </button>
        </div>
    </div>
</form>

