import { Pipe, PipeTransform } from '@angular/core';

// full formatted date time
@Pipe({name: 'huf'})
export class HufPipe implements PipeTransform {
    transform(huf: number): string {
        if (!huf) huf=0;
        
        const money = new Intl.NumberFormat('hu-HU', { 
            currency: 'HUF',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
        
        return money.format(huf) + ' Ft';
    }
}
