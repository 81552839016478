<ul class="breadcrumb"> 
    <li>Sablonok</li>
</ul>

<div class="flex"> 
    <div class="padding-l-10">
        <app-template-category-selector (onSelect)="changeCategory($event)"></app-template-category-selector>
    </div>
    <div class="padding-l-10">
        <mat-radio-group (change)="changeType($event)">
            <mat-radio-button [checked]="type == 'pdf'" value="pdf">Pdf</mat-radio-button>
            <mat-radio-button [checked]="type == 'email'" value="email">Email</mat-radio-button>
          </mat-radio-group>
    </div>
    
    <div class="full text-right">
        <span class="padding-l-10">
            <button mat-mini-fab  color="accent" [routerLink]="['template']"  matTooltip="Új Sablon Létrehozása"> 
                <mat-icon>add</mat-icon>
            </button>
        </span>
    </div> 
</div>

<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Sablon Név</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="link" (click)="open(row)">{{row.name}} </span>
        </mat-cell>
    </ng-container>


    <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef>Kategória</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-template-category-container [category]="row.category"></app-template-category-container>    
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>Típus</mat-header-cell>
        <mat-cell *matCellDef="let row">
           <app-icon-template-type [template]="row"></app-icon-template-type>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
></mat-paginator>


