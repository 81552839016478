import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    isLoading: any = new Subject<boolean>();

    show() {
        setTimeout(() => {
          this.isLoading.next(true);
        }, 200);    
    }
    hide() {
        // delay to hide 1000 sec to give time to loader animation
        setTimeout(() => {
            this.isLoading.next(false);
         }, 700);    
    }
}