import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttributeService } from 'src/app/services/attribute.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';
import { DlgEditAttributeComponent } from '../../edit/dlg-edit-attribute/dlg-edit-attribute.component';

@AutoUnsub()
@Component({
  selector: 'app-dlg-search-attribute',
  templateUrl: './dlg-search-attribute.component.html',
  styleUrls: ['./dlg-search-attribute.component.scss']
})
export class DlgSearchAttributeComponent implements OnInit {
  txtSearch: string = '';
  options = [];
  timeout = null;

  list$;
  dlgAttEdit$;

  constructor(
    private service: AttributeService,
    public dialogRef: MatDialogRef<DlgSearchAttributeComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
      // help to avoid: ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => {
      this.search();
    }, 0)
  }

  search() {
    this._doSearch();
  }

  _doSearch() {
    let options = {params: {
      search: this.txtSearch,
      limit: 100
    }};

    this.list$ = this.service.list(options).subscribe(data => {
      this.options = data.results
     });
  }

select(entity) {
    this.txtSearch = entity.nameHu;

    setTimeout(() => {
      this.dialogRef.close(entity);
    }, 500);
   
}

delete() {
  this.txtSearch = '';

  setTimeout(() => {
    this.dialogRef.close('');
  }, 500);
}

openCreateDlg() {
  const dialogConfig = new MatDialogConfig();

  dialogConfig.data = null;

  const dialogRef = this.dialog.open(DlgEditAttributeComponent, dialogConfig);

  this.dlgAttEdit$ = dialogRef.afterClosed().subscribe(res => {
    if (res) {
      this.select(res);
    }
  });
}

}
