import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Action } from 'src/app/models/projects/action.model';

@Component({
  selector: 'app-bottom-action-detail',
  templateUrl: './bottom-action-detail.component.html',
  styleUrls: ['./bottom-action-detail.component.scss']
})
export class BottomActionDetailComponent implements OnInit {
  action: Action;
  @Output() onChange: EventEmitter<Action> = new EventEmitter();

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _bottomSheetRef: MatBottomSheetRef<BottomActionDetailComponent>,
  ) {
    this.action = data;
  }

  ngOnInit(): void {
  }

  changed(action) {
    this.action = action;
    this.onChange.emit(this.action);
  }

  close() {
    this._bottomSheetRef.dismiss();
  }

}
