import { Component, Input, Output, OnInit, EventEmitter, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dlg-wysiwyg',
  templateUrl: './dlg-wysiwyg.component.html',
  styleUrls: ['./dlg-wysiwyg.component.scss']
})
export class DlgWysiwygComponent implements OnInit {
  content: any = '';
  @Output() onSave: EventEmitter<string> = new EventEmitter();

  config: any;


  constructor(
    public dialogRef: MatDialogRef<DlgWysiwygComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    // workaround, to display the content in the ck editor
    setTimeout(() => {  
      this.content = data;
    }, 0);  
  }

  ngOnInit() {
    this.config = {
      toolbar: {
        items: ['heading', '|', 'bold', 'italic', '|',  // underline option is not available
        'link', '|', 
        'bulletedList', 'numberedList', '|',
        'outdent', 'indent', '|', 
        'insertTable', '|',
        'undo', 'redo']
      },
      allowedContent: true,
      extraAllowedContent: '*{*}'
    }
  }

  save() {
    this.onSave.emit(this.content);
    this.dialogRef.close(true);
  }

  close() {
    // close button is separatly implemented to prevent to close the dialog accidenlty
    // when open the dialog add to the config: dialogConfig.disableClose =  true;
    this.dialogRef.close(true);
  }

}
