import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { Action } from 'src/app/models/projects/action.model';
import { ProjectService } from 'src/app/services/projects/project.service';
import { EnumTaskStatus } from 'src/app/enums/tasks/taskStatus.enum';

@Component({
  selector: 'app-project-actions-tree',
  templateUrl: './project-actions-tree.component.html',
  styleUrls: ['./project-actions-tree.component.scss']
})
export class ProjectActionsTreesComponent implements OnInit, OnChanges {
  @Input() actions: Action[];
  @Input() isPlan: boolean = false;
  
  chartData: any = null;

  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  constructor(
    private projectService: ProjectService) {  
  }

  ngOnInit(): void {
    this.renderTree();
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.renderTree();
  }

  renderTree() {
    if (this.actions.length === 0) return;

    // set some data to desgin the tree
    this.actions.forEach(a => {
      let color = '#dcdde1';
      if (a.status == EnumTaskStatus.InProgress) { color = '#fbc531'};
      if (a.status == EnumTaskStatus.Blocked) { color = '#c23616'};
      if (a.status == EnumTaskStatus.Done) { color = '#44bd32'};
      if (a.status == EnumTaskStatus.Skipped) { color = '#000000'};

      let lineWidth = (this.isPlan) ? 2 : 15;

      a['itemStyle'] = { color: color }
      a['lineStyle'] = { color: color, width: lineWidth}
    })

    // transform to a tree structure
    let treeData = this.projectService.getActionTree(this.actions);

    let me = this;

    this.chartData = {
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        formatter: function(n) {
          return n.name;
        }
      },
      series: [
        {
          top: '0%',
          left: '35px',
          bottom: '0%',
          right: '20%',
          type: 'tree',
          data: [treeData],
          symbolSize: 15,
          label: {
            position: 'top',
            distance: 6,
            show: true,
            formatter: function(d) {
              if (me.isPlan) {
                return d.data._actionTemplate.name.split(' ').join('\n');
              }
              return d.name.split(' ').join('\n');
            },
            backgroundColor: '#f5f6fa',
            borderRadius: 5,
            padding: 4,
            fontSize: 9,
            color: '#353b48'
          },
          expandAndCollapse: false,
          animationDuration: 400,
          animationDurationUpdate: 600
        },
      ],
    }
  }

  onChartEvent(event) {
    this.onSelect.emit(event.data.id);
  }
}