import { Injectable } from '@angular/core';
 
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarRef } from '@angular/material/snack-bar';
import { EnumNotificationType } from '../enums/notificationType.enum';
import { EnumNotificationLevel } from '../enums/notificationLevel.enum';
import { NotificationContentComponent } from '../shared/snippets/notification-content/notification-content.component';


@Injectable()
export class NotificationService {
  snackBarConfig: MatSnackBarConfig;
  snackBarRef: MatSnackBarRef<any>;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  snackBarAutoHide = '2000';

  constructor(private snackBar: MatSnackBar) { }

  notify(type, level=EnumNotificationLevel.Error) {
    // @type - enum which identify the error message
    // @level - set the color of the notification
    this.snackBarConfig = new MatSnackBarConfig();
    this.snackBarConfig.horizontalPosition = this.horizontalPosition;
    this.snackBarConfig.verticalPosition = this.verticalPosition;
    this.snackBarConfig.duration = parseInt(this.snackBarAutoHide, 0);

    let cssClass = 'snackbar-warning';
    if (level == EnumNotificationLevel.Success) {
      cssClass = 'snackbar-success';
    } else if (level == EnumNotificationLevel.Error) {
      cssClass = 'snackbar-danger';
    }
    this.snackBarConfig.panelClass = cssClass;

    // notification type
    this.snackBarConfig.data = type;
    
    //this.snackBarRef = this.snackBar.open(message, '', this.snackBarConfig);
    this.snackBarRef = this.snackBar.openFromComponent(NotificationContentComponent,  this.snackBarConfig);
}

success() {
  this.notify(EnumNotificationType.Success, EnumNotificationLevel.Success);
}

error() {
  this.notify(EnumNotificationType.Error,  EnumNotificationLevel.Error);
}

updated() {
  this.notify(EnumNotificationType.Updated, EnumNotificationLevel.Success);
}

created() {
  this.notify(EnumNotificationType.Created,  EnumNotificationLevel.Success);
}

deleted() {
  this.notify(EnumNotificationType.Deleted, EnumNotificationLevel.Success);
}

emailSent() {
  this.notify(EnumNotificationType.EmailSent, EnumNotificationLevel.Success);
}

dirty() {
  // data is dirty on the page, don't forget to save
  this.notify(EnumNotificationType.Dirty, EnumNotificationLevel.Warning);
}

}