import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'app-default-price-table',
  templateUrl: './default-price-table.component.html',
  styleUrls: ['./default-price-table.component.scss']
})
export class DefaultPriceTableComponent implements OnInit {
  @Input() defaultConfig: any;

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if (!this.defaultConfig || isEmpty(this.defaultConfig)) {
      // provide default if empty
      this.defaultConfig['includeBrutto'] = false;
    }
  }

  changeBrutto() {
    this.defaultConfig.includeBrutto = !this.defaultConfig.includeBrutto;
    this.onChange.emit('changed')
  }

}
