import { Item } from './item.model';

export class BuiltInItem {
    id: number = null;
    item: number = null;
    built_in: number = null
    _item?: any = null;
    _built_in?: any = null;
    amount: number = null;
    
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.item  = data.item;
            this.built_in = data.built_in;
            this.amount = data.amount;

            if ('_item' in data) {
                this._item = new Item(data._item);
            }

            if ('_built_in' in data) {
                this._built_in = new Item(data.built_in);
            }

            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);

        }  
    }
  }
