import { Component, OnInit, Input } from '@angular/core';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { PeoplePhone } from 'src/app/models/people.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { PeopleService } from 'src/app/services/people.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-person-phone',
  templateUrl: './person-phone.component.html',
  styleUrls: ['./person-phone.component.scss']
})
export class PersonPhoneComponent extends BaseEntity implements OnInit {
  @Input() peopleId: number;

  phones: PeoplePhone[] = [];

  phoneList$;
  phoneCreate$;
  phoneUpdate$;
  phoneDelete$;

  constructor(
    private peopleService: PeopleService,
    private modelFactory: ModelFactory
  ) {
    super();
  }

  ngOnInit() {
    let params = {contact: this.peopleId};
    this.phoneList$ = this.peopleService.listPhones(params).subscribe(resp => {
      this.phones = resp;
    })
  }

  save(phone) {
    if (typeof phone.id === 'string') {
      // create
      this.create(phone);
    } else {
      this.update(phone);
    }
  }

  create(phone) {
    this.phoneCreate$ = this.peopleService.createPhone(phone).subscribe(res => {
      // fitler out the dummy email
      this.phones = this.phones.filter(e => e.phone != res.phone);
      // add the newly created to the list
      this.phones.push(res)
    })
  }

  update(phone) {
    this.phoneUpdate$ = this.peopleService.updatePhone(phone).subscribe()
  }

  add() {
    // add new element to the list
    let phone = this.modelFactory.getNewModel(PeoplePhone);
    phone.setPeopleId(this.peopleId);
    // set a dummy ID
    phone.setId(this.getNextId());
    this.phones.push(phone);
  }

  delete(phone) {
    if (typeof phone.id === 'string') {
      // delete only from the list
      this.phones = this.phones.filter(e => e.id !== phone.id);
    } else {
      // delete from the server
      this.phoneDelete$ = this.peopleService.deletePhone(phone.id).subscribe(res => {
        this.phones = this.phones.filter(e => e.id !== phone.id);
      });
    }
  }

}
