import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnumTaskCustomerInterestCondtion } from 'src/app/enums/tasks/taskCustomerInteresetCondition.enum';
import { EnumTaskCustomerOrderStatus } from 'src/app/enums/tasks/taskCustomerOrderStatus.enum';
import { EnumTaskInterestStatus } from 'src/app/enums/tasks/taskInterestStatus.enum';
import { Audit } from 'src/app/models/audit.model';
import { AuditService } from 'src/app/services/audit.service';
import { isTruthy } from 'src/app/utils/isTruthy.util';
import { TaskUtil } from 'src/app/utils/task.util';
import { DateUtil } from 'src/app/utils/date.util';

@Component({
  selector: 'app-audits',
  templateUrl: './audits.component.html',
  styleUrls: ['./audits.component.scss']
})
export class AuditsComponent implements OnInit {
  audits: Audit[];

  enumTaskInterestStatus = EnumTaskInterestStatus;
  enumTaskCustomerInterestCondition = EnumTaskCustomerInterestCondtion;
  enumTaskOrderStatus = EnumTaskCustomerOrderStatus;

  selectedDate: Date = new Date(new Date().setDate(new Date().getDate()-1));

  constructor(
    private auditService: AuditService,
    public router: Router,
  ) { }

  ngOnInit(): void {

    this.fetchAudit();
  }

  fetchAudit() {
    let start = DateUtil.startOfDay(this.selectedDate);
    let end = DateUtil.endOfDay(this.selectedDate);
    
    let params:any = {}
    params.dates = start.toISOString() + '&' + end.toISOString();
    params.limit = 200

    this.auditService.list({params}).subscribe(resp => {
        this.audits = resp.results;
    });
  }

  openTask(task) {
    let path = TaskUtil.getTaskPath(task.taskType);
        
    this.router.navigate([path],  {
      queryParams: {id: task.id}, 
    });
  }

  isTruthyValue(value) {
    return isTruthy(value);
  }

  setToday() {
    this.selectedDate = new Date();
    this.fetchAudit();
  }

  setYestarday() {
    this.selectedDate = new Date(new Date().setDate(new Date().getDate()-1));
    this.fetchAudit();
  }

  setNextDay() {
    this.selectedDate = new Date(new Date().setDate(this.selectedDate.getDate()+1));

    if (this.selectedDate > new Date()) {
      this.setToday();
      return;
    }

    this.fetchAudit();
  }

  setPrevDay() {
    this.selectedDate = new Date(new Date().setDate(this.selectedDate.getDate()-1));
    this.fetchAudit();
  }

}
