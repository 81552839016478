<table class="table" *ngIf="offers.length > 0">
    <tr *ngFor="let offer of offers">
        <td>
            <app-product-container [product]="offer._offer"></app-product-container>
        </td>
        <td class="w50">
            <button mat-icon-button  color="warn"  matTooltip="Törlés" (confirm-click)="delete(offer)" confirm>
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </tr>
</table>


<button class="margin-20-0" mat-mini-fab  color="accent" (click)="openProductSearchDlg()" matTooltip="Hozzáadás"> 
    <mat-icon>add</mat-icon>
</button>

