import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from '../notification';
import { Utils } from '../../shared/utils';
import { TaskPdf } from '../../models/tasks/taskPdf.model';
import { ModelFactory } from '../modelFactory.service';
import { TemplateSection } from 'src/app/models/template.model';


@Injectable({
    providedIn: 'root'
  })
export class TaskPdfService {
    baseUrl = environment.baseUrl;

    URL = '/tasks/pdfs';

    
    constructor(
        private http: HttpClient,
        private notificationService: NotificationService,
        private modelFactory: ModelFactory
        ) {}

    list(options=null) {
        // list all the contacts
        // @params: obj of params
        // @url: full url with params (next and prev for pagination)
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL + '/';
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }

        if (!options.params.task || !options.params.category) {
            throw "Task and Pdf Type Template Category!";
            return;
        }

        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res = res.map(i => new TaskPdf(i));
                return res;
            })
        )
    }

    get(id) {
        let url = this.baseUrl + this.URL + '/' + id + '/';
        return this.http.get<TaskPdf>(url).pipe(
            map(res => new TaskPdf(res))
        )
    }

    update(payload) {
        let url = this.baseUrl + this.URL + '/' + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new TaskPdf(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    delete(payload) {
        let url = this.baseUrl + this.URL + '/' + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

    create(payload) {
        // create PDF 
        let url = this.baseUrl + this.URL  + '/generate_pdf/';
        // don't need notification handeld by the Comonent
        return this.http.post(url, payload)
    }

    get_pdf_data(taskPdf): any {
        // return the raw data based on which the task was created
        let url = this.baseUrl + this.URL  + '/get_pdf_data/?id=' + taskPdf.id;
        return this.http.get(url);
    }

    pdfSectionToPdfSectionConnector(pdfData) {
        /**
         * this helper task transform a pdfSection to templateSection to make it editable again
         * @pdfData: data based on which the pdf was generated
         * @taskPdf: data based on which revive
         */
        let templateSections = []

        pdfData.sections.forEach(pds => {
            let templateSection = this.modelFactory.getNewModel(TemplateSection)
            templateSection.order = pds.order;
            templateSection.column = pds.column;
            templateSection.defaultSection = pds.defaultSection;

            if (pdfData.language == 'en') {
                templateSection.titleEn = pds.title;
                templateSection.bodyEn = pds.body;
            } else {
                templateSection.titleHu = pds.title;
                templateSection.bodyHu = pds.body;
            }

            /**
             * Have collect and merge all the config setup for the different sections
            */

            // extend the config from the taskPdf
            if (pds.defaultSection) {
               templateSection.defaultConfig = {...templateSection.defaultConfig, ...pds.defaultConfig};
            }

            templateSections.push(templateSection)
        })

        return templateSections;
    }
}