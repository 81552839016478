import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnumTaskTypes } from 'src/app/enums/tasks.enums';

@Component({
  selector: 'app-task-type-selector',
  templateUrl: './task-type-selector.component.html',
  styleUrls: ['./task-type-selector.component.scss']
})
export class TaskTypeSelectorComponent implements OnInit {
  @Input() taskType: EnumTaskTypes | string;
  // show Select option (no team select)
  @Input() showSelect: boolean = false;

  @Output() onChange: EventEmitter<EnumTaskTypes|string> = new EventEmitter();

  enumTaskTypes = EnumTaskTypes

  constructor() { }

  ngOnInit(): void {
  }

  update() {
    this.onChange.emit(this.taskType)
  }

}
