/**
 * Email Servive:
 * @list: list the emails which are sent out form the system
 * @send : send out the custom emails
 */
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { NotificationService } from './notification';
import { Utils } from '../shared/utils';
import { EmailLog } from 'src/app/models/emailLog.model';


@Injectable({
    providedIn: 'root'
  })
export class EmailService {
    baseUrl = environment.baseUrl;

    URL = '/logs/emails';
    URL_SEND = '/email-send';
    
    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {}

    list(options=null) {
        // list all the contacts
        // @params: obj of params
        // @url: full url with params (next and prev for pagination)
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL + '/';
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }

        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(i => new EmailLog(i));
                return res;
            })
        )
    }

    send(payload) {
        // validate payload: subject, body, cc
        if (!('subject' in payload)) throw('No Subject');
        if (!('body' in payload)) throw('No Body');
        if (!('to' in payload) || !('cc' in payload) || !('bcc' in payload)) throw('No Recipient');
    
        // form data
        let fd: any = new FormData();
        fd.append('subject', payload.subject);
        fd.append('body', payload.body);
        fd.append('to', payload.to);
        fd.append('cc', payload.cc);
        fd.append('bcc', payload.bcc);
        fd.append('sendByMyself', payload.sendByMyself)

        if (payload.taskPdf) {
            fd.append('taskPdf', payload.taskPdf);
        }

        payload.attachments.forEach(attachment => {
            fd.append('file', attachment);
        })

        let headers = new HttpHeaders;
        headers = headers.delete('Content-Type');
        headers = headers.set('Accept', 'application/json');

        let url = this.baseUrl + this.URL_SEND + '/';

        return this.http.post(url, fd, {headers}).pipe(
            tap(res => { this.notificationService.emailSent(); })
        )
    }
}