import { Component, Input, OnInit } from '@angular/core';
import { EnumTaskCustomerInterestCondtion } from 'src/app/enums/tasks/taskCustomerInteresetCondition.enum';
import { EnumTaskInterestStatus } from 'src/app/enums/tasks/taskInterestStatus.enum';
import { Task } from 'src/app/models/tasks/task.model';
import { TaskCustomerInterest } from 'src/app/models/tasks/taskCustomerInterest.model';
import { TaskCustomerInterestService } from 'src/app/services/tasks/taskCustomerInterest.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-task-customer-interest-detail',
  templateUrl: './task-customer-interest-detail.component.html',
  styleUrls: ['./task-customer-interest-detail.component.scss']
})
export class TaskCustomerInterestDetailComponent implements OnInit {
  @Input() task: Task;

  taskCustomerInterest: TaskCustomerInterest;

  enumTaskInterestStatus = EnumTaskInterestStatus;
  enumTaskCustomerInterestCondition = EnumTaskCustomerInterestCondtion;

  taskGet$;
  taskUpdate$;

  constructor(
    private taskCustomerInterestService: TaskCustomerInterestService
  ) { }

  ngOnInit() {
    this.taskGet$ = this.taskCustomerInterestService.get(this.task.id).subscribe(resp => {
      this.taskCustomerInterest = resp;

      this.taskCustomerInterestService.onChange(this.taskCustomerInterest);
    })
  }

  save() {
    this.taskUpdate$ = this.taskCustomerInterestService.update(this.taskCustomerInterest).subscribe(resp => {
      this.taskCustomerInterest = resp;

      this.taskCustomerInterestService.onChange(this.taskCustomerInterest);
    })
  }

}
