import { Component, OnInit, Input } from '@angular/core';
import { Action } from 'src/app/models/projects/action.model';
import { EnumActionStatus } from 'src/app/enums/actionStatus.enum'
import { ProjectService } from 'src/app/services/projects/project.service';
import { EnumTaskStatus } from 'src/app/enums/tasks/taskStatus.enum';

@Component({
  selector: 'app-project-status',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.scss']
})
export class ProjectStatusComponent implements OnInit {
  @Input() actions: Action[];

  // built to help easy to visualize the status
  data = [];


  constructor() {
  }

  ngOnInit() {   
      this.actions.forEach(action => {
        let section = this.createTask(action);
        this.data.push(section);
      })
  }

  createTask(action) {
    let percent = 100 / this.actions.length;

    let strPercent = Math.floor(percent) + '%'

    let tooltip = "Nem kezdőtött el";
    if (action.status == EnumTaskStatus.InProgress) tooltip = 'Folyamatban';
    if (action.status == EnumTaskStatus.Blocked) tooltip = 'Elakadt';
    if (action.status == EnumTaskStatus.Done) tooltip = 'Befejezve';
    if (action.status == EnumTaskStatus.Skipped) tooltip = 'Átugorva';


    let section = {
      tooltip: action.name + ':' + tooltip,
      class: action.status,
      percent: strPercent
    }


    return section;
  }

}
