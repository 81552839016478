<ul class="breadcrumb"> 
    <li>Projekt Sablonok</li>  
</ul>

<div class="flex"> 
    <button (click)="doSearch()" mat-mini-fab color="primary" [disabled]="txtSearch === ''">
        <mat-icon matSuffix>search</mat-icon>
    </button>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Keresés</mat-label>
            <input [(ngModel)]="txtSearch" matInput placeholder="?" (keyup.enter)="doSearch()">
            <mat-icon  class="pointer" matTooltip="Keresés Törlése" (click)="noSearch()" matSuffix>close</mat-icon>
        </mat-form-field>
    </div>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Típus</mat-label>
            <mat-select name="project_type" [(ngModel)]="project_type">
                <mat-option (click)="changeType('')" [value]="">Válassz</mat-option>
                <mat-option (click)="changeType(option)" *ngFor="let option of projectTypes" [value]="option.id">
                    {{option.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="padding-l-10">
        <mat-radio-group (change)="changeStatus($event)">
            <mat-radio-button [checked]="!archived" value="active">Active</mat-radio-button>
            <mat-radio-button [checked]="archived" value="archived" matTooltip="Nem válsztható ki új Projektek létrhozásakor">Archivált</mat-radio-button>
          </mat-radio-group>
    </div>
    
    <div class="full text-right">
        <span class="padding-l-10">
            <button mat-mini-fab  color="accent" [routerLink]="['project']"  matTooltip="Új Projekt Terv"> 
                <mat-icon>add</mat-icon>
            </button>
        </span>
    </div> 
</div>

<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Sablon Név</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="link" (click)="open(row)">{{row.name}} </span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>Projekt Típus</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.project_type">
                <span class="color-dot" [style.backgroundColor]="row._projectType.color"></span>
                <span>{{row._projectType.name}}</span>
            </ng-container>
        </mat-cell>
    </ng-container>


    <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef>Leírás</mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{row.description | truncate:8}}
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
></mat-paginator>

