<div class="helper-text padding-20-0">
    Lezáratlan Szervíz Események és Tennivalóid listája
</div>
<div class="table-responsive">
<table class="table table-striped" *ngIf="taskEvents && taskEvents.length > 0">
    <thead>
      <tr>
        <th class="w50"></th>
        <th>Tervezett Időpont</th>
        <th>Feladat</th>
        <th>Leírás</th>
      </tr>
</thead>
    <tbody>
      <tr *ngFor="let taskEvent of taskEvents">
        <td>
            <app-static-true-false-icon [value]="taskEvent.done"></app-static-true-false-icon>
        </td>
            <td>
                <div>{{taskEvent.start | dateTime}}</div>
                <div class="helper-text">{{taskEvent.end | dateTime}}</div>
            </td>
            <td>
                <div class="text-yellow">
                    <app-task-type-container [type]="taskEvent._task.taskType"></app-task-type-container>
                </div>
                <div class="flex">
                    <app-status-container [status]="taskEvent._task.status" [showLabel]="false"></app-status-container>
                    <span class="link" (click)="openTask(taskEvent._task)" matTooltip="{{taskEvent._task.name}}">{{taskEvent._task.name | truncate: 5}} </span>
                </div>
            </td>
            <td matTooltip="{{taskEvent.problem}}">{{taskEvent.problem |truncate:12 }}</td>
      </tr>
    </tbody>
</table> 
</div>

<ng-container *ngIf="taskEvents && taskEvents.length == 0">Nincs Esemény/Tennivaló</ng-container>

<br><br>
