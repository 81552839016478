import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { EnumFileUploadType } from 'src/app/enums/fileUploadType.enum';
import { Task } from 'src/app/models/tasks/task.model';
import { TaskDoc } from 'src/app/models/tasks/taskDoc';
import { FileUploadService } from 'src/app/services/fileUpload.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { TaskDocService } from 'src/app/services/tasks/taskDoc.service';

@Component({
  selector: 'app-task-docs',
  templateUrl: './task-docs.component.html',
  styleUrls: ['./task-docs.component.scss']
})
export class TaskDocsComponent implements OnInit {
  @Input() task: Task

  taskDocs: TaskDoc[] = [];

  constructor(
    private fileUploadService: FileUploadService,
    private taskDocService: TaskDocService,
    private modelFacotry: ModelFactory
  ) {
  }

  ngOnInit(): void {
      this.fetchDocs()
  }

  fetchDocs() {
    let params = {task: this.task.id}
    this.taskDocService.list({params}).subscribe(res =>{
      this.taskDocs = res;
    });
  }

  saveFile(formData: any) {
    // if a file is uploaded create the instance and after upload the file
    let taskDoc = this.modelFacotry.getNewModel(TaskDoc);
    taskDoc.task = this.task.id;
    taskDoc._task = this.task;

    this.taskDocService.create(taskDoc).subscribe(tD => {
      this.fileUploadService.upload(formData, EnumFileUploadType.TaskDoc, tD).subscribe(res => {
        this.fetchDocs()
      });
    });
  }

  deleteDoc(taskDoc) {
    this.taskDocService.delete(taskDoc).subscribe(res => {
      this.taskDocs = this.taskDocs.filter(tD => tD.id != taskDoc.id);
    })
  }

  update(taskDoc) {
    this.taskDocService.update(taskDoc).subscribe(res => {
       // js already up-to-date
    });
  }
}
