<div class="section">

    <ng-container *ngIf="createPdfView; else tmpListPdf">
        <div class="padding-10-0">
            <button mat-flat-button color="primary" (click)="createPdfView=!createPdfView; isPdfGenerated=false">
                <mat-icon>arrow_back</mat-icon>
                Vissza
            </button>
        </div>
        <ng-container *ngIf="isPdfGenerated; else tmpGenerate">
            <div class="alert alert-success">PDF legenerálódott. A Vissz gombra kattintas utan megjelenik a listában.</div>
        </ng-container>
        <ng-template #tmpGenerate>
            <ng-container *ngTemplateOutlet="generatePDf"></ng-container>
        </ng-template>
      </ng-container>

<ng-template #tmpListPdf>
    <div class="padding-10-0">
            <button mat-flat-button color="primary" (click)="createPdfView=!createPdfView;  isPdfGenerated=false">
                <mat-icon>add</mat-icon>
                Új Javítási Munkalap Generálás
              </button>
        </div>
    <app-task-pdfs *ngIf="task" 
    [task]="task" 
    [templateCategory]="TEMPLATE_CATEGORY"
    [allowedEmailSending]="true"
    [allowGenerateNewPdf]="false"></app-task-pdfs> 
</ng-template>     

<ng-template #generatePDf>

<div *ngIf="!task.organization" class="text-red padding-20-0">Nincs Cég kiválasztva a feladathoz!</div>    

<form  #f="ngForm">
    <div class="padding-10-0" class="text-yellow margin-20-0">
        <span class="badge badge-pill badge-warning">1</span> Szervíz Esemény Kiválasztása
    </div>
    <div class="padding-10-0">
        <mat-form-field appearance="outline">
            <mat-label>
               Tennivaló
            </mat-label>
            <mat-select name="event" required [ngModel]="taskEvent"  (selectionChange)="populateTaskEvent($event)">
                <ng-container *ngFor="let taskEvent of taskEvents">
                    <mat-option [value]="taskEvent" matTooltip="{{taskEvent.problem}}">{{taskEvent.start|date:'yyyy-MM-dd'}} [{{taskEvent._assignee.firstName}}] {{taskEvent.problem | truncate:6}}</mat-option>
                </ng-container>
            </mat-select>
          </mat-form-field>
    </div>

    <div class="padding-10-0" class="text-yellow margin-20-0">
        <span class="badge badge-pill badge-warning">2</span> Gép Kiválasztása
    </div>
    <div class="padding-10-0">
        <mat-form-field appearance="outline">
            <mat-label>Termék</mat-label>
            <mat-select name="product" required [ngModel]="taskProduct"  (selectionChange)="populateTaskProduct($event)">
                <ng-container *ngFor="let taskProduct of taskProducts">
                    <mat-option [value]="taskProduct">{{taskProduct._product.nameHu}} {{taskProduct._product.articleNumber}} {{taskProduct._item.serialNumber}}</mat-option>
                </ng-container>
            </mat-select>
          </mat-form-field>
    </div>

    <div class="padding-10-0 w400">
        <mat-form-field appearance="outline">
            <mat-label>Üzemóra</mat-label>
            <input matInput type="number" pattern="^[0-9]\d*$" name="machine_hours" [(ngModel)]="section.defaultConfig.machineHours" autocomplete="off">
            <mat-hint>Jelenlegi Üzemóra: {{currentRunnintHours}}</mat-hint>
        </mat-form-field>
    </div>

    <div class="padding-10-0" class="text-yellow margin-20-0">
        <span class="badge badge-pill badge-warning">3</span>
         Megrendelő
        <mat-icon matTooltip="Az a személy akinek a nevére a Javitási Munkalap ki lesz állítva.">info</mat-icon>
    </div>
    <div class="padding-10-0 w400">
        <app-people-selector [org]="task?._organization"  (onChange)="populatePeople($event)"></app-people-selector>
    </div>

    <p class="padding-20-0">
            <button mat-flat-button  color="accent" (click)="generate()" [disabled]="f.invalid">
                PDF Generálás
            </button>
    </p>
</form>
</ng-template>

</div>