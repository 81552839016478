<div class="flex margin-10-0" *ngIf="project && project.id">
    <div class="full">
        <ng-container *ngIf="project.open">
            <button mat-flat-button color="accent"  (click)="setState('close')"  matTooltip="Projekt Aktív, kattints hogy lezárd">
                Aktív
            </button>
        </ng-container>
        <ng-container *ngIf="!project.open">
            <button mat-flat-button  color="warn" (click)="setState('open')"  matTooltip="Projekt Lezárva, kattints hogy aktiváld">
                Lezárva
            </button>
        </ng-container>
    </div>
    <div>
        <span>
            <button mat-mini-fab  color="primary" (click)="edit()" matTooltip="Szerkeszés">
                <mat-icon>edit</mat-icon>
            </button>
        </span>
        <span class="padding-l-10">
            <app-quick-log-info [data]="project"></app-quick-log-info>
        </span>
    </div>
</div>  
<div class="section" style="border-top: 1px solid #ffffff" [style.border-color]="project?._projectType?.color">
    <ng-container *ngIf="project">
        <div>
            <h5 class="full">
                <span class="color-dot" [style.backgroundColor]="project?._projectType?.color" matTooltip="{{project?._projectType?.name}}"></span>
                {{project.name}}
            </h5>
            <ng-container *ngIf="project.description">
                <p class="text-secondary padding-10-0">{{project.description}}</p>
            </ng-container>
        </div>

        <div class="table-responsive">
            <table class="table table-condensed wAuto">
                <tr>
                    <td>Azonosító</td>
                    <td>
                        <span class="ref-number">{{project.refNumber}}</span>
                    </td>
                </tr>
                <tr>
                    <td>Fontosság</td>
                    <td>
                        <app-importance-container [importance]="project.importance"></app-importance-container>
                    </td>
                </tr>
                <tr>
                    <td>Felelős</td>
                    <td>
                        <ng-container *ngIf="project.assignee">
                            <app-avatar [user]="project._assignee"></app-avatar>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td>Cég</td>
                    <td>
                        <ng-container *ngIf="project.organization">
                            <app-bottom-organisation [data]="project.organization"></app-bottom-organisation>
                            <span>{{project._organization.name}}</span>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td>Beszállótó</td>
                    <td>
                        <ng-container *ngIf="project.partner">
                            <app-bottom-organisation [data]="project.partner"></app-bottom-organisation>
                            <span>{{project._partner.name}}</span>
                        </ng-container>
                    </td>
                </tr>
            </table>
        </div>
    </ng-container>  
</div>

<!--
    Project Action Tree
-->
<div class="section">
    <div class="header">
        <div class="title">
            <span>Lépések</span>
        </div>
    </div>
    <ng-container *ngIf="project">
        <app-project-actions-tree [actions]="project.actions" (onSelect)="openBottomAction($event)"></app-project-actions-tree>
    </ng-container>

    <div style="min-height: 150px;" *ngIf="dataSource.data; else tmpAddFirstAction">
        <mat-tree  #tree [dataSource]="dataSource" [treeControl]="treeControl" class="action-tree">
        <!-- This is the tree node template for leaf nodes -->
        <!-- There is inline padding applied to this node using styles.
        This padding value depends on the mat-icon-button width. -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <div class="action flex">
                <div class="full">
                    <app-status-container [status]="node.status" [showLabel]="false"></app-status-container>
                    <span class="link" (click)="openBottomAction(node.id)">{{node.name | truncate:6}}</span>
                    <span *ngIf="node._actionTemplate.taskType" class="text-green text-small" matTooltip="Hozzárendelt Feladat típus">
                        &nbsp;&#x2794;&nbsp;
                        <app-task-type-container [type]="node._actionTemplate.taskType"></app-task-type-container>
                    </span>
                </div>
                <app-bottom-action [action]="node" (onChange)="actionChanged($event)"></app-bottom-action>
            </div>  
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <div class="mat-tree-node">
            <button mat-icon-button matTreeNodeToggle>
                <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            <div class="flex action">
                <div class="full flex">
                    <app-status-container [status]="node.status" [showLabel]="false"></app-status-container> 
                    <span class="link" (click)="openBottomAction(node.id)" matTooltip="{{node.name}}">{{node.name | truncate:6}}</span>
                    <span *ngIf="node._actionTemplate.taskType" class="text-green text-small" matTooltip="Hozzárendelt Feladat típus">
                        &nbsp;&#x2794;&nbsp;
                        <app-task-type-container [type]="node._actionTemplate.taskType"></app-task-type-container>
                    </span>
                </div>
                <app-bottom-action [action]="node" (onChange)="actionChanged($event)"></app-bottom-action>
            </div>
            
            </div>
            <!-- There is inline padding applied to this div using styles.
                This padding value depends on the mat-icon-button width.  -->
            <div [class.action-tree-invisible]="!treeControl.isExpanded(node)" role="group">
            <ng-container matTreeNodeOutlet></ng-container>
        </div>
        </mat-nested-tree-node>
    </mat-tree>
    </div>

    <ng-template #tmpAddFirstAction>
        <button mat-flat-button color="accent" (click)="addFirstAction()" matTooltip="Lépés létrhozása">
            <mat-icon>add</mat-icon> Első Lépés
        </button>
    </ng-template>
</div>


<app-project-forecast [project]="project"></app-project-forecast>
