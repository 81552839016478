
/**
 * Serve as a base calss for a Componenet which manage one view
 * - collect the common functionlities (like new element id counting)
 */

 export abstract class BaseEntity {
    // when adding a new element to the list we have to create a dummy id for 
    PREFIX_ID = '__new__';
    nextId = 0;

    constructor() {}

    getNextId() {
        let nextId = this.nextId++;
        return this.PREFIX_ID + nextId;
    }

    setOrderNumbers(elements) {
        // function set the 'order' number form 0 up to ....
        // indpendenlty what is the current value we only relay on the current order
        elements.forEach((e, i) => {
          e.order = i;
        });
      }

    moveElementUp(elements, element) {
        // helper function to move one element up based on the order number
        let index = element.order;

        if (index === 0) {
          return;
        }
    
        // add elemnt to the new position
        elements.splice(index - 1, 0, element);
        // delete the lement from the old postion
        elements.splice(index + 1, 1);
    
        this.setOrderNumbers(elements);
      }
    
      moveElementDown(elements, element) {
          // helper function to move an element down in the list based on the order number
          let index = element.order;
    
          if (index === elements.length) {
            //last element
            return;
          }
    
          // add elemnt to the new position
          elements.splice( index + 2, 0, element);
          // delete the lement from the old postion
          elements.splice(index, 1);
    
          this.setOrderNumbers(elements);
      }
 }