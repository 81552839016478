import { Component, Input, OnInit } from '@angular/core';
import { EnumLanguage } from 'src/app/enums/languages.enum';
import { EnumProductType } from 'src/app/enums/productType.enum';
import { EnumTemplateCategory } from 'src/app/enums/templates/templateCategory.enum';
import { Task } from 'src/app/models/tasks/task.model';
import { TaskEvent } from 'src/app/models/tasks/taskEvent.model';
import { TaskProduct } from 'src/app/models/tasks/taskProduct.model';
import { TemplateSection } from 'src/app/models/template.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { NotificationService } from 'src/app/services/notification';
import { TaskEventService } from 'src/app/services/tasks/taskEvent.service';
import { TaskPdfService } from 'src/app/services/tasks/taskPdf.service';
import { TaskProductService } from 'src/app/services/tasks/taskProduct.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-default-section-service-report',
  templateUrl: './default-section-service-report.component.html',
  styleUrls: ['./default-section-service-report.component.scss']
})
export class DefaultSectionServiceReportComponent implements OnInit {
  @Input() task: Task;

  TEMPLATE_CATEGORY = EnumTemplateCategory.ServiceReport;

    // this is the only section in the pdf
    section: TemplateSection;
    sections: TemplateSection[] = [];

     // pdf list vs create view
    createPdfView = false;
    isPdfGenerated = false;  // set to true if the pdf generated

    taskEvents: TaskEvent[] = [];
    taskEvent: TaskEvent;

    // list of Task Product from the task where the selected TaskEvent happend
    taskProducts: TaskProduct[] = [];
    // the selected TaskProduct - about the breakdwon report
    taskProduct: TaskProduct;

    currentRunnintHours: number;

    eventGet$;
    prodGet$;
    pdfCreate$;

  constructor(
    private taskEventService: TaskEventService,
    private taskProductService: TaskProductService,
    private taskPdfService: TaskPdfService,
    private modelFactory: ModelFactory,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.createSection();
    this.fetchTaskEvents();
    this.fetchTaskProducts();
  }

  createSection() {
    this.section = this.modelFactory.getNewModel(TemplateSection);
    this.section.defaultSection = this.TEMPLATE_CATEGORY;
    this.section.defaultConfig = {
      taskEventId: null,
      taskProductId: null,
      peopleId: null,  // recipient
      machineHours: null
    }
  }

  fetchTaskEvents() {
    this.eventGet$ = this.taskEventService.getByTask(this.task.id).subscribe(res => {
      this.taskEvents = res.results;

      if (this.taskEvents.length === 1) {
        this.taskEvent = this.taskEvents[0];

        this.section.defaultConfig.taskEventId = this.taskEvent.id;
      }
    })
  }

  fetchTaskProducts() {
    this.prodGet$ = this.taskProductService.getByTask(this.task.id).subscribe(res => {
      this.taskProducts = res;

      this.taskProducts = this.taskProducts.filter(tP => tP._product.type == EnumProductType.Machine);

      if (this.taskProducts.length === 1) {
        this.taskProduct = this.taskProducts[0];
        this.section.defaultConfig.taskProductId = this.taskProduct.id

        this.currentRunnintHours = this.taskProduct._item.runningHours;
      }
    })
  }

  populateTaskEvent(event) {
    this.section.defaultConfig.taskEventId = event.value.id;
  }

  populateTaskProduct(event) {
    this.section.defaultConfig.taskProductId = event.value.id;

    this.currentRunnintHours = event.value._item.runningHours;
  }

  populatePeople(people) {
    this.section.defaultConfig.peopleId = people.id
  }

  generate() {
    let data = {
      fileName: (this.task.language == EnumLanguage.En) ? 'service_report' : 'javitasi_munkalap',
      sections: [this.section]
    };

    let payload = {
      taskId: this.task.id,
      data: data,
      templateCategory: this.TEMPLATE_CATEGORY
    }

    this.pdfCreate$ = this.taskPdfService.create(payload).subscribe(res => {
      this.isPdfGenerated = true;
      this.notificationService.success();
    });
  }


}
