import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-static-true-false-icon',
  templateUrl: './static-true-false-icon.component.html',
  styleUrls: ['./static-true-false-icon.component.scss']
})
export class StaticTrueFalseIconComponent implements OnInit {
  @Input()
  value: boolean;
  
  constructor() { }

  ngOnInit() {
  }

}
