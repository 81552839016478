import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EnumTaskImportance } from 'src/app/enums/tasks/taskImportance.enums';

@Component({
  selector: 'app-importance-container',
  templateUrl: './importance-container.component.html',
  styleUrls: ['./importance-container.component.scss']
})
export class ImportanceContainerComponent implements OnInit, OnChanges {
  @Input() importance: EnumTaskImportance | string | number;

  importances = [];
  diffFromTotal = [];

  enumTaskImportance = EnumTaskImportance;

  constructor() { }

  ngOnInit() {
    this.buildBar();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.buildBar()
  }  

  buildBar() {
    // convert to number
    this.importance = +this.importance;
    // create an array with the length as the importance nubmber (1-5)
    this.importances.length = +this.importance;

    this.diffFromTotal.length = 5 - this.importance;
  }

}
