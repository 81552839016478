import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from 'src/app/models/projects/project.model';
import { Action } from 'src/app/models/projects/action.model';
import { ProjectService } from 'src/app/services/projects/project.service';
import { EnumActionStatus } from 'src/app/enums/actionStatus.enum';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { TaskNote } from 'src/app/models/tasks/taskNote.model';
import { TaskEvent } from 'src/app/models/tasks/taskEvent.model';
import { TaskNoteService } from 'src/app/services/tasks/taskNote.service';
import { TaskEventService } from 'src/app/services/tasks/taskEvent.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent extends BaseEntity implements OnInit {
  project: Project = null;

  selectedAction: Action = null;

  isCreate: number = 0; //open the create project dialog or not (0 not, 1 open)

  projectNotes: TaskNote[];
  projectEvents: TaskEvent[];

  projGet$;
  taskNoteList$;
  taskEventList$;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private taskNoteService: TaskNoteService,
    private taskEventService: TaskEventService
  ) {
    super();
  }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id');
    this.isCreate = + this.route.snapshot.queryParamMap.get('create');

    if (id) {
      this.projGet$ = this.projectService.get(id).subscribe(i => {
        this.project = i;
      });
    }
  }

  edit() {
    this.router.navigate(['../project-setup'],  {
      queryParams: {id: this.project.id}, 
      relativeTo: this.route  
    });
  }

  setStatus(status) {
    // TODO: Whole file could be deleted
  }

  onTabClick(event) {
    if (event.tab.textLabel.toLowerCase().startsWith('jegy')) {
        this.fetchNotes();
    } else if (event.tab.textLabel.toLowerCase().startsWith('ese')) {
      this.fetchEvents();
    }
  }

  fetchNotes() {
    if (this.projectNotes) return;

    let params = {
      limit: 1000,
      project: this.project.id
    }

    this.taskNoteList$ = this.taskNoteService.list({params}).subscribe(res => {
      this.projectNotes = res;
    })
  }

  fetchEvents() {
    if (this.projectEvents) return;

    let params = {
      limit: 1000,
      project: this.project.id
    }

    this.taskEventList$ = this.taskEventService.list({params}).subscribe(res => {
      this.projectEvents = res.results;
    })
  }

}
