import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { min } from 'lodash-es';
import { Attribute } from 'src/app/models/attribute.model';
import { AttributeService } from 'src/app/services/attribute.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';

@Component({
  selector: 'app-page-attributes',
  templateUrl: './page-attributes.component.html',
  styleUrls: ['./page-attributes.component.scss']
})
export class PageAttributesComponent implements OnInit {
  attribute: Attribute = null;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private attributeService: AttributeService,
    private modelFactory: ModelFactory
  ) { }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id')

    if (id) {
      this.attributeService.get(id).subscribe(i => {
        this.attribute = i;
      });

    } else {
      this.attribute = this.modelFactory.getNewModel(Attribute);
    }
  }

  created(event) {

  }

  updated(event) {

  }

  deleted(event) {

  }

}
