import { AppRoutingModule } from "../app-routing.module";

export class Audit {
    who_name: string = null;
    who_email: string = null;
    when: Date = null;
    what_table: string = null;
    what_id: number = null;
    changes: any = null;
    user: any = null;
    operation: string = null;
    details: any = null

    
    constructor(data: any) {
       if (data) {
            this.who_name = data.who_name;
            this.who_email  = data.who_email;
            this.when  = new Date(data.when);
            this.what_table = data.what_table;
            this.what_id = data.what_id;
            this.changes = JSON.parse(data.changes);
            this.user = data.user;
            this.operation = data.operation;
            this.details = data.details;
        }  

    }
  }
