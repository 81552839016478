import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnumProductType } from 'src/app/enums/productType.enum';
import { ProjectForecast, ProjectForecastPart } from 'src/app/models/projects/projectForecast.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { ProjectForecastService } from 'src/app/services/projects/projectForecast.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';
import { DlgSearchProductComponent } from '../../search/dlg-search-product/dlg-search-product.component';


@AutoUnsub()
@Component({
  selector: 'app-dlg-edit-project-forecast',
  templateUrl: './dlg-edit-project-forecast.component.html',
  styleUrls: ['./dlg-edit-project-forecast.component.scss']
})
export class DlgEditProjectForecastComponent implements OnInit {
  projectForecast: ProjectForecast;
  projectForecastParts: ProjectForecastPart[] = [];

  years: number[] = [];

  @Output() onChange: EventEmitter<ProjectForecast> = new EventEmitter();

  foreCreate$;
  foreUpdate$;
  foreDel$;
  dlgProdSearch$;
  forePart$;
  forePartUpdate$;
  forePartDel$;

  constructor(
    private projectForecastService: ProjectForecastService,
    private modelFactory: ModelFactory,
    public dialogRef: MatDialogRef<DlgEditProjectForecastComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.projectForecast = data.projectForecast;
    this.projectForecastParts = data.projectForecast.parts;
  }

  ngOnInit(): void {
    this.setYearsForDropdown();
  }

  saveForecast() {
    (this.projectForecast.id) ? this.updateForecast() : this.createForecast();
  }

  createForecast() {
    this.foreCreate$ = this.projectForecastService.create(this.projectForecast).subscribe(res => {
      this.projectForecast = res;
      this.onChange.emit(this.projectForecast);
      this.dialogRef.close();
    })
  }

  updateForecast() {
    this.foreUpdate$ = this.projectForecastService.update(this.projectForecast).subscribe(res => {
      this.projectForecast = res;
      this.onChange.emit(this.projectForecast);
      this.dialogRef.close();
    })
  }

  deleteForecast() {
      this.foreDel$ = this.projectForecastService.delete(this.projectForecast).subscribe(() => {
        this.onChange.emit(null);
      this.dialogRef.close();
      });
    }

    editForecastProduct() {
      this.openProductSearchDlg((product) => {
        this.projectForecast.product = product.id;
        this.projectForecast._product = product
      }, EnumProductType.Machine);
    }

    openProductSearchDlg(callback, productType) {
      const dialogConfig = new MatDialogConfig();
      
      let name = (this.projectForecast.product) ? this.projectForecast._product.nameHu : '';
      
      dialogConfig.data = {
        name: name,
        productType: productType
      };
  
      const dialogRef = this.dialog.open(DlgSearchProductComponent, dialogConfig);
  
      this.dlgProdSearch$ = dialogRef.componentInstance.onSelected.subscribe(product => {
        if (product) {
          callback(product);
        }
      });
    }

    setYearsForDropdown() {
      // create a list of year from the last year
      let year = new Date().getFullYear() - 1;

      for (var i = 0; i < 3; i++) {
          this.years.push(year + i);
      }
    }

    setYear(event) {
      this.projectForecast.ev = event.value;
    }

    setQuarter(event) {
      this.projectForecast.negyedev = event.value;
    }

    addPart() {
      let part = this.modelFactory.getNewModel(ProjectForecastPart);

      this.openProductSearchDlg((product) => {
        part.forecast = this.projectForecast.id;
        part._forecast = this.projectForecast;
        part.product = product.id;
        part._product = product;

        this.forePart$ = this.projectForecastService.createPart(part).subscribe(res => {
          this.projectForecastParts.push(res);
          this.onChange.emit(this.projectForecast);
        })
      }, EnumProductType.Part);
    }

    updatePart(p) {
      this.forePartUpdate$ = this.projectForecastService.updatePart(p).subscribe(res => {
        this.onChange.emit(this.projectForecast);
      })
    }

    deletePart(p) {
      this.forePartDel$ = this.projectForecastService.deletePart(p).subscribe(res => {
        this.projectForecastParts = this.projectForecastParts.filter(pfp => pfp.id != p.id);
        
        this.projectForecast.parts = this.projectForecastParts;
        this.onChange.emit(this.projectForecast);
      })
    }

}
