import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { SecurityService } from 'src/app/services/security.service';
import { TaskService } from 'src/app/services/tasks/task.service';
import { TaskUtil } from 'src/app/utils/task.util';

@Component({
  selector: 'app-my-tasks',
  templateUrl: './my-tasks.component.html',
  styleUrls: ['./my-tasks.component.scss']
})
export class MyTasksComponent implements OnInit {
  tasks: Task[];
  user: User;

  constructor(
    private taskService: TaskService,
    private ss: SecurityService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.fetchTasks();
  }

  fetchTasks() {
    let params = {
      limit: 500,
      assignee: this.ss.user.id,
      open: true
    }
    this.taskService.list({params}).subscribe(res => {
      this.tasks = res.results
    })
  }

  openTask(task) {
    let path = TaskUtil.getTaskPath(task.taskType);
        
    this.router.navigate([path],  {
      queryParams: {id: task.id}, 
    });
  }

}
