<div class="padding-20-0 text-center">Megjegyzés Létrehozása</div>
<form class="padding-10-0" #f="ngForm">
    <mat-form-field appearance="outline">
        <!--<mat-label></mat-label>-->
        <textarea rows=5 matInput name="description" [(ngModel)]="comment"  autocomplete="off" required></textarea>
    </mat-form-field>

    <div class="flex padding-l-10">
        <div class="full">
            <button mat-mini-fab  color="primary" (click)="create()" [disabled]="f.invalid" matTooltip="Létrhozás">
                <mat-icon>check</mat-icon>
            </button>
        </div>
    </div>
</form>
