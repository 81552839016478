import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-request-forgotten-passowrd',
  templateUrl: './request-forgotten-passowrd.component.html',
  styleUrls: ['./request-forgotten-passowrd.component.scss']
})
export class RequestForgottenPassowrdComponent implements OnInit {
  email: string;
  emailSent: boolean = false;

  forg$;

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    // if already logged in redirect to the dash
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['app/dash']);
      return;
    }
  }

  // submit the form for enter
  keyDownFunction(event) {
    if(event.keyCode == 13) {
      this.requestNewPassword();
    }
  }
  
  requestNewPassword() : void {
    this.forg$ = this.authService.forgottenPassword(this.email).subscribe((resp) => {
      this.emailSent = true;
    });
  }

}
