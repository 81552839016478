import { HttpParams } from '@angular/common/http';

export class Utils {
    static getParams(params: object) {
        // transform an object to http request params
        // set as the key and values
        if (!params) {
            return
        }

        let httpparam = new HttpParams();

        for (let key in params) {
            httpparam = httpparam.append(key, params[key]);
        }
        
        return httpparam
    }

    static validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static isTruthy(value) {
        if (value == 'true') {
            return true;
        }

        if (value) {
            return true;
        }

        return false
    }
}