import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { NotificationService } from './notification';
import { BehaviorSubject, Observable } from 'rxjs';
import { Exchange } from '../models/exchange.model';

@Injectable({
    providedIn: 'root'
})
export class ExchangeService {
    baseUrl = environment.baseUrl;

    URL = '/exchange-rate/';

    constructor(
        private httpClient: HttpClient,
        private notificationService: NotificationService
        ) {}

    get() {
        // get the user company company
        let url = this.baseUrl  + this.URL;
        return this.httpClient.get(url).pipe(
            // return a list but we currently only one makes sense
            map(res => new Exchange(res)),
            tap(res => this.setExchangeObs(res))
        )
    }

    private profileObs$: BehaviorSubject<Exchange> = new BehaviorSubject(null);

    getExchangeObs(): Observable<Exchange> {
        return this.profileObs$.asObservable();
    }

    setExchangeObs(profile: Exchange) {
        this.profileObs$.next(profile);
    }
}