export class EmailLog {
    _taskPdf: any = null;
    subject: string = null;
    to: string =  null;
    cc: string = null;
    bcc: string = null;
    sender: string = null;
    
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;

    constructor(data: any) {
       if (data) {
            if (data._taskPdf) {
                this._taskPdf = data._taskPdf;
            }

            this.subject = data.subject;
            this.to = data.to;
            this.cc = data.cc;
            this.bcc = data.bcc;
            this.sender = data.sender;
            
            if (data.creator) {
                this.creator = data.creator;
            }
           
            if (data.lastModifier) {
                this.lastModifier = data.lastModifier;
            }
           
            if (data.created) {
                this.created = new Date(data.created);
            }
            
            if (data.updated) {
                this.updated = new Date(data.updated);
            }
        }  
    }
  }
