<mat-dialog-content>
    <!--Tiny editor hosted by ourself-->
    <editor
    
    [(ngModel)]="content"
    [init]="{
      base_url: '/tinymce',
      suffix: '.min',
      height: 500,
      menubar: false,
      plugins: ['link code', 'table paste', 'lists advlist', 'charmap'],
      toolbar:
        'undo redo | formatselect | bold italic | \
        alignleft aligncenter alignright alignjustify | link \
        bullist numlist outdent indent | removeformat | \
        table'
    }"
  ></editor>

</mat-dialog-content> 
<div mat-dialog-actions>
    <button mat-flat-button  color="primary" (click)="save()">
        Módosítás
    </button>
    <button mat-stroked-button (click)="close()">Mégse</button>
</div>
