<div class="section">

    <ng-container *ngIf="createPdfView; else tmpListPdf">
        <div class="padding-10-0">
            <button mat-flat-button color="primary" (click)="createPdfView=!createPdfView">
                <mat-icon>arrow_back</mat-icon>
                Vissza
            </button>
        </div>
        <ng-container *ngTemplateOutlet="generatePDf"></ng-container>
      </ng-container>

<ng-template #tmpListPdf>
    <div class="padding-10-0">
            <button mat-flat-button color="primary" (click)="createPdfView=!createPdfView">
                <mat-icon>add</mat-icon>
                Új Break Down Report Generálás
              </button>
        </div>
    <app-task-pdfs *ngIf="task" 
    [task]="task" 
    [templateCategory]="TEMPLATE_CATEGORY"
    [allowedEmailSending]="false"
    [allowGenerateNewPdf]="false"></app-task-pdfs> 
</ng-template>     

<ng-template #generatePDf>
<form  #f="ngForm">
    <div class="padding-10-0" class="text-yellow margin-20-0">
        <span class="badge badge-pill badge-warning">1</span> Szervíz Esemény Hibaüzenet Kiválasztása
    </div>
    <div class="padding-10-0">
        <mat-form-field appearance="outline" class="w300">
            <mat-label>
                Hiba üzenetek a Projektben
            </mat-label>
            <mat-select name="language" required  (selectionChange)="populateTaskEvent($event)">
                <ng-container *ngFor="let taskEvent of taskEvents">
                    <mat-option [value]="taskEvent" matTooltip="{{taskEvent.problem}}">{{taskEvent.problem | truncate:12}}</mat-option>
                </ng-container>
            </mat-select>
          </mat-form-field>
    </div>
      <div class="p-10-0 m-l-20">
        <mat-form-field appearance="outline">
            <mat-label>Details of Problem</mat-label>
            <textarea rows="6" matInput name="desc" [(ngModel)]="breakDownSection.defaultConfig.description" autocomplete="off" required></textarea>
        </mat-form-field>
      </div>
      <div class="p-10-0 m-l-20 flex">
          <div class="full padding-r-10">
            <mat-form-field appearance="outline">
                <mat-label>Customer name</mat-label>
                <input matInput name="orgName" [(ngModel)]="breakDownSection.defaultConfig.orgName" autocomplete="off" required>
            </mat-form-field>
          </div>
          <mat-form-field appearance="outline" class="w200">
            <mat-label>Customer’s country</mat-label>
            <input matInput name="orgCountry" [(ngModel)]="breakDownSection.defaultConfig.orgCountry" autocomplete="off" required>
           </mat-form-field>
    </div>
      <div class="p-10-0 m-l-20 flex">
          <div class="padding-r-10">
            <mat-form-field appearance="outline" class="w250">
                <mat-label>Last name of engineer</mat-label>
                <input matInput name="lastname" [(ngModel)]="breakDownSection.defaultConfig.user.lastName" autocomplete="off" required>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline" class="w250">
                <mat-label>First name of engineer</mat-label>
                <input matInput name="firstname" [(ngModel)]="breakDownSection.defaultConfig.user.firstName" autocomplete="off" required>
            </mat-form-field>
          </div>
    </div> 


    <div class="padding-10-0" class="text-yellow margin-20-0">
        <span class="badge badge-pill badge-warning">2</span> Gép
    </div>
    <div class="padding-10-0">
        <mat-form-field appearance="outline" class="w300" *ngIf="taskMachines.length > 0; else tmpNoMachines">
            <mat-label>Gépek a Feladatból</mat-label>
            <mat-select name="language"  (selectionChange)="setMachine($event)">
                <ng-container *ngFor="let taskMachine of taskMachines">
                    <mat-option [value]="taskMachine">
                        {{taskMachine._item._product.nameEn | truncate: 5}} {{taskMachine._item._product.articleNumber}} {{taskMachine._item.serialNumber}}
                    </mat-option>
                </ng-container>
            </mat-select>
          </mat-form-field>
          <ng-template #tmpNoMachines>
            <span class="text-red">A kiválasztott hibaüzenethez tartozó feladathoz nem tartozik gép</span>
          </ng-template>
    </div>
    <p class="flex m-l-20">
        <mat-form-field appearance="outline" class="full margin-r-10">
            <mat-label>Type of machine model</mat-label>
            <input matInput name="machineType" [(ngModel)]="breakDownSection.defaultConfig.machineType" autocomplete="off" required>
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="w250">
            <mat-label>S/N°</mat-label>
            <input matInput name="machineSN" [(ngModel)]="breakDownSection.defaultConfig.machineSN" autocomplete="off">
        </mat-form-field>
    </p>
    <p class="flex m-l-20">
        <mat-form-field appearance="outline" class="full margin-r-10">
            <mat-label>Type of CNC control</mat-label>
            <input matInput name="cncControlType" [(ngModel)]="breakDownSection.defaultConfig.cncControlType" autocomplete="off">
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="w250">
            <mat-label>S/N°</mat-label>
            <input matInput name="cncControlSN" [(ngModel)]="breakDownSection.defaultConfig.cncControlSN" autocomplete="off">
        </mat-form-field>
    </p>

    <div class="padding-10-0" class="text-yellow margin-20-0">
        <span class="badge badge-pill badge-warning">3</span> Alkatész
    </div>
    <div class="padding-10-0">
        <mat-form-field appearance="outline" class="w300" *ngIf="taskParts.length > 0; else tmpNoTaskParts">
            <mat-label>Alkatészek</mat-label>
            <mat-select name="language" required  (selectionChange)="setPart($event)">
                <ng-container *ngFor="let taskPart of taskParts">
                    <mat-option [value]="taskPart">
                        {{taskPart._product.nameEn | truncate: 5}} {{taskPart._product.articleNumber}}
                    </mat-option>
                </ng-container>
            </mat-select>
          </mat-form-field>
          <ng-template #tmpNoTaskParts>
              <span class="text-red">Ehhez a Feladathoz nem tartozik alkatrész</span>
          </ng-template>
    </div>
    <div class="flex m-l-20">
        <div class="full padding-r-10">
           <mat-form-field appearance="outline">
               <mat-label>Parts name and specifications</mat-label>
               <input matInput name="partName" [(ngModel)]="breakDownSection.defaultConfig.partName" autocomplete="off" required>
           </mat-form-field>
        </div>
        <mat-form-field appearance="outline" class="w250">
           <mat-label>Part code</mat-label>
           <input matInput name="partCode" [(ngModel)]="breakDownSection.defaultConfig.partCode" autocomplete="off" required>
       </mat-form-field>
   </div>

   <div class="padding-10-0" class="text-yellow margin-20-0">
    <span class="badge badge-pill badge-warning">4</span> Others
   </div>
   <p>
    <mat-form-field appearance="outline">
        <mat-label>M/C installation date</mat-label>
        <input type="date" matInput name="mcInstallationDate" [(ngModel)]="breakDownSection.defaultConfig.mcInstallationDate" autocomplete="off">
    </mat-form-field>
    </p>
    <p class="padding-10-0">
        <mat-checkbox name="warranty" [(ngModel)]="breakDownSection.defaultConfig.warranty">Warranty</mat-checkbox>
    </p>
    <p class="padding-10-0">
        <mat-checkbox name="exchange" [(ngModel)]="breakDownSection.defaultConfig.exchange">Exchange</mat-checkbox>
    </p>




<p class="padding-20-0">
        <button mat-flat-button  color="primary" (click)="generate()" [disabled]="f.invalid">
            PDF Genérálás
        </button>
</p>
</form>
</ng-template>

</div>