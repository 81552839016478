import { Component, Input, OnInit } from '@angular/core';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { PeopleEmail } from 'src/app/models/people.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { PeopleService } from 'src/app/services/people.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-person-email',
  templateUrl: './person-email.component.html',
  styleUrls: ['./person-email.component.scss']
})
export class PersonEmailComponent extends BaseEntity implements OnInit {
  @Input() peopleId: number;

  emails: PeopleEmail[] = [];

  emailList$;
  emailCreate$;
  emailUpdate$;
  emailDelete$;

  constructor(
    private peopleServive: PeopleService,
    private modelFactory: ModelFactory
  ) {
    super();
  }

  ngOnInit() {
    let params = {contact: this.peopleId};
    this.emailList$ = this.peopleServive.listEmails(params).subscribe(resp => {
      this.emails = resp;
    })
  }

  save(email) {
    if (typeof email.id === 'string') {
      // create
      this.create(email);
    } else {
      this.update(email);
    }
  }

  create(email) {
    this.emailCreate$ = this.peopleServive.createEmail(email).subscribe(res => {
      // fitler out the dummy email
      this.emails = this.emails.filter(e => e.email != res.email);
      // add the newly created to the list
      this.emails.push(res)
    })
  }

  update(email) {
    this.emailUpdate$ = this.peopleServive.updateEmail(email).subscribe();
  }

  add() {
    // add new element to the list
    let email = this.modelFactory.getNewModel(PeopleEmail);
    email.setPeopleId(this.peopleId);
    email.setId(this.getNextId());
    this.emails.push(email);
  }

  delete(email) {
    if (typeof email.id === 'string') {
      // delete only from the list
      this.emails = this.emails.filter(e => e.id !== email.id);
    } else {
      // delete from the server
      this.emailDelete$ = this.peopleServive.deleteEmail(email.id).subscribe(res => {
        this.emails = this.emails.filter(e => e.id !== email.id);
      });
    }
  
  }


}
