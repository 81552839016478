<app-file-upload (onFileSelect)="upload($event)"></app-file-upload>

<ng-container *ngFor="let productDocument of productDocuments">
    <div class="section flex margin-10-0">
        <div class="full">
            <a target="_blank" href="{{productDocument.file}}">
                {{productDocument.file | fileNameFromPath}}
            </a>
        </div>
        <div class="flex padding-20-0">
            <div class="padding-r-20">
                <a target="_blank" href="{{productDocument.file}}">
                    <mat-icon>open_in_new</mat-icon>
                </a> 
            </div>
            <button mat-mini-fab  class="mat-fab mat-danger" matTooltip="Documentum törlése"  (confirm-click)="delete(productDocument)" confirm>
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
</ng-container>
