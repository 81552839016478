<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Cégek</a></li>  
    <li>
        <ng-container *ngIf="organisation">
            {{organisation.name}}
        </ng-container>    
    </li>  
</ul>


<mat-tab-group animationDuration="0ms">
    <mat-tab label="Cég">
        <ng-container *ngTemplateOutlet="companyTab"></ng-container>
    </mat-tab>
    <ng-container *ngIf="organisation && organisation.id">
        <mat-tab label="Kontaktok">
            <ng-container *ngTemplateOutlet="personsTab"></ng-container>
        </mat-tab>
        <mat-tab label="Termékek">
            <ng-container *ngTemplateOutlet="itemsTab"></ng-container>
        </mat-tab>
    </ng-container>
</mat-tab-group>


<ng-template #companyTab class="padding-20-0">
    <div class="grid block-md">
        <div class="section">
            <div class="header">
                <div class="title">
                    <span>Cég</span>
                    <mat-icon matTooltip="Migrált adat" class="m-l-10 text-yellow" *ngIf="organisation && organisation.migrated">move_down</mat-icon>
                </div>
            </div>
            <ng-container *ngIf="organisation">
                <app-organisation [organisation]="organisation"
                (eventCreated)="created($event)"
                (eventDeleted)="deleted($event)" 
                (eventUpdated)="updated($event)"
                ></app-organisation>
            </ng-container>
        </div>
        
        
        <ng-container *ngIf="organisation && organisation.id">
            <app-organisation-email  [organisationId]="organisation.id"></app-organisation-email>
            <app-organisation-phone  [organisationId]="organisation.id"></app-organisation-phone>
            <app-organisation-address  [organisationId]="organisation.id"></app-organisation-address>
        </ng-container>
    </div>
</ng-template>

<ng-template #personsTab>
    <ng-container *ngIf="persons">
    <div class="section">
        <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th class="w100">&nbsp;</th>
                    <th>Név</th>
                    <th>Email</th>
                    <th>Telefon</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let p of persons">
                    <tr>
                        <td>
                            <app-bottom-people [data]="p"></app-bottom-people>
                        </td>
                        <td>{{p.fullName}}</td>
                        <td>
                            <ng-container *ngFor="let e of p.emails">
                                <div class="padding-10">{{e.email}} 
                                    <ng-container *ngIf="e.name"><span class="text-secondary text-small">({{e.name}})</span></ng-container>
                                    </div>
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngFor="let p of p.phones">
                                <div class="padding-10">{{p.phone}} 
                                    <ng-container *ngIf="p.name"><span class="text-secondary text-small">({{p.name}})</span></ng-container>
                                </div>
                            </ng-container>
                        </td>
                        <td>
                            <div class="flex">
                                <div class="padding-r-10">
                                    <button mat-icon-button  color="secondary" (click)="openPerson(p)" matTooltip="Megnyitás">
                                        <mat-icon>open_in_new</mat-icon>
                                    </button>
                                </div>
                                <div  class="padding-r-10">
                                    <button mat-icon-button color="warn"  matTooltip="Kontakt - Cég kapcsolat törlése"  (confirm-click)="deleteOrgPerson(p)" confirm>
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                                <div>
                                    <button mat-icon-button color="primary"  matTooltip="Szerkesztés"  (click)="editOrgPerson(p)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        </div>
    </div>
</ng-container>
<div class="padding-20">
    <button mat-mini-fab  color="accent" (click)="addPersonDlg()" matTooltip="Új Kontakt"> 
        <mat-icon>add</mat-icon>
    </button>
</div>
</ng-template>

<ng-template #itemsTab>
    <ng-container *ngIf="organisation && organisation.id">
        <app-organisation-item  [organization]="organisation"></app-organisation-item>
    </ng-container>    
</ng-template>



