<div class="header flex">
    <div class="full">
        <div class="title"><span>Események</span></div>
    </div>
    <div>
        <button mat-flat-button color="accent" (click)="openCommentCreateDlg()">
            <mat-icon>add</mat-icon>Megjegyzés
        </button>
    </div>      
</div>

<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef>Dátum</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <div>{{row.updated | date:'yyyy-MM-dd'}}<br><span class="text-grey text-small">{{row.updated | date:'H:mm'}}</span></div>
        </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="user">
        <mat-header-cell *matHeaderCellDef>Felhasználó</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.employee; else noEmployee">
                <div>{{row._employee.lastName}} {{row._employee.firstName}}</div>
            </ng-container>
            <ng-template #noEmployee>Rendszer Frissítés</ng-template>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actionType">
        <mat-header-cell *matHeaderCellDef>Részletek</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.eventType == enumActionEventType.NotStarted">
                <div>
                    <span class="text-grey text-small">Státusz:</span><br>
                    Nem Kezdődött el
                </div>
            </ng-container>
            <ng-container *ngIf="row.eventType == enumActionEventType.InProgress">
                <div>
                    <span class="text-grey text-small">Státusz:</span><br>
                    Folyamatban
                </div>
            </ng-container>
            <ng-container *ngIf="row.eventType == enumActionEventType.Blocked">
                <div>
                    <span class="text-grey text-small">Státusz:</span><br>
                    Elakadt
                </div>
            </ng-container>
            <ng-container *ngIf="row.eventType == enumActionEventType.Done">
                <div>
                    <span class="text-grey text-small">Státusz:</span><br>
                    Befejezve
                </div>
            </ng-container>
            <ng-container *ngIf="row.eventType == enumActionEventType.Skipped">
                <div>
                    <span class="text-grey text-small">Státusz:</span><br>
                    Átugorva
                </div>
            </ng-container>
            <ng-container *ngIf="row.eventType == enumActionEventType.Note">
                {{row.note}}...
            </ng-container>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>
