<div mat-dialog-title>
    <div class="flex">
        <div class="full">Kontakt</div>
        <div>
            <span [mat-dialog-close]>
                <mat-icon class="text-red">close</mat-icon>
            </span>
        </div>
    </div>
</div>
<mat-dialog-content>    
    <mat-tab-group *ngIf="showTabs; else tmpCreatePerson" animationDuration="0ms">
        <mat-tab label="Kontakt">
            <ng-container *ngTemplateOutlet="tmpCreatePerson"></ng-container>
        </mat-tab>
        <mat-tab label="Telefon">
            <ng-template matTabContent>
                <app-person-phone  [peopleId]="people.id"></app-person-phone>
            </ng-template>
        </mat-tab>
        <mat-tab label="Email">
            <ng-template matTabContent>
                <app-person-email  [peopleId]="people.id"></app-person-email>
            </ng-template>
        </mat-tab>
    </mat-tab-group>

</mat-dialog-content>

<ng-template #tmpCreatePerson>
    <app-person
    [people]="people"
    [organisation]="organisation"
    (eventCreated)="created($event)"
    (eventDeleted)="deleted($event)" 
    (eventUpdated)="updated($event)"
    ></app-person>
</ng-template>