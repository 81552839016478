<div class="flex"> 
    <div class="padding-l-10 w200">
        <mat-form-field appearance="outline">
            <button matPrefix (click)="changeSearch()" mat-mini-fab color="primary" [disabled]="textSearch === ''">
                <mat-icon>search</mat-icon>
            </button>
            <mat-label>Keresés</mat-label>
            <input [(ngModel)]="textSearch" matInput placeholder="?" (keyup.enter)="changeSearch()">
            <mat-icon  class="pointer" matTooltip="Keresés Törlése" (click)="noSearch()" matSuffix>close</mat-icon>
        </mat-form-field>
    </div>
    <div class="padding-l-10 w150">
        <app-importance-selector [importance]="importance" [showAll]="true" (onChange)="importanceChagned($event)"></app-importance-selector>
    </div>
    <div class="padding-l-10 w150">
        <app-task-status-selector [status]="status" [showAll]="true" (onChange)="statusChanged($event)"></app-task-status-selector>
    </div>
    <div class="padding-l-10 w150">
        <app-user-selector [user]="assignee" (onChange)="assigneeChanged($event)"></app-user-selector>
    </div>
    <div class="padding-l-10 w150">
        <mat-form-field appearance="outline">
            <mat-label>Állapot</mat-label>
            <mat-select name="state" [(ngModel)]="open" (selectionChange)="stateChanged($event)">
                <mat-option [value]="true">Aktív</mat-option>
                <mat-option [value]="false">Lezárva</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
