<div class="section">

    <ng-container *ngIf="createPdfView; else tmpListPdf">
        <div class="padding-10-0">
            <button mat-flat-button color="primary" (click)="createPdfView=!createPdfView">
                <mat-icon>arrow_back</mat-icon>
                Vissza
            </button>
        </div>
        <ng-container *ngTemplateOutlet="generatePDf"></ng-container>
    </ng-container>

<ng-template #tmpListPdf>
    <div class="padding-10-0">
            <button mat-flat-button color="primary" (click)="createPdfView=!createPdfView">
                <mat-icon>add</mat-icon>
                Új Átadás/Átvétel Generálás
              </button>
        </div>
    <app-task-pdfs *ngIf="task" 
    [task]="task" 
    [templateCategory]="TEMPLATE_CATEGORY"
    [allowedEmailSending]="true"
    [allowGenerateNewPdf]="false"></app-task-pdfs> 
</ng-template>     

<ng-template #generatePDf>
    <div *ngIf="task && !task.organization; else tmpSetup" class="alert alert-danger">Nincs Cég hozzárendelve a Feladatohoz</div>

    <ng-template #tmpSetup>
        <!--atado--->
        <div class="padding-10-0">
            <div><span class="badge badge-pill badge-warning">1</span> <span class="text-blue">Átadó</span></div>
            <div class="w300">
                <app-user-selector [user]="task._assignee" (onChange)="setUser($event)"></app-user-selector>
            </div>     
        </div>

        <!--atvevo--->
        <div class="padding-10-0">
            <div><span class="badge badge-pill badge-warning">2</span> <span class="text-blue">Átvevő</span></div>
            <div class="w300">
                <app-people-selector [org]="task._organization" (onChange)="setPeople($event)"></app-people-selector>
            </div>     
        </div>

         <!--cim--->
         <div class="padding-10-0">
            <div><span class="badge badge-pill badge-warning">3</span> <span class="text-blue">Üzembe helyezés helye</span></div>

            <div class="flex">
                <div class="margin-r-10">
                    <button [matMenuTriggerFor]="toOrg" mat-mini-fab  color="accent"  matTooltip="Cég címei">
                        <mat-icon>storefront</mat-icon>
                    </button>
                    <mat-menu #toOrg="matMenu">
                        <button mat-menu-item *ngFor="let a of addresses" (click)="copyToAddress(a)">{{a.postcode}} {{a.city}} {{a.line}}</button>
                        </mat-menu>
                </div>
                <div class="full padding-l-10">
                    <app-country-selector [country]="section.defaultConfig.location.country" (eventSelect)="section.defaultConfig.location.country=$event"></app-country-selector>
                </div>
            </div>

            <div class="flex">
                <div class="margin-r-10">
                    <mat-form-field appearance="outline" class="w200">
                        <mat-label>Irsz.</mat-label>
                        <input matInput name="name" [(ngModel)]="section.defaultConfig.location.postcode" autocomplete="off" required>
                    </mat-form-field>
                </div>
                <div class="full padding-l-10">
                    <mat-form-field appearance="outline">
                        <mat-label>Város</mat-label>
                        <input matInput name="name" [(ngModel)]="section.defaultConfig.location.city" autocomplete="off" required>
                    </mat-form-field>
                </div>
            </div>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Cím</mat-label>
                    <input matInput name="name" [(ngModel)]="section.defaultConfig.location.line" autocomplete="off" required>
                </mat-form-field>
            </p>       
        </div>


        <!--comment--->
        <div class="padding-10-0">
            <div><span class="badge badge-pill badge-warning">4</span> <span class="text-blue">Megjegzés</span></div>
            <mat-form-field appearance="outline">
                <textarea rows=4 matInput name="description" [(ngModel)]="section.defaultConfig.comment"  autocomplete="off"></textarea>
            </mat-form-field>     
        </div>
        

        <div class="text-center">
            <button mat-flat-button  color="accent" (click)="generate()">
                PDF Generálás
            </button>
        </div>
    </ng-template>
  
</ng-template>

</div>

