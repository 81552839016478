<div clas="flex">
    <mat-form-field appearance="outline" class="w300">
        <mat-label>Referencia Szám</mat-label>
        <input matInput name="ref_number" [(ngModel)]="refNumber" (keyup)="sendChange()"  autocomplete="off" >
        <mat-icon matSuffix matTooltip="Korábban általunk kiküldött referencia szám">info</mat-icon>
    </mat-form-field>
    
    <ng-container *ngIf="task.action">
        <button [matMenuTriggerFor]="refList" mat-mini-fab class="margin-10" color="primary" matTooltip="Keresés a Projektben">
            <mat-icon>search</mat-icon>
        </button>
        <mat-menu #refList="matMenu" style="width: 350px;">
            <div *ngFor="let task of tasks" class="padding-10 cursor" (click)="selectTask(task)">
                <div class="text-yellow text-tiny">
                    <app-task-type-container [type]="task.taskType"></app-task-type-container>
                </div>
                <div class="text-small">{{task.name}}</div>
                <div><span class="ref-number">{{task.refNumber}}</span></div>
            </div>
          </mat-menu>
    </ng-container>
</div>

