import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError, EMPTY } from 'rxjs';
import { catchError, concatMap, mapTo, tap } from 'rxjs/operators';
import { Tokens } from '../models/tokens';
import { environment } from '../../environments/environment';
import { TokenService } from "./token.interceptor";
import { User } from "../models/user";

@Injectable()
export class AuthService {
    private readonly JWT_TOKEN = 'JWT_TOKEN';
    private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
   

    URL_USER = '/accounts/users/';
    URL_ACCESS_LEVELS = '/accounts/users/accesses/get_module_access/'

    private loggedUser: string;
    loggedIn$ = new BehaviorSubject<boolean>(false);
    //user
    user: User = null;
    user$ = new BehaviorSubject<User>(null);
    //access
    accessLevel = null;
    accessLevel$ = new BehaviorSubject<any>(null);

    baseUrl = environment.baseUrl;

    constructor (
        private http: HttpClient,
        private tokenService: TokenService
    ) {
        this.setUpTokenService();
    }

    login(user: {email: string, password: string}): Observable<boolean> {
        return this.http.post<any>(this.baseUrl+'/accounts/signin/', user)
            .pipe(
                tap(tokens => this.doLoginUser(user.email, tokens)),
                tap(res => this.loggedIn$.next(true)),
                tap(res => this.fetchUser(res.access).subscribe()),
                tap(res => this.fetchAccessLevels().subscribe()),
                mapTo(true),
                catchError(error => {
                    return of(false);
                })
            )
    }

    logout() {
        this.doLogoutUser();
        this.unsetUser();
    }

    isLoggedIn() {
        return !!this.getJwtToken();
    }

    public refreshToken() {
        return this.http.post<any>(this.baseUrl+'/accounts/token/refresh/', {
            'refresh': this.getRefreshToken()
        }).pipe(
            catchError((error: any) => {
                this.logout();
                return throwError('Refresh Token Invalid.');
            }),
            tap((tokens: Tokens) => {
                this.storeTokens(tokens);
            })
        )
    }

    public getJwtToken() {
        return localStorage.getItem(this.JWT_TOKEN);
    }

    getRefreshToken() {
        return localStorage.getItem(this.REFRESH_TOKEN);
    }

    private doLoginUser(username: string, tokens: Tokens) {
        this.loggedUser = username;
        this.storeTokens(tokens);
    }

    private doLogoutUser() {
        this.loggedUser = null;
        this.removeTokens();
    }

    private storeTokens(tokens: Tokens) {
        if (tokens.access) {
            localStorage.setItem(this.JWT_TOKEN, tokens.access);
        }

        if (tokens.refresh) {
            localStorage.setItem(this.REFRESH_TOKEN, tokens.refresh);
        }     
    }

    private setUpTokenService() {
        // from the stored tokens set up the TokenService
        let accessToken = this.getJwtToken()
        let refreshToken = this.getRefreshToken()

        if (accessToken) {
            this.tokenService.setAccessToken(accessToken);
        }    

        if (refreshToken) {
            this.tokenService.setRefreshTOken(refreshToken);
        }    
    }

    private removeTokens() {
        localStorage.removeItem(this.JWT_TOKEN);
        localStorage.removeItem(this.REFRESH_TOKEN);
    }

    forgottenPassword(email) {
        return this.http.post<any>(this.baseUrl+'/accounts/password/new/request/', {email:email})
            .pipe(
                mapTo(true),
                catchError(error => {
                    return of(false);
                })
            )
    }

    fetchUser(token?: any) {
        let userId = null;
        
        if (token) {
            token = this.tokenService.parseJwt(token)
            userId = token.user_id;
        } else {
            userId = this.tokenService.getCurrentUserId();
        }
        
        if (!userId) {
            return EMPTY;
        }

        let url = this.baseUrl + this.URL_USER + userId + '/';

        return this.http.get<any>(url).pipe(
            // set the value and send the mesage
            tap(res => {
                this.user = new User(res);
                this.user$.next(this.user);
            })
        )
    }

    unsetUser() {
        this.user = null;
        this.user$.next(null);
        this.accessLevel$.next(null);
    }

    fetchAccessLevels() {
        let url = this.baseUrl + this.URL_ACCESS_LEVELS;
        return this.http.get<any>(url).pipe(
            tap(res => {
                this.accessLevel = res;
                this.accessLevel$.next(res);
            })
        )
    }


}