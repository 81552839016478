import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { BaseList } from 'src/app/common/baseClasses/baseList';
import { EnumProductType } from 'src/app/enums/productType.enum';
import { Item } from 'src/app/models/item.model';
import { InventoryTransactionService } from 'src/app/services/inventoryTransaction.service';
import { UrlParamService } from 'src/app/services/urlParam.service';
import { DlgEditInventoryTransactionComponent } from 'src/app/shared/dialogs/edit/dlg-edit-inventory-transaction/dlg-edit-inventory-transaction.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';
import {FormGroup, FormControl} from '@angular/forms';
import { downloadFile } from 'src/app/utils/file.util'; 
import { EnumNotificationType } from 'src/app/enums/notificationType.enum';
import { EnumNotificationLevel } from 'src/app/enums/notificationLevel.enum';
import { NotificationService } from 'src/app/services/notification';
import { ItemService } from 'src/app/services/item.service';


@AutoUnsub()
@Component({
  selector: 'app-inventory-transactions',
  templateUrl: './inventory-transactions.component.html',
  styleUrls: ['./inventory-transactions.component.scss'],
  providers: [UrlParamService]
})
export class InventoryTransactionsComponent extends BaseList implements OnInit  {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['direction', 'item', 'quantity', 'price', 'date', 'reason', 'log'];

  item: Item;
  dates: string // dates param

  invTList$;
  dlgInvTEdit$;

  searchRange = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor(
    private inventoryTransactionService: InventoryTransactionService,
    private itemService: ItemService,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {
    super();

    this.setDefaultDateRange();

     // set data for the base service
     this.setUrlParamService(this.urlParamService);
     this.setActivatedRoute(this.route);
  }

  ngOnInit(): void {
    this.doGetInitalOptions();
  }

  doGetInitalOptions() {
    let defaultParams = {}

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed basedmov on the url we have to set them
      let item_id = null;
      
      for (const key in options.params) {
        if (key == 'item') {
          // before we set it we have to fetch
          item_id = options.params[key]
        } else {
          this[key] = options.params[key];
        } 
      }

      if (this.dates) {
        // based on the param we have to se the value
        let d: Array<string> = this.dates.split('@');
        this.searchRange.get('start').setValue(new Date(d[0]));
        this.searchRange.get('end').setValue(new Date(d[1]));
      }

      
      if (item_id) {
        // it is only the id which we get from the url, we have to fetch it
        this.itemService.get(item_id).subscribe(res => {
          this.item = res;
        })
      }
  
      this.invTList$ = this.inventoryTransactionService.list(options).subscribe(data => {
        this.setListResponseData(data);
       });
  
       if ('create' in options.params) {
        // if the create param send (independently for the value we open the project creation)
        this.openInventoryTransactionEdit('in');
      }
    })
  }

  openInventoryTransactionEdit(direction, item=null) {
    // @direction of the transaction
    // @item if we want to work with a particular item 
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '450px';
      
    dialogConfig.data = {
      direction: direction,
      item: item
    };

    const dialogRef = this.dialog.open(DlgEditInventoryTransactionComponent, dialogConfig);

    this.dlgInvTEdit$ = dialogRef.componentInstance.onChange.subscribe(inventoryTransaction => {
      // let's reaload the page with the params in the url
      this.doGetInitalOptions();

    });
  }

  removeItem() {
    this.item = null;
    this.urlParamService.delete('item');
    this.urlParamService.set();
  }

  searchItem(item) {
    // reset
    this.pageIndex = 0;

    this.setDefaultDateRange();
    this.urlParamService.delete('dates');

    // set
    this.item = item;
    let param = {item: this.item.id};
    this.urlParamService.update(param);
    this.urlParamService.set();
  }

  searchByDate() {
    // search by the selected date range)
    // reset
    this.pageIndex = 0;
    this.urlParamService.firstPage();
    this.urlParamService.delete('item');
    this.item = null;

    let param = {
      dates: this.searchRange.value.start.toISOString() + '@' + this.searchRange.value.end.toISOString()
    };

    this.urlParamService.update(param);
    this.urlParamService.set();
  }

  setDefaultDateRange() {
    this.dates = null;
    this.searchRange.get('start').setValue(null);
    this.searchRange.get('end').setValue(null);
  }

  removeSelectedRange() {
    this.setDefaultDateRange();

    this.pageIndex = 0;

    this.urlParamService.delete('dates');
    this.urlParamService.set();
  }

  csvExport() {
    if (!this.item && !this.dates) {
      this.notificationService.notify(
        EnumNotificationType.InventoryTransactionCsvExportNoFilter, 
        EnumNotificationLevel.Warning);
      return
    }

    let params = {};
    if (this.dates) {
      params['dates'] = this.dates;
    } else if (this.item) {
      params['item'] = this.item
    }
      
    this.inventoryTransactionService.csvExport(params).subscribe(res => {
      let fileName = 'ich_raktar_tranzakciok_' + new Date().toISOString() + '.csv';
      downloadFile(res, fileName)
    })
  }

}
