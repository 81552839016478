import { Component, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { DlgSearchUserComponent } from '../../../dialogs/search/dlg-search-user/dlg-search-user.component';
import { EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnumModule } from 'src/app/enums/modules.enum';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';
import { UsersService } from 'src/app/services/users.service';

/**
 * The list of users are filtered by access to the module
 * - only those users are going to be in the list who have access to the module
 */
@AutoUnsub()
@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss']
})
export class UserSelectorComponent implements OnInit, OnChanges {
  @Input() user: User;
  @Output() onChange: EventEmitter<User> = new EventEmitter();

  fullName: string = null;

  module: EnumModule;

  dlgUserSearch$;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private userService: UsersService
  ) {
    
  }

  ngOnInit() {
    this.setName();
  
    this.module = this.route.snapshot.data['module'];
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setName();
  }

  setName() {
    if (typeof this.user === 'number') {
      // we have only the user id we have to fetch the user object to display the name
      this.fetchUserById(this.user);
    } else {
      this.fullName = (this.user?.fullName) ? this.user.fullName : null;
    }
  }

  fetchUserById(id) {
    this.userService.get(id).subscribe(res => {
      this.user = res;
      this.setName();
    });
  }

  openUserSearchDlg() {
    const dialogConfig = new MatDialogConfig();
        
    dialogConfig.data = {
      name: this.fullName,
      module: this.module
    };

    const dialogRef = this.dialog.open(DlgSearchUserComponent, dialogConfig);

    this.dlgUserSearch$ = dialogRef.afterClosed().subscribe(user => {
      if (user) {
        this.user = user;
        this.fullName = this.user.fullName;
      } else if (user == '') {
        this.user = null;
        this.fullName = '';
      }

      this.onChange.emit(this.user);
    });
  }

}
