
<ng-container *ngIf="!category">
  <app-template-category-selector [category]="category" (onSelect)="selectCategory($event)"></app-template-category-selector>
</ng-container>
  
<div *ngIf="templates.length > 0; else tmpNoTemplate">
    <mat-form-field appearance="outline">
        <mat-label>Sablonok</mat-label>
        <mat-select name="category" [ngModel]="template?.id" (selectionChange)="selectTemplate($event)">
              <mat-option [value]="t.id"  *ngFor="let t of templates">{{t.name}}</mat-option>
        </mat-select>
      </mat-form-field>
</div>

<ng-template #tmpNoTemplate>
  <div class="text-red">A kategóriához nincs sablon!</div>
</ng-template>
  