import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/services/notification';
import { UsersService } from 'src/app/services/users.service';
import { EnumNotificationType } from 'src/app/enums/notificationType.enum';
import { EnumNotificationLevel } from 'src/app/enums/notificationLevel.enum';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss']
})
export class SetNewPasswordComponent implements OnInit {
  password: string;
  password2: string;
  token: string;
  // after reset the password only show login button
  showOnlyLogin = false;

  paramsGet$;
  newPass$;

  constructor(
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private userService: UsersService
  ) {
    this.paramsGet$ = this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
    });
  }

  ngOnInit() {

  }

  validatePassword() {
    if (!this.validateSamePassword()) {
      this.notificationService.notify(EnumNotificationType.PassswordNotEqual, EnumNotificationLevel.Error);
      return false;
    };

    if (!this.validateMinLength()) {
      this.notificationService.notify(EnumNotificationType.PasswordMinLength, EnumNotificationLevel.Error);
      return false;
    }

    if (!this.validateContainsLowerCase()) {
      this.notificationService.notify(EnumNotificationType.PasswordLowerCase, EnumNotificationLevel.Error);
      return false;
    }

    if (!this.validateContainsUpperCase()) {
      this.notificationService.notify(EnumNotificationType.PasswordUpperCase, EnumNotificationLevel.Error);
      return false;
    }

    if (!this.validateContainsNumber()) {
      this.notificationService.notify(EnumNotificationType.PasswordNumber, EnumNotificationLevel.Error);
      return false;
    }

    if (!this.validateContainsSpecialChars()) {
      this.notificationService.notify(EnumNotificationType.PasswordSpecialChar, EnumNotificationLevel.Error);
      return false;
    }

    return true;
  }

  validateSamePassword() {
    return this.password === this.password2;
  };

  validateContainsLowerCase() {
      return this.password.toUpperCase() != this.password;
  }

  validateContainsUpperCase() {
    return this.password.toLowerCase() != this.password;
  }

  validateMinLength() {
    return this.password.length >= 8;
  }

  validateContainsNumber() {
    return /\d/.test(this.password);
  }

  validateContainsSpecialChars() {
    let specialChars = /[!#$%&+\-<=>?@\[\])(^_{}\|]/;
    
    return specialChars.test(this.password);
  }

  updatePassword() {
      if (!this.validatePassword()) {
        return
      }

      this.newPass$ = this.userService.setNewPassword(this.token, this.password).subscribe(() => {
        this.showOnlyLogin = true;
      });
  }

}
