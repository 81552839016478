import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { EnumLanguage } from 'src/app/enums/languages.enum';
import { TaskProduct } from 'src/app/models/tasks/taskProduct.model';

@Component({
  selector: 'app-default-section-product-list',
  templateUrl: './default-section-product-list.component.html',
  styleUrls: ['./default-section-product-list.component.scss']
})
export class DefaultSectionProductListComponent implements OnInit {
  @Input() taskProducts: TaskProduct[] = [];
  @Input() language: EnumLanguage | string = EnumLanguage.Hu
  @Input() pdfSection: any;
  // return the generated html markup of the table
  @Output() onChange: EventEmitter<string> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    // set the data from the default section
    this.pdfSection.title = this.pdfSection.defaultConfig?.titleHu;
    if (this.language == 'en') {
      this.pdfSection.title = this.pdfSection.defaultConfig?.titleEn;
    }
  }

}
