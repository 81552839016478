import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationService } from './notification';
import { Item } from '../models/item.model';
import { BuiltInItem } from '../models/builtInItem.model';
import { Utils } from '../shared/utils';


@Injectable({
    providedIn: 'root'
  })
export class ItemService {
    baseUrl = environment.baseUrl;

    URL_ITEMS = '/items/';
    URL_BUILT_IN_ITEMS = '/built-in-items/';

    // list items
    dataSource$ = new BehaviorSubject<any[]>([])
    data = this.dataSource$.asObservable();

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {}

    list(options=null) {
        // @params: obj of params
        // @url: full url with params (next and prev for pagination)
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL_ITEMS;
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }

        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(i => new Item(i));
                return res;
            })
        )
    }

    get(id) {
        let url = this.baseUrl + this.URL_ITEMS + id + '/';
        return this.http.get<Item>(url).pipe(
            map(res => new Item(res))
        )
    }

    create(payload) {
        let url = this.baseUrl + this.URL_ITEMS;
        return this.http.post(url, payload).pipe(
            map(result => new Item(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    update(payload) {
        let url = this.baseUrl + this.URL_ITEMS + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new Item(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    delete(payload) {
        let url = this.baseUrl + this.URL_ITEMS + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

    getQrCode(id) {
        let url = this.baseUrl + this.URL_ITEMS + 'get_qr_code/?id=' + id;
        return this.http.get(url, { responseType: 'blob' }).pipe(
            //tap(res => { this.notificationService.deleted(); })
        )
    }

    /**
     * Built In Items
     */

    getBuiltInItems(builtInId:number) {
        // pass a an item id to get what other itmes are built into it
        let params = Utils.getParams({
            limit: 1000,
            builtin: builtInId
        });

        let url = this.baseUrl + this.URL_BUILT_IN_ITEMS;

        return this.http.get<any>(url, {params: params}).pipe(
            map(res => {
                res.results = res.results.map(i => new BuiltInItem(i));
                return res;
            })
        )
    }

    createBuiltIn(payload) {
        let url = this.baseUrl + this.URL_BUILT_IN_ITEMS;
        return this.http.post(url, payload).pipe(
            map(result => new BuiltInItem(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    updateBuiltIn(payload) {
        let url = this.baseUrl + this.URL_BUILT_IN_ITEMS + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new BuiltInItem(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    deleteBuiltIn(payload) {
        let url = this.baseUrl + this.URL_BUILT_IN_ITEMS + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }
}