export class TaskCustomerOrder {
    task: number = null;
    _task: any = null;

    status: string; // task specific status
    
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.task = data.task;
            if (data._task) {
                this._task = data._task;
            }
            
            this.status = data.status;
            
            if (data.creator) {
                this.creator = data.creator;
            }
           
            if (data.lastModifier) {
                this.lastModifier = data.lastModifier;
            }
           
            if (data.created) {
                this.created = new Date(data.created);
            }
            
            if (data.updated) {
                this.updated = new Date(data.updated);
            }
        }  
    }
  }
