<div class="section">
  <div class="flex">
    <div class="full"></div>
    <div>
      <button mat-button (click)="reLoadPdfs()"
        matTooltip="A frissen kíszített PDF fájlok nem jelennek meg automatikusan a listában, a gomb segítségével a lista újra letölthető.">
        <mat-icon>refresh</mat-icon> Frissítés
      </button>
    </div>
  </div>
 
  <ng-container *ngIf="pdfs.length > 0; else tmpNoPdf">
  <div class="table-responsive">
  <table class="table">
    <ng-container *ngFor="let pdf of pdfs">
      <tr>
        <td class="w50">
          <ng-container *ngIf="pdf.status === enumTaskPdfGenerationSatus.Done">
            <mat-icon class="text-green" matTooltip="PDF generálás sikeresen befejeződött">done</mat-icon>
          </ng-container>
          <ng-container *ngIf="pdf.status === enumTaskPdfGenerationSatus.Pending">
            <mat-spinner [diameter]="19" matTooltip="PDF generálás folyamatban vagy hiba történt"></mat-spinner>
          </ng-container>
          <ng-container *ngIf="pdf.status === enumTaskPdfGenerationSatus.Failed">
            <mat-icon class="text-red" matTooltip="PDF generálás sikerteln volt">close</mat-icon>
          </ng-container>
        </td>
        <td class="w200">
          <app-avatar [user]="pdf.creator"></app-avatar>
          <span>{{pdf.created | dateTime}}</span>
        </td>
        <td  class="w100">
          <ng-container *ngIf="pdf.status === enumTaskPdfGenerationSatus.Done; else pdfGeneration">
            <div>{{pdf.name}}</div>
            <a target="_balank" class="link" href="{{pdf.file}}" matTooltip="PDF Letöltés">
              <mat-icon>cloud_download</mat-icon>
            </a>
          </ng-container>
          <ng-template #pdfGeneration>
          </ng-template>
        </td>
        <td *ngIf="allowedEmailSending">
          <ng-container *ngIf="pdf.sent; else tmpSendPdf">
            <div class="text-green"><mat-icon>mark_email_read</mat-icon> PDF elküldve</div>
            <div class="text-small text-blue pointer" (click)="sendOffer(pdf)"><mat-icon>refresh</mat-icon> Újra Küldés</div>
          </ng-container>
          <ng-template #tmpSendPdf>
            <button mat-flat-button color="accent" mat-button (click)="sendOffer(pdf)">
              PDF Küldés <mat-icon>send</mat-icon>
          </button>
          </ng-template>
        </td>
        <td class="w50">
          <button class="text-yellow" *ngIf="pdf.sent" mat-icon-button (click)="checkEmailLog(pdf)" matTooltip="Elküldött email részletei">
            <mat-icon>drafts</mat-icon>
        </button>
        </td>
        <td class="w50" *ngIf="allowGenerateNewPdf">
          <button mat-icon-button  class="mat-primary" matTooltip="Új PDF készításe ez alapján" (click)="createNewPdf(pdf)">
              <mat-icon>edit_note</mat-icon>
          </button>
        </td>
        <td class="w50">
          <button mat-icon-button  class="mat-danger" (confirm-click)="deletePdf(pdf)" confirm>
              <mat-icon>delete</mat-icon>
          </button>
        </td>
      </tr>
      <ng-container *ngIf="pdf.emailLogs">
        <tr *ngFor="let log of pdf.emailLogs" class="helper-text">
            <td class="w50">&nbsp;</td>
            <td class="w200">{{log.created | dateTime}} <br>
                <app-avatar [user]="log.creator"></app-avatar>
            </td>
            <td class="w200">{{log.subject}}</td>
            <td colspan="99">
              <div>
                <span class="text-blue">Küldte: </span>{{log.sender}}
              </div>
              <div>
                <span class="text-blue">Címzettek: </span>{{log.to}} {{log.cc}} {{log.bcc}}
              </div>
            </td> 
        </tr>
      </ng-container>
    </ng-container>
  </table>
</div>  
</ng-container>

<ng-template #tmpNoPdf>
  <div class="helper-text">Nincs Generált Pdf</div>
</ng-template>

</div>