<mat-dialog-content>
    <mat-form-field appearance="outline">
        <mat-label>Irány</mat-label>
        <mat-select name="direction" [(ngModel)]="inventoryTransaction.direction">
            <mat-option [value]="">-Válassz-</mat-option>
            <mat-option [value]="'in'">Bevételezés</mat-option>
            <mat-option [value]="'out'">Kivételezés</mat-option>
        </mat-select>
        <mat-icon matPrefix *ngIf="inventoryTransaction.direction == 'in'" class="text-green">vertical_align_bottom</mat-icon>
        <mat-icon matPrefix *ngIf="inventoryTransaction.direction == 'out'" class="text-red">vertical_align_top</mat-icon>
    </mat-form-field>

    <app-item-selector [item]="item" (onChange)="setItem($event)"></app-item-selector>
 
    <ng-container *ngIf="inventoryTransaction.item">
        <ng-container *ngIf="!inventory || inventory.stock === 0; else tmpStockDetails">
            <div class="alert alert-danger">Nincs a Raktárban</div>
        </ng-container>
        <ng-template #tmpStockDetails>
            <div  class="alert alert-info">
                A raktárban {{inventory.stock}} {{inventoryTransaction._item._product.unitHu}} van.
            </div>   
        </ng-template>

        <mat-form-field appearance="outline">
            <mat-label>
                <ng-container class="capitalize" *ngIf="inventoryTransaction._item._product.unitHu; else tmpDefaultUnit">{{inventoryTransaction._item._product.unitHu}}</ng-container>
                <ng-template #tmpDefaultUnit>Darab</ng-template>
            </mat-label>
            <input matInput type="number" [disabled]="forceQuantity" pattern="^[0-9]\d*$" name="quantitiy" [(ngModel)]="inventoryTransaction.quantity" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="inventoryTransaction.direction == 'in'">
            <mat-label>Egységár</mat-label>
            <input matInput type="number" pattern="^[0-9]\d*$" name="price" [(ngModel)]="inventoryTransaction.price" autocomplete="off" required>
            <mat-icon matPrefix>euro</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Raktármozgás oka</mat-label>
            <mat-select name="reason" [(ngModel)]="inventoryTransaction.reason" [required]="inventoryTransaction.direction == 'out'">
                <mat-option [value]="">--Válassz--</mat-option>
                <ng-container *ngIf="inventoryTransaction.direction == 'out'">
                    <mat-option [value]="'builtin'">Beépítve</mat-option>
                    <mat-option [value]="'company'">Céghez került</mat-option>
                    <mat-option [value]="'broken'">Törött</mat-option>
                    <mat-option [value]="'sent_back'">Visszaküldve</mat-option>
                </ng-container>
                <mat-option [value]="'correction'">Korrekció</mat-option>
            </mat-select>
        </mat-form-field>

        <ng-container *ngIf="inventoryTransaction.reason == 'company'">
            <div class="helper-text">Cég</div>
            <app-org-selector (onChange)="setOrg($event)" [org]="org"></app-org-selector>
        </ng-container>

        <ng-container *ngIf="inventoryTransaction.reason == 'builtin'">
            <div class="helper-text">Beépítve</div>
            <app-item-selector (onChange)="setBuiltin($event)" [item]="builtIn"></app-item-selector>
        </ng-container>

        <div class="flex">
            <p class="full">
                <ng-container *ngIf="inventoryTransaction.direction == 'in'">
                    <mat-checkbox name="foreignItem" [(ngModel)]="inventoryTransaction.foreignItem"  matTooltip="A Raktárban van de nem a cég tulajdona">Idegen Termék</mat-checkbox>
                </ng-container>
            </p>
            <button (click)="save()" mat-mini-fab color="primary" [disabled]="isDisableSave()">
                <mat-icon matPrefix *ngIf="inventoryTransaction.direction == 'in'">vertical_align_bottom</mat-icon>
                <mat-icon matPrefix *ngIf="inventoryTransaction.direction == 'out'">vertical_align_top</mat-icon>
            </button>
        </div>


    </ng-container>

</mat-dialog-content>
