
    <div mat-dialog-title>
        <div class="flex">
            <div class="full">
                    <ng-container *ngIf="task.id; else tmpUjTask">{{task.name | truncate:5}}</ng-container>
                    <ng-template #tmpUjTask>Új Feldat</ng-template>
            </div>
            <div>
                <span [mat-dialog-close]>
                    <mat-icon class="text-red">close</mat-icon>
                </span>
            </div>
        </div>
    </div>
    <mat-dialog-content>
        <form #f="ngForm">
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Név</mat-label>
                    <input matInput name="nev" [(ngModel)]="task.name"  autocomplete="off" required>
                </mat-form-field>
                
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Leírás</mat-label>
                    <textarea rows=3 matInput name="description" [(ngModel)]="task.description"  autocomplete="off"></textarea>
                </mat-form-field>
                
            </p>
            <p>
                <app-task-status-selector [status]="task.status" (onChange)="task.status=$event"></app-task-status-selector>
            </p>
            <p>
                <app-importance-selector [importance]="task.importance" (onChange)="task.importance=$event"></app-importance-selector>
            <p>
                <mat-label class="helper-text">Felelős</mat-label>
                <app-user-selector [user]="task._assignee" (onChange)="setAssignedUser($event)"></app-user-selector>
            </p>
            <p  class="padding-10-0">
                <mat-label  class="helper-text">
                    <ng-container *ngIf="isVendor; else tmpClient">Beszállító</ng-container>
                    <ng-template #tmpClient>Cég</ng-template>
                </mat-label>
                <app-org-selector [org]="task._organization" [isVendor]="isVendor" (onChange)="setOrg($event)"></app-org-selector>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Projekt Lépés</mat-label>
                    <ng-container *ngIf="task.action; else noResult">
                        <input matInput name="template" disabled [(ngModel)]="task._action.name"  autocomplete="off">
                    </ng-container>
                    <ng-template #noResult>
                        <input matInput name="template" disabled [(ngModel)]="task.action" autocomplete="off">
                    </ng-template>
                    <mat-icon class="pointer" (click)="openActionSearchDlg()" matSuffix matTooltip="Szerkesztés">edit</mat-icon>
                </mat-form-field>
            </p>
            <p  class="padding-10-0">
                <mat-label class="helper-text">Kommunikáció Nyelve (Pdf, Email)</mat-label>
                <app-language-selector [language]="task.language" (onChange)="task.language=$event"></app-language-selector>
            </p>
            <p  class="padding-10-0">
                <mat-label class="helper-text">Pénznem</mat-label>
                <app-currency-selector [currency]="task.currency" (onChange)="task.currency=$event"></app-currency-selector>
            </p>
            <div class="flex">
                <div class="full">
                    <span class="padding-20-0">
                        <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                            <mat-icon>save</mat-icon>
                        </button>
                    </span>
                    <ng-container *ngIf="task.id">
                        <span class="padding-l-10 padding-10-0">
                            <app-quick-log-info [data]="task"></app-quick-log-info>           
                        </span>
                    </ng-container>
                </div>
                <ng-container *ngIf="task.id">
                    <span class="padding-20-0 padding-l-10">
                        <button mat-icon-button  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                            <mat-icon>delete</mat-icon>
                        </button>
                    </span>
                </ng-container>
            </div>
        </form>
  </mat-dialog-content>


