import { Component, OnInit, Inject, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product } from 'src/app/models/product.model';
import { ProductService } from 'src/app/services/product.service';
import { EnumProductType } from 'src/app/enums/productType.enum';
import { DlgEditProductComponent } from '../../edit/dlg-edit-product/dlg-edit-product.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

/**
 * Both possible to listen to close dialog to get the selected option
 * but better to listen to the emitted event
 */
@AutoUnsub()
@Component({
  selector: 'app-dlg-search-product',
  templateUrl: './dlg-search-product.component.html',
  styleUrls: ['./dlg-search-product.component.scss']
})
export class DlgSearchProductComponent implements OnInit {
  @ViewChild('focus', {static: true}) focus:any;

  @Output() onSelected: EventEmitter<Product> = new EventEmitter();

  txtSearch: string = '';
  productType = EnumProductType.Machine
  options = [];
  product: Product = new Product(null);
  // disable type selection option
  disableTypeSelection: boolean = false

  preventItemCreation = false;

  list$;
  dlgProdEdit$;

  constructor(
    private service: ProductService,
    public dialogRef: MatDialogRef<DlgSearchProductComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data.product) {
      this.product = data.product;
      this.txtSearch = this.product.nameHu
    }

    if (data.productType) {
      // overwirte the default selection of the productType
      this.productType = data.productType;
    }

    if (data.disableTypeSelection) {
      this.disableTypeSelection = data.disableTypeSelection
    }

    if (data.preventItemCreation) {
      this.preventItemCreation = data.preventItemCreation;
    }
  }

  ngOnInit() {
    // // help to avoid: ExpressionChangedAfterItHasBeenCheckedError
    // setTimeout(() => {
    //   this.search();
    // }, 0)
  }

  search() {
    if (this.txtSearch.length < 3) {
      return;
    }

    this._doSearch();
  }

  _doSearch() {
    let options = {
      params: {
        productType: this.productType,
        search: this.txtSearch,
        limit: 50
      }
    };

    this.list$ = this.service.list(options).subscribe(data => {
      this.options = data.results;

      this.focus.nativeElement.focus();
    });
  }

  select(entity) {
    this.product = entity;
    this.txtSearch = entity.nameHu;

    this.onSelected.emit(this.product);

    setTimeout(() => {
      this.dialogRef.close(entity);
    }, 300);

  }

  delete() {
    this.txtSearch = '';
    this.product = new Product(null);

    this.onSelected.emit(null);

    setTimeout(() => {
      this.dialogRef.close('');
    }, 300);
  }

  changeType(event) {
    this.productType = event.value
    
    setTimeout(() => {
      this.search();
    }, 0)
  }

  createProductDlg() {
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.data = {
      preventItemCreation: this.preventItemCreation
    };

    const dialogRef = this.dialog.open(DlgEditProductComponent, dialogConfig);

    this.dlgProdEdit$ = dialogRef.componentInstance.onChange.subscribe(product => {
      this.select(product);
    });
  }

}

