<div class="flex padding-10-0">
    <div class="full">
        <button mat-flat-button  color="accent" (click)="create()">
            <mat-icon>add</mat-icon> Új Jegyzet
        </button>
    </div>
    <div *ngIf="task._action?.project">
        <mat-checkbox name="inProject" [(ngModel)]="fetchByProject" (change)="fetch()">Összes Projekt Jegyzet</mat-checkbox>
    </div>
</div>

<ng-container *ngFor="let taskNote of taskNotes">
    <div class="section">
        <div class="flex">
            <div class="full">
                <div class="text-small text-yellow">
                    <app-task-type-container [type]="taskNote._task.taskType"></app-task-type-container>
                </div>
                <app-avatar [user]="taskNote.lastModifier"></app-avatar>
            </div>
            <div class="flex">
                <div>
                    <button (click)="update(taskNote)" color="primary" mat-icon-button>
                        <mat-icon>edit</mat-icon>
                      </button>
                </div>
                <div>
                    <button (click)="delete(taskNote)" color="warn" mat-icon-button>
                        <mat-icon>delete</mat-icon>
                      </button>
                </div>
                <div class="padding-10 text-small text-bold bg-light-grey border-radius">
                    {{taskNote.updated | date:'yyyy-MM-dd H:mm'}}
                </div>
            </div>
        </div>
        <p class="full margin-l-20 padding-10-0" [innerHtml]="taskNote.note | safeHtml"></p>
    </div>
</ng-container>
