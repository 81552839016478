import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { People, PeopleEmail, PeoplePhone } from '../models/people.model';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationService } from './notification';
import { Utils } from '../shared/utils';
import { ListResponse } from '../interfaces/httpResponse.interface';

// PEOPLE === CONTACT

@Injectable({
    providedIn: 'root'
  })
export class PeopleService {
    baseUrl = environment.baseUrl;

    URL_CONTACTS = '/contacts/';
    URL_CONTACTS_EMAIL = '/contacts/emails/';
    URL_CONTACTS_PHONE = '/contacts/phones/';

    // list of the contacts
    dataSource$ = new BehaviorSubject<any[]>([])
    data = this.dataSource$.asObservable();

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
    ) {}

    list(options=null) {
        // list all the contacts
        // @params: obj of params
        // @url: full url with params (next and prev for pagination)
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL_CONTACTS;
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }
        
        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(c => new People(c));
                return res;
            })
        )
    }

    get(id) {
        let url = this.baseUrl + this.URL_CONTACTS + id + '/';
        return this.http.get<People>(url).pipe(
            map(res => new People(res))
        )
    }

    create(payload) {
        let url = this.baseUrl + this.URL_CONTACTS;
        return this.http.post(url, payload).pipe(
            map(result => new People(result)),
            tap(res => { this.notificationService.created(); })
        )
    }


    update(payload) {
        // update organisation
        let url = this.baseUrl + this.URL_CONTACTS + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new People(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    delete(payload) {
        let url = this.baseUrl + this.URL_CONTACTS + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            map(res => new People(res)),
            tap(res => { this.notificationService.deleted(); })
        )
    }

    // EMAILS

    listEmails(params=null) {
        // list contacts's email

        if (params) {
            params = Utils.getParams(params);
        }

        let url = this.baseUrl + this.URL_CONTACTS_EMAIL;
        
        return this.http.get<ListResponse>(url, {params}).pipe(
            map(res => res.results.map(data => new PeopleEmail(data)))
        )
    }

    createEmail(payload) {
        // create Contact Email
        let url = this.baseUrl + this.URL_CONTACTS_EMAIL;
        return this.http.post(url, payload).pipe(
            map(result => new PeopleEmail(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    updateEmail(payload) {
        // update Organisation Email
        let url = this.baseUrl + this.URL_CONTACTS_EMAIL + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(result => new PeopleEmail(result)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    deleteEmail(emailId) {
        // update Organisation Email
        let url = this.baseUrl + this.URL_CONTACTS_EMAIL + emailId + '/';
        return this.http.delete(url).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

     // Phones

     listPhones(params=null) {
        // list contacts's phones

        if (params) {
            params = Utils.getParams(params);
        }

        let url = this.baseUrl + this.URL_CONTACTS_PHONE;
        
        return this.http.get<ListResponse>(url, {params}).pipe(
            map(res => res.results.map(data => new PeoplePhone(data)))
        )
    }

    createPhone(payload) {
        // create Organisation Phone
        let url = this.baseUrl + this.URL_CONTACTS_PHONE;
        return this.http.post(url, payload).pipe(
            map(result => new PeoplePhone(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    updatePhone(payload) {
        // update  Phone
        let url = this.baseUrl + this.URL_CONTACTS_PHONE + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(result => new PeoplePhone(result)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    deletePhone(id) {
        // delete Phone
        let url = this.baseUrl + this.URL_CONTACTS_PHONE + id + '/';
        return this.http.delete(url).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }
}