<form #f="ngForm">
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Név - HU</mat-label>
            <input matInput name="nameHu" [(ngModel)]="attributeGroup.nameHu" required autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Név - EN</mat-label>
            <input matInput name="nameEn" [(ngModel)]="attributeGroup.nameEn" required autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <span class="padding-20-0">
            <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
        <ng-container *ngIf="attributeGroup.id">
            <span class="padding-20-0 padding-l-10">
                <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                    <mat-icon>delete</mat-icon>
                </button>
            </span>
            <span class="padding-l-10 padding-10-0">
                <app-quick-log-info [data]="attributeGroup"></app-quick-log-info>           
            </span>
        </ng-container>
    </p>
</form>
