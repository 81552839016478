<div mat-dialog-title>
    <div class="flex">
        <div class="full">
            <ng-container *ngIf="org" matTooltip="{{org.name|truncate:3}}">{{org.name|truncate:3}} termékei</ng-container>
        </div>
        <div> 
            <mat-icon class="text-red" [mat-dialog-close]="true">close</mat-icon>
        </div>
    </div>
    
</div>
<mat-dialog-content>

<mat-dialog-content>
    <ng-container *ngIf="editMode; else tmpList">
        <!-- EDIT MODE-->
        <button mat-flat-button class="margin-10-0"  color="primary" (click)="backToListMode()">
            <mat-icon>chevron_left</mat-icon> Vissza
        </button>
        <app-organisation-item  [organization]="org"></app-organisation-item>
    </ng-container>
    <ng-template #tmpList>
        <!--LIST MODE -->
        <ng-container *ngIf="orgItems.length > 0; else tmpNoOrgitem">
            <table class="table">
            <tr *ngFor="let oi of orgItems">
                <td>
                    <app-item-container [item]="oi._item"></app-item-container>
                </td>
                <td>
                    {{oi.amount}}
                    <ng-container *ngIf="oi._item._product.unitHu; else tmpDefaultQuantity">{{oi._item._product.unitHu}}</ng-container>
                    <ng-template #tmpDefaultQuantity>db</ng-template>
                </td>
                <td>
                    <button mat-flat-button  color="accent" (click)="select(oi)">
                        <mat-icon>done</mat-icon> Kiválaszt
                    </button>
                </td>
            </tr>
            </table>
        </ng-container>
        <ng-template #tmpNoOrgitem>
            <span class="helper-text">Nincs Raktári Termék a cégnél</span>
        </ng-template>
        <div class="padding-20-0">
            <button mat-mini-fab  color="primary" (click)="editMode=!editMode">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
    </ng-template>
</mat-dialog-content>