<ng-container *ngIf="builtIns && builtIns.length; else noBuiltIns">
    <table class="table">
        <thead>
            <tr>
                <th></th>
                <th>Beépített Termék</th>
                <th>Darab</th>
                <th></th>
            </tr>
        </thead>
        <ng-container *ngFor="let builtin of builtIns">
            <tr>
                <td class="w50">
                    <app-icon-item-type [itemType]="builtin._item._product.type"></app-icon-item-type>
                </td>
                <td>
                    <div>
                        <span class="link" 
                            matTooltip="{{builtin._item._product.nameEn}}"
                            (click)=openItem(builtin.item)>
                           {{builtin._item._product.nameHu}}
                        </span>    
                    </div>
                    <div>
                        <span class="badge badge-pill badge-warning">{{builtin._item.serialNumber}}</span>
                    </div>
                </td>
                <td>
                    <mat-form-field class="w100"> 
                        <mat-label>Darab</mat-label>
                        <input matInput type="number" name="amount" [(ngModel)]="builtin.amount" autocomplete="off">
                    </mat-form-field>
                </td>
                <td class="w200">
                    <div class="flex">
                        <span class="padding-l-20">
                            <button mat-mini-fab  color="primary"  matTooltip="Mentés" (click)="save(builtin)">
                                <mat-icon>save</mat-icon>
                            </button>
                        </span>
                        <span class="padding-l-20">
                            <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete(builtin)" confirm>
                                <mat-icon>delete</mat-icon>
                            </button>
                        </span>
                        <span class="padding-l-20">
                            <app-quick-log-info [data]="builtin"></app-quick-log-info>
                        </span>
                    </div>
                </td>
            </tr>
        </ng-container>
    </table>
</ng-container>
<ng-template #noBuiltIns>
    Nincs másik termék beépítve ebbe a termékbe
</ng-template>

<div class="padding-10-0">
    <button mat-mini-fab color="accent" (click)="add()" matTooltip="Beépített Termék Hozzáadása">
        <mat-icon>add</mat-icon>
    </button>
</div>

