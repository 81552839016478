<ul class="breadcrumb"> 
    <li>Profilom</li>  
</ul>

<mat-tab-group animationDuration="0ms">
    <mat-tab label="Alap Adatok">
        <ng-container *ngTemplateOutlet="defaultDataTab"></ng-container>
    </mat-tab>
    <mat-tab label="Aláírás">
        <ng-container *ngTemplateOutlet="signatureTab"></ng-container>
    </mat-tab>
  </mat-tab-group>


<!-- defaultDataTab -->
<ng-template #defaultDataTab>  
<div class="section padding-10-0">  
<form #f="ngForm">
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Kerszténv</mat-label>
            <input matInput name="firstname" [(ngModel)]="data.firstName" autocomplete="off" required>
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Vezetéknév</mat-label>
            <input matInput name="lastname" [(ngModel)]="data.lastName" autocomplete="off" required>
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Mobil Szám</mat-label>
            <input matInput name="phonePersonal" [(ngModel)]="data.phonePersonal" autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Céges TelefonSzám</mat-label>
            <input matInput name="phoneCompany" [(ngModel)]="data.phoneCompany" autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Cím</mat-label>
            <input matInput name="address" [(ngModel)]="data.address" autocomplete="off">
        </mat-form-field>
    </p>
    <p  class="padding-10-0">
        <mat-form-field appearance="outline">
            <mat-label>
                Nyelv
            </mat-label>
            <mat-select name="language"  [(ngModel)]="data.language" required>
                <mat-option [value]="enumLanguage.Hu">Magyar</mat-option>
                <mat-option [value]="enumLanguage.Ge">Német</mat-option>
            </mat-select>
          </mat-form-field>
    </p>
    <!--<p>
        <mat-form-field appearance="outline">
            <mat-label>Időzóna</mat-label>
            <input matInput name="timezone" [(ngModel)]="data.timezone" autocomplete="off" disabled>
        </mat-form-field>
    </p>-->
    <p>
        <span class="padding-l-10">
            <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
    </p>
</form>
</div>
</ng-template>


<!-- signatureTab -->
<ng-template #signatureTab class="padding-20-0">
    <div *ngIf="profile" class="flex flex-align-item-start">
        <mat-card class="w300">
            <mat-card-header>
                <mat-card-title>
                    <span>Felhasználó Aláírása</span>
                </mat-card-title>
                <mat-card-subtitle>
                    Altalában kép a generált pdf fájlok végén lesz hozzáadva
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <img src="{{profile.signature}}" class="img-fluid">
            </mat-card-content>
            <mat-card-actions>
                <app-file-upload (onFileSelect)="onSignatureUpload($event)"></app-file-upload>
            </mat-card-actions>
        </mat-card>
    </div>  
 </ng-template>