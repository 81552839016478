import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TemplateSectionVersion } from 'src/app/models/template.model';
import { DlgTemplateSectionVersionsComponent } from 'src/app/shared/dialogs/edit/dlg-template-section-versions/dlg-template-section-versions.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

/**
 * Manage the different Versions of the Sections
 */
@AutoUnsub()
@Component({
  selector: 'app-template-section-versions',
  templateUrl: './template-section-versions.component.html',
  styleUrls: ['./template-section-versions.component.scss']
})
export class TemplateSectionVersionsComponent implements OnInit {
  // maybe at this point we only know the the id (and not the full object) hence we roll with that
  @Input() sectionId: number

  // after the dlg opened and the version selected we pass it back to the caller
  @Output() onSelect: EventEmitter<TemplateSectionVersion> = new EventEmitter();

  templateSectionVersion: TemplateSectionVersion;

  dlgTempSecV$;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  openDlg() {
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.data = {
      sectionId: this.sectionId,
    };

    dialogConfig.width = '700px';
    dialogConfig.minHeight = 200;
    dialogConfig.disableClose =  true;

    const dialogRef = this.dialog.open(DlgTemplateSectionVersionsComponent, dialogConfig);

    this.dlgTempSecV$ = dialogRef.componentInstance.onSelect.subscribe(version => {
        this.templateSectionVersion = version;
        this.onSelect.emit(this.templateSectionVersion);
    });
   }
  

}
