import {HttpEvent, HttpInterceptor, HttpHandler,HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, switchMap, finalize } from 'rxjs/operators';
import { TokenService } from './token.interceptor';
import { ErrorMsgService } from './errorMsg.service';
import { LoaderService } from './loader.service';
import { NullValueService } from './nullValue.service';
import { AuthService } from './auth.service';


export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: TokenService,
    private errorService: ErrorMsgService,
    private loaderService: LoaderService,
    private nullValueService: NullValueService,
    private authService: AuthService
    ) {}

  /*
  Functonalities:
  - Loader Service
  - Refresh Token
  - Error Handlang around the request
  */

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // show loading
    this.loaderService.show();

    // we only refershing the token
    if (request.url.indexOf('refresh') !== -1) {
      return next.handle(request).pipe(
          finalize(() => this.loaderService.hide())
      );
    }

    // updateing the request with the new token
    let token = this.authService.getJwtToken();
    request = this.tokenService.addTokenToRequest(request, token);
    request = this.nullValueService.setNullValues(request);

    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            // unauthenticated access -> refresh the token
            return  this.authService.refreshToken().pipe(
              switchMap((res) => {
                request = this.tokenService.addTokenToRequest(request, res.access);
                return next.handle(request).pipe(
                    catchError((error: any) => {
                      // handler the request errors with the new token
                      this.loaderService.hide();
                      // should we return?
                      return this.handleError(error);
                    }),
                ) as Observable<HttpEvent<any>>;
              }));
          } else if (error.status === 503) {
              // maintenance
              this.errorService.show(error.error);
              this.loaderService.hide();
          }
          else {
            // handle the error of the request
            this.handleError(error);
            this.loaderService.hide();
          }
        }),
        finalize(() => this.loaderService.hide())
      ) as Observable<HttpEvent<any>>;
  }

  handleError(error: HttpErrorResponse ) {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      this.errorService.show(error);
    }

    return throwError(errorMessage);
  }
}