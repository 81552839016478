import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { Task } from 'src/app/models/tasks/task.model';
import { TaskProduct } from 'src/app/models/tasks/taskProduct.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { NotificationService } from 'src/app/services/notification';
import { TaskProductService } from 'src/app/services/tasks/taskProduct.service';
import { DlgSearchProductComponent } from 'src/app/shared/dialogs/search/dlg-search-product/dlg-search-product.component';
import { EnumNotificationType } from 'src/app/enums/notificationType.enum';
import { EnumNotificationLevel } from 'src/app/enums/notificationLevel.enum';
import { DlgSearchProjectProductsComponent } from 'src/app/shared/dialogs/search/dlg-search-project-products/dlg-search-project-products.component';
import { DlgSearchItemComponent } from 'src/app/shared/dialogs/search/dlg-search-item/dlg-search-item.component';
import { EnumTaskTypes } from 'src/app/enums/tasks.enums';
import { DlgEditInventoryTransactionComponent } from 'src/app/shared/dialogs/edit/dlg-edit-inventory-transaction/dlg-edit-inventory-transaction.component';
import { EnumInventoryDirection } from 'src/app/enums/inventory.enum';
import { InventoryService } from 'src/app/services/inventory.service';
import { EnumProductType } from 'src/app/enums/productType.enum';
import { DlgOrgItemComponent } from 'src/app/shared/dialogs/search/dlg-org-item/dlg-org-item.component';
import { OrganisationItem } from 'src/app/models/organisation';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';
import { TaskUtil } from 'src/app/utils/task.util';
import { ItemService } from 'src/app/services/item.service';
import { SecurityService } from 'src/app/services/security.service';
import { EnumModule } from 'src/app/enums/modules.enum';
import { Organisation } from 'src/app/models/organisation';


@AutoUnsub()
@Component({
  selector: 'app-task-products',
  templateUrl: './task-products.component.html',
  styleUrls: ['./task-products.component.scss']
})
export class TaskProductsComponent  extends BaseEntity implements OnInit {
  @Input() task: Task;

  // @type: product vs item
  // define what kind of things are handeld here
  @Input() type: string = 'product'

  // how to search in the items, default is by type but this can be sent true to search by org
  @Input() searchByOrg: boolean = false;

  @Output() onChange: EventEmitter<TaskProduct[]> = new EventEmitter();

  taskProducts: TaskProduct[] = null;

  // stocks of the the taskproducts
  stocks: any;

  taskUtil = TaskUtil


  // manage what information is visible and editable for the different type of tasks
  readCostPrice = false;
  editCostPrice = false;
  readSellingPrice = false;
  editSellingPrice = false;
  inventoryOut = false;
  inventoryIn = false;

  // helper calculation for total
  // take account the quantity 
  // it is alwasy euro, number is fine
  totalCostPrice: number;
  totalSellingPrice: number;

  // access
  hasAccessInventory = false;

  listProd$;
  getstock$;
  taskProdCreate$;
  taskProdCreate1$;
  inv2$;
  inv1$;
  dlgItemSearch$;
  dlgProdSearch$;
  prodUpdate$;
  prodDel$;
  dlgprojProdSearch$;
  taskProdSetup$;
  dlgItemSearch2$;
  dlgInvTEdit$;
  dlgOrgItem$;
  prodServiceCreate$;
  invGetItem$;
  addRelatedProd$;

  constructor(
    private taskProductService: TaskProductService,
    private modelFactory: ModelFactory,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private inventoryService: InventoryService,
    private itemService: ItemService,
    private ss: SecurityService,
  ) { 
    super();

    this.type = (this.type === 'item') ? 'item' : 'product';
  }

  ngOnInit() {
    this.setReadWriteFields();

    this.hasAccessInventory = this.ss.hasAccess(EnumModule.Inventory)

    this.fetch();
  }

  fetch() {
    let params = {task: this.task.id};

    this.listProd$ = this.taskProductService.list({params}).subscribe(resp => {
      this.taskProducts = resp.results;

      // sort by active status
      this.taskProducts.sort((a:any,b:any) => b.active - a.active)

      this.taskProductService.onProductsChange(this.taskProducts);

      this.fetchStocks();

      this.calculateTotals();
    })
  }

  fetchStocks() {
    if (!this.hasAccessInventory) {
      return
    }
    this.getstock$ = this.taskProductService.getStocks(this.task.id).subscribe(resp => {
      this.stocks = resp.stocks;

      // add stock info to the taskProducts
      this.taskProducts.forEach(taskProduct => {
        taskProduct['stock'] = null;
        

        if (taskProduct.id in this.stocks) {
          taskProduct['stock'] = this.stocks[taskProduct.id];
        }
      });
    })
  }

  calculateTotals() {
      // total cost and selling price take account the quantity
      // cost price calculation is hard because can be cost price or inventory price

      // sell price
      this.totalSellingPrice = 0;
      
      this.taskProducts.forEach(taskProduct => {
        if (taskProduct.sellingPrice > 0) {
          this.totalSellingPrice += (taskProduct.sellingPrice * taskProduct.amount);
        }
      });
  }

  openSearchInactiveDlg() {
    // Example Machine has to be alwasy an Item (we need to exactly what it is -> Serial Number)
      this.openItemSearchDlg(EnumProductType.Machine, true);
  }

  openSearchMachineDlg() {
    // based on the task type we search for Product or Item
    if (this.type === 'item') {
      this.openItemSearchDlg();
    } else {
      this.openProductSearchDlg(EnumProductType.Machine, (taskProduct) => {
        if (taskProduct._product.type == EnumProductType.Machine) {
           // machine
          // create the taskProduct
          this.taskProductService.create(taskProduct).subscribe(res => {
            taskProduct = res;

            if (!this.hasAccessInventory) {
              this.taskProducts.push(taskProduct);
              this.onChange.emit(this.taskProducts);
              return
            }

            //fetch stock for the product
            this.inv2$ = this.inventoryService.getByProduct(res.product).subscribe(resStock => {
              // return a list of stock with the product
              taskProduct['stock'] = resStock;
              this.taskProducts.push(taskProduct);
              this.onChange.emit(this.taskProducts);
            });
          })  
        } else {
          alert('ERROR - ez csak gepekhez hasznalhato')
        }
       
      });
    }
  }

  openSearchPartDlg() {
    this.openItemSearchDlg(EnumProductType.Part)
  }

  openSearchServiceDlg() {
    this.openItemSearchDlg(EnumProductType.Service)
  }

  openItemSearchDlg(
    productType: EnumProductType = EnumProductType.Machine,
    inactive: boolean = false) {
    let dialogConfig = new MatDialogConfig();
    
    let name = '';
    
    dialogConfig.data = {
      name: name,
      searchByOrg: this.searchByOrg,
      disableTypeSelection: true,
      productType: productType,
    };

    // if we search by or and the task is part of a project which has org
    // we pass it to the window
    if (this.searchByOrg && this.task.action) {
      if (this.task._action._project.organization) {
        dialogConfig.data['orgId'] = this.task._action._project.organization
      }
    }

    const dialogRef_item = this.dialog.open(DlgSearchItemComponent, dialogConfig);
    
    this.dlgItemSearch$ = dialogRef_item.componentInstance.onSave.subscribe(item => {
      if (item) {
        let inList = this.taskProducts.find(tp => tp.item === item.id);

        if (inList) {
          this.notificationService.notify(EnumNotificationType.ItemInList, EnumNotificationLevel.Error)
          return;
        }
      }

      let taskProduct = this.modelFactory.getNewModel(TaskProduct)

      if (inactive) {
        // minta gep
        taskProduct.active = false;
      }
          
      taskProduct.task = this.task.id;
      taskProduct._task = this.task;
      taskProduct.item = item.id;
      taskProduct._item = item;
      taskProduct.product = item.product;
      taskProduct._product = item._product;
      taskProduct.id = this.getNextId();

      this.createTaskProduct(taskProduct);
    });
  }

  openProductSearchDlg(type: EnumProductType, callback) {
    let dialogConfig = new MatDialogConfig();
    
    let name = '';
    
    dialogConfig.data = {
      name: name,
      productType: type,
      disableTypeSelection: true
    };

    const dialogRef = this.dialog.open(DlgSearchProductComponent, dialogConfig);

    this.dlgProdSearch$ = dialogRef.afterClosed().subscribe(product => {
      if (product) {
          let pInList = this.taskProducts.find(tp => tp.product === product.id);
          
          if (pInList) {
            this.notificationService.notify(EnumNotificationType.ProductInList, EnumNotificationLevel.Error)
            return;
          }

          let taskProduct = this.modelFactory.getNewModel(TaskProduct)
          
          taskProduct.task = this.task.id;
          taskProduct._task = this.task;
          taskProduct.product = product.id;
          taskProduct._product = product;
          taskProduct.id = this.getNextId();

          callback(taskProduct)
      } 
    });
  }

  update(tp, callback=null) {
    // updated data already in the list
    this.prodUpdate$ = this.taskProductService.update(tp).subscribe();
    this.calculateTotals();
    if (callback) {
      callback();
    }
  }

  delete(tp) {
    this.prodDel$ = this.taskProductService.delete(tp).subscribe(() => {
        this.taskProducts = this.taskProducts.filter(taskProduct => taskProduct.id != tp.id);

        this.calculateTotals();

        this.onChange.emit(this.taskProducts);

    });
  }

  openProjectProductSearch() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = 410;
    dialogConfig.disableClose =  true
    dialogConfig.data = {

      task: this.task,
      taskType: this.type
    };

    const dialogRef = this.dialog.open(DlgSearchProjectProductsComponent, dialogConfig);

    this.dlgprojProdSearch$ = dialogRef.componentInstance.onSelect.subscribe((taskProducts) => {      
     this.taskProdSetup$ =  this.taskProductService.setUp(this.task.id, taskProducts).subscribe(products => {
          this.taskProducts = products;

          this.calculateTotals();

          this.fetchStocks(); 
      });
    });
  }

  connectToItem(taskProduct) {
    // open the dialog and assign the itme to the taskProduct
    let dialogConfig = new MatDialogConfig(); 
    
    dialogConfig.data = {
      item: null
    };

    const dialogRef2 = this.dialog.open(DlgSearchItemComponent, dialogConfig);

    this.dlgItemSearch2$ = dialogRef2.componentInstance.onSave.subscribe(item => {
      if (item) {
        taskProduct.item = item.id;
        taskProduct._item = item;
      }

      if (typeof taskProduct.id == 'number') {
        // already exist 
        this.update(taskProduct);
      } else {
        // creating a new one
        this.createTaskProduct(taskProduct);
      }
      

      this.onChange.emit(this.taskProducts);
    })
  }

  setReadWriteFields() {
    if (!this.task) return;

    // manage the visibility of the fields which are visible and editable for a given TaskType
    if (EnumTaskTypes.TaskVendorInterest == this.task.taskType) {
      this.editCostPrice = true;
    } else if (EnumTaskTypes.TaskVendorOrder == this.task.taskType) {
      this.readSellingPrice;
      this.editCostPrice = true;
    } else if (EnumTaskTypes.TaskCustomerOffer == this.task.taskType) {
      this.readCostPrice = true;
      this.editSellingPrice = true;
    } else if (EnumTaskTypes.TaskCustomerOrder == this.task.taskType) {
      this.editCostPrice = true;
      this.editSellingPrice = true;
    } else if (EnumTaskTypes.TaskInventoryIn == this.task.taskType) {
      this.inventoryIn = true;
      this.editCostPrice = true;
      this.readSellingPrice;
    } else if (EnumTaskTypes.TaskInventoryOut == this.task.taskType) {
      this.inventoryOut = true;
      this.readCostPrice = true;
      this.readSellingPrice = true;
    } else if (EnumTaskTypes.TaskService == this.task.taskType) {
      this.inventoryOut = true;
    }
  }

  openInventoryTransaction(direction, taskProduct) {
    // @direction of the transaction
    // @item if we want to work with a particular item 
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '450px';
      
    dialogConfig.data = {
      direction: direction,
      item: taskProduct._item,
      builtIn: this.getMachineFromTheList(),
      quantity: taskProduct.amount,
      forceQuantity: true,
      org: this.task._organization
    };

    if (direction == EnumInventoryDirection.In) {
      dialogConfig.data.price = taskProduct.costPrice;
    }

    const dialogRef = this.dialog.open(DlgEditInventoryTransactionComponent, dialogConfig);

    this.dlgInvTEdit$ = dialogRef.componentInstance.onChange.subscribe(inventoryTransaction => {
      taskProduct.inventory_transaction = inventoryTransaction.id;
      this.update(taskProduct, () => {
        setTimeout(() => { this.fetch()}, 300)
      })
    });
  }

  getMachineFromTheList() {
    // return the Machine Item from the TaskProducts if there is only one, otherwise null
    let machineItems = [];
    
    this.taskProducts.forEach(tP => {
      if (tP._product.type == EnumProductType.Machine) { 
        machineItems.push(tP._item)
      }
    })

    if (machineItems.length == 1) {
      return machineItems[0];
    }

    return null;
  }

  openOrgItemSelector() {
    // allow to select form the org itmes of the org
    const dialogConfig = new MatDialogConfig();
      
    dialogConfig.data = {
      organizastion: this.task._organization,
    };

    const dialogRef = this.dialog.open(DlgOrgItemComponent, dialogConfig);

    this.dlgOrgItem$ = dialogRef.componentInstance.onSelect.subscribe(orgItem => {
      // only add if not already there
      let _tp = this.taskProducts.find(tp => tp.item == orgItem.item);
      if (_tp) {
        this.notificationService.notify(EnumNotificationType.ItemInTaskProduct, EnumNotificationLevel.Error);
        return
      }

      let taskProduct = this.modelFactory.getNewModel(TaskProduct);
      taskProduct.task = this.task.id;
      taskProduct._task = this.task;
      taskProduct.item = orgItem.item;
      taskProduct._item = orgItem._item;
      taskProduct.product = orgItem._item.product;
      taskProduct._product = orgItem._item._product;

      this.createTaskProduct(taskProduct);
    });
  }

  createTaskProduct(taskProduct) {
    // create the taskProduct
    this.taskProdCreate$ = this.taskProductService.create(taskProduct).subscribe(res => {
      taskProduct = res;

      if (!this.hasAccessInventory) {
        this.taskProducts.push(taskProduct);
        this.onChange.emit(this.taskProducts);
        return
      }

      //fetch stock for the product
      this.inv1$ = this.inventoryService.getByItem(taskProduct.item).subscribe(resStock => {
        // return a single item
        taskProduct['stock'] = [];
        if (resStock) taskProduct['stock'].push(resStock);
        this.taskProducts.push(taskProduct);

        this.onChange.emit(this.taskProducts);
      });
    }) 
  }

  showInactiveCreateButton() {
    // check if show the inactive creation option or not
    // minta gep
    let show = false;

    // only visible some tasks
    if (this.task.taskType === EnumTaskTypes.TaskVendorInterest) {
      show = true;
    } else if (this.task.taskType === EnumTaskTypes.TaskVendorOrder) {
      show = true;
    }

    if (!this.taskProducts) return false;

    this.taskProducts.forEach(tP => {
      if (!tP.active && tP._product.type == EnumProductType.Machine) { show = false; }
    })

    return show;
  }

  showOrgItemSelector() {
    // decide should we offer the OrgItem selector
    let show = false;

    if (!this.task.organization) {
      // no org no option
      return false;
    }

    // only visible some tasks
    if (this.task.taskType === EnumTaskTypes.TaskService) {
      show = true;
    }

    return show;

  }

  addRelatedProduct(taskProduct) {
    this.addRelatedProd$ = this.taskProductService.addRelatedProducts(this.task.id, taskProduct.product).subscribe(allTaskProducts => {
      if (allTaskProducts.length === this.taskProducts.length) {
        // no extra product created
        this.notificationService.notify(EnumNotificationType.NoTaskRelatedOffers, EnumNotificationLevel.Error);
      } else {
        this.notificationService.success()
        this.taskProducts = allTaskProducts;
      }
    })
  }

}
