<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Bevételezés a Raktából</a></li> 
    <ng-container *ngIf="task">
        <li>{{task.name}}</li>  
    </ng-container> 
</ul>

<ng-container>
    <mat-tab-group  animationDuration="0ms" dynamicHeight (selectedTabChange)="onTabClick($event)">
        <mat-tab label="Feladat">
            <ng-container *ngTemplateOutlet="taskDetails"></ng-container>
        </mat-tab>
        <mat-tab label="Jegyzetek">
            <ng-template matTabContent>
                <ng-container *ngTemplateOutlet="taskNotes"></ng-container>
            </ng-template>
        </mat-tab>
        <ng-container *ngIf="hasAccessProjects">
            <mat-tab label="Projekt" *ngIf="task && task.action;">
                <ng-template matTabContent>
                    <ng-container *ngTemplateOutlet="projectDetails"></ng-container>
                </ng-template>
            </mat-tab>
        </ng-container>
    </mat-tab-group>
</ng-container>


<ng-template #taskDetails>
    <ng-container *ngIf="task">
        <app-task-detail [task]="task" (onChange)="taskUpdated($event)"></app-task-detail>
    </ng-container>
    <ng-container *ngIf="task">
        <app-task-products [task]="task" [type]="'item'"></app-task-products>
    </ng-container>
</ng-template>


<ng-template #projectDetails>
    <ng-container *ngIf="project">
        <app-project-details [project]="project" (onChange)="projectUpdated($event)"></app-project-details>
    </ng-container>   
</ng-template>

<ng-template #taskNotes matTabContent >
    <app-task-notes [task]="task"></app-task-notes>
</ng-template>


