export class Attribute {
    id: number = null;
    nameHu: string = null;
    nameEn: string = null;
    group: number = null;
    _group: any = null;
    
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.nameHu  = data.nameHu;
            this.nameEn  = data.nameEn;

            this.group = data.group;
            if (data._group) {
                this._group = data._group;
            }

            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);

        }  

    }
  }
