import { Component, Input, OnInit } from '@angular/core';
import { Task } from 'src/app/models/tasks/task.model';

@Component({
  selector: 'app-default-config-customer-confirmation',
  templateUrl: './default-config-customer-confirmation.component.html',
  styleUrls: ['./default-config-customer-confirmation.component.scss']
})
export class DefaultConfigCustomerConfirmationComponent implements OnInit {
  @Input() defaultConfig: any;

  // if task provided allow for the child view to provide a search option accross the project
  // if not provided no search option
  @Input() task: Task;

  constructor() { }

  ngOnInit(): void {
  }

  updateSelect(ref_number) {
    this.defaultConfig['refNumber'] = ref_number;
  }

  updatePartnerSelect(ref_number) {
    this.defaultConfig['partnerRefNumber'] = ref_number;
  }

}
