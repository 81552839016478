import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './services/auth.guard';

import { LoginComponent } from './public/login/login.component';
import { DashComponent } from './dash/dash.component';
import { ContactsComponent } from './contacts/contacts.component';
//accounts
import { AccountsComponent } from './accounts/accounts.component';
import { CompaniesComponent } from './accounts/companies/companies.component';
import { OrganisationsComponent } from './contacts/organisations/organisations.component';
import { OrganisationComponent } from './contacts/organisations/page-organisation/organisation/organisation.component';
import { UsersComponent } from './accounts/users/users.component';
import { UserComponent } from './accounts/users/user/user.component';
import { PersonsComponent } from './contacts/persons/persons.component';
import { PersonComponent } from './contacts/persons/person/person.component';
import { ProjectsComponent } from './projects/projects.component';
import { ItemComponent } from './items/page-item/item/item.component';
import { ItemsComponent } from './items/items.component';
import { ProductComponent } from './products/page-product/product/product.component';
import { ProductsComponent } from './products/products.component';
import { ManufacturersComponent } from './manufacturers/manufacturers.component';
import { CategoriesComponent } from './categories/categories.component';
import { PageManufacturerComponent } from './manufacturers/page-manufacturer/page-manufacturer.component';
import { PageCategoryComponent } from './categories/page-category/page-category.component';
import { PageItemComponent } from './items/page-item/page-item.component';
import { PageProductComponent } from './products/page-product/page-product.component';
import { PageOrganisationComponent } from './contacts/organisations/page-organisation/page-organisation.component';
import { PagePersonComponent } from './contacts/persons/page-person/page-person.component';
import { RequestForgottenPassowrdComponent } from './public/request-forgotten-passowrd/request-forgotten-passowrd.component';
import { ModuleGuard } from './services/module.guard';
import { ProjectPlansComponent } from './project-plans/project-plans.component';
import { ProjectPlanComponent } from './project-plans/project-plan/project-plan.component';
import { ActionsPlansComponent } from './project-plans/actions-plans/actions-plans.component';
import { ActionPlanComponent } from './project-plans/actions-plans/page-action-plans/action-plan/action-plan.component';
import { PageActionPlansComponent } from './project-plans/actions-plans/page-action-plans/page-action-plans.component';
import { ProjectComponent } from './projects/project/project.component';
import { ProjectTypesComponent } from './project-plans/project-types/project-types.component';
import { ProjectTypeComponent } from './project-plans/project-types/project-type/project-type.component';
import { SetNewPasswordComponent } from './public/set-new-password/set-new-password.component';
import { AttributesComponent } from './attribute-options/attributes/attributes.component';
import { AttributeGroupsComponent } from './attribute-options/attribute-groups/attribute-groups.component';
import { PageAttributeGroupsComponent } from './attribute-options/attribute-groups/page-attribute-groups/page-attribute-groups.component';
import { PageAttributesComponent } from './attribute-options/attributes/page-attributes/page-attributes.component';
import { TemplatesComponent } from './templates/templates.component';
import { TemplateComponent } from './templates/template/template.component';
import { MyProfileComponent } from './accounts/my-profile/my-profile.component';
import { ConfigurationComponent } from './accounts/configuration/configuration.component';
import { EnumActionStatus } from './enums/actionStatus.enum';
import { EnumModule } from './enums/modules.enum';
import { TaskCustomerOfferComponent } from './tasks/task-customer-offers/task-customer-offer/task-customer-offer.component';
import { TaskCustomerOffersComponent } from './tasks/task-customer-offers/task-customer-offers.component';
import { TaskVendorOrdersComponent } from './tasks/task-vendor-orders/task-vendor-orders.component';
import { TaskCustomerOrdersComponent } from './tasks/task-customer-orders/task-customer-orders.component';
import { TaskVendorInterestsComponent } from './tasks/task-vendor-interests/task-vendor-interests.component';
import { TaskCustomerDeliveryComponent } from './tasks/task-customer-deliveries/task-customer-delivery/task-customer-delivery.component';
import { TaskCustomerInterestsComponent } from './tasks/task-customer-interests/task-customer-interests.component';
import { TaskCustomerInterestComponent } from './tasks/task-customer-interests/task-customer-interest/task-customer-interest.component';
import { VerifyEmailComponent } from './public/verify-email/verify-email.component';
import { TaskVendorInterestComponent } from './tasks/task-vendor-interests/task-vendor-interest/task-vendor-interest.component';
import { TaskCustomerOrderComponent } from './tasks/task-customer-orders/task-customer-order/task-customer-order.component';
import { TaskVendorOrderComponent } from './tasks/task-vendor-orders/task-vendor-order/task-vendor-order.component';
import { TaskCustomerDeliveriesComponent } from './tasks/task-customer-deliveries/task-customer-deliveries.component';
import { TaskService } from './services/tasks/task.service';
import { TasksAllComponent } from './tasks/tasks-all/tasks-all.component';
import { TaskServicesComponent } from './tasks/task-services/task-services.component';
import { TaskServiceComponent } from './tasks/task-services/task-service/task-service.component';
import { TaskActivityComponent } from './tasks/task-activities/task-activity/task-activity.component';
import { TaskActivitiesComponent } from './tasks/task-activities/task-activities.component';
import { ForecastComponent } from './reports/forecast/forecast.component';
import { InventoryComponent } from './inventory/inventory.component';
import { InventoryTransactionsComponent } from './inventory/inventory-transactions/inventory-transactions.component';
import { TaskInventoriesInComponent } from './tasks/task-inventories-in/task-inventories-in.component';
import { TaskInventoryInComponent } from './tasks/task-inventories-in/task-inventory-out/task-inventory-in.component';
import { TaskInventoriesOutComponent } from './tasks/task-inventories-out/task-inventories-out.component';
import { TaskInventoryOutComponent } from './tasks/task-inventories-out/task-inventory-out/task-inventory-out.component';
import { TaskCustomerConfirmationComponent } from './tasks/task-customer-confirmations/task-customer-confirmation/task-customer-confirmation.component';
import { TaskCustomerConfirmationsComponent } from './tasks/task-customer-confirmations/task-customer-confirmations.component';
import { TaskCustomerHandoverComponent } from './tasks/task-customer-handovers/task-customer-handover/task-customer-handover.component';
import { TaskCustomerHandoversComponent } from './tasks/task-customer-handovers/task-customer-handovers.component';
import { EmailLogsComponent } from './accounts/email-logs/email-logs.component';
import { ServiceMgmtComponent } from './service-mgmt/service-mgmt.component';
/**
 * data-> module is manage the access of the page
 */

const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'request-forgotten-password', component: RequestForgottenPassowrdComponent},
  { path: 'new-password', component: SetNewPasswordComponent},
  { path: 'verify-email', component: VerifyEmailComponent},
  { path: 'app', canActivate: [AuthGuard], canActivateChild: [ModuleGuard], children: [
    { path: 'dash', component: DashComponent, data: {module: 'open'}},
    { path: '', redirectTo: 'dash', pathMatch: 'full'},
    { path: 'contacts', data: {module: 'contacts'}, children: [
      { path: 'organisations', data: {module: 'contacts'}, children: [
        {path: 'organisation', component: PageOrganisationComponent, data: {module: 'contacts'}},
        {path: '', component: OrganisationsComponent,  data: {module: 'contacts'}}
      ]}, 
      { path: 'persons', data: {module: 'contacts'}, children: [
        {path: 'person', component: PagePersonComponent,  data: {module: 'contacts'}},
        {path: '', component: PersonsComponent, data: {module: 'contacts'}}
      ]}, 
      {path: '', component: ContactsComponent,  data: {module: 'contacts'}}
    ]},
    { path: 'accounts', data: {module: 'accounts'}, children: [
      {path: 'company', component: CompaniesComponent,  pathMatch: 'full', data: {module: 'accounts'}},
      {path: 'configuration', component: ConfigurationComponent,  pathMatch: 'full', data: {module: 'accounts'}},
      {path: 'email-logs', component: EmailLogsComponent,  pathMatch: 'full', data: {module: 'accounts'}},
      {path: 'users', data: {module: 'accounts'}, children: [
        {path: 'user', component: UserComponent, data: {module: 'accounts'}},
        {path: '', component: UsersComponent, data: {module: 'accounts'}}
      ]},
    ]},
    // exception - everybody have access (at least partially) it's own profile data
    {path: 'my-profile', component: MyProfileComponent,  pathMatch: 'full', data: {module: 'open'}},
    {path: 'projects', data: {module: 'projects'}, children: [
      {path: 'project', component: ProjectComponent,  data: {module: 'projects'}},
      {path: '', component: ProjectsComponent, data: {module: 'projects'}}
    ]},
    {path: 'items', data: {module: 'items'}, children: [
      {path: 'item', component: PageItemComponent,  data: {module: 'items'}},
      {path: '', component: ItemsComponent, data: {module: 'items'}}
    ]},
    {path: 'products', data: {module: 'items'}, children: [
      {path: 'product', component: PageProductComponent, data: {module: 'items'}},
      {path: '', component: ProductsComponent, data: {module: 'items'}}
    ]},
    {path: 'manufacturers', data: {module: 'items'}, children: [
      {path: 'manufacturer', component: PageManufacturerComponent, data: {module: 'items'}},
      {path: '', component: ManufacturersComponent, data: {module: 'items'}}
    ]},
    {path: 'categories', data: {module: 'items'}, children: [
      {path: 'category', component: PageCategoryComponent, data: {module: 'items'}},
      {path: '', component: CategoriesComponent, data: {module: 'items'}}
    ]},
    {path: 'project-plans', data: {module: 'projectplanning'}, children: [
      {path: 'project', component: ProjectPlanComponent, data: {module: 'projectplanning'}},
      {path: 'actions', data: {module: 'projectplanning'}, children: [
        {path: 'action', component: PageActionPlansComponent, data: {module: 'projectplanning'}},
        {path: '', component: ActionsPlansComponent, data: {module: 'projectplanning'}}
      ]},
      {path: 'types', data: {module: 'projectplanning'}, children: [
        {path: 'type', component: ProjectTypeComponent, data: {module: 'projectplanning'}},
        {path: '', component: ProjectTypesComponent, data: {module: 'projectplanning'}}
      ]},
      {path: '', component: ProjectPlansComponent, data: {module: 'projectplanning'}}
    ]},
    {path: 'attributes', data: {module: 'items'}, children: [
      {path: 'groups', data: {module: 'items'},  children: [
        {path: 'group', component: PageAttributeGroupsComponent, data: {module: 'items'}},
        {path: '', component: AttributeGroupsComponent, data: {module: 'items'}}
      ]},
      {path: 'attributes', data: {module: 'items'},  children: [
        {path: 'attribute', component: PageAttributesComponent, data: {module: 'items'}},
        {path: '', component: AttributesComponent, data: {module: 'items'}}
      ]}
    ]},
    {path: 'tasks', data: {module: 'open'}, children: [
      {path: 'all', component: TasksAllComponent, data: {module: 'open'}},
      {path: 'customer-interests', data: {module: EnumModule.TaskClients}, children: [
        {path: 'customer-interest', component: TaskCustomerInterestComponent, data: {module: EnumModule.TaskClients}},
        {path: '', component: TaskCustomerInterestsComponent, data: {module: EnumModule.TaskClients}}
      ]},
      {path: 'customer-offers', data: {module: EnumModule.TaskClients}, children: [
        {path: 'customer-offer', component: TaskCustomerOfferComponent, data: {module: EnumModule.TaskClients}},
        {path: '', component: TaskCustomerOffersComponent, data: {module: EnumModule.TaskClients}}
      ]},
      {path: 'vendor-interests', data: {module: EnumModule.TaskVendors}, children: [
        {path: 'vendor-interest', component: TaskVendorInterestComponent, data: {module: EnumModule.TaskVendors}},
        {path: '', component: TaskVendorInterestsComponent, data: {module: EnumModule.TaskVendors}}
      ]},
      {path: 'customer-orders', data: {module: EnumModule.TaskClients}, children: [
        {path: 'customer-order', component: TaskCustomerOrderComponent, data: {module: EnumModule.TaskClients}},
        {path: '', component: TaskCustomerOrdersComponent, data: {module: EnumModule.TaskClients}}
      ]},
      {path: 'vendor-orders', data: {module: EnumModule.TaskVendors}, children: [
        {path: 'vendor-order', component: TaskVendorOrderComponent, data: {module: EnumModule.TaskVendors}},
        {path: '', component: TaskVendorOrdersComponent, data: {module: EnumModule.TaskVendors}}
      ]},
      {path: 'customer-deliveries', data: {module: EnumModule.TaskServices}, children: [
        {path: 'customer-delivery', component: TaskCustomerDeliveryComponent, data: {module: EnumModule.TaskServices}},
        {path: '', component: TaskCustomerDeliveriesComponent, data: {module: EnumModule.TaskServices}}
      ]},
      {path: 'services', data: {module: EnumModule.TaskServices}, children: [
        {path: 'service', component: TaskServiceComponent, data: {module: EnumModule.TaskServices}},
        {path: '', component: TaskServicesComponent, data: {module: EnumModule.TaskServices}}
      ]},
      {path: 'activites', data: {module: EnumModule.TaskServices}, children: [
        {path: 'activity', component: TaskActivityComponent, data: {module: EnumModule.TaskServices}},
        {path: '', component: TaskActivitiesComponent, data: {module: EnumModule.TaskServices}}
      ]},
      {path: 'inventories-in', data: {module: EnumModule.Inventory}, children: [
        {path: 'inventory-in', component: TaskInventoryInComponent, data: {module: EnumModule.Inventory}},
        {path: '', component: TaskInventoriesInComponent, data: {module: EnumModule.Inventory}}
      ]},
      {path: 'inventories-out', data: {module: EnumModule.Inventory}, children: [
        {path: 'inventory-out', component: TaskInventoryOutComponent, data: {module: EnumModule.Inventory}},
        {path: '', component: TaskInventoriesOutComponent, data: {module: EnumModule.Inventory}}
      ]},
      {path: 'customer-confirmations', data: {module: EnumModule.TaskClients}, children: [
        {path: 'customer-confirmation', component: TaskCustomerConfirmationComponent, data: {module: EnumModule.TaskClients}},
        {path: '', component: TaskCustomerConfirmationsComponent, data: {module: EnumModule.TaskClients}}
      ]},
      {path: 'customer-handovers', data: {module: EnumModule.TaskClients}, children: [
        {path: 'customer-handover', component: TaskCustomerHandoverComponent, data: {module: EnumModule.TaskClients}},
        {path: '', component: TaskCustomerHandoversComponent, data: {module: EnumModule.TaskClients}}
      ]},
    ]},
    {path: 'templates', data: {module: 'templates'}, children: [
      {path: 'template', component: TemplateComponent, data: {module: 'templates'}},
      {path: '', component: TemplatesComponent, data: {module: 'templates'}}
    ]},
    {path: 'reports', data: {module: 'accounts'}, children: [
      {path: 'forecast', component: ForecastComponent,  data: {module: 'accounts'}},
    ]},
    {path: 'services', data: {module: EnumModule.TaskServices}, children: [
      {path: 'management', component: ServiceMgmtComponent,  data: {module: EnumModule.TaskServices}},
    ]},
    {path: 'inventory', data: {module: EnumModule.Inventory}, children: [
      {path: 'transactions', component: InventoryTransactionsComponent, data: {module: EnumModule.Inventory}},
      {path: '', component: InventoryComponent, data: {module: EnumModule.Inventory}}
    ]},
  ]},
  { path: '**', component: LoginComponent}
];
//useHash: hash based urls: to make work deeplinking
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
