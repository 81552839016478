<div class="section">
 <form #f="ngForm" *ngIf="taskCustomerDelivery">
    <div class="header">
        <div class="title"><span>Címek</span></div>
    </div>    
    <div class="row">
        <div class="col-6">
            <div class="text-bold text-blue padding-20-0 flex">
                <div class="full flex">
                    <mat-icon>home_work</mat-icon>
                    <span>Szállító</span>
                </div>
                <span>
                    <button mat-mini-fab  color="accent" (click)="copyCompanyFrom()"  matTooltip="Saját cím">
                        <mat-icon>home_work</mat-icon>
                    </button>
                </span>  
            </div>
            <p>
                <app-country-selector [country]="taskCustomerDelivery.fromAddressCountry" (eventSelect)="taskCustomerDelivery.fromAddressCountry=$event"></app-country-selector>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Irányító szám</mat-label>
                    <input matInput name="postcode" [(ngModel)]="taskCustomerDelivery.fromAddressPostcode" autocomplete="off">
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Város</mat-label>
                    <input matInput name="city" [(ngModel)]="taskCustomerDelivery.fromAddressCity" autocomplete="off">
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Cím</mat-label>
                    <input matInput name="line" [(ngModel)]="taskCustomerDelivery.fromAddressLine" autocomplete="off">
                </mat-form-field>
            </p>
        </div>
        <div class="col-6">
            <div class="text-bold text-blue padding-20-0 flex">
                <div class="full flex">
                    <mat-icon>storefront</mat-icon>
                    <span>Vevő</span>
                </div>
                <span class="padding-r-10">
                    <button [matMenuTriggerFor]="toOrg" mat-mini-fab  color="accent"  matTooltip="Cég címei">
                        <mat-icon>storefront</mat-icon>
                    </button>
                    <mat-menu #toOrg="matMenu">
                        <button mat-menu-item *ngFor="let a of addresses" (click)="copyToAddress(a)">{{a.postcode}} {{a.city}} {{a.line}}</button>
                      </mat-menu>
                </span>               
            </div>
            <p>
                <app-country-selector [country]="enumCountry.Hungary" (eventSelect)="taskCustomerDelivery.toAddressCountry=$event"></app-country-selector>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Irányító szám</mat-label>
                    <input matInput name="topostcode" [(ngModel)]="taskCustomerDelivery.toAddressPostcode" autocomplete="off">
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Város</mat-label>
                    <input matInput name="tocity" [(ngModel)]="taskCustomerDelivery.toAddressCity" autocomplete="off">
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Cím</mat-label>
                    <input matInput name="toline" [(ngModel)]="taskCustomerDelivery.toAddressLine" autocomplete="off">
                </mat-form-field>
            </p>
        </div>
    </div>
    
    <div class="padding-20-0">
                <button mat-mini-fab  color="primary" (click)="save()" matTooltip="Mentés">
                    <mat-icon>save</mat-icon>
                </button>
                <span class="padding-l-10">
                    <app-quick-log-info [data]="taskCustomerDelivery"></app-quick-log-info>
                </span> 
        </div>
    </form>
</div>

   

