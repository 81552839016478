import { path } from "d3";
import { EnumCurrency } from "../enums/currencies.enum";
import { EnumTaskTypes } from "../enums/tasks.enums";
import { EnumTaskStatus } from "../enums/tasks/taskStatus.enum";

export class TaskUtil {
    static getTaskListPath(taskType) {
        let path = null;  
        if (taskType == EnumTaskTypes.TaskCustomerInterest) {
            path = '/app/tasks/customer-interests';
        } else if (taskType == EnumTaskTypes.TaskCustomerOffer) {
            path = '/app/tasks/customer-offers';
        } else if (taskType == EnumTaskTypes.TaskCustomerOrder) {
            path = '/app/tasks/customer-orders';
        } else if (taskType == EnumTaskTypes.TaskCustomerConfirmation) {
          path = '/app/tasks/customer-confirmations';
      }  else if (taskType == EnumTaskTypes.TaskVendorInterest) {
          path = '/app/tasks/vendor-interests';
        } else if (taskType == EnumTaskTypes.TaskVendorOrder) {
          path = '/app/tasks/vendor-orders';
        } else if (taskType == EnumTaskTypes.TaskCustomerDeliver) {
          path = '/app/tasks/customer-deliveries';
        } else if (taskType == EnumTaskTypes.TaskCustomerHandover) {
          path = '/app/tasks/customer-handovers';
        } else if (taskType == EnumTaskTypes.TaskService) {
          path = '/app/tasks/services';
        } else if (taskType == EnumTaskTypes.TaskActivity) {
          path = '/app/tasks/activites';
        } else if (taskType == EnumTaskTypes.TaskInventoryIn) {
          path = '/app/tasks/inventories-in';
        } else if (taskType == EnumTaskTypes.TaskInventoryOut) {
          path = '/app/tasks/inventories-out';
        }

        return path;
      }

      static getTaskPath(taskType) {
        let path = null;  
        if (taskType == EnumTaskTypes.TaskCustomerInterest) {
            path = '/app/tasks/customer-interests/customer-interest';
        } else if (taskType == EnumTaskTypes.TaskCustomerOffer) {
            path = '/app/tasks/customer-offers/customer-offer';
        } else if (taskType == EnumTaskTypes.TaskCustomerOrder) {
            path = '/app/tasks/customer-orders/customer-order';
        }  else if (taskType == EnumTaskTypes.TaskCustomerConfirmation) {
          path = '/app/tasks/customer-confirmations/customer-confirmation';
        } else if (taskType == EnumTaskTypes.TaskVendorInterest) {
          path = '/app/tasks/vendor-interests/vendor-interest';
        } else if (taskType == EnumTaskTypes.TaskVendorOrder) {
          path = '/app/tasks/vendor-orders/vendor-order';
        } else if (taskType == EnumTaskTypes.TaskCustomerDeliver) {
          path = '/app/tasks/customer-deliveries/customer-delivery';
        } else if (taskType == EnumTaskTypes.TaskCustomerHandover) {
          path = '/app/tasks/customer-handovers/customer-handover';
        } else if (taskType == EnumTaskTypes.TaskService) {
          path = '/app/tasks/services/service';
        } else if (taskType == EnumTaskTypes.TaskActivity) {
          path = '/app/tasks/activites/activity';
        } else if (taskType == EnumTaskTypes.TaskInventoryIn) {
          path = '/app/tasks/inventories-in/inventory-in';
        } else if (taskType == EnumTaskTypes.TaskInventoryOut) {
          path = '/app/tasks/inventories-out/inventory-out';
        }

        return path;
      }

      static getAutoOfferPrice(price, currency=EnumCurrency.EUR) {
        // return the default customer offer price (x1.6)
        // usage: based the price/cost which is comming from TaskVendorInterest for a TaskProduct is transformed to the price for the Customer Offer

        const RATIO_OFFER_PRICE = 1.6;

        let offerPrice = +price * RATIO_OFFER_PRICE;

        if (currency == EnumCurrency.EUR) {
          return parseFloat(offerPrice.toFixed(2))
        } else {
          // HUF
          return parseInt(offerPrice.toFixed(0))
        }
      }
}