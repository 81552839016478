<ul class="breadcrumb"> 
    <li>Email Logs</li>  
</ul>

<mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="datetime">
        <mat-header-cell *matHeaderCellDef>Dátum</mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{row.updated | dateTime}}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="created">
        <mat-header-cell *matHeaderCellDef>Felhsználó</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-avatar [user]="row.creator"></app-avatar>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="from">
        <mat-header-cell *matHeaderCellDef>Küldő</mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{row.sender}}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="to">
        <mat-header-cell *matHeaderCellDef>Címzett</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <div>
                <span *ngIf="row.to" class="badge badge-success" matTooltip="{{row.to}}">TO</span>
                <span *ngIf="row.cc" class="badge badge-info" matTooltip="{{row.cc}}">CC</span>
                <span *ngIf="row.bcc" class="badge badge-warning" matTooltip="{{row.bcc}}">BCC</span>
            </div>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="subject">
        <mat-header-cell *matHeaderCellDef>Tárgy</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="helper-text text-tiny" matTooltip="{{row.subject}}">{{row.subject | truncate:5}}</span>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row class="cursor" *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="0"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
    [showFirstLastButtons]="false"
></mat-paginator>
