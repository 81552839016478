<div class="flex padding-20-0">
    <div>
        <button mat-flat-button color="accent" (click)="openAttributeSearchDlg()">
            <mat-icon>add</mat-icon> Műszaki Adat
        </button>
    </div>
    <div  class="padding-l-10">
        <button mat-stroked-button color="accent" (click)="openAttributeGroupSearchDlg()" matTooltip="Minden Műszaki Adat hozzáadaása egy csoportból">
            <mat-icon>add</mat-icon> Műszaki Adatok Csoport
        </button>
    </div>
</div>


<ng-container *ngFor="let group of groupedProductAttributes | keyvalue">
    <div class="section">
        <div class="header">
            <div class="title"><span>{{group.value.group.nameHu}}</span></div>
        </div>
        <ng-container *ngFor="let productAttribute of group.value.attributes">
            <form #f="ngForm">
                <div class="flex flex-wrap padding-10" [style.backgroundColor]="(productAttribute.id | typeof) === 'number' ? 'white' : '#f7e0e0'">
                    <div class="padding-r-10">
                        <mat-form-field appearance="outline">
                            <mat-label>{{productAttribute._attribute.nameHu}}</mat-label>
                            <input matInput name="valueHu" [(ngModel)]="productAttribute.valueHu" required autocomplete="off">
                             <mat-icon matSuffix  (click)="productAttribute.valueEn = productAttribute.valueHu" matTooltip="Magyar verzió másolása az Angolhoz">content_copy</mat-icon>   
                        </mat-form-field>
                    </div>
                    <div  class="padding-r-10">
                        <mat-form-field appearance="outline">
                            <mat-label>{{productAttribute._attribute.nameEn}}</mat-label>
                            <input matInput name="valueEn" [(ngModel)]="productAttribute.valueEn" required autocomplete="off">
                                <mat-icon matSuffix  (click)="productAttribute.valueHu = productAttribute.valueEn" matTooltip="Angol verzió másolása a Magyarhoz">content_copy</mat-icon>       
                        </mat-form-field>
                    </div>
                    <div class="flex">
                        <span class="padding-20-0">
                            <button mat-mini-fab color="primary" (click)="save(productAttribute)" [disabled]="f.invalid"
                                matTooltip="Mentés">
                                <mat-icon>save</mat-icon>
                            </button>
                        </span>
                        <ng-container *ngIf="productAttribute.id">
                            <span class="padding-20-0 padding-l-10">
                                <button mat-icon-button color="warn" matTooltip="Törlés"
                                    (confirm-click)="delete(productAttribute)" confirm>
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </span>
                            <span class="padding-l-10 padding-10-0">
                                <app-quick-log-info [data]="productAttribute"></app-quick-log-info>
                            </span>
                        </ng-container>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>
</ng-container>