<div class="section">

    <ng-container *ngIf="createPdfView; else tmpListPdf">
        <div class="padding-10-0">
            <button mat-flat-button color="primary" (click)="createPdfView=!createPdfView">
                <mat-icon>arrow_back</mat-icon>
                Vissza
            </button>
        </div>
        <ng-container *ngTemplateOutlet="generatePDf"></ng-container>
    </ng-container>

<ng-template #tmpListPdf>
    <div class="padding-10-0">
            <button mat-flat-button color="primary" (click)="createPdfView=!createPdfView">
                <mat-icon>add</mat-icon>
                Új Szállítólevél Generálás
              </button>
        </div>
    <app-task-pdfs *ngIf="task" 
    [task]="task" 
    [templateCategory]="TEMPLATE_CATEGORY"
    [allowedEmailSending]="true"
    [allowGenerateNewPdf]="false"></app-task-pdfs> 
</ng-template>     

<ng-template #generatePDf>
    <div *ngIf="task && !task.organization" class="alert alert-danger">Nincs Cég hozzárendelve a Feladatohoz</div>
    
    <div class="helper-text padding-10-0">Vevő és Szállító címeket a Feladatnál lehet megadni.</div>
    
    <div class="padding-10-0">
        <div>
            <span class="badge badge-pill badge-warning">1</span> Megrendlés szám
        </div>
        <app-task-partner-ref-number-container [task]="task" (onSelect)="updatePartnerSelect($event)"></app-task-partner-ref-number-container>
    </div>
    <div class="padding-10-0">
        <div>
            <span class="badge badge-pill badge-warning">2</span> Megjegyzés
        </div>
        <mat-form-field appearance="outline">
            <textarea rows="3" matInput name="description" [(ngModel)]="this.section.defaultConfig.comments"  autocomplete="off"></textarea>
        </mat-form-field>
        
    </div>
    <div class="text-center">
        <button mat-flat-button  color="accent" (click)="generate()">
            PDF Generálás
        </button>
    </div>
</ng-template>

</div>
