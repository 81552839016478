import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseTaskEntity } from 'src/app/common/baseClasses/baseTaskEntity';
import { EnumTemplateCategory } from 'src/app/enums/templates/templateCategory.enum';
import { Project } from 'src/app/models/projects/project.model';
import { Template, TemplateSection } from 'src/app/models/template.model';
import { ProjectService } from 'src/app/services/projects/project.service';
import { SecurityService } from 'src/app/services/security.service';
import { TaskService } from 'src/app/services/tasks/task.service';
import { TaskPdfService } from 'src/app/services/tasks/taskPdf.service';
import { TaskProductService } from 'src/app/services/tasks/taskProduct.service';
import { TemplateSelectorComponent } from 'src/app/shared/snippets/selectors/template-selector/template-selector.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

AutoUnsub()
@Component({
  selector: 'app-task-vendor-interest',
  templateUrl: './task-vendor-interest.component.html',
  styleUrls: ['./task-vendor-interest.component.scss']
})
export class TaskVendorInterestComponent  extends BaseTaskEntity implements OnInit {
  TEMPLATE_CATEGORY = EnumTemplateCategory.TaskVendorInterest;
  
  @ViewChild(TemplateSelectorComponent) templateSelectorComp: TemplateSelectorComponent
  templateSections: TemplateSection[] = [];
  template: Template;

  // pdf list vs create view
  createPdfView = false;
  isPdfGenerated = false;  // set to true if the pdf generated
  editPdf = false; // only ture if loaded an already generated pdf.

  pdfCreate$;
  getData$;
  
  constructor(
    private route: ActivatedRoute,
    private _taskService: TaskService,
    private _projectService: ProjectService,
    private _taskProductService: TaskProductService,
    private taskPdfService: TaskPdfService,
    private _ss: SecurityService
  ) {
    super(_taskService, _taskProductService, _projectService, _ss);
  }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id');

    if (id) {
      this.fetchTaskById(id);
    }
  }

  setTemplate(template) {
    this.template = template;
  }

  setTemplateSections(sections) {
    // set template from dropdown
    if (!sections) {
      sections = [];
    }

    this.templateSections = sections;
  }

  generateOfferPdf(pdfData) {
    this.pdfCreate$ = this.taskPdfService.create({
      taskId: this.task.id,
      data: pdfData,
      templateId: this.template.id,
    }).subscribe(() => {
      this.resetPdfGeneration();
      this.isPdfGenerated = true;
    }, error => {
      alert('error');
  });

  }

  resetPdfGeneration() {
    this.isPdfGenerated = false;
    this.template = null;
    this.setTemplateSections([]);
    this.setTemplate(null);
    this.editPdf = false;

    if (this.templateSelectorComp) {
      this.templateSelectorComp.unset();
    }
  }

  createPdfFromPdf(taskPdf) {
    this.getData$ = this.taskPdfService.get_pdf_data(taskPdf).subscribe(res => {
      this.createPdfView = true;
      this.editPdf = true;
      this.isPdfGenerated = false;

      let templateSections = this.taskPdfService.pdfSectionToPdfSectionConnector(res.data);
      
      if (taskPdf.template) {
        this.setTemplate(taskPdf._template);
      }
      
      this.setTemplateSections(templateSections);
    });
  }

  backToPdfList() {
    this.resetPdfGeneration();
    this.createPdfView = false;
  }
}
