import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnumModule } from 'src/app/enums/modules.enum';
import { User } from 'src/app/models/user';
import { UsersService } from 'src/app/services/users.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-dlg-search-user',
  templateUrl: './dlg-search-user.component.html',
  styleUrls: ['./dlg-search-user.component.scss']
})
export class DlgSearchUserComponent implements OnInit {
    // if it is passed to the comoponent filter down the user by access
    // - we have to grab the moduel in the comonenet becuse I haven't been able to manage to grab it in a User Selector Comp.
    module: EnumModule = null;

    //text which we searching 
    txtSearch: string = '';
    timeout = null;
    options: User[] = null;

    list$;

  constructor(
    private userService: UsersService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DlgSearchUserComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.txtSearch = data.name;

    if (data.module) {
      this.module = data.module;
    }

    this.list$ = this.userService.getActiveUsers().subscribe(users => {
      //users = this.filterUserByAccess(users);
      // TODO: filter users by access - it should be server side
      console.log("*** Users not filtered by Access ***");
      this.options = users;
    });
  }

  ngOnInit() {}

  select(user) {
    if (!user) {
      return;
    }
    this.txtSearch = user.lastName + ' ' + user.firstName;

    setTimeout(() => {
      this.dialogRef.close(user);
    }, 200);
     
  }

  delete() {
    this.txtSearch = '';

    setTimeout(() => {
      this.dialogRef.close('');
    }, 300);
  }

  filterUserByAccess(users) {
    // Only show the employees how have access to the module
    let  usersWithAccess = [];

    users.forEach(user => {
      if ('access' in user) {
        if (this.module in user.access) {
          if (user.access[this.module].access) {
            usersWithAccess.push(user);
          }
        }
      }
    });

    return usersWithAccess;
  }

}
