<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Kategória</a></li>  
    <li>{{category.nameHu}}</li>  
</ul>


<mat-tab-group animationDuration="0ms" dynamicHeight>
    <mat-tab label="Kategória">
        <div class="section">
            <div class="header">
                <div class="title">
                    <span>Katgória</span>
                    <mat-icon matTooltip="Migrált adat" class="m-l-10 text-yellow" *ngIf="category && category.migrated">move_down</mat-icon>
                </div>
            </div>
            <app-category
            (eventCreated)="created($event)"
            (eventDeleted)="deleted($event)" 
            (eventUpdated)="updated($event)"
            [category]="category"></app-category>
        </div>
    </mat-tab>
    <mat-tab label="Marketing Leírás" *ngIf="category.id">
        <div class="section">
            <div class="header">
                <div class="title"><span>Marketing Leírás</span></div>
            </div>
            <div class="alert alert-warning">
                Az itt megadott szöveg látható lehet ügyfelek számára!
            </div>

            <mat-tab-group>
                <mat-tab label="Magyar">
                    <app-html-container [content]="category.marketingHu" (onChange)="updateMarketingHu($event)"></app-html-container>
                </mat-tab>
                <mat-tab label="Angol">
                    <app-html-container [content]="category.marketingEn" (onChange)="updateMarketingEn($event)"></app-html-container>
                </mat-tab>
            </mat-tab-group>
        </div>
    </mat-tab>
</mat-tab-group>

