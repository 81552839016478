export enum EnumTemplateDefaultSection {
    PdfHeader = 'pdf_header',  // based on the context/template type
    SignitureAndStamp = 'signiture_and_stamp',

    ProductsMarketing = 'products_marketing',  //product details for marketing purposes
    ProductList = 'product_list', // list of products in bulletponts
    PriceTable = 'price_table',

    CustomerConfirmation = 'customer_confirmation',  // section to confirm the order (their ref number and ours included)

    ProductTable = 'product_table',  //table in the Vendor Offer and Interest pdfs
    InvoiceAndDeliveryAddress = 'invoice_and_delivery_address',  // company own addresses
    VatNumber = 'vat_number' // company vat number
}