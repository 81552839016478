import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Organisation, OrganisationItem } from 'src/app/models/organisation';
import { OrganisationService } from 'src/app/services/organisation.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

/**
 * Allow to select an Item from the Org
 */

@AutoUnsub()
@Component({
  selector: 'app-dlg-org-item',
  templateUrl: './dlg-org-item.component.html',
  styleUrls: ['./dlg-org-item.component.scss']
})
export class DlgOrgItemComponent implements OnInit {
  org: Organisation;
  orgItem: OrganisationItem;
  orgItems: OrganisationItem[] = [];

  editMode: boolean = false;

  onSelect: EventEmitter<OrganisationItem> = new EventEmitter();

  orgItemList$;

  constructor(
    private organisationService: OrganisationService,
    public dialogRef: MatDialogRef<DlgOrgItemComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.org = data.organizastion;

    if (!this.org) throw 'No Organsation...';
  }

  ngOnInit(): void {
    this.fetch();
  }

  fetch() {
    let params = {organization: this.org.id, limit:1000};
    this.orgItemList$ = this.organisationService.listItems({params}).subscribe(resp => {
      this.orgItems = resp.results;

      this.sortByType();
    })
  }

  sortByType() {
     // sort by product type (machines first)
    this.orgItems.sort(function(a, b) {
      let textA = a._item._product.type.toUpperCase();
      let textB = b._item._product.type.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  }

  select(orgItem) {
    this.onSelect.emit(orgItem);
    this.dialogRef.close();
  }

  backToListMode() {
    this.editMode = !this.editMode;
    this.fetch();
  }

}
