import { Injectable } from "@angular/core";

/**
 * Intercept the HTTP request body and set the Empty Strings to NULL.
 * This makes more sense for the backend. Especially if the field is a number and we want to unset it!
 * If we would left it a string it wouldn't be possible 
 */

@Injectable()
export class NullValueService {

    constructor()  {}
    
    setNullValues(request) {
        for (let key in request.body) {
            if (request.body[key] === '') {
                request.body[key] = null;
            }
        }

        return request
    }


}