import { Component, Inject, OnInit } from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import { EnumNotificationType } from 'src/app/enums/notificationType.enum';

@Component({
  selector: 'app-notification-content',
  templateUrl: './notification-content.component.html',
  styleUrls: ['./notification-content.component.scss']
})
export class NotificationContentComponent implements OnInit {
  enumNotificationType = EnumNotificationType

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: EnumNotificationType
  ) {}

  ngOnInit() {
  }

}
