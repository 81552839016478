import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Task } from 'src/app/models/tasks/task.model';
import { TaskService } from 'src/app/services/tasks/task.service';

/**
 * Allow to search or provide in a project for a tas ref number
 */

@Component({
  selector: 'app-task-ref-number-container',
  templateUrl: './task-ref-number-container.component.html',
  styleUrls: ['./task-ref-number-container.component.scss']
})
export class TaskRefNumberContainerComponent implements OnInit {
  @Input() task: Task;

  @Output() onSelect: EventEmitter<string> = new EventEmitter();

  refNumber: string;

  tasks: Task[];

  tasks$;

  constructor(
    private taskService: TaskService
  ) { }

  ngOnInit(): void {
    this.fetchTasks();
  }

  fetchTasks() {
    if (!this.task) return;

    if (!this.task._action.project) return;

    let params = {
      limit: 1000,
      project: this.task._action.project 
    }
    
    this.tasks$ = this.taskService.list({params}).subscribe(res => {
      this.tasks = res.results;
    })
  }

  selectTask(task) {
    this.refNumber = task.refNumber;
    this.onSelect.emit(task.refNumber);
  }

  sendChange() {
    this.onSelect.emit(this.refNumber);
  }

}
