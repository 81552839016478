export enum EnumModule {
  /**
   * THIS LIST IS A COPY OF Backend MODULES
   */
  //1. Project Planning
  ProjectPlanning = 'projectplanning',

  //Project
  Projects = 'projects',

  //Template
  Templateing = 'templateing',

  //Tasks
  TaskClients = 'taskclients',
  // TaskCustomerInterest = 'taskcustomerinterest',
  // TaskCustomerOffer = 'taskcustomeroffer',
  // TaskCustomerOrder = 'taskcustomerorder',
  // TaskCustomerConfirmation = 'taskcustomerconfirmation',
  // TaskCustomerHandover = 'taskcustomerhandover',
  TaskVendors = 'taskvendors',
  // TaskVendorInterest = 'taskvendorinterest',
  // TaskVendorOrder = 'taskvendororder',

  //Group: which related to "services"
  TaskServices = 'taskservices',

  // Group: Inventory management related otpions
  Inventory = 'inventory',

  //INTERNALLY DEFINED ACCESS RIGHTS
  Profile = 'profile',
  Accounts = 'accounts',
  Company = 'company',
  GeneralSettings = 'generalsettings',

  //Read and Write Modules for non Admins
  Contacts = 'contacts',
  Items = 'items',
  PdfSnippets = 'pdfsnippets',

  //access based on the model
  Audit = 'audit'
  }