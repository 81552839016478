/** Search for the set of products in the Task and set them for the given Task */

import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project } from 'src/app/models/projects/project.model';
import { Task } from 'src/app/models/tasks/task.model';
import { TaskProduct } from 'src/app/models/tasks/taskProduct.model';
import { TaskProductService } from 'src/app/services/tasks/taskProduct.service';
import { groupBy } from 'lodash-es';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-dlg-search-project-products',
  templateUrl: './dlg-search-project-products.component.html',
  styleUrls: ['./dlg-search-project-products.component.scss']
})
export class DlgSearchProjectProductsComponent implements OnInit {  
  task: Task;
  taskProducts: TaskProduct[] = [];
  
  // contains the grouped data strucutre
  groupedData: any;
  // contains the selected task products
  selectedTaskProducts: TaskProduct[] = [];
  // list of ids, only to help to update the UI 
  selectedTaskProductIds = []

  project: Project;

  @Output() onSelect: EventEmitter<TaskProduct[]> = new EventEmitter();

  list$;
  
  constructor(
    private taskProductService: TaskProductService,
    public dialogRef: MatDialogRef<DlgSearchProjectProductsComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    // it is possible to only send the project (task is not mandatory)
    this.task = data.task;

    if (this.task) {
      this.project = this.task._action._project;
    } else if (data.project) {
      this.project = data.project;
    }


    this.fetchProjectProducts();
  }

  ngOnInit() {
   
  }

  fetchProjectProducts() {
    let params = {
      project: this.project.id
    };

    this.list$ = this.taskProductService.list({params}).subscribe(res => {
      this.groupByTask(res);
    })
  }

  groupByTask(data) {
    // group by task id
    // TODO: chain removed (not implemented in lodash-es) - check and update the function
    data = groupBy(data, 'task')

    let  transformedData = []

    for (const [key, value] of Object.entries(data)) {
      transformedData.push({
        task: key,
        products: value,
        _task: value[0]._task
      });
    
    }

    this.taskProducts = transformedData;
  }

  selectTaskProduct(tp, addOnly=false, removeOnly=false) {
    let stp = this.selectedTaskProducts.find(stp => stp.id == tp.id);

    if (stp) {
      // remove
      if (!addOnly) {
        this.selectedTaskProducts = this.selectedTaskProducts.filter(stp => stp.id != tp.id);
      }
    } else {
      // add
      if (!removeOnly) {
        this.selectedTaskProducts.push(tp);
      }
    }

    this.selectedTaskProductIds =  this.selectedTaskProducts.map((obj) => obj.id);
  }

  save() {
    this.onSelect.emit(this.selectedTaskProducts);
    this.close();
  }

  close() {
    this.dialogRef.close()
  }

  selectedAllTaskProduct(e:Event, taskProduct: any) {
    // all task product select checkbox
    e.stopPropagation();

    // checked is unkown property of the general html element
    let addOnly = ((e.target as any).checked) ? true : false;
    let removeOnly = ((e.target as any).checked) ? false : true;
 
   taskProduct.products.forEach(tp => {
    this.selectTaskProduct(tp, addOnly=addOnly, removeOnly=removeOnly)
   })

  }

}
