<ul class="breadcrumb"> 
    <li>Felhasználók</li>  
</ul>

<div class="padding-10-0 flex flex-right">
    <button mat-flat-button color="primary" [routerLink]="['user']" i18n="@@usersTableUser">Új Felhasználó</button>
</div>

<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Név</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <mat-icon [ngStyle]="{'color': (row.color) ? row.color : 'black'}">account_circle</mat-icon>
            <span class="link" (click)="open(row)">{{row.lastName}} {{row.firstName}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>Státusz</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.status}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef>Aktív</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-static-true-false-icon [value]="row.isActive"></app-static-true-false-icon>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="admin">
        <mat-header-cell *matHeaderCellDef>Admin</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-static-true-false-icon [value]="row.admin"></app-static-true-false-icon>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="verified">
        <mat-header-cell *matHeaderCellDef>Email Megerősítve</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-static-true-false-icon [value]="row.verified"></app-static-true-false-icon>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="worker">
        <mat-header-cell *matHeaderCellDef>Dolgozó</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <app-static-true-false-icon [value]="row.isWorker"></app-static-true-false-icon>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
></mat-paginator>
