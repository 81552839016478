import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-task-list-filter-container',
  templateUrl: './task-list-filter-container.component.html',
  styleUrls: ['./task-list-filter-container.component.scss']
})
export class TaskListFilterContainerComponent implements OnInit {
  // TODO: security to know the module for the User list!

  @Input() textSearch: string = '';
  @Input() open: boolean = true;
  @Input() importance = null;
  @Input() status = null;
  @Input() assignee: User = null;

  // emitters for the filter options
  @Output() onChangeSearch: EventEmitter<string> = new EventEmitter();
  @Output() onChangeImportance: EventEmitter<string> = new EventEmitter();
  @Output() onChangeStatus: EventEmitter<string> = new EventEmitter();
  @Output() onChangeAssignee: EventEmitter<User> = new EventEmitter();
  @Output() onChangeOpen: EventEmitter<boolean> = new EventEmitter();


  constructor() {}

  ngOnInit() {
    
  }

  changeSearch () {
    this.textSearch = this.textSearch;
    this.onChangeSearch.emit(this.textSearch);
  }

  noSearch() {
    this.textSearch = '';
    this.onChangeSearch.emit(this.textSearch);
  }

  importanceChagned(importance) {
    this.importance = importance;
    this.onChangeImportance.emit(this.importance);
  }

  statusChanged(status) {
    this.status = status;
    this.onChangeStatus.emit(this.status);
  }

  assigneeChanged(assignee) {
    this.assignee = assignee;
    this.onChangeAssignee.emit(this.assignee);
  }

  stateChanged(event) {
    // open vs closed changed
    this.onChangeOpen.emit(this.open);
  }
}
