import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { EnumLanguage } from 'src/app/enums/languages.enum';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  @Input() language: EnumLanguage | string;

  @Output() onChange: EventEmitter<EnumLanguage | string> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (!this.language) {
      this.language = 'hu';
    }
  }

  selected() {
    this.onChange.emit(this.language);
  }

}
