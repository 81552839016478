<ng-container *ngIf="task.organization">
<div class="flex flex-right">
    <ng-container *ngIf="hasAccessProjects">
        <div  *ngIf="!showProjects">   
            <div class="link padding-10 text-small" (click)="loadProjects()">{{task._organization.name}} Aktív Projektek</div>
        </div>
    </ng-container>
    <div  *ngIf="!showOrgItems">   
        <div class="link padding-10 text-small" (click)="loadOrgItems()">{{task._organization.name}} Termékei</div>
    </div>
  </div>    

<!--Active Projects-->
  <div class="section" *ngIf="showProjects">
    <div class="flex">
        <div class="full helper-text">Actív Projektek:</div>
        <div class="text-right cursor padding-10-0" (click)="showProjects=!showProjects">
            <mat-icon class="text-red" matTooltip="Bezár">close</mat-icon>
        </div>
    </div>
   
    <ng-container *ngIf="orgProjects.length > 0; else tmpNoProjects">
        <div class="table-responsive">
            <table class="table">
            <tr *ngFor="let p of orgProjects">
                <td >
                    <div class="flex w150">
                        <span class="link" routerLink="/app/projects/project"  [queryParams]="{id: p.id}" matTooltip="{{p.name}}">{{p.name | truncate: 4}} </span>
                    </div>
                </td>
                <td>
                    <app-importance-container [importance]="p.importance"></app-importance-container>
                </td>
                <td>
                    <app-avatar [user]="p._assignee"></app-avatar>
                </td>
                <td class="w200">
                    <app-project-status [actions]="p.actions"></app-project-status>
                </td>
            </tr>
            </table>
        </div>
    </ng-container>
    <ng-template #tmpNoProjects>Nincs nyitott projekt a céggel</ng-template>
  </div>

<!--Org Items-->
  <div class="section" *ngIf="showOrgItems">
    <div class="flex">
        <div class="full text-bold">{{task._organization.name}} termékei:</div>
        <div class="text-right cursor padding-10-0" (click)="showOrgItems=!showOrgItems">
            <mat-icon class="text-red"  matTooltip="Bezár">close</mat-icon>
        </div>
    </div>
   
    <ng-container *ngIf="orgItems.length > 0; else tmpNoItem">
        <table class="table">
        <tr *ngFor="let oi of orgItems">
            <td>
               <app-item-container [item]="oi._item"></app-item-container>
            </td>
            <td>
                {{oi.amount}}
                <ng-container *ngIf="oi._item._product.unitHu; else tmpDefaultQuantity">{{oi._item._product.unitHu}}</ng-container>
                <ng-template #tmpDefaultQuantity>db</ng-template>
            </td>
            <td class="helper-text">{{oi.updated | dateDate}}</td>
        </tr>
        </table>
    </ng-container>
    <ng-template #tmpNoItem>Nincs termék cégnél</ng-template>
  </div>

</ng-container>

<div class="section" style="border-top: 2px solid #ffffff" [style.border-color]="borderColor">
    <ng-container *ngIf="task">
        <div class="flex flex-align-item-start block-md">
            <div class="full">
                <span class="text-small text-yellow">
                    <app-task-type-container [type]="task.taskType"></app-task-type-container>
                </span>
                
                <h5>{{task.name}}</h5>
                <p class="text-secondary padding-10-0" matTooltip="{{task.description}}">{{task.description}}</p>
            </div>
            <span>
                <div class="padding-10-0">
                    <app-task-status-selector  [status]="task.status" (onChange)="setStatus($event)"></app-task-status-selector>
                </div>
            </span> 
        </div>

        <div class="table-responsive">
            <table class="table table-condensed wAuto">
                <tr>
                    <td>Azonosító</td>
                    <td>
                        <span class="ref-number">{{task.refNumber}}</span>
                    </td>
                </tr>
                <tr>
                    <td>Fontosság</td>
                    <td>
                        <app-importance-container [importance]="task.importance"></app-importance-container>
                    </td>
                </tr>
                <tr>
                    <td>Felelős</td>
                    <td>
                        <ng-container *ngIf="task.assignee">
                            <app-avatar [user]="task._assignee"></app-avatar>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td>
                        <ng-container *ngIf="isVendor; else tmpClient">Beszállító</ng-container>
                        <ng-template #tmpClient>Cég</ng-template>
                    </td>
                    <td>
                        <ng-container *ngIf="task.organization">
                            <app-bottom-organisation [data]="task.organization"></app-bottom-organisation>
                            <span>{{task._organization.name}}</span>
                            <span *ngIf="task._organization.language == 'en'" class="badge badge-pill badge-warning m-l-10">
                                {{task._organization.language == "en" ? "Angol" : "Magyar"}}
                            </span>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td>Projekt</td>
                    <td>
                        <ng-container *ngIf="task.action">
                            <span class="color-dot" [style.backgroundColor]="task._action._project?._projectType?.color" matTooltip="{{task._action._project?._projectType?.name}}"></span>
                            <span>{{task._action._project.name | truncate:7}}</span> / 
                            <span class="helper-text">{{task._action.name}}</span>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="flex">
                            <mat-icon matTooltip="Pdf és Email Sablonok nyelve">info</mat-icon>
                            <span>Kommunikáció Nyelve</span>
                        </div>
                    </td>
                    <td>
                        <ng-container *ngIf="task.language">
                            <div class="text-secondary">
                                <ng-container *ngIf="task.language == 'hu'">Magyar</ng-container>
                                <ng-container *ngIf="task.language == 'en'">Angol</ng-container>
                            </div>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="flex">
                            <mat-icon matTooltip="Pénznem a PDF fájlokban">info</mat-icon>
                            <span>Pénznem</span>
                        </div>
                    </td>
                    <td>
                        <ng-container *ngIf="task.currency">
                            <div class="text-secondary">
                                <ng-container *ngIf="task.currency == 'huf'">HUF</ng-container>
                                <ng-container *ngIf="task.currency == 'eur'">EUR</ng-container>
                            </div>
                        </ng-container>
                    </td>
                </tr>
            </table>
        </div>

        <div class="flex" *ngIf="task.id">
            <div  class="full" >
                <ng-container *ngIf="task.open" >
                    <button mat-flat-button color="accent"  (click)="setState('closed')"  matTooltip="Feladat Aktív, kattints hogy archiváld">
                        Aktív
                    </button>
                </ng-container>
                <ng-container *ngIf="!task.open">
                    <button mat-flat-button  color="warn" (click)="setState('open')"  matTooltip="Feladat Lezárva, kattints hogy aktiváld">
                        Lezárva
                    </button>
                </ng-container>
            </div>
            <div> 
                <span *ngIf="task.open">
                    <button mat-mini-fab  color="primary" (click)="openTaskEdit()" matTooltip="Szerkeszés">
                        <mat-icon>edit</mat-icon>
                    </button>
                </span>
                <span class="padding-l-10">
                    <app-quick-log-info [data]="task"></app-quick-log-info>
                </span>
            </div>   
        </div>
    </ng-container>  
</div>
