import { Component, Inject, OnInit, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Organisation } from 'src/app/models/organisation';
import { People } from 'src/app/models/people.model';
import { OrganisationService } from 'src/app/services/organisation.service';
import { forkJoin } from 'rxjs';
import { PeopleService } from 'src/app/services/people.service';
import { EnumLanguage } from 'src/app/enums/languages.enum';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

/**
 * Based on the passed Organisaation (id or org) allow to select a contact detail
 * - if id passed grab the organisation with the contact
 * - if org passed (has to contain contacts) disaplay the data and show it
 * 
 * RETRUN: return one of the selected value from Org or conact
 * - name
 * - email
 * 
 * THIS IS NOT FOR SEARCH FOR A PEOPLE!!!
 */

@AutoUnsub()
@Component({
  selector: 'app-dlg-search-org-contact',
  templateUrl: './dlg-search-org-contact.component.html',
  styleUrls: ['./dlg-search-org-contact.component.scss']
})
export class DlgSearchOrgContactComponent implements OnInit {
  organisation: Organisation = null;
  people: People[];

  onSelect = new EventEmitter();

  peopleList$;

  // defulat return the selecte name
  type = 'name';
  // language, used for name to determine the order
  language = EnumLanguage.Hu

  constructor(
    private organisationService: OrganisationService,
    private peopleService: PeopleService,
    public dialogRef: MatDialogRef<DlgSearchOrgContactComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.organisation = data.organisation;

    if (data.type) {
      this.type = data.type;
    }

    if (data.language) {
      this.language = data.language;
    }

    if (!this.organisation) throw 'No Organsation...';

    if (typeof this.organisation === 'number') {
      // grab the organisation data ....
      this.fetchAll();
    } else {
      // fecth people only
      this.fetchPeople(this.organisation.id);
    }
  }

  ngOnInit() {
  }

  fetchPeople(orgId) {
    let params = {limit: 100, organisation: orgId}
    this.peopleList$ = this.peopleService.list({params}).subscribe(res => {
      this.people = res.results;
    })
  }

  fetchAll() {
    let params = {limit: 100, organisation: this.organisation}

    // load the data which need for the pdf creation
    let org = this.organisationService.get(this.organisation)
    let people = this.peopleService.list({params});

    forkJoin([org, people]).subscribe(res => {
      this.organisation = res[0];
      this.people = res[1];
    });
  }

  select(data) {
    this.onSelect.emit(data);

    setTimeout(() => {
      this.dialogRef.close();
    }, 200);
     
  }

}
