import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnumInventoryDirection, EnumInventoryReason } from 'src/app/enums/inventory.enum';
import { Inventory, InventoryTransaction } from 'src/app/models/inventory.model';
import { Item } from 'src/app/models/item.model';
import { Organisation } from 'src/app/models/organisation';
import { InventoryService } from 'src/app/services/inventory.service';
import { InventoryTransactionService } from 'src/app/services/inventoryTransaction.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-dlg-edit-inventory-transaction',
  templateUrl: './dlg-edit-inventory-transaction.component.html',
  styleUrls: ['./dlg-edit-inventory-transaction.component.scss']
})
export class DlgEditInventoryTransactionComponent implements OnInit {
  @Output() onChange: EventEmitter<InventoryTransaction> = new EventEmitter();

  // set it if we know which item we want to work with
  item: Item;
  inventoryTransaction: InventoryTransaction;
  // if the inventory passed in we don't have to grab it
  inventory: Inventory;

  // changing the quantity is disabeld
  // used for TaskProducts to ensure only one transaction is allowed per taskProduct
  forceQuantity = false;

  // the org where item is go after it is taken out
  org: Organisation;
  // builtin item 
  builtIn: Item;

  enumInventoryDirection = EnumInventoryDirection;
  enumInventoryReason = EnumInventoryReason;

  invGet$;
  invTCreate$;
  
  constructor(
    private inventoryService: InventoryService,
    private inventoryTransactionService: InventoryTransactionService,
    private modelFactory: ModelFactory,
    public dialogRef: MatDialogRef<DlgEditInventoryTransactionComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.inventoryTransaction = this.modelFactory.getNewModel(InventoryTransaction);

    this.inventoryTransaction.quantity = (data.quantity) ? data.quantity : 1;
    this.inventoryTransaction.price = (data.price) ? data.price : null;
    this.forceQuantity = (data.forceQuantity) ? true : false;
    
  
    if(data.direction) {
      this.inventoryTransaction.direction = data.direction;
    }

    if (data.item) {
      this.item = data.item;
      this.inventoryTransaction._item = data.item;
      this.inventoryTransaction.item = data.item.id;
    }

    if (data.inventory) {
      this.inventory = data.inventory;
    }

    if (data.direction == EnumInventoryDirection.Out) {
      // org only could be taken account if the it goas out
      this.org = (data.org) ? data.org : null;
      this.builtIn = (data.builtIn) ? data.builtIn : null;

      if (this.org) {
        this.inventoryTransaction.reason = EnumInventoryReason.Company;
        this.setOrg(this.org);
      }

      if (this.builtIn) {
        if (this.item?.id == this.builtIn.id) {
          //safeguard to do not builtin itself
          this.builtIn = null;
        } else {
          this.setBuiltin(this.builtIn);
        }
      }
    }
  }

  ngOnInit(): void {
    //Avoid -> NG0100: Expression has changed after it was checked
    setTimeout(() => {
      this.fetchInventory();
    }, 350)
  }

  fetchInventory() {
    if (this.inventoryTransaction.item) {
      this.invGet$ = this.inventoryService.getByItem(this.inventoryTransaction.item).subscribe(res => {
        this.inventory = res;
      })
    } else {
      this.inventory = null;
    }
  }

  setItem(item) {
    if (item) {
      this.inventoryTransaction.item = item.id;
      this.inventoryTransaction._item = item;
    } else {
      this.inventoryTransaction.item = null;
      this.inventoryTransaction._item = null;
    }

    this.fetchInventory();
  }

  isDisableSave() {
    if (this.inventoryTransaction.direction == 'out' && !this.inventory) {
      // we don't know the stock level, cannot decide that we want to take out or not
      return true;

    }

    if (this.inventoryTransaction.direction == 'out' && this.inventory.stock < this.inventoryTransaction.quantity) {
      // stock level is lower than what we need
      return true;
    }

    if (this.inventoryTransaction.direction == 'out' && !this.inventoryTransaction.reason) {
      // out form the stock require reason
      return true;
    }

    if (this.inventoryTransaction.direction == 'out' && this.inventoryTransaction.reason == EnumInventoryReason.Builtin) {
      if (!this.inventoryTransaction.builtin) {
        return true;
      }
    }

    if (this.inventoryTransaction.direction == 'out' && this.inventoryTransaction.reason == EnumInventoryReason.Company) {
      if (!this.inventoryTransaction.organization) {
        return true;
      }
    }

    if (this.inventoryTransaction.direction == 'in' && !this.inventoryTransaction.price) {
      // price mandatory put something into the inventory
      return true;
    }

    return false;
  }

  setOrg(org) {
    this.inventoryTransaction.organization = org.id;
    this.inventoryTransaction._organization = org;
  }

  setBuiltin(builtin) {
    this.inventoryTransaction.builtin = builtin.id;
    this.inventoryTransaction._builtin = builtin;
  }

  save() {
    this.invTCreate$ = this.inventoryTransactionService.create(this.inventoryTransaction).subscribe(res => {
      this.onChange.emit(res);
      this.dialogRef.close();
    })
  }

}
