import { Component, Input, OnInit } from '@angular/core';
import { EnumCountry } from 'src/app/enums/countries.enums';
import { Organisation, OrganisationAddress } from 'src/app/models/organisation';
import { Task } from 'src/app/models/tasks/task.model';
import { TaskCustomerDelivery } from 'src/app/models/tasks/taskCustomerDelivery.model';
import { CompanyService } from 'src/app/services/company.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { TaskCustomerDeliveryService } from 'src/app/services/tasks/taskCustomerDelivery.service';
import { forkJoin } from 'rxjs';
import { Company } from 'src/app/models/company.model';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-task-customer-delivery-detail',
  templateUrl: './task-customer-delivery-detail.component.html',
  styleUrls: ['./task-customer-delivery-detail.component.scss']
})
export class TaskCustomerDeliveryDetailComponent implements OnInit {
  @Input() task: Task;

  taskCustomerDelivery: TaskCustomerDelivery | any;

  enumCountry = EnumCountry;

  org: Organisation | any;
  orgAddresses: OrganisationAddress[] | any = [];
  company: Company | any;

  addresses = []

  forkList:  any[] = [];

  taskGet$;
  taskUpdate$;

  constructor(
    private taskCustomerDeliveryService: TaskCustomerDeliveryService,
    private companyService: CompanyService,
    private orgService: OrganisationService
  ) { }

  ngOnInit(): void {
    let taskCustomerDelivery$ = this.taskCustomerDeliveryService.get(this.task.id);
    this.forkList.push(taskCustomerDelivery$);

    let company$ = this.companyService.load();
    this.forkList.push(company$);

    if (this.task.organization) {
      let org$ = this.orgService.get(this.task.organization);
      let params = {limit: 1000, organisation: this.task.organization}
      
      let addresses$ = this.orgService.listAddresses(params)
      
      this.forkList.push(org$, addresses$)
    }

    forkJoin(this.forkList).subscribe(res => {
      this.taskCustomerDelivery = res[0];
      this.company = res[1];

      if (this.task.organization) {
        this.org = res[2];
        this.orgAddresses = res[3];

        let toSave = false;

        if (!this.taskCustomerDelivery.toAddressCity && !this.taskCustomerDelivery.toAddressPostcode && !this.taskCustomerDelivery.toAddressLine) {
          // populate the data with the defaults
          this.taskCustomerDelivery.toAddressCountry = this.org.addressCountry;
          this.taskCustomerDelivery.toAddressCity = this.org.addressCity;
          this.taskCustomerDelivery.toAddressPostcode = this.org.addressPostcode;
          this.taskCustomerDelivery.toAddressLine = this.org.addressLine1 + ' ' + this.org.addressLine2;

          toSave = true;
        }

        if (!this.taskCustomerDelivery.fromAddressCity && !this.taskCustomerDelivery.fromAddressPostcode && !this.taskCustomerDelivery.fromAddressLine) {
           // populate the data with the defaults
           this.taskCustomerDelivery.fromAddressCountry = EnumCountry.Hungary;
           this.taskCustomerDelivery.fromAddressCity = this.company.addressCity;
           this.taskCustomerDelivery.fromAddressPostcode = this.company.addressPostcode;
           this.taskCustomerDelivery.fromAddressLine = this.company.addressLine1 + ' ' + this.company.addressLine2;
 
           toSave = true;
        }

        if (toSave) {
          this.save();
        }

        this.setAddresses();
      }
     
    });

    this.taskGet$ = this.taskCustomerDeliveryService.get(this.task.id).subscribe(resp => {
      this.taskCustomerDelivery = resp;
    });


  }

  save() {
    this.taskUpdate$ = this.taskCustomerDeliveryService.update(this.taskCustomerDelivery).subscribe(resp => {
      this.taskCustomerDelivery = resp;
    })
  }

  copyCompanyFrom() {
      this.taskCustomerDelivery.fromAddressCountry = EnumCountry.Hungary;
      this.taskCustomerDelivery.fromAddressCity = this.company.addressCity;
      this.taskCustomerDelivery.fromAddressPostcode = this.company.addressPostcode;
      this.taskCustomerDelivery.fromAddressLine = this.company.addressLine1 + ' ' + this.company.addressLine2;

      this.save();
  }

  copyToAddress(address) {
    this.taskCustomerDelivery.toAddressCountry = address.country
    this.taskCustomerDelivery.toAddressCity = address.city;
    this.taskCustomerDelivery.toAddressPostcode = address.postcode;
    this.taskCustomerDelivery.toAddressLine = address.line;

    this.save();
  }

  setAddresses() {
    // create a list of addresses of the company with the same porperties
    if (this.org) {
      let line = '';
      if (this.org.addressLine1) { line += this.org.addressLine1;}
      if (this.org.addressLine2) { line += ' ' + this.org.addressLine2;}


      let orgAddress = {
        country: this.org.addressCountry,
        postcode: this.org.addressPostcode,
        city: this.org.addressCity,
        line: line
       }

      this.addresses.push(orgAddress); 
    }

    if (!this.orgAddresses) return;

    this.orgAddresses.forEach(address => {
      let line = '';
      if (address.addressLine1) { line += address.addressLine1;}
      if (address.addressLine2) { line += ' ' + address.addressLine2;}

      let a = {
        country: address.country,
        postcode: address.postcode,
        city: address.city,
        line: line
       }

      this.addresses.push(a); 
    });
  }

}
