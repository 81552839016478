import { Component, Input, OnInit } from '@angular/core';
import { EnumProductType } from 'src/app/enums/productType.enum';
import { EnumTaskPdfGenerationStatus } from 'src/app/enums/tasks/taskPdfGenerationStatus.enum';
import { EnumTemplateCategory } from 'src/app/enums/templates/templateCategory.enum';
import { Task } from 'src/app/models/tasks/task.model';
import { TaskEvent } from 'src/app/models/tasks/taskEvent.model';
import { TaskProduct } from 'src/app/models/tasks/taskProduct.model';
import { TemplateSection } from 'src/app/models/template.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { NotificationService } from 'src/app/services/notification';
import { TaskService } from 'src/app/services/tasks/task.service';
import { TaskEventService } from 'src/app/services/tasks/taskEvent.service';
import { TaskPdfService } from 'src/app/services/tasks/taskPdf.service';
import { TaskProductService } from 'src/app/services/tasks/taskProduct.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-default-section-breakdown-report',
  templateUrl: './default-section-breakdown-report.component.html',
  styleUrls: ['./default-section-breakdown-report.component.scss']
})
export class DefaultSectionBreakdownReportComponent implements OnInit {
  @Input() task: Task;

  TEMPLATE_CATEGORY = EnumTemplateCategory.BreakDownReprot;

  // this is the only section in the pdf
  breakDownSection: TemplateSection;

  templateSections: TemplateSection[] = [];

  // pdf list vs create view
  createPdfView = false;
  isPdfGenerated = false;  // set to true if the pdf generated

  taskEvents: TaskEvent[] = [];
  
  // list of Task Product from the task where the selected TaskEvent happend
  taskMachines: TaskProduct[] = [];
  // the selected TaskProduct - about the breakdwon report
  taskMachine: TaskProduct;

  // parts in this current task
  taskParts: TaskProduct[] = [];
  // selected part - about the breakdown report
  taskPart: TaskProduct;

  taskProdList$;
  taskProdList2$;
  eventList$;
  pdfServiceCreate$;

  constructor(
    private taskEventService: TaskEventService,
    private taskProductService: TaskProductService,
    private taskPdfService: TaskPdfService,
    private modelFactory: ModelFactory,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.createSection();

    this.fetchTaskEventsByProject();

    this.populateParts();
  }

  createSection() {
    this.breakDownSection = this.modelFactory.getNewModel(TemplateSection);
    this.breakDownSection.defaultSection = this.TEMPLATE_CATEGORY;
    this.breakDownSection.defaultConfig = {
      partName: null,
      partCode: null,
      orderNumber: this.task.refNumber,
      machineType: null,
      machineSN: null,
      cncControlType: null,
      cncControlSN: null,
      orgName: null,
      orgCountry: 'Hungary',
      mcInstallationDate: null,
      warranty: false,
      exchange: false,
      user: {
        id: null,
        firstName: null,
        lastName: null,
        email: null
      },
      description: null
    }
  }

  populateTaskEvent(event) {
    // poulate the event and the org
    this.breakDownSection.defaultConfig.orgName = event.value._task?._organization.name;
    
    this.breakDownSection.defaultConfig.description = event.value.problem;
    
    this.breakDownSection.defaultConfig.user =  {
      id: event.value?._assignee.id,
      firstName: event.value?._assignee.firstName,
      lastName: event.value?._assignee.lastName,
      email: event.value?._assignee.email,
    }

    this.populateMachines(event.value.task)
  }

  populateMachines(taskId) {
    // now we know the task event time to figure out the taskPorudcts => Items of the Task with the selected event
    // - this is the machine
    let params = {task: taskId};
    this.taskProdList$ = this.taskProductService.list({params}).subscribe(res => {
      this.taskMachines = res.results;

      // 1. filter out every non Machine and Products (only Items)
      this.taskMachines = this.taskMachines.filter(tM => tM.item);
      this.taskMachines = this.taskMachines.filter(tM => tM._product.type == EnumProductType.Machine);


      if (this.taskMachines.length == 1) {
        // if there is only one select it
        this.taskMachine = this.taskMachines[0];

        this.breakDownSection.defaultConfig.machineType = this.taskMachine._product.nameEn;
        this.breakDownSection.defaultConfig.machineSN = this.taskMachine._item.serialNumber;
      }
    });
  }

  populateParts() {
    let params = {task: this.task.id};
    this.taskProdList2$ = this.taskProductService.list({params}).subscribe(res => {
      this.taskParts = res.results;

      // 1. filter out every non Parts and Products (only Items)
      // TODO: THIS TASK WORKS WITH PRODUCTS!
      //this.taskParts = this.taskParts.filter(tM => tM.item);
      this.taskParts = this.taskParts.filter(tM => tM._product.type == EnumProductType.Part);

      if (this.taskParts.length == 1) {
        // if there is only one select it
        this.taskPart = this.taskParts[0];

        this.breakDownSection.defaultConfig.partName = this.taskPart._product.nameEn;
        this.breakDownSection.defaultConfig.partCode = this.taskPart._product.articleNumber;
      }
    });
  }

  setMachine(event) {
    this.breakDownSection.defaultConfig.machineType = event.value._product.nameEn;
    this.breakDownSection.defaultConfig.machineSN = event.value._item.serialNumber;
  }

  setPart(event) {
    this.breakDownSection.defaultConfig.partName = event.value._product.nameEn;
    this.breakDownSection.defaultConfig.partCode = event.value._product.articleNumber;
  }

  fetchTaskEventsByProject() {
    if (!this.task.action) return;

    let params = {
      limit:1000,
      project: this.task._action.project
    }

    this.eventList$ = this.taskEventService.list({params}).subscribe(res => {
      this.taskEvents = res.results;
    })
  }

  generate() {
    let data = {
      fileName: 'break_down_report',
      language: 'en',
      sections:  [this.breakDownSection]
    };

    let payload = {
      taskId: this.task.id,
      data: data,
      templateCategory: this.TEMPLATE_CATEGORY
    }

    this.pdfServiceCreate$ = this.taskPdfService.create(payload).subscribe(res => {
      this.notificationService.success();
    });
  }

}
