import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from './models/company.model';
import { Exchange } from './models/exchange.model';
import { User } from './models/user';
import { AuthService } from './services/auth.service';
import { CompanyService } from './services/company.service';
import { ExchangeService } from './services/exchange.service';
import { SecurityService } from './services/security.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loggedIn: boolean = false;
  user: User;
  company: Company = null;

  exchange: Exchange = null;
  valueHuf: any = 0;
  valueEur: any = 0;

  today =  new Date().toISOString().slice(0, 10);

  // access
  hasAccessProjects = false;
  hasAccessTaskClients = false;
  hasAccessTaskVendors = false;
  hasAccessInvetnory = false;
  hasAccessTaskServices = false;

  constructor (
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private ss: SecurityService,
    private companyService: CompanyService,
    private exchangeService: ExchangeService
  ) {}

  ngOnInit() {

    this.authService.loggedIn$.subscribe((val) => {
      // get static data or the observable status
      this.loggedIn = val || this.authService.isLoggedIn();
    });

    // get the logged in user data
    this.authService.user$.subscribe(user => {
     this.user = user;
    })

    // get the logged in user data
    this.authService.accessLevel$.subscribe(accessLevel => {
      this.hasAccessProjects = accessLevel.projects.access;
      this.hasAccessTaskClients = accessLevel.taskvendors.access;
      this.hasAccessTaskVendors = accessLevel.taskclients.access;
      this.hasAccessTaskServices = accessLevel.taskservices.access;
      this.hasAccessInvetnory = accessLevel.inventory.access;
    });

    //get company details
    if (!this.company) {
      this.companyService.load().subscribe(company => {
        this.company = company;
      });
    } 

    // get exchange rate
    this.exchangeService.get().subscribe(exchange => {
      this.exchange = exchange;
      this.valueHuf = this.exchange.rate;
      this.valueEur = 1;
    })
  }

  logout() {
    this.loggedIn = false;
    this.authService.logout();
    this.router.navigate(['login'])
  }

  setToday() {
    (document.getElementById('headerTodayInput') as HTMLInputElement).value = new Date().toISOString().slice(0, 10);
  }

  updateValueEur() {
    if (!this.exchange) return;

    this.valueEur = parseFloat(this.valueHuf)/this.exchange.rate;
    this.valueEur = this.valueEur.toFixed(1);
  }

  updateValueHuf() {
    if (!this.exchange) return;

    this.valueHuf = parseFloat(this.valueEur)*this.exchange.rate;
    this.valueHuf = this.valueHuf.toFixed(1)
  }
}
