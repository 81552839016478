import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Organisation } from 'src/app/models/organisation';
import { People } from 'src/app/models/people.model';
import { PeopleService } from 'src/app/services/people.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';
import { DlgEditPersonComponent } from '../../edit/dlg-edit-person/dlg-edit-person.component';

@AutoUnsub()
@Component({
  selector: 'app-dlg-search-people',
  templateUrl: './dlg-search-people.component.html',
  styleUrls: ['./dlg-search-people.component.scss']
})
export class DlgSearchPeopleComponent implements OnInit {
//text which we searching 
txtSearch: string = '';
// if organisation passed restrict the search for the People of Company
organisation: Organisation = null;
// if it is set we have a default selection
people: People = null;
options = [];
timeout = null;

@Output() onChange: EventEmitter<People> = new EventEmitter();

peopleList$;
dlgPeopleEdit$;

constructor(
  private peopleService: PeopleService,
  private dialog: MatDialog,
  public dialogRef: MatDialogRef<DlgSearchPeopleComponent>,
  @Inject(MAT_DIALOG_DATA) data
) {
  if (data.people) {
    // passed the default selection
    this.people = data.people;
    this.txtSearch = this.people.fullName;
  }
  else if (data.name) {
    // we have a name to search for
    this.txtSearch = data.name;
  }
 
  this.organisation = data.organisation;
}

ngOnInit() {
  setTimeout(() => {
    this.search();
  }, 100)
}

search() {
  this._doSearch();
}

_doSearch() {
  if (this.txtSearch.length % 2) {
    // only search after evey 2 charackters
    // if remaining part 0 do the search if 1 stop
    return;
  }

  this.fetch();  
}

fetch() {
  let params = {
    search: this.txtSearch,
    limit: 25
  };

  if (this.organisation) {
    params['organisation'] = this.organisation.id
  }

  this.peopleList$ = this.peopleService.list({params}).subscribe(data => {
    this.options = data.results
   });
}

select(people) {
  if (!people) {
    return;
  }
 

  this.onChange.emit(people);

  setTimeout(() => {
    this.txtSearch = people.fullName;
    this.dialogRef.close(people);
  }, 200);
   
}

delete() {
  this.txtSearch = '';

  setTimeout(() => {
    this.dialogRef.close('');
  }, 300);
}

createPeople() {
  let dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose =  true;

  dialogConfig.data = {
    organisation: this.organisation
  };

  const dialogRef = this.dialog.open(DlgEditPersonComponent, dialogConfig);

  this.dlgPeopleEdit$ = dialogRef.componentInstance.onChange.subscribe(people => {
    this.txtSearch = people.fullName;
    this.select(people);
  })
}

removeOrg() {
  this.organisation = null;
  this.txtSearch = null;
}

}

