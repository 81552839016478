<div class="flex">
    <div>
        <button mat-mini-fab color="primary" (click)="setNextDay()" matTooltip="következő nap">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
    </div>
    <div class="padding-10 text-bold">{{selectedDate | dateDate}}</div>
    <div>
        <button mat-mini-fab color="primary" (click)="setPrevDay()" matTooltip="előző nap">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
    </div>
    <div class="padding-10">
        <button mat-mini-fab color="accent" (click)="setYestarday()" matTooltip="tegnap">
            <mat-icon>flag</mat-icon>
          </button>
    </div>
</div>

<table class="table table-striped" *ngIf="audits && audits.length > 0">
    <thead>
      <tr>
        <th scope="col">Mikor?</th>
        <th scope="col" class="w150">Ki?</th>
        <th scope="col">Mit?</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let audit of audits">
        <td>
            <ng-container *ngIf="audit.operation === 'create'">
                <span class="badge badge-success">Létrhozva</span>
            </ng-container>
            <ng-container *ngIf="audit.operation === 'update'">
                <span class="badge badge-warning">Frissitve</span>
            </ng-container>
            <ng-container *ngIf="audit.operation === 'delete'">
                <span class="badge badge-danger">Törölve</span>
            </ng-container>
            <br>
            <div style="white-space: nowrap;">{{audit.when | dateTime}}</div>
        </td>
        <th ><app-avatar [user]="audit.user"></app-avatar></th>
        <td>
            <!--prject-->
            <div *ngIf="audit.details?.project">
                <span class="text-yellow">Projekt</span>
                <app-project-container [project]="audit.details.project"></app-project-container>
                <div *ngFor="let change of audit.changes" class="helper-text">
                    <div *ngIf="change.field == 'name'">Név: {{change.to}}</div>
                    <div *ngIf="change.field == 'importance'">Fontosság: {{change.to}}</div>
                    <div *ngIf="change.field == 'project_type'">Típus: {{change.to}}</div>
                    <div *ngIf="change.field == 'assignee'">Felelős: {{change.to}}</div>
                    <div *ngIf="change.field == 'organization'">Cég: {{change.to}}</div>
                    <div *ngIf="change.field == 'open'" class="flex">
                        <app-static-true-false-icon [value]="change.to"></app-static-true-false-icon>
                        <span *ngIf="change.to">Aktív</span>
                        <span *ngIf="!change.to">Lezárva</span>
                    </div> 
                </div>
            </div>

            <!--Action-->
            <div *ngIf="audit.details?.action">
                <span class="text-yellow">Projekt Lépés</span>
                <div>{{audit.details.action.name}}</div>
                <app-project-container [project]="audit.details.action._project"></app-project-container>
                <div *ngFor="let change of audit.changes" class="helper-text">
                    <div *ngIf="change.field == 'status'">
                        <app-status-container [status]="change.to" [showLabel]="true"></app-status-container> 
                    </div>
                </div>
            </div>

            <!--ActionEvent-->
            <div *ngIf="audit.details?.actionEvent">
                <span class="text-yellow">Projekt Esemény</span>
                <div>{{audit.details.actionEvent.action.name}}</div>
                <app-project-container [project]="audit.details.actionEvent.action._project"></app-project-container>
                
                <div *ngFor="let change of audit.changes" class="helper-text">
                    <div *ngIf="change.field == 'note'">
                        Jegyzet: {{change.to}}
                    </div>
                </div>
            </div>

            <!--Task-->
            <div *ngIf="audit.details?.task">
                <div class="text-yellow">
                    Feladat: <app-task-type-container [type]="audit.details.task.taskType"></app-task-type-container>
                </div>
                <div class="flex">
                    <app-status-container [status]="audit.details.task.status" [showLabel]="false"></app-status-container>
                    <span class="link" (click)="openTask(audit.details.task)" matTooltip="{{audit.details.task.name}}">{{audit.details.task.name | truncate: 5}} </span>
                </div>
                <div *ngFor="let change of audit.changes" class="helper-text">
                    <div *ngIf="change.field == 'name'">Név: {{change.to}}</div>
                    <div *ngIf="change.field == 'importance'" class="flex">
                        <div>Fontosság </div>
                        <app-importance-container [importance]="change.to"></app-importance-container>
                    </div>
                    <div *ngIf="change.field == 'assignee'">Felelős: {{change.to}}</div>
                    <div *ngIf="change.field == 'organization'">Cég: {{change.to}}</div>
                    <div *ngIf="change.field == 'open'" class="flex">
                        <app-static-true-false-icon [value]="change.to"></app-static-true-false-icon>
                        <span *ngIf="change.to">Aktív</span>
                        <span *ngIf="!change.to">Lezárva</span>
                    </div>
                    <div *ngIf="change.field == 'status'">
                        <app-status-container [status]="change.to" [showLabel]="true"></app-status-container> 
                    </div>
                    <div *ngIf="change.field == 'language'" class="flex">
                        <div>Nyelv: </div>
                        <div *ngIf="change.to == 'en'">Angol</div>
                        <div *ngIf="change.to == 'hu'">Magyar</div>
                    </div>    
                    <div *ngIf="change.field == 'currency'" class="flex">
                        <div>Pénznem: </div>
                        <div *ngIf="change.to == 'eur'">Euro</div>
                        <div *ngIf="change.to == 'huf'">Forint</div>
                    </div>
                    <div *ngIf="change.field == 'action'">Lépés: {{change.to}}</div>
                </div>
            </div>

            <!--TaskEvent-->
            <div *ngIf="audit.details?.taskEvent">
                <div class="helper-text">
                    <span  class="text-yellow">Feladat Esemény: </span><app-task-type-container [type]="audit.details.taskEvent.task.taskType"></app-task-type-container>
                </div>
                <div class="flex">
                    <app-status-container [status]="audit.details.taskEvent.task.status" [showLabel]="false"></app-status-container>
                    <span class="link" (click)="openTask(audit.details.taskEvent.task)" matTooltip="{{audit.details.taskEvent.task.name}}">{{audit.details.taskEvent.task.name | truncate: 5}} </span>
                </div>
                <div>Esemény: {{audit.details.taskEvent.name}}</div>
                <div *ngFor="let change of audit.changes" class="helper-text">
                    <div *ngIf="change.field == 'name'">Név: {{change.to}}</div>
                    <div *ngIf="change.field == 'assignee'">Felelős: {{change.to}}</div>
                    <div *ngIf="change.field == 'start' && isTruthyValue(change.to)" >Kezdés: {{change.to | strToDate | dateTime }}</div>
                    <div *ngIf="change.field == 'start' && isTruthyValue(change.to)">Befejezés: {{change.to | strToDate | dateTime }}</div>
                    <div *ngIf="change.field == 'actual_start' && isTruthyValue(change.to)">Tényleges Kezdés: {{change.to | strToDate | dateTime }}</div>
                    <div *ngIf="change.field == 'actual_end' && isTruthyValue(change.to)">Tényleges Befejezés: {{change.to | strToDate | dateTime }}</div>
                    <div *ngIf="change.field == 'done'">
                        <div *ngIf="change.to == 'True'" class="flex">
                            <app-static-true-false-icon [value]="true"></app-static-true-false-icon>
                            <span>Befejezve</span>
                        </div>
                        <div *ngIf="change.to == 'False'" class="flex">
                            <app-static-true-false-icon [value]="false"></app-static-true-false-icon>
                            <span>Nincs Befejezve</span>
                        </div>
                    </div> 
                </div>
            </div>

             <!--TaskProduct-->
             <div *ngIf="audit.details?.taskProduct">
                <div class="helper-text">
                    <span class="text-yellow">Feladat Termék: </span><app-task-type-container [type]="audit.details.taskProduct.task.taskType"></app-task-type-container>
                </div>
                <div class="flex">
                    <app-status-container [status]="audit.details.taskProduct.task.status" [showLabel]="false"></app-status-container>
                    <span class="link" (click)="openTask(audit.details.taskProduct.task)" matTooltip="{{audit.details.taskProduct.task.name}}">{{audit.details.taskProduct.task.name | truncate: 5}} </span>
                </div>
                <app-item-container [item]="audit.details.taskProduct._item" *ngIf="audit.details.taskProduct.item; else tmplTaskProductProduct"></app-item-container>
                <ng-template #tmplTaskProductProduct>
                    <app-product-container [product]="audit.details.taskProduct._product"></app-product-container>
                </ng-template>
                
            </div>

             <!--TaskNote-->
             <div *ngIf="audit.details?.taskNote">
                <div class="helper-text">
                    <span class="text-yellow">Feladat Jegyzet: </span><app-task-type-container [type]="audit.details.taskNote.task.taskType"></app-task-type-container>
                </div>
                <div class="flex">
                    <app-status-container [status]="audit.details.taskNote.task.status" [showLabel]="false"></app-status-container>
                    <span class="link" (click)="openTask(audit.details.taskNote.task)" matTooltip="{{audit.details.taskNote.task.name}}">{{audit.details.taskNote.task.name | truncate: 5}} </span>
                </div>
                <div *ngFor="let change of audit.changes" class="helper-text">
                    <div *ngIf="change.field == 'note'" matTooltip="{{change.to}}" [innerHtml]="change.to | safeHtml">{{change.to | stripHtml | truncate:15}}</div>
                </div>
            </div>

            <!--TaskDoc-->
            <div *ngIf="audit.details?.taskDoc">
                <div class="helper-text">
                    <span class="text-yellow">Feladat Dokumentum: </span><app-task-type-container [type]="audit.details.taskDoc.task.taskType"></app-task-type-container>
                </div>
                <div class="flex">
                    <app-status-container [status]="audit.details.taskDoc.task.status" [showLabel]="false"></app-status-container>
                    <span class="link" (click)="openTask(audit.details.taskDoc.task)" matTooltip="{{audit.details.taskDoc.task.name}}">{{audit.details.taskDoc.task.name | truncate: 5}} </span>
                </div>
                <div *ngFor="let change of audit.changes" class="helper-text">
                    <div *ngIf="change.field == 'description' && isTruthyValue(change.to)" matTooltip="{{change.to}}">{{change.to | stripHtml | truncate:15}}</div>
                    <div *ngIf="change.field == 'doc_ref_number' && isTruthyValue(change.to)" matTooltip="{{change.to}}">Referencia szám: {{change.to}}</div>
                </div>
                <div>
                    <a target="_blank" href="{{audit.details.taskDoc.file}}" matTooltip="{{audit.details.taskDoc.file | fileNameFromPath}}">
                        <mat-icon>download_for_offline</mat-icon>
                    </a>   
                </div>
            </div>

            <!--TaskPDF-->
            <div *ngIf="audit.details?.taskPDF">
                <div class="helper-text">
                    <span class="text-yellow">Feladat Generált PDF: </span><app-task-type-container [type]="audit.details.taskPDF.task.taskType"></app-task-type-container>
                </div>
                <div class="flex">
                    <app-status-container [status]="audit.details.taskPDF.task.status" [showLabel]="false"></app-status-container>
                    <span class="link" (click)="openTask(audit.details.taskPDF.task)" matTooltip="{{audit.details.taskPDF.task.name}}">{{audit.details.taskPDF.task.name | truncate: 5}} </span>
                </div>
                <div *ngFor="let change of audit.changes" class="helper-text">
                    <div class="flex" *ngIf="change.field == 'sent' && isTruthyValue(change.to)">
                         <app-static-true-false-icon [value]="change.to"></app-static-true-false-icon>
                         <span>Email elküldve</span>
                    </div>
                    <div *ngIf="change.field == 'status' && isTruthyValue(change.to)">
                        <div class="text-green" *ngIf="change.to == 'done'">Létrehozva</div>
                    </div>
                </div>
                <div>
                    <a target="_blank" href="{{audit.details.taskPDF.file}}" matTooltip="{{audit.details.taskPDF.file | fileNameFromPath}}">
                        <mat-icon>download_for_offline</mat-icon>
                    </a>   
                </div>
            </div>

            <!--Inventory Transaction-->
            <div *ngIf="audit.details?.inventoryTransaction">
                <div class="helper-text" class="flex">
                    <span class="text-yellow">Raktár Mozgás</span>
                    <span  class="text-green flex" *ngIf="audit.details.inventoryTransaction.direction == 'in'">
                        <mat-icon>vertical_align_bottom</mat-icon>
                        <span>Betét</span>
                    </span>
                    <span class="text-red flex" *ngIf="audit.details.inventoryTransaction.direction == 'out'">
                        <mat-icon>vertical_align_top</mat-icon>
                        <span>Kivét</span>
                    </span>
                </div>
                <div class="padding-5-0">
                    <app-item-container [item]="audit.details.inventoryTransaction.item" [showLocation]="true"></app-item-container>
                </div>
                
                
                <div *ngFor="let change of audit.changes" class="helper-text">
                    <div class="flex" *ngIf="change.field == 'quantity' && isTruthyValue(change.to)">
                         <span>Mennyiség&nbsp;</span>
                         <span class="badge badge-info">{{change.to}}</span>
                    </div>
                </div>

                <div *ngIf="audit.details.inventoryTransaction._organization">
                        <span class="helper-text">Céghez került:&nbsp;
                        </span>{{audit.details.inventoryTransaction._organization.name}}
                </div>
                <div *ngIf="audit.details.inventoryTransaction.builtIn">
                        <span class="helper-text">Beépítve:&nbsp;</span>
                        <app-item-container [item]="audit.details.inventoryTransaction.builtIn" [showLocation]="true"></app-item-container>
                    </div>
            </div>


            <!--Task Customer Interest-->
            <div *ngIf="audit.details?.taskCustomerInterest">
                <div class="helper-text">
                    <span class="text-yellow">Feladat: </span><app-task-type-container [type]="audit.details.taskCustomerInterest.task.taskType"></app-task-type-container>
                </div>
                <div class="flex">
                    <app-status-container [status]="audit.details.taskCustomerInterest.task.status" [showLabel]="false"></app-status-container>
                    <span class="link" (click)="openTask(audit.details.taskCustomerInterest.task)" matTooltip="{{audit.details.taskCustomerInterest.task.name}}">{{audit.details.taskCustomerInterest.task.name | truncate: 5}} </span>
                </div>
                <div *ngFor="let change of audit.changes">
                    <div *ngIf="change.field == 'status' && isTruthyValue(change.to)">
                        <div *ngIf="change.to == enumTaskInterestStatus.WaitingOffer">Ajánlatra Vár</div>
                        <div *ngIf="change.to == enumTaskInterestStatus.WaitingCallback">Visszahívásra Vár</div>
                        <div *ngIf="change.to == enumTaskInterestStatus.WaitingDecision">Döntésre Várunk</div>
                        <div *ngIf="change.to == enumTaskInterestStatus.PersonalMeeting">Személyes Találozó</div>
                    </div>
                    <div *ngIf="change.field == 'condition' && isTruthyValue(change.to)">
                        <div *ngIf="change.to == enumTaskCustomerInterestCondition.New">Új Gép</div>
                        <div *ngIf="change.to == enumTaskCustomerInterestCondition.Used">Használt Gép</div>
                        <div *ngIf="change.to == enumTaskCustomerInterestCondition.Both">Új vagy Használt Gép</div>
                    </div>
                </div>
            </div>

             <!--Task Customer Order-->
             <div *ngIf="audit.details?.taskCustomerOrder">
                <div class="helper-text">
                    <span class="text-yellow">Feladat: </span><app-task-type-container [type]="audit.details.taskCustomerOrder.task.taskType"></app-task-type-container>
                </div>
                <div class="flex">
                    <app-status-container [status]="audit.details.taskCustomerOrder.task.status" [showLabel]="false"></app-status-container>
                    <span class="link" (click)="openTask(audit.details.taskCustomerOrder.task)" matTooltip="{{audit.details.taskCustomerOrder.task.name}}">{{audit.details.taskCustomerOrder.task.name | truncate: 5}} </span>
                </div>
                <div *ngFor="let change of audit.changes">
                    <div *ngIf="change.field == 'status' && isTruthyValue(change.to)">
                        <div *ngIf="change.to == enumTaskOrderStatus.WaitingResponse">Válaszra várunk</div>
                        <div *ngIf="change.to == enumTaskOrderStatus.KeepInTouch">Kapcsolattartás</div>
                        <div *ngIf="change.to == enumTaskOrderStatus.BoughtSomebodyElse"  class="text-red" >Mástól vett</div>
                        <div *ngIf="change.to == enumTaskOrderStatus.NotNow" class="text-red">Nem aktuális</div>
                        <div *ngIf="change.to == enumTaskOrderStatus.Sold" class="text-green">Eladva</div>
                    </div>
                </div>
            </div>
        </td>
      </tr>
    </tbody>
</table>

<div class="text-center padding-20-0" *ngIf="audits && audits.length == 0">
    Nem történt semmi érdekes...
</div>