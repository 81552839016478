import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SecurityService } from 'src/app/services/security.service';
import { BottomTaskEventSearchComponent } from 'src/app/shared/bottomSheets/bottom-task-event-search/bottom-task-event-search.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {
  @Output('clicked') menuClicked: any = new EventEmitter();

  //modules
  contacts = false;
  items = false;
  admin = false;
  projectPlanning = false;
  projects = false;
  taskClients = false;
  taskVendors = false;
  taskServices = false;
  inventory = false;
  templateing = false;
  taskAny = false;
  // need write access to taskservices to  open the event planning section
  // individaul event security is hard....
  hasAccessToEventPlanning = false;

  secLevel$;

  constructor(
    private securityService: SecurityService,
    private bottomSheet: MatBottomSheet,
  ) { }

  ngOnInit() {
    this.secLevel$ = this.securityService.modelAccessLevels$.subscribe((res) => {
      if (!res) {
        // no response yet
        return
      }
      // lead is finieshed, now we can check the values
      this.admin = this.securityService.user.admin;
      this.contacts = this.securityService.hasAccess('contacts')
      this.items = this.securityService.hasAccess('items')
      this.projectPlanning = this.securityService.hasAccess('projectplanning')
      this.projects = this.securityService.hasAccess('projects')
      this.templateing = this.securityService.hasAccess('templateing')
      this.taskClients = this.securityService.hasAccess('taskclients')
      this.taskServices = this.securityService.hasAccess('taskservices')
      this.taskVendors = this.securityService.hasAccess('taskvendors')
      this.inventory = this.securityService.hasAccess('inventory')
      this.hasAccessToEventPlanning = this.admin || this.securityService.modelAccessLevels.taskservices.accessLevel > 1;
      this.taskAny = this.taskClients || this.taskServices || this.taskVendors|| this.inventory
    })
  
  }

  clicked() {
    this.menuClicked.emit();
  }

  searchInTaskEvents() {
    this.bottomSheet.open(BottomTaskEventSearchComponent, {closeOnNavigation: true});
  }

}
