import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { Manufacturer } from 'src/app/models/manufacturers.model';
import { ManufacturerService } from 'src/app/services/manufacturer.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-manufacturer',
  templateUrl: './manufacturer.component.html',
  styleUrls: ['./manufacturer.component.scss']
})
export class ManufacturerComponent extends BaseEntity implements OnInit {
  @Input() manufacturer: Manufacturer;
  @Input() isDlg: boolean = false;

  @Output() eventCreated: EventEmitter<Manufacturer> = new EventEmitter();
  @Output() eventDeleted: EventEmitter<any> = new EventEmitter();
  @Output() eventUpdated: EventEmitter<Manufacturer> = new EventEmitter();

  manCreate$;
  manUpdate$;
  manDelete$;
  
  constructor(
    private manufacturerService: ManufacturerService,
  ) {
    super();
  }

  ngOnInit() {
  }

  save() {
    (this.manufacturer.id) ? this.update() : this.create();
  }

  create() {
    this.manCreate$ = this.manufacturerService.create(this.manufacturer).subscribe(m => {
      this.manufacturer = m;
      this.eventCreated.emit(this.manufacturer);
    })
  }

  update() {
    this.manUpdate$ = this.manufacturerService.update(this.manufacturer).subscribe(m => {
      this.manufacturer = m;
      this.eventUpdated.emit(this.manufacturer);
    })
  }

  delete() {
      this.manDelete$ = this.manufacturerService.delete(this.manufacturer).subscribe(() => {
        this.eventDeleted.emit();
      });
    }

}





