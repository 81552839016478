export enum EnumInventoryDirection {
    In = 'in',
    Out = 'out'
}

export enum EnumInventoryReason {
    Builtin = 'builtin',
    Company = 'company',
    Broken = 'broken',
    SentBack = 'sent_back',
    Correction = 'correction'
}