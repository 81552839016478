import { isEmpty } from "lodash-es";

export class Template {
    id: number;
    name: string;
    description: string;
    type: string;
    category: string;
    fileNameHu: string;
    fileNameEn: string;
    created?: Date;
    updated?: Date;

   constructor(data) {
       if (data) {
           this.id = data.id;
           this.name = data.name;
           this.description = data.description;
           this.type = data.type;
           this.category = data.category;
           this.fileNameHu = data.fileNameHu;
           this.fileNameEn = data.fileNameEn;
           
           if (data.created) {
            this.created =  new Date(data.created);
           }

           if (data.updated) {
            this.updated = new Date(data.updated);
           }  
       }
   }
}

export class TemplateSection {
    id: number;
    template: number;
    _template: any;
    titleHu: string;
    titleEn: string;
    bodyHu: string;
    bodyEn: string;
    order: number;
    defaultSection: string = ''; // if empty string it is a custom section
    defaultConfig: any = {}; // obj. cofnig setup for a specific default section type
    columns: boolean;
    created?: Date;
    updated?: Date;

   constructor(data) {
       if (data) {
           this.id = data.id;
           this.titleHu = data.titleHu;
           this.titleEn = data.titleEn;
           this.bodyHu = data.bodyHu;
           this.bodyEn = data.bodyEn;
           this.order = data.order;
           this.columns = data.columns;

           if (data.defaultConfig ===  null) {
               this.defaultConfig = {};
           } 
           else if (isEmpty(data.defaultConfig)) {
               this.defaultConfig = {};
           } else {
               //this.defaultConfig = JSON.parse(data.defaultConfig);
               // the data come in JSON format already
               this.defaultConfig = data.defaultConfig;    
           }

           if (data.defaultSection.length > 0) {
               // looks this is a custom section
               this.defaultSection = data.defaultSection;
           }
           
           this.template = data.template;
           if (data._template) {
               this._template = data._template;
           }
           
           if (data.created) {
            this.created =  new Date(data.created);
           }

           if (data.updated) {
            this.updated = new Date(data.updated);
           }  
       }
   }
}


export class TemplateSectionVersion {
    id: number;
    section: number;
    _section: any;
    titleHu: string;
    bodyHu: string;
    titleEn: string;
    bodyEn: string;
    created?: Date;
    updated?: Date;

   constructor(data) {
       if (data) {
           this.id = data.id;
           this.titleHu = data.titleHu;
           this.bodyHu = data.bodyHu;
           this.titleEn = data.titleEn;
           this.bodyEn = data.bodyEn;

           this.section = data.section;
           if (data._section) {
               this._section = data._template;
           }
           
           if (data.created) {
            this.created =  new Date(data.created);
           }

           if (data.updated) {
            this.updated = new Date(data.updated);
           }  
       }
   }
}
