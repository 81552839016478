import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Manufacturer } from 'src/app/models/manufacturers.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';

@Component({
  selector: 'app-dlg-edit-manufacturer',
  templateUrl: './dlg-edit-manufacturer.component.html',
  styleUrls: ['./dlg-edit-manufacturer.component.scss']
})
export class DlgEditManufacturerComponent implements OnInit {
  manufacturer: Manufacturer = null;


  constructor(
    private modelFactory: ModelFactory,
    public dialogRef: MatDialogRef<DlgEditManufacturerComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.manufacturer = this.modelFactory.getNewModel(Manufacturer);

    if (data) {
      this.manufacturer = data;
    }
  }

  ngOnInit() {

  }

  created(entity) {
    this.closeDlg(entity);
  }

  updated(entity) {
    this.closeDlg(entity);
  }

  deleted(event) {
    this.closeDlg(null);
  }

  closeDlg(entity) {
    this.dialogRef.close(entity);
  }

}

