<div class="section">
    <div class="header">
        <div class="title full">
            <ng-container [ngSwitch]="task.taskType">
                <ng-container *ngSwitchCase="enumsTaskTypes.TaskCustomerDeliver">
                    <span>Szállítási Esemény</span>
                </ng-container>
                <ng-container *ngSwitchCase="enumsTaskTypes.TaskService">
                    <span>Szervíz Esemény</span>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <span>Tennivalók</span>
                </ng-container>
            </ng-container>
        </div>
        <div>
            <button class="margin-l-10" mat-icon-button  color="primary" (click)="searchInEvents()"  matTooltip="Keresés a Tennivaló Részletekben">
                <mat-icon>search</mat-icon>
            </button>  
            <ng-container *ngIf="onlyOneEvent && taskEvents && taskEvents.length == 1; else tmpCreate">
                <!--not allow to create-->
            </ng-container>
            <ng-template #tmpCreate>
                <button mat-mini-fab  color="accent" (click)="create()"  matTooltip="Új Hozzáadaása">
                    <mat-icon>add</mat-icon>
                </button> 
            </ng-template>
        </div>        
    </div>

<div class="table-responsive">   
<table class="table" *ngIf="taskEvents && taskEvents.length > 0">  
    <thead>
        <tr>
            <th colspan="2">
                <ng-container [ngSwitch]="task.taskType">
                    <ng-container *ngSwitchCase="enumsTaskTypes.TaskCustomerDeliver">
                        <span>Szállítás</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="enumsTaskTypes.TaskService">
                        <span>Szervíz Időpontok</span>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <span>Tennivalók</span>
                    </ng-container>
                </ng-container>
            </th>
            <th>Felelős</th>
            <th>Dátum</th>
            <th></th>
        </tr>
    </thead>
    <ng-container *ngFor="let a of taskEvents">
        <tr>
            <td class="w50">
                <mat-checkbox name="done" [(ngModel)]="a.done" matTooltip="Elvégezve?" (change)="doneChange(a)"></mat-checkbox>
            </td>
            <td>
                <span matTooltip="{{a.name}}">{{a.name | truncate:10}}</span>
                <div class="text-red" matTooltip="{{a.problem}}">{{a.problem | truncate:10}}</div>
                <div class="text-green" matTooltip="{{a.comment}}">{{a.comment | truncate:10}}</div>
            </td>
            <td>
                <app-avatar [user]="a._assignee"></app-avatar>
            </td>
            <td>
                <ng-container [ngSwitch]="task.taskType">
                    <ng-container *ngSwitchCase="enumsTaskTypes.TaskCustomerDeliver">
                        <div *ngIf="a.start || a.actualStart">
                            <span *ngIf="!a.done">{{a.start | date:'yyyy-MM-dd'}}</span>
                            <span *ngIf="a.done">{{a.actualStart | date:'yyyy-MM-dd'}}</span>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <div class="flex text-small" *ngIf="a.start || a.actualStart">
                            <span class="helper-text">Kezdés</span>&nbsp;
                            <span *ngIf="!a.done">{{a.start | date:'yyyy-MM-dd H:mm'}}</span>
                            <span *ngIf="a.done">{{a.actualStart | date:'yyyy-MM-dd H:mm'}}</span>
                        </div>
                        <div class="flex text-small" *ngIf="a.end || a.actualEnd">
                            <span  class="helper-text">Befejezve</span>&nbsp;
                            <span *ngIf="!a.done">{{a.end | date:'yyyy-MM-dd H:mm'}}</span>
                            <span *ngIf="a.done">{{a.actualEnd | date:'yyyy-MM-dd H:mm'}}</span>
                        </div>
                        <div class="flex text-small" *ngIf="a.pause">
                            <span  class="helper-text">Szünet</span>&nbsp;
                            <span>{{a.pause/60}} perc</span>
                        </div>
                    </ng-container>
                 </ng-container>
            </td>
            <td>
                <button mat-mini-fab  color="primary" (click)="openEdtiTaskEventDlg(a)" matTooltip="Szerkesztés">
                    <mat-icon>edit</mat-icon>
                </button>
                <span class="padding-l-10">
                    <app-quick-log-info [data]="a"></app-quick-log-info>
                </span>
            </td>
        </tr>
    </ng-container>
</table>
</div>

</div>
