import { AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project } from 'src/app/models/projects/project.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { cloneDeep } from 'lodash-es';
import { Action } from 'src/app/models/projects/action.model';
import { ActionService } from 'src/app/services/projects/action.service';
import { DlgSearchPlanActionComponent } from '../../search/dlg-search-plan-action/dlg-search-plan-action.component';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { ProjectService } from 'src/app/services/projects/project.service';
import { DlgSearchProjectTemplateComponent } from '../../search/dlg-search-project-template/dlg-search-project-template.component';
import { ProjectType } from 'src/app/models/projects/projectType.model';
import { ProjectTypeService } from 'src/app/services/projects/projectType.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { EnumProjectImportance } from 'src/app/enums/projectImportance.enum';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-dlg-edit-project',
templateUrl: './dlg-edit-project.component.html',
  styleUrls: ['./dlg-edit-project.component.scss']
})
export class DlgEditProjectComponent extends BaseEntity implements OnInit {
  project: Project;
  actions: Action[] = [];
  projectTypes: ProjectType[] = null;

  // Project Saved
  @Output() onChangeProject: EventEmitter<Project> = new EventEmitter();

  // Actions Changed
  @Output() onChangeActions: EventEmitter<Action[]> = new EventEmitter();

  @ViewChild('tree') tree;

  treeControl = new NestedTreeControl<Action>(node => node?.children);
  dataSource = new MatTreeNestedDataSource<Action>();
  hasChild = (_: number, node: Action) => !!node?.children && node?.children.length > 0;

  projTypeList$;
  projCreate$;
  projUpdate$;
  projDel$;
  actList$;
  dlgPlanActSearch$;
  actSave$;
  dlgSearchProjTemp$;

  constructor(
    private modelFactory: ModelFactory,
    public dialogRef: MatDialogRef<DlgEditProjectComponent>,
    private actionService: ActionService,
    private projectService: ProjectService,
    private projectTypeService: ProjectTypeService,
    private dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super();
    
    this.project = this.modelFactory.getNewModel(Project);
    this.project.importance = EnumProjectImportance.Normal;

    if (data.project) {
      this.project = cloneDeep(data.project);
    }

    this.projTypeList$ = this.projectTypeService.list({params:{limit: 1000}}).subscribe(res => {
      this.projectTypes = res.results;
    });
  }

  ngOnInit() {

  }

  setTreeData() {
    let actionTree = this.projectService.getActionTree(this.actions);
    // mat-tree expect an array
    this.dataSource.data = (actionTree) ? [actionTree] : null;

    this.openAllAction();
  }
  

  saveProject() {
    (this.project.id) ? this.updateProject() : this.createProject();
  }

  createProject() {
    this.projCreate$ = this.projectService.create(this.project).subscribe(res => {
      this.project = res;

      this.onChangeProject.emit(this.project);
    })
  }

  updateProject() {
    this.projUpdate$ = this.projectService.update(this.project).subscribe(res => {
      this.project = res;

      this.onChangeProject.emit(this.project);
    })
  }

  deleteProject() {
    this.projDel$ = this.projectService.delete(this.project).subscribe(() => {
      this.onChangeProject.emit(null);

      this.router.navigate(['/app/projects/']);

      this.dialogRef.close();
    })
  }

  closeDlg() {
    this.dialogRef.close();
  }

  // Actions

  fetchActions() {
     // list action for Project
     let options = {params: {project: this.project.id}}
     this.actList$ = this.actionService.list(options).subscribe(resp => {
       this.actions = resp;

       this.setTreeData();
     })
  }

  addChildAction(action) {
    // add new child action to the selected action
    this.openActionPlanSearch(action);
  }

  addFirstAction() {
    this.openActionPlanSearch(null);
  }

  setActionName(action, name) {
    let a = this.actions.find(a => a.id == action.id);
    a.name = name;
  }

openActionPlanSearch(parentAction) {
    // get the actionPlan
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = null;

    const dialogRef = this.dialog.open(DlgSearchPlanActionComponent, dialogConfig);
    this.dlgPlanActSearch$ = dialogRef.componentInstance.onClose.subscribe(actionPlan => {
      this.createAction(actionPlan, parentAction)
    });
  }

  createAction(actionPlan, parentAction) {
    // based on actionPlan create an Action and add it as a last to the list
    let action = this.modelFactory.getNewModel(Action);
    action.id = this.getNextId();
    action.project = this.project.id;
    action._project = this.project;
    action.name = actionPlan.name;
    action.order = this.actions.length + 1;
    action.action_template = actionPlan.id;
    action._actionTemplate = actionPlan;
    // add parent
    if (parentAction) {
      action.parent = parentAction.id;
      action._parent = parentAction;
    }

    this.actions.push(action);

    this.saveActions();
  }

  saveActions() {
    this.actSave$ = this.actionService.save(this.project.id, this.actions).subscribe(actions => {
      this.actions = actions;
      this.setTreeData();
      this.onChangeActions.emit(this.actions);
    })
  }


  deleteAction(action) {
    this.actions = this.actions.filter(a => a.id != action.id);
    
    this.setTreeData();

    // this is just a signal that the project is changed
    this.onChangeActions.emit(this.actions);
  }

  moveUpAction(_newParent) {
    // get the original actions
    let newParent =  this.actions.find(a => a.id == _newParent.id);
    let originalParent =  this.actions.find(a => a.id == newParent.parent);

    // set parent's parent  id on this for the new parent
    newParent.parent = originalParent.parent;
    newParent._parent = cloneDeep(originalParent._parent); // copied by reference and we swapt them...

    // set the new parent for the original parent
    originalParent.parent = newParent.id;
    originalParent._parent = cloneDeep(newParent); // copied by reference and we swapt them...

    // 3. replace other nodes originalParents with the newParent
    let childsOfOriginalParent = this.actions.filter(a => a.parent == originalParent.id && a.id != newParent.id);
    childsOfOriginalParent.forEach(a => {
      a.parent = newParent.id
      a._parent = cloneDeep(newParent); // copied by reference and we swapt them...
    });

    // 4. set the childs of the new parent to the original parent
    let childsOfNewParent = this.actions.filter(a => a.parent == newParent.id && a.id != originalParent.id);
    childsOfNewParent.forEach(a => {
      a.parent = originalParent.id
      a._parent = originalParent
    });

    this.setTreeData();
  }

  moveLeftAction(_action) {
    /** move the action to the same lavel as the parent action, 
     * if no parent action stick it to the root
     */
    let action =  this.actions.find(a => a.id == _action.id);

    if (!action.parent) {
      return
    }

    let parentAction = this.actions.find(a => a.id == action.parent);

    if (parentAction.parent) {
      action.parent = parentAction.parent;
      action._parent = parentAction._parent;
    }

    this.setTreeData();
  }

  openTemplateSearchDlg() {
    const dialogConfig = new MatDialogConfig();
    
    // current company name
    let name = (this.project.project_template) ? this.project._projectTemplate.name : '';
    
    dialogConfig.data = {
      name: name,
      context: 'project'
    };

    const dialogRef = this.dialog.open(DlgSearchProjectTemplateComponent, dialogConfig);

    this.dlgSearchProjTemp$ = dialogRef.afterClosed().subscribe(project => {
      if (project) {
        this.project.project_template = project.id;
        this.project._projectTemplate = project;
        this.project.project_type = project.project_type;
        this.project._projectType = project._projectType;
      } else if (project == '') {
        this.project.project_template = null;
        this.project._projectTemplate = null;
        this.project.project_type = null;
        this.project._projectType = null;
      }
    });
  }

  setOrg(org) {
    if (org) {
      this.project.organization = org.id;
      this.project._organization = org;
    } else {
      this.project.organization = null;
      this.project._organization = null;
    }
  }

  setPartner(partner) {
    if (partner) {
      this.project.partner = partner.id;
      this.project._partner = partner;
    } else {
      this.project.partner = null;
      this.project._partner = null;
    }
  }

  setAssginedUser(user) {
    if (user) {
      this.project.assignee = user.id;
      this.project._assignee = user;
    } else {
      this.project.assignee = null;
      this.project._assignee = null;
    }
  }

  
  onTabClick(event) {
    if (event.tab.textLabel.toLowerCase().startsWith('lépés')) {
      if (this.project.id) {
        this.fetchActions();
      }
    }  
  }

  openAllAction() {
    // opena all node when start
    // hack to make it work:  https://github.com/angular/components/issues/12469
    setTimeout(() => {
      if (this.dataSource.data) {
        this.treeControl.dataNodes = this.dataSource.data;
        this.tree.treeControl.expandAll();
      }
    }, 200)
  }

}
