import { Pipe, PipeTransform } from '@angular/core';

// from a datetime string keep only the ISO date part
//2019-10-07T09:43:31Z => 2019-10-07
@Pipe({name: 'isoDate'})
export class IsoDate implements PipeTransform {
  transform(value: string): string {
    let newStr: string = value.substring(0,10); 
    return newStr;
  }
}