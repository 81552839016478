import { Pipe, PipeTransform } from '@angular/core';

// full formatted date time
@Pipe({name: 'eur'})
export class EurPipe implements PipeTransform {
    transform(eur: number): string {
        if (!eur) eur=0;
        
        // Todo: why? no  € added to the string?
        const money = new Intl.NumberFormat('hu-HU', { 
            currency: 'EUR',
            //currencyDisplay: 'narrowSymbol',
            minimumFractionDigits: 0,
            maximumFractionDigits: 1
        });
        
        return  '€ ' + money.format(eur);
    }
}
