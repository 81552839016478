<div class="flex">
    <div class="full"><h3>{{data.lastName}} {{data.firstName}}</h3></div>
    <div> 
      <button color="primary" mat-mini-fab  (click)="edit()">
        <mat-icon>open_in_new</mat-icon>
      </button>
    </div>
</div>

<table class="table table-striped">
    <tbody>
    <ng-container *ngIf="data.emails">
        <ng-container *ngFor="let email of data.emails">
            <tr>
                <td><mat-icon>email</mat-icon></td>
                <td> {{email.email}}</td>
                <td>{{email.name}}</td>
            </tr>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="data.phones">
        <ng-container *ngFor="let phone of data.phones">
            <tr>
                <td> <mat-icon>phone</mat-icon></td>
                <td>{{phone.phone}}</td>
                <td>{{phone.name}}</td>
            </tr>
        </ng-container>
    </ng-container>
    </tbody>    
</table>
