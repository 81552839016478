import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlParamService } from '../services/urlParam.service';
import { BaseList } from '../common/baseClasses/baseList';
import { PlanningProjectService } from 'src/app/services/projects/planningProject.service';
import { ProjectType } from '../models/projects/projectType.model';
import { ProjectTypeService } from '../services/projects/projectType.service';
import { AutoUnsub } from '../utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-project-plans',
  templateUrl: './project-plans.component.html',
  styleUrls: ['./project-plans.component.scss'],
  providers: [UrlParamService]
})
export class ProjectPlansComponent extends BaseList implements OnInit  {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['name', 'type', 'description'];

  archived = false;
  project_type = null;

  projectTypes: ProjectType[] = null;

  typeList$;
  planList$;

  constructor(
    private planningProjectService: PlanningProjectService,
    private projectTypeService: ProjectTypeService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService
  ) {
    super()

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit() {
    let params = {
      limit: 1000,
      // add context becuawe types are a different module (project)
      context: 'projectplanning'
    }

    this.typeList$ = this.projectTypeService.list({params}).subscribe(res => {
      this.projectTypes = res.results;
      this.doGetInitalOptions();
    })
  }

  doGetInitalOptions() {
    let defaultParams = {archived: this.archived}

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      }

      this.planList$ = this.planningProjectService.list(options).subscribe(data => {
        this.setListResponseData(data);
       });
    })
  }

  open(entity) {
    this.router.navigate(['project'],  {
      queryParams: {id: entity.id}, 
      relativeTo: this.route  
    });
  }

  changeStatus(event) {
    this.resetFilters();

    // active or archived
    this.archived = (event.value == 'archived') ? true : false;
    
    let param = {archived: this.archived};   
    this.urlParamService.update(param);
    this.urlParamService.set();
  }

  changeType(projectType) {
    this.resetFilters();

    let param = {project_type: null}; 
    
    this.project_type = null;
    
    if (projectType) {
      this.project_type = projectType;
      param = {project_type: projectType.id};   
    }
 
    this.urlParamService.update(param);
    this.urlParamService.set();
  }

}
