import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from "@angular/router";
import { Product } from 'src/app/models/product.model';
import { ProductService } from 'src/app/services/product.service';
import { DlgEditManufacturerComponent } from 'src/app/shared/dialogs/edit/dlg-edit-manufacturer/dlg-edit-manufacturer.component';
import { DlgEditCategoryComponent } from 'src/app/shared/dialogs/edit/dlg-edit-category/dlg-edit-category.component';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { DlgWysiwygComponent } from 'src/app/shared/dialogs/dlg-wysiwyg/dlg-wysiwyg.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-page-product',
  templateUrl: './page-product.component.html',
  styleUrls: ['./page-product.component.scss']
})
export class PageProductComponent extends BaseEntity implements OnInit {
  product: Product = null;

  prodGet$;
  dlgManEdit$;
  dlgCatCreate$;
  prodMarHu$;
  prodMarEn$;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private dialog: MatDialog,
    private modelFactory: ModelFactory
  ) {
    super();

    this.product = this.modelFactory.getNewModel(Product)
  }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id')

    if (id) {
      this.prodGet$ = this.productService.get(id).subscribe(p => {
        this.product = p;
      });
    }
  }

  created(entity) {
    this.product = entity;
     //  navigate to the page
     this.router.navigate(['./'],  {
      queryParams: {id: this.product.id}, 
      relativeTo: this.route  
    });
  }

  deleted(event) {
       //  navigate to the page
       this.router.navigate(['../../products'],  {
        relativeTo: this.route  
      });
  }

  updated(entry) {
    //
  }

  createManufactureDlg() {
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.data = null;

    const dialogRef = this.dialog.open(DlgEditManufacturerComponent, dialogConfig);

    this.dlgManEdit$ = dialogRef.afterClosed().subscribe(manufacturer => {});
  }

  createCategoryDlg() {
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.data = null;

    const dialogRef = this.dialog.open(DlgEditCategoryComponent, dialogConfig);

    this.dlgCatCreate$ = dialogRef.afterClosed().subscribe(category => {});
  }

  updateMarketingHu(content) {
    this.product.marketingHu = content;
    this.prodMarHu$ = this.productService.update(this.product).subscribe();
  }

  updateMarketingEn(content) {
    this.product.marketingEn = content;
    this.prodMarEn$ = this.productService.update(this.product).subscribe();
  }




}
