<div class="section">
    <div class="header">
        <div class="title"><span>Falhsznóáló Profil: {{profile.user.firstName}}  {{profile.user.lastName}}</span></div>
    </div>
    <form #f="ngForm" class="form-group">
        <p>
            <mat-form-field appearance="standard">
                <mat-label>Órabér</mat-label>
                <input matInput name="hourlyRate" type="number" [(ngModel)]="profile.hourlyRate" autocomplete="off" required>
                <span matSuffix>Ft</span>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field appearance="standard">
                <mat-label>Munkahelyi Telefon</mat-label>
                <input matInput name="phoneCompany"  [(ngModel)]="profile.phoneCompany" autocomplete="off">
            </mat-form-field>
        </p>

        <p>
            <mat-form-field appearance="standard">
                <mat-label>Mobile Telefon</mat-label>
                <input matInput name="phoneMobile" [(ngModel)]="profile.phonePersonal" autocomplete="off">
            </mat-form-field>
        </p>

        <!--<p>
            <mat-form-field appearance="standard">
                <mat-label>Cím</mat-label>
                <input matInput name="address" [(ngModel)]="profile.address" autocomplete="off" disabled>
            </mat-form-field>
        </p>

        <p>
            <mat-form-field appearance="standard">
                <mat-label>Nyelv</mat-label>
                <input matInput name="language" [(ngModel)]="profile.language" autocomplete="off" disabled>
            </mat-form-field>
        </p>-->

        <p>
            <mat-form-field appearance="standard">
                <mat-label>Időzóna</mat-label>
                <input matInput name="timezone" [(ngModel)]="profile.timezone" autocomplete="off" disabled>
            </mat-form-field>
        </p>
        
        <p>
            <button mat-raised-button  color="primary" [disabled]="f.invalid"  (click)="update()">
                Mentés
            </button>
        </p>
    </form>
</div>

<!-- signature -->
<div class="flex flex-align-item-start">
    <mat-card class="w300">
        <mat-card-header>
            <mat-card-title>
                <span>Felhasználó Aláírása</span>
            </mat-card-title>
            <mat-card-subtitle>
                Altalában kép a generált pdf fájlok végén lesz hozzáadva
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <img src="{{profile.signature}}" class="img-fluid">
        </mat-card-content>
        <mat-card-actions>
            <app-file-upload (onFileSelect)="onSignatureUpload($event)"></app-file-upload>
        </mat-card-actions>
    </mat-card>
</div>
