export class ModuleSecurity {
    module = null;
    user = null;
    entity = null;

    constructor(module, user, entity) {
        this.module = module;
        this.user = user;
        this.entity = entity;
    }

    isAdmin() {
        // true if the user is an org admin
        return this.module.admin
    }

    canRead() {
        if (this.user.admin) {
            return true;
        }

        if (!this.entity.creator.id) {
            return false
        }

        if (this.user.id === this.entity.creator.id) {
            // this created by the user
            return this._canReadSelf()
        } else {
            // created by others
            return this._canReadOthers()
        }
    }

    canEdit() {
        if (this.user.admin) {
            return true;
        }

        if (this.entity.creator) {
            if (this.user.id === this.entity.creator.id) {
                // this created by the user
                return this._canEditSelf()
            } else {
                // created by others
                return this._canEditOthers()
            }
        }  

        // no creator - admins only
        return false;
    }

    _canReadSelf() {
        return this.module.myself > 0;
    }

    _canEditSelf() {
        return this.module.myself > 1;
    }

    _canReadOthers() {
        return this.module.others > 0;
    }

    _canEditOthers() {
        return this.module.others > 1;
    }

}