<div class="flex">
    <div class="full helper-text">Cégnél lévő Raktári Termékek</div>
    <div class="padding-10">
        <button mat-mini-fab color="accent" (click)="add()" matTooltip="Új Termék Hozzáadás">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>



<ng-container *ngFor="let orgItem of orgItems">
    <div class="section">
        <div class="row">
            <div class="col-md-6">
                <app-item-container [item]="orgItem._item"></app-item-container>
                <mat-icon matTooltip="Migrált adat" class="m-l-10 text-yellow" *ngIf="orgItem && orgItem?.migrated">move_down</mat-icon>
            </div>
            <div class="col-md-3">
                <mat-form-field class="w100" appearance="outline"> 
                    <mat-label *ngIf="orgItem._item._product.unitHu; else tmpDefaultQuantity">{{orgItem._item._product.unitHu}}</mat-label>
                    <ng-template #tmpDefaultQuantity>Darab</ng-template>
                    <input matInput type="number" name="amount" [(ngModel)]="orgItem.amount" autocomplete="off">
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <p class="flex">
                    <span class="padding-l-10">
                        <button mat-mini-fab  color="primary" (click)="updateOrgItem(orgItem)"  [disabled]="orgItem.amount < 1" matTooltip="Mentés">
                            <mat-icon>save</mat-icon>
                        </button>
                    </span>
                    <span class="padding-l-10">
                        <button mat-icon-button  color="warn" (click)="deleteOrgItemDlg(orgItem)"  matTooltip="Törlés">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </span>
                    <span class="padding-l-10">
                        <app-quick-log-info [data]="orgItem"></app-quick-log-info>
                    </span>
                </p>     
            </div>
        </div>
        <div>
            <span (click)="fetchBuiltInItems(orgItem)" *ngIf =" (orgItem.builtInItems | typeof) === 'undefined'" class="link text-small">Beépített Termékek</span>
            
            <div class="helper-text" *ngIf="orgItem.builtInItems?.length == 0">Nincs beépített termék</div>
            
            <div *ngIf="orgItem.builtInItems?.length > 0">
                <div class="helper-text">Beépített Termékek:</div>
            </div>
            <ng-container *ngFor="let builtIn of orgItem.builtInItems">
                <div>
                    <span class="badge badge-info" matTooltip="Mennyiség"> {{builtIn.amount}}</span>
                    {{builtIn._item._product.nameHu}}
                    <span class="helper-text text-small" matTooltip="Frissitve">{{builtIn.updated | dateDate}}</span>
                </div>
            </ng-container>
        </div>
        
    </div>
</ng-container>