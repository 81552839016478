<div class="section">
    <div class="header">
        <div class="title full"><span>Előrejelzés</span></div>
        <button mat-flat-button  color="accent" (click)="openProjectProductSearch()"> 
            <mat-icon>add</mat-icon> Projekt Árucikk Csoportok
        </button>
    </div>
    
    <div class="table-responsive" *ngIf="porjectForecasts.length > 0">   
        <table class="table"> 
            <thead>
                <tr>
                    <th>Aktív</th>
                    <th class="w200">Árucikk Cs.</th>
                    <th>Vétel</th>
                    <th>Eladás</th>
                    <th>Inbetr.</th>
                    <th>Transport</th>
                    <th>Schulung</th>
                    <th>Garantie R.</th>
                    <th>Negyedév</th>
                    <th class="w200">Felhasználva</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <ng-container *ngFor="let p of porjectForecasts">
                <tr>
                    <td>
                        <app-static-true-false-icon [value]="p.active"></app-static-true-false-icon>
                    </td>
                    <td class="w50">
                        <app-icon-item-type [itemType]="p._product.type"></app-icon-item-type>
                        <span matTooltip="p._product.nameHu"></span>{{p._product.nameHu  | truncate:4}}
                    </td>
                    <td>
                        <app-currency-container [value]="p.ek_vetel" [currency]="'eur'"></app-currency-container>
                    </td>
                    <td>
                        <app-currency-container [value]="p.vk_eladas" [currency]="'eur'"></app-currency-container>                    
                    </td>
                    <td>
                        <app-currency-container [value]="p.inbetr" [currency]="'eur'"></app-currency-container>                    
                    </td>
                    <td>
                        <app-currency-container [value]="p.transport" [currency]="'eur'"></app-currency-container>
                    </td>
                    <td>
                        <app-currency-container [value]="p.schulung" [currency]="'eur'"></app-currency-container>
                    </td>
                    <td>
                        <app-currency-container [value]="p.garancia" [currency]="'eur'"></app-currency-container>
                    </td>
                    <td>
                        {{p.ev}}-{{p.negyedev}}
                    </td>
                    <td>
                        <ul *ngFor="let p of p.parts" class="text-tiny">
                            <li>{{p._product.nameHu | truncate: 4}} (<span class="text-blue"><app-currency-container [value]="p.cost" [currency]="'eur'"></app-currency-container></span>)
                            </li> 
                        </ul>
                    </td>
                    <td>
                        <div class="flex">
                            <span class="padding-l-10">
                                <button mat-mini-fab  color="primary" (click)="edit(p)" matTooltip="Szerkesztés">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </span>
                            <span class="padding-l-10">
                                <button mat-icon-button  color="warn" (confirm-click)="delete(p)" confirm matTooltip="Töröl">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </span>
                            <ng-container *ngIf="p.id">
                                <span class="padding-l-10">
                                    <app-quick-log-info [data]="p"></app-quick-log-info>           
                                </span>
                            </ng-container>
                        </div>   
                    </td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td colspan="99">
                        <table class="table wAuto">
                            <tr>
                                <td>Odds</td>
                                <td><span class="badge badge-warning">{{p.esely | formatPercent}}</span></td>
                            </tr>
                            <tr>
                                <td>Summe Kosten</td>
                                <td>
                                    <app-currency-container [value]="p.sumKosten" [currency]="'eur'"></app-currency-container>
                                </td>
                            </tr>
                            <tr>
                                <td>Gemeinkosten</td>
                                <td>
                                    <app-currency-container [value]="p.gemeinKosten" [currency]="'eur'"></app-currency-container>
                                    &nbsp;<span class="helper-text">({{p.gemeinpercent | formatPercent}})</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Provision</td>
                                <td>
                                    <app-currency-container [value]="p.gemeinKosten" [currency]="'eur'"></app-currency-container>
                                    &nbsp;<span class="helper-text">({{p.vorabgewinn | formatPercent}})</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Gewinnauf. ICH</td>
                                <td>
                                    <app-currency-container [isColor]="true" [value]="p.gewinnAuf" [currency]="'eur'"></app-currency-container>
                                    &nbsp;<span class="helper-text">({{p.gweinnAufPerc | formatPercent}})</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Gewinnauf. Brutto</td>
                                <td>
                                    <app-currency-container [isColor]="true" [value]="p.gewinnBrutto" [currency]="'eur'"></app-currency-container>
                                    &nbsp;<span class="helper-text">({{p.gewinnBurttoPerc | formatPercent}})</span>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>

    <div class="padding-10-0 margin-10-0">
        <button mat-mini-fab  color="accent" (click)="openProductSearchDlg()"> 
            <mat-icon>add</mat-icon>
        </button>
    </div>



</div>
