export enum EnumFileUploadType {
    // company
    CompanyPdfHeader = 'company_pdf_header',
    CompanyLogo = 'company_logo',
    CompanyStamp = 'company_stamp',
    // user,profile
    ProfileSignature = 'profile_signature',
    // product
    ProductImage = 'product_image',
    ProductDocument = 'product_document',
    // TaskDoc
    TaskDoc = 'task_doc'
  }