<div class="section">
    <div class="header">
        <div class="title"><span>Megrendelés a Vevőtől</span></div>
    </div>

 <form #f="ngForm" *ngIf="taskCustomerOrder">
        <mat-form-field appearance="outline">
            <mat-label>Státusz</mat-label>
            <mat-select name="status" [(ngModel)]="taskCustomerOrder.status" (selectionChange)="save()">
                <mat-option [value]="enumTaskOrderStatus.WaitingResponse">Válaszra várunk</mat-option>
                <mat-option [value]="enumTaskOrderStatus.KeepInTouch">Kapcsolattartás</mat-option>
                <mat-option [value]="enumTaskOrderStatus.BoughtSomebodyElse">Mástól vett</mat-option>
                <mat-option [value]="enumTaskOrderStatus.NotNow">Nem aktuális</mat-option>
                <mat-option [value]="enumTaskOrderStatus.Sold">Eladva</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="padding-20-0">
                <!--<button mat-mini-fab  color="primary" (click)="save()" matTooltip="Mentés">
                    <mat-icon>save</mat-icon>
                </button>-->
                <span class="padding-l-10">
                    <app-quick-log-info [data]="taskCustomerOrder"></app-quick-log-info>
                </span>
        </div>
    </form>
</div>

