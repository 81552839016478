import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Organisation } from 'src/app/models/organisation';
import { People } from 'src/app/models/people.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';

@Component({
  selector: 'app-dlg-edit-person',
  templateUrl: './dlg-edit-person.component.html',
  styleUrls: ['./dlg-edit-person.component.scss']
})
export class DlgEditPersonComponent implements OnInit {
  people: People = new People(null);
  organisation: Organisation = null;

  showTabs = false;

  @Output() onChange: EventEmitter<People> = new EventEmitter();

  constructor(
    private modelFactory: ModelFactory,
    public dialogRef: MatDialogRef<DlgEditPersonComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data.people) {
      this.people = data.people;
      this.showTabs = true;
    } else {
      this.people = this.modelFactory.getNewModel(People);
    }
   
    this.organisation = data.organisation
  }

  ngOnInit() {
  }

  created(people) {
    if (people.id !== this.people.id) {
      this.people.id = people.id;
      this.showTabs = true;
    }

    this.onChange.emit(people);
  }

  updated(people) {
    //this.closeDlg(people);
    this.onChange.emit(people);
  }

  deleted(event) {
    this.onChange.emit(null);
  }
}
