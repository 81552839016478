import { AuthService } from "./auth.service";

/**
 * THIS SERVICE RUN WHEN THE APP LOAD (Page Reload)
 * If there is a token try to grab the user and the access level
 * so when the app start we have this information to work with
 * @param authService 
 */

export function initApp(authService: AuthService) {
    return () => {
        return authService.fetchUser().toPromise().then(
            user => {authService.user$.next(user)}
        ).then(() => {
            return authService.fetchAccessLevels().toPromise().then(
                accessLevels => {authService.accessLevel$.next(accessLevels)}
            )
        }).catch((e) => {
            // no stored token to grab the User, let's login
            console.log('Init App Error', e)
        })
    }
}