export class ActionEvent {
    id: Number = null;
    eventType: string = null;
    employee: number = null;
    _employee: any = null;
    note: string = null;
    action: number = null;
    _action: any = null;


    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 
    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.employee  = data.employee;
            this.action = data.action
            this.eventType = data.eventType;
            this.note = data.note;

            if ('_employee' in data) {
                this._employee = data._employee;
            }

            if ('_action' in data) {
                this._action = data._action;
            }
            
            if ('creator' in data) {
                this.creator = data.creator;
            }    
            
            if ('lastModifier' in data) {
                this.lastModifier = data.lastModifier;
            }    
            
            if ('created' in data) {
                this.created = new Date(data.created);
            }    
            
            if ('updated' in data) {
                this.updated = new Date(data.updated);
            }

        }  
    }
  }
