<div class="section">
    <div class="header">
        <div class="title">
            <span>Email ({{emails.length}})</span>
        </div>
    </div>

    <div *ngFor="let email of emails">
            <form class="from-group-inline" #f="ngForm">
                <p>
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" name="name" [(ngModel)]="email.email" autocomplete="off" required email>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline">
                        <mat-label>Megjegyzés</mat-label>
                        <input matInput name="industry" [(ngModel)]="email.name" autocomplete="off" required>
                    </mat-form-field>
                </p>
                <p class="flex">
                    <span class="padding-l-10">
                        <button mat-mini-fab  color="primary" (click)="save(email)"  [disabled]="f.invalid" matTooltip="Mentés">
                            <mat-icon>save</mat-icon>
                        </button>
                    </span>
                    <span class="padding-l-10">
                        <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete(email)" confirm>
                            <mat-icon>delete</mat-icon>
                        </button>
                    </span>
                    <span class="padding-l-10">
                        <app-quick-log-info [data]="email"></app-quick-log-info>
                    </span>
                </p>     
            </form>          
    </div>   
    <div class="padding-20-0">
        <button mat-mini-fab color="accent" (click)="add()" matTooltip="Új Email">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
