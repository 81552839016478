<div mat-dialog-title>
    <div class="flex">
        <div class="full">{{project.name | truncate:5}}</div>
            <span [mat-dialog-close]>
                <mat-icon class="text-red">close</mat-icon>
            </span>
        </div>
    </div>

<ng-container *ngIf="taskProducts.length > 0; else noProducts">  
    <mat-dialog-content>
        <div class="helper-text text-tiny">Feladatok</div>
        <mat-accordion>
            <ng-container *ngFor="let taskProduct of taskProducts">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title matTooltip=" {{taskProduct._task.name}}">
                            <input type="checkbox"
                            matTooltip="Az összes termék kiválasztása"
                            (click)="selectedAllTaskProduct($event, taskProduct)">
                            &nbsp;{{taskProduct._task.name |truncate:8}} ({{taskProduct.products.length}})
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <table class="table table-condensed">
                        <tr *ngFor="let tp of taskProduct.products" [ngClass]="(tp.active) ? '' : 'bg-light-grey'">
                            <td>
                                <mat-checkbox (change)="selectTaskProduct(tp)" [checked]="selectedTaskProductIds.includes(tp.id)"></mat-checkbox>
                            </td>
                            <td>
                                <app-item-container [item]="tp._item" *ngIf="tp._item; else tmpTpProduct"></app-item-container>
                                <ng-template #tmpTpProduct>
                                    <app-product-container [product]="tp._product"></app-product-container>
                                </ng-template>    
                            </td>
                            <td>
                                <mat-form-field *ngIf="tp.active" appearance="outline" class="w100">
                                    <mat-label class="capitalize">
                                        <ng-container *ngIf="tp._product.unitHu; else tmpDefaultUnit">{{tp._product.unitHu}}</ng-container>
                                        <ng-template #tmpDefaultUnit>Darab</ng-template>
                                    </mat-label>
                                    <input matInput type="number" name="name" [(ngModel)]="tp.amount" autocomplete="off" required>
                                </mat-form-field>
                            </td>
                        </tr>
                    </table>

                </mat-expansion-panel>
            </ng-container>
        </mat-accordion>
    </mat-dialog-content> 

    <div mat-dialog-actions>
        <button mat-flat-button  color="primary" (click)="save()" [disabled]="selectedTaskProducts.length === 0">
            Mentés ({{selectedTaskProducts.length}})
        </button>
    </div>
 </ng-container>

 <ng-template #noProducts>
     <div class="text-center helper-text">A Projekt feladatokhoz nem tartoznak Termék</div>
 </ng-template>
   


