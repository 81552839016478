<mat-form-field appearance="outline">
    <mat-label>Fontosság</mat-label>
    <mat-select name="status"  [(ngModel)]="importance"  (selectionChange)="update()">
        <ng-container *ngIf="showAll">
            <mat-option [value]="">Összes</mat-option>
        </ng-container>
        <mat-option [value]="enumTaskImportance.SuperHigh">Nagyon Fontos</mat-option>
        <mat-option [value]="enumTaskImportance.High">Fontos</mat-option>
        <mat-option [value]="enumTaskImportance.Normal">Normal</mat-option>
        <mat-option [value]="enumTaskImportance.Low">Nem Fontos</mat-option>
        <mat-option [value]="enumTaskImportance.VeryLow">Mellékes</mat-option>
    </mat-select>
    <mat-icon matPrefix [class.super-heigh]="importance == 5" *ngIf="importance == 5">brightness_1</mat-icon>
    <mat-icon matPrefix [class.heigh]="importance == 4" *ngIf="importance == 4">brightness_1</mat-icon>
    <mat-icon matPrefix [class.normal]="importance == 3" *ngIf="importance == 3">brightness_1</mat-icon>
    <mat-icon matPrefix [class.low]="importance == 2" *ngIf="importance == 2">brightness_1</mat-icon>
    <mat-icon matPrefix [class.veryLow]="importance == 1" *ngIf="importance == 1">brightness_1</mat-icon>
</mat-form-field>

