import { Pipe, PipeTransform } from '@angular/core';
import { number } from 'echarts';

@Pipe({name: 'formatPercent'})
export class FormatPercentPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) {
        return '';
    }
    return value.toFixed(1) + '%';
  }
}