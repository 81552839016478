<ul class="breadcrumb"> 
    <li>Raktári Termékek</li>  
</ul>

<div class="flex block-md"> 
    <div class="flex">
        <button (click)="lookup()" mat-mini-fab color="primary" [disabled]="txtSearch === ''">
            <mat-icon matSuffix>search</mat-icon>
        </button>
        <div class="padding-l-10">
            <mat-form-field appearance="standard">
                <mat-label>Keresés</mat-label>
                <input [(ngModel)]="txtSearch" matInput placeholder="?" (keyup.enter)="lookup()">
                <mat-icon  class="pointer" matTooltip="Keresés Törlése" (click)="noSearch()" matSuffix>close</mat-icon>
            </mat-form-field>
        </div>
    </div>
    <div class="padding-l-10">
        <mat-radio-group (change)="changeType($event)">
            <mat-radio-button [checked]="productType === 'machine'" value="machine">Gép</mat-radio-button>
            <mat-radio-button [checked]="productType === 'part'" value="part">Alkatrész</mat-radio-button>
            <mat-radio-button [checked]="productType === 'service'" value="service">Szolgáltatás</mat-radio-button>
          </mat-radio-group>
    </div>
    
    <div class="full text-right padding-10-0">
        <span>
            <button mat-mini-fab  color="accent" [routerLink]="['item']"  matTooltip="Új Termék"> 
                <mat-icon>add</mat-icon>
            </button>
        </span>
    </div> 
</div>

<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="manufacturer">
        <mat-header-cell *matHeaderCellDef>Gyártó</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row._product.manufacturer">
                <img src="{{row._product._manufacturer.imageUrl}}" loading="lazy">
            </ng-container>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="nameHu">
        <mat-header-cell *matHeaderCellDef>Termék</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="link margin-r-10" (click)="open(row)">
                <app-item-container [item]="row"></app-item-container>
            </span>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
    [showFirstLastButtons]="true"
></mat-paginator>
