import {Pipe, PipeTransform} from '@angular/core';
// usage in the template *ngIf = (item | typeof) === 'number'
@Pipe({
  name: 'typeof'
})
export class TypeofPipe implements PipeTransform {

  transform(value: any): any {
    return typeof value;
  }

}