<div class="helper-text padding-20-0">
    Aktív Feladataid
</div>
<div class="table-responsive">
<table class="table table-striped" *ngIf="tasks && tasks.length > 0">
    <thead>
      <tr>
        <th class="w50">Aktív</th>
        <th class="w50">Fontosság</th>
        <th class="w200">Típus</th>
        <th>Feladat</th>
      </tr>
</thead>
    <tbody>
      <tr *ngFor="let task of tasks">
        <td>
            <app-static-true-false-icon [value]="task.open"></app-static-true-false-icon>
        </td>
        <td>
            <app-importance-container [importance]="task.importance"></app-importance-container>
        </td>
        <td>
            <div class="text-yellow">
                <app-task-type-container [type]="task.taskType"></app-task-type-container>
            </div>
           
        </td>
        <td>
            <div class="flex">
                <app-status-container [status]="task.status" [showLabel]="false"></app-status-container>
                <span class="link" (click)="openTask(task)" matTooltip="{{task.name}}">{{task.name | truncate: 5}} </span>
            </div>
        </td>
      </tr>
    </tbody>
</table> 
</div>

<ng-container *ngIf="tasks && tasks.length == 0">Nincs Feladatod</ng-container>

<br><br>

