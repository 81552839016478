<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Lépés Terv</a></li> 
    <ng-container *ngIf="actionPlan.name">
        <li>{{actionPlan.name}}</li>  
    </ng-container> 
</ul>
<div class="section">
    <div class="header">
        <div class="title"><span>Lépés</span></div>
    </div>
    <app-action-plan [actionPlan]=actionPlan></app-action-plan>
</div>

