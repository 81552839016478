<div class="row">
    <div class="col-6">
        <div class="text-bold text-blue padding-20-0 flex">
            <div class="full flex">
                <mat-icon>receipt</mat-icon>
                <span>Számlázási cím</span>
            </div>  
        </div>
        <p>
            <app-country-selector [country]="enumCountry.Hungary" (eventSelect)="defaultConfig.invoiceCountry=$event"></app-country-selector>
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Irányító szám</mat-label>
                <input matInput name="postcode" [(ngModel)]="defaultConfig.invoicePostcode" autocomplete="off">
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Város</mat-label>
                <input matInput name="city" [(ngModel)]="defaultConfig.invoiceCity" autocomplete="off">
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Cím</mat-label>
                <input matInput name="line" [(ngModel)]="defaultConfig.invoiceLine" autocomplete="off">
            </mat-form-field>
        </p>
    </div>
    <div class="col-6">
        <div class="text-bold text-blue padding-20-0 flex">
            <div class="full flex">
                <mat-icon>location_on</mat-icon>
                <span>Szállító Cím</span>
            </div>             
        </div>
        <p>
            <app-country-selector [country]="enumCountry.Hungary" (eventSelect)="defaultConfig.deliveryCountry=$event"></app-country-selector>
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Irányító szám</mat-label>
                <input matInput name="topostcode" [(ngModel)]="defaultConfig.deliveryPostcode" autocomplete="off">
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Város</mat-label>
                <input matInput name="tocity" [(ngModel)]="defaultConfig.deliveryCity" autocomplete="off">
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Cím</mat-label>
                <input matInput name="toline" [(ngModel)]="defaultConfig.deliveryLine" autocomplete="off">
            </mat-form-field>
        </p>
    </div>
</div>
