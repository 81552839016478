<div clas="flex">
    <mat-form-field appearance="outline" class="w300">
        <mat-label>Partner Referencia Száma</mat-label>
        <input matInput name="ref_number" [(ngModel)]="partnerRefNumber" (keyup)="sendChange()"  autocomplete="off" >
        <mat-icon matSuffix matTooltip="Általunk hivatkozott a partner által előzöleg küldött dokumentum referencia száma">info</mat-icon>
    </mat-form-field>
    
    <ng-container *ngIf="task.action">
        <button [matMenuTriggerFor]="partnerRefList" mat-mini-fab class="margin-10" color="primary" matTooltip="Keresés a Projek dokumentumokban">
            <mat-icon>search</mat-icon>
        </button>
        <mat-menu #partnerRefList="matMenu" style="width: 350px;">
            <div *ngFor="let taskDoc of taskDocs" class="padding-10 cursor" (click)="select(taskDoc)">
                <div class="text-yellow text-tiny">
                    <app-task-type-container [type]="taskDoc._task.taskType"></app-task-type-container>
                </div>

                <div class="helper-text">{{taskDoc.created | dateTime | isoDate }}</div>
                
                <div class="text-tiny">{{taskDoc.file | fileNameFromPath | slice:0:40}}</div>
                
                <ng-container *ngIf="taskDoc.docRefNumber; else tmpNoRef">
                    <div><span class="ref-number">{{taskDoc.docRefNumber}}</span></div>
                </ng-container>
                <ng-template #tmpNoRef><div class="text-small text-red">Nincs Referencia szám</div></ng-template>
            </div>
          </mat-menu>
    </ng-container>
</div>
