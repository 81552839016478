import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationService } from './notification';
import { Utils } from '../shared/utils';
import { Product, ProductRelatedOffers, ProductAttribute, ProductImage, ProductDocument } from '../models/product.model';

@Injectable({
    providedIn: 'root'
  })
export class ProductService {
    baseUrl = environment.baseUrl;

    URL_PRODUCTS = '/products/';
    URL_RELATED_OFFERS = '/products/related-offers/';
    URL_PRODUCT_ATTRIBUTES = '/products/attributes/';
    URL_PRODUCT_IMAGES = '/products/images/';
    URL_PRODUCT_IMAGES_UPLOAD = '/products/images/upload/';
    URL_PRODUCT_DOCUMENTS = '/products/documents/';
    URL_PRODUCT_DOCUMENTS_UPLOAD = '/products/documents/upload';

    // list items
    dataSource$ = new BehaviorSubject<any[]>([])
    data = this.dataSource$.asObservable();

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {}

    list(options=null) {
           // list all the contacts
        // @params: obj of params
        // @url: full url with params (next and prev for pagination)
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL_PRODUCTS;
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }

        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(i => new Product(i));
                return res;
            })
        )
    }

    get(id) {
        let url = this.baseUrl + this.URL_PRODUCTS + id + '/';
        return this.http.get<Product>(url).pipe(
            map(res => new Product(res))
        )
    }

    create(payload) {
        let url = this.baseUrl + this.URL_PRODUCTS;
        return this.http.post(url, payload).pipe(
            map(result => new Product(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    update(payload) {
        let url = this.baseUrl + this.URL_PRODUCTS + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new Product(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    delete(payload) {
        let url = this.baseUrl + this.URL_PRODUCTS + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

    /** KIAJANLHATO - Related Offers */

    getRelatedOffers(productId) {
        let url = this.baseUrl + this.URL_RELATED_OFFERS;

        let params = {
            product: productId,
            limit: '1000'
        }

        return this.http.get<any>(url, {params: params}).pipe(
            map(res => {
                res.results = res.results.map(i => new ProductRelatedOffers(i));
                return res.results;
            })
        )
    }

    createRelatedOffer(payload) {
        let url = this.baseUrl + this.URL_RELATED_OFFERS;
        return this.http.post(url, payload).pipe(
            map(result => new ProductRelatedOffers(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    updateRelatedOffer(payload) {
        let url = this.baseUrl + this.URL_RELATED_OFFERS + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new ProductRelatedOffers(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    deleteRelatedOffer(payload) {
        let url = this.baseUrl + this.URL_RELATED_OFFERS + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

    /** Attributes */

    getProductAttributes(productId) {
        let url = this.baseUrl + this.URL_PRODUCT_ATTRIBUTES;

        let params = {
            product: productId,
            limit: '1000'
        }

        return this.http.get<any>(url, {params: params}).pipe(
            map(res => {
                res.results = res.results.map(i => new ProductAttribute(i));
                return res.results;
            })
        )
    }

    createProductAttribute(payload) {
        let url = this.baseUrl + this.URL_PRODUCT_ATTRIBUTES;
        return this.http.post(url, payload).pipe(
            map(result => new ProductAttribute(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    updateProductAttribute(payload) {
        let url = this.baseUrl + this.URL_PRODUCT_ATTRIBUTES + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new ProductAttribute(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    deleteProductAttribute(payload) {
        let url = this.baseUrl + this.URL_PRODUCT_ATTRIBUTES + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

    /** Images */

    getProductImages(productId) {
        let url = this.baseUrl + this.URL_PRODUCT_IMAGES;

        let params = {
            product: productId,
            limit: '1000'
        }

        return this.http.get<any>(url, {params: params}).pipe(
            map(res => {
                res.results = res.results.map(i => new ProductImage(i));
                return res.results;
            })
        )
    }

    createProductImage(payload) {
        let url = this.baseUrl + this.URL_PRODUCT_IMAGES;
        return this.http.post(url, payload).pipe(
            map(result => new ProductImage(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    updateProductImage(payload) {
        let url = this.baseUrl + this.URL_PRODUCT_IMAGES + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new ProductImage(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    deleteProductImage(payload) {
        let url = this.baseUrl + this.URL_PRODUCT_IMAGES + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

    /** Documents */

    getProductDocuments(productId) {
        let url = this.baseUrl + this.URL_PRODUCT_DOCUMENTS;

        let params = {
            product: productId,
            limit: '1000'
        }

        return this.http.get<any>(url, {params: params}).pipe(
            map(res => {
                res.results = res.results.map(i => new ProductDocument(i));
                return res.results;
            })
        )
    }

    createProductDocument(payload) {
        let url = this.baseUrl + this.URL_PRODUCT_DOCUMENTS;
        return this.http.post(url, payload).pipe(
            map(result => new ProductDocument(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    updateProductDocument(payload) {
        let url = this.baseUrl + this.URL_PRODUCT_DOCUMENTS + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new ProductDocument(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    deleteProductDocument(payload) {
        let url = this.baseUrl + this.URL_PRODUCT_DOCUMENTS + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }
}