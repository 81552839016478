import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification';
import { UsersService } from 'src/app/services/users.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  token: string;

  paramGet$;
  verify$;

  constructor(
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private userService: UsersService,
    private router: Router,
  ) {
    this.paramGet$ = this.activatedRoute.queryParams.subscribe(params => {
        this.token = params['token'];

        this.verify()
    });
  }

  ngOnInit(): void {
  }

  verify() {
    if (!this.token) {
      return;
    }


    this.verify$ = this.userService.verifyEmail(this.token).subscribe(() => {
      this.router.navigate(['app/dash'])
    });
}

}
