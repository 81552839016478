import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EnumTemplateCategory } from 'src/app/enums/templates/templateCategory.enum';
import { EnumTemplateType } from 'src/app/enums/templates/templateType.enum';
import { Template, TemplateSection } from 'src/app/models/template.model';
import { TemplateService } from 'src/app/services/template.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

/**
 * Template selector Component
 * @return the sections with default for the selected templates
 */
@AutoUnsub()
@Component({
  selector: 'app-template-selector',
  templateUrl: './template-selector.component.html',
  styleUrls: ['./template-selector.component.scss']
})
export class TemplateSelectorComponent implements OnInit {

  @Input() category: string | EnumTemplateCategory; // selected template category
  @Input() type: string | EnumTemplateType = EnumTemplateType.Pdf; // selected template category
  EnumTemplateCategory = EnumTemplateCategory;
  EnumTemplateType = EnumTemplateType;

  templates: Template[] = []; // list of tempatles from which is allowed to choose from
  template: Template // selected template
  sections: TemplateSection[] // sections of the Template

  // event to get the selected Template
  @Output() eventTemplate: EventEmitter<Template> = new EventEmitter();
  // event to get the Sections of the Template
  @Output() eventSections: EventEmitter<TemplateSection[]> = new EventEmitter();

  tempList$;
  sectionGet$;

  constructor(
   private templateService: TemplateService
  ) { }

  ngOnInit() {
    if (this.category) {
      // category is selected let't fetch the templates
      this.fetchTemplates();
    }
    
  }

  selectCategory(event) {
     this.category = event;
     this.fetchTemplates();
  }

  selectTemplate(event) {
    let templateId = event.value;
    this.template = this.templates.find(t => t.id === templateId);

    this.eventTemplate.emit(this.template);

    this.fetchSections();
  }

  fetchTemplates() {
    let params = {
      limit: 1000,
      category: this.category,
      type: this.type,
      // need at least read acces for the task
      context: 'task'
    }

    this.tempList$ = this.templateService.list({params}).subscribe(res => {
      this.templates = res.results;
      this.eventTemplate.emit(this.template)
    });
  }

  fetchSections() {
    // need atleast read access to the sections
    this.sectionGet$ = this.templateService.getTemplateSections(this.template.id, 'task').subscribe(res => {
      this.sections = res;
      
      this.eventSections.emit(this.sections);
    })
  }

  unset() {
    this.template = null;
  }

}
