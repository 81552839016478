
export function AutoUnsub() {
    // overwrite the ngOnDestroy function to unsubscribe every module level subscritpion
    return function(constructor) {
        constructor.prototype.ngOnDestroy = function() {
            for(const prop in this) {
                const property = this[prop];
                  if(property && typeof property?.unsubscribe === "function") {
                    property.unsubscribe()
                  }
            }
         
        }
    }
  }