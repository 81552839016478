export class ProjectType {
    id: number = null;
    name: string = null;
    description: string = null;
    color: string = null;
    archived: boolean = false;

    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.name  = data.name;
            this.description = data.description;
            this.color = data.color;
            this.archived = data.archived;

            if ('creator' in data) {
                this.creator = data.creator;
            }

            if ('lastModifier' in data) {
                this.lastModifier = data.lastModifier;
            } 
            
            if ('created' in data) {
                this.created = new Date(data.created);
            }

            if ('updated' in data) {
                this.updated = new Date(data.updated);
            }
        }  
    }
  }
