<div class="forgotten-password-page">
    <mat-card class="login-card">
      <p class="padding-20-0">
        <img src="assets/logo/ich-logo-small.png" alt="IC-Hungary">
      </p>
      <ng-container *ngIf="!emailSent; else done">
        <p class="padding-20-0 text-secondary">
            Ha az email létezik a rendszerben, küldünk egy emailt a megadott email címre, ami segítségével megadható lesz az új jelszó.
        </p>
        <mat-card-content>
          <form class="form" (keydown)="keyDownFunction($event)">
            <table cellspacing="0">
              <tr>
                <td>
                  <mat-form-field class="example-full-width">
                  <input matInput placeholder="Email" [(ngModel)]="email" name="email" required email>
                  </mat-form-field>
                </td>
              </tr>
              </table>
            </form>
          </mat-card-content>
        <mat-card-actions>
          <div class="flex">
            <div class="full">
              <button mat-raised-button (click)="requestNewPassword()" color="primary"  [disabled]="!email">Új jelszó Kérése</button>
            </div> 
            <div>
              <button mat-button [routerLink]="['./../login']" color="secondary">Belepés</button>
            </div>
          </div>
        </mat-card-actions>
    </ng-container> 
    <ng-template #done>
      <div class="flex">
        <div>
            <app-static-true-false-icon [value]="true"></app-static-true-false-icon>
        </div>
        <div>Email elküldve</div>
      </div>
    </ng-template>
  </mat-card>
</div>


