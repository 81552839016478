import { Injectable } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable()
export class UrlParamService {
  // holds all the current params for a Component
  // DO not provide for Root!
  params = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  update(param) {
    // update the current params whith the new one
    // example param: {search: '...'}
    this.params = {...this.params, ...param};

    this.firstPage();
  }

  delete(param) {
    // delete the param form params
    if (param in this.params) {
      delete this.params[param];
    }

    this.firstPage();
  }

  truncate() {
    // unset all the params
    this.params = {};
  }

  firstPage() {
    // set the pagination to the first page
    this.params['offset'] = 0;
  }
  
  setParamsFromUrl(url) {
    // set the current params from the url
    
    let urlQueryString = url.substring(url.indexOf('?')+1);
    let urlParams: any = new URLSearchParams(urlQueryString);

    let params = {};

    for (let p of urlParams.entries()) {
        params[p[0]]=p[1];
      }

    this.params = params
}

setFromUrl(url, handling) {
  // based on an Url (Next, Prev) -> Update the Browser URL with the params
  // quick helper method
  this.setParamsFromUrl(url);

  this.set(handling)
}

set(handling=null) {
    // update the Browser path in the url with the current params
    // handling: merge or '' (overwrite)

    if (!handling) {
      handling = ''; // overwrite
    }

    if (!('offset' in this.params)) {
        this.params['offset'] = 0;
    }

    this.router.navigate([], { 
        relativeTo: this.route,
        queryParamsHandling: handling,
        queryParams: this.params
      });
}

getPageIndex(params=null) {
  // based on the offset and limit value return the pageIndex for pagination
  if (!params) {
    params = this.params;
  }

  if (!('offset' in params)) {
    return 0;
  }

  if (!('limit' in params)) {
    return 0;
  }

  let pageIndex = parseInt(params['offset']) / parseInt(params['limit']);

  return pageIndex;
}

}