import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { EnumTaskTypes } from 'src/app/enums/tasks.enums';
import { OrganisationItem } from 'src/app/models/organisation';
import { Project } from 'src/app/models/projects/project.model';
import { Task } from 'src/app/models/tasks/task.model';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ProjectService } from 'src/app/services/projects/project.service';
import { SecurityService } from 'src/app/services/security.service';
import { TaskService } from 'src/app/services/tasks/task.service';
import { DlgEditTaskComponent } from 'src/app/shared/dialogs/edit/tasks/dlg-edit-task/dlg-edit-task.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent implements OnInit {
  @Input() task: Task;

  isVendor = false;

  showProjects = false;
  showOrgItems = false;
  // open project with the organizastion
  orgProjects: Project[] = [];
  // items of the company
  orgItems: OrganisationItem[] = [];

  // access
  hasAccessProjects = false;


  @Output() onChange: EventEmitter<Task> = new EventEmitter();

  borderColor = '#ffffff';

  dlgTaskEdit$;
  taskUpdate$;
  taskUpdatStatus$;
  projList$;
  orgList$;

  constructor(
    private dialog: MatDialog,
    private taskService: TaskService,
    private projectService: ProjectService,
    private orgService: OrganisationService,
    private ss: SecurityService
  ) {
  }

  ngOnInit() {
    if ([EnumTaskTypes.TaskVendorInterest, EnumTaskTypes.TaskVendorOrder].includes(this.task.taskType as EnumTaskTypes)) {
      // the org is a vendor not a client
      this.isVendor = true;
    }
  }

  setBorderColor() {
    // if project has a color set it for the Task
    let color = null;

    if (this.task._action && this.task._action._project && this.task._action._project._projectType) {
     color = this.task._action._project._projectType.color;
    }

   if (color) {
     this.borderColor = color;
   }
  }

  openTaskEdit() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      task: this.task
    };

    dialogConfig.minWidth = '410px';
    dialogConfig.disableClose =  true;

    const dialogRef = this.dialog.open(DlgEditTaskComponent, dialogConfig);

    this.dlgTaskEdit$ = dialogRef.componentInstance.onChange.subscribe(task => {
      this.task = task;

      this.onChange.emit(this.task);
    }); 
  }

  setState(state) {
    this.task.open = (state === 'open') ? true : false;
    this.taskUpdate$ = this.taskService.update(this.task).subscribe(task => {
      this.task = task;

      this.onChange.emit(this.task);
    });
  }

  setStatus(status) {
    this.task.status = status;

    this.taskUpdatStatus$ = this.taskService.update(this.task).subscribe(res => {
      this.task = res;

      this.onChange.emit(this.task);
    })
  }

  loadProjects() {
    if (!this.hasAccessProjects) return;

    this.showProjects = true;
    this.showOrgItems = false;

    if (!this.task.organization) return;

    let params = {
      organization: this.task.organization,
      open: true,
      limit: 200
    }

    this.projList$ = this.projectService.list({params}).subscribe(projects => {
      this.orgProjects = projects.results;
    })

  }

  loadOrgItems() {
    this.showOrgItems = true;
    this.showProjects = false;

    if (!this.task.organization) return;

    let params = {
      organization: this.task.organization,
      limit: 1000
    }

    this.orgList$ = this.orgService.listItems({params}).subscribe(res => {
      this.orgItems = res.results;
    })

  }
}
