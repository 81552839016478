<ng-container *ngIf="project">
    <ul class="breadcrumb"> 
        <li><a [routerLink]="['../']">Projektek</a></li> 
        <ng-container *ngIf="project">
            <li>{{project.name}}</li>  
        </ng-container> 
    </ul>

    <mat-tab-group animationDuration="0ms" dynamicHeight  (selectedTabChange)="onTabClick($event)">
        <mat-tab label="Részletek"> 
            <app-project-details [project]="project"></app-project-details>
        </mat-tab>
        <mat-tab label="Jegyzetek">
            <ng-container *ngIf="projectNotes && projectNotes.length > 0; else tmpNoProjectNotes">
                <ng-container *ngFor="let taskNote of projectNotes">
                    <div class="section">
                        <div class="flex">
                            <div class="full">
                                <app-avatar [user]="taskNote.lastModifier"></app-avatar>
                            </div>
                            <div class="flex">
                                <div class="padding-10 text-small text-bold bg-light-grey border-radius">
                                    {{taskNote.updated | date:'yyyy-MM-dd H:mm'}}
                                </div>
                            </div>
                        </div>
                        <p class="full margin-l-20 padding-10-0" [innerHtml]="taskNote.note | safeHtml"></p>
                        <div class="flex flex-right">
                            <span class="helper-text">
                                <app-task-type-container [type]="taskNote._task.taskType"></app-task-type-container>
                            </span>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #tmpNoProjectNotes>
                <div class="helper-text">Nincsenek Jegyzetek</div>
            </ng-template>
           
        </mat-tab>
        <mat-tab label="Tennivalók">
            <ng-container  *ngIf="projectEvents && projectEvents.length > 0; else tmpNoProjectEvents">
                <div class="section" *ngFor="let a of projectEvents">
                    <div class="flex">
                        <div class="full">
                            <app-avatar [user]="a._assignee"></app-avatar>
                        </div>
                        <div>
                            <div class="flex" *ngIf="a.start || a.actualStart">
                                <span class="helper-text">Kezdés</span>&nbsp;
                                <span *ngIf="!a.done">{{a.start | date:'yyyy-MM-dd H:mm'}}</span>
                                <span *ngIf="a.done">{{a.actualStart | date:'yyyy-MM-dd H:mm'}}</span>
                            </div>
                            <div class="flex" *ngIf="a.end || a.actualEnd">
                                <span  class="helper-text">Befejezve</span>&nbsp;
                                <span *ngIf="!a.done">{{a.end | date:'yyyy-MM-dd H:mm'}}</span>
                                <span *ngIf="a.done">{{a.actualEnd | date:'yyyy-MM-dd H:mm'}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex">
                        <app-static-true-false-icon [value]="a.done"></app-static-true-false-icon>
                        <span>{{a.name}}</span>
                    </div>
                    <div class="text-red padding-10" matTooltip="{{a.problem}}">{{a.problem}}</div>
                    <div class="text-green padding-10" matTooltip="{{a.comment}}">{{a.comment}}</div>
                    <div class="flex flex-right">
                        <span class="helper-text">
                            <app-task-type-container [type]="a._task.taskType"></app-task-type-container>
                        </span>
                    </div>
                </div>
            </ng-container>
            <ng-template #tmpNoProjectEvents>
                <div class="helper-text">Nincsenek Események</div>
            </ng-template>
        </mat-tab>
      </mat-tab-group>
    
    
</ng-container>

