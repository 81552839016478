<div class="flex" [ngClass]="(!showLabel) ? 'flex-inline' : ''">
    <ng-container *ngIf="status">
        <mat-icon [ngClass]="status" *ngIf="status == enumTaskStatus.NotStarted" matTooltip="Nem kezdődőtt el">brightness_1</mat-icon>
        <mat-icon [ngClass]="status" *ngIf="status == enumTaskStatus.InProgress" matTooltip="Folyamatban">brightness_1</mat-icon>
        <mat-icon [ngClass]="status" *ngIf="status == enumTaskStatus.Blocked" matTooltip="Elakadt">brightness_1</mat-icon>
        <mat-icon [ngClass]="status" *ngIf="status == enumTaskStatus.Done" matTooltip="Befejeződőtt">brightness_1</mat-icon>
        <mat-icon [ngClass]="status" *ngIf="status == enumTaskStatus.Skipped" matTooltip="Átugorva">brightness_1</mat-icon>
        &nbsp;
    </ng-container>
    <span *ngIf="showLabel">
        <ng-container *ngIf="status == enumTaskStatus.NotStarted"> Nem kezdődőtt el</ng-container>
        <ng-container *ngIf="status == enumTaskStatus.InProgress"> Folyamatban</ng-container>
        <ng-container *ngIf="status == enumTaskStatus.Blocked">Elakadt</ng-container>
        <ng-container *ngIf="status == enumTaskStatus.Done">Befejeződőtt</ng-container>
        <ng-container *ngIf="status == enumTaskStatus.Skipped">Átugorva</ng-container>
    </span>
</div>

