import { User } from "./user";

export class Organisation {
    id: number = null;
    name: string = null;
    industry?: string = null;
    taxNumber?: string = null;
    isVendor?: boolean = false;
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    migrated?: boolean;

    email: string = null;
    phone: string = null;
    addressCountry: string  = null;
    addressCity: string = null;
    addressLine1: string = null;
    addressLine2: string = null;
    addressPostcode: string = null;

    assigned_user_1: any;
    _assignedUser1: any;
    assigned_user_2: any;
    _assignedUser2: any;

    language: string = null;

     //extra informaion - email, phone and addresses
     emails? = [];
     phones? = [];
     addresses? = [];

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.name = data.name;
            this.industry = data.industry;
            this.taxNumber = data.taxNumber;
            this.isVendor = data.isVendor;
            this.email = data.email;
            this.phone = data.phone;
            this.addressCountry = data.addressCountry;
            this.addressCity = data.addressCity;
            this.addressLine1 = data.addressLine1;
            this.addressLine2 = data.addressLine2;
            this.addressPostcode = data.addressPostcode;
            this.language = data.language;

            this.assigned_user_1 = data.assigned_user_1;
            if (this.assigned_user_1) {
                this._assignedUser1 = new User(data._assignedUser1);
            }

            this.assigned_user_2 = data.assigned_user_2;
            if (this.assigned_user_2) {
                this._assignedUser2 = new User(data._assignedUser2);
            }

            this.migrated = data.migrated;
            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);

            if ('emails' in data) {
                this.emails = data.emails;
            }
    
            if ('phones' in data) {
                this.phones = data.phones;
            }
    
            if ('addresses' in data) {
                this.addresses = data.addresses;
            }
        }
    }
  }

export class OrganisationEmail {
    id: number | String = null;
    name: String = null;
    email: String = null;
    organization: Number = null;
    created?: Date = null;
    updated?: Date = null;
    creator?: {color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {color: String, email: String, firstName: String, lastName: String} = null;

    constructor(data: any) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.email = data.email;
            this.organization = data.organization;
            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }  
    }

    setOrganisationId(id: number) {
        this.organization = id;
    }

    setId(id: string) {
        // only use newly created data (before we sending it to the server)
        // therefore for security reasons we only allow to passing string (dummy) id
        this.id = id;
    }
}

export class OrganisationPhone {
    id: number | String = null;
    name: String = null;
    phone: String = null;
    organization: Number = null;
    created?: Date = null;
    updated?: Date = null;
    creator?: {color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {color: String, email: String, firstName: String, lastName: String} = null;

    constructor(data: any) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.phone = data.phone;
            this.organization = data.organization;
            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }  
    }

    setOrganisationId(id: number) {
        this.organization = id;
    }

    setId(id: string) {
        // only use newly created data (before we sending it to the server)
        // therefore for security reasons we only allow to passing string (dummygit ) id
        this.id = id;
    }
}

export class OrganisationAddress {
    id: number | String = null;
    name: String = null;
    country: string  = null;
    city: String = null;
    addressLine1: string = null;
    addressLine2?: string = null;
    postcode?: string = null;
    organization: Number = null;
    created?: Date = null;
    updated?: Date = null;
    creator?: {color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {color: String, email: String, firstName: String, lastName: String} = null;

    constructor(data: any) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.organization = data.organization;
            this.country = data.country;
            this.city = data.city;
            this.addressLine1 = data.addressLine1;

            if (data.postcode) {
                this.postcode = data.postcode;
            }

            if (data.addressLine2) {
                this.addressLine2 = data.addressLine2;
            }
            
            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }  
    }

    setOrganisationId(id: number) {
        this.organization = id;
    }

    setId(id: string) {
        // only use newly created data (before we sending it to the server)
        // therefore for security reasons we only allow to passing string (dummygit ) id
        this.id = id;
    }
}

    export class OrganisationItem {
        id: number | String = null;
        organization: number = null;
        _organization?: any = null;
        item: number = null;
        _item?: any = null;

        amount: number;
        
        migrated?: boolean = false;
        created?: Date = null;
        updated?: Date = null;
        creator?: {color: String, email: String, firstName: String, lastName: String} = null;
        lastModifier?:  {color: String, email: String, firstName: String, lastName: String} = null;
    
        constructor(data: any) {
            if (data) {
                this.id = data.id;
                this.organization = data.organization;
                this.item = data.item;
                this.amount = data.amount;
    
                if (data._organization) {
                    this._organization = data._organization;
                }
    
                if (data._item) {
                    this._item = data._item;
                }
                
                this.migrated = data.migrated;
                this.creator = data.creator;
                this.lastModifier = data.lastModifier;
                this.created = new Date(data.created);
                this.updated = new Date(data.updated);
            }  
        }
    
        setOrganisation(id: number, organization?: Organisation) {
            this.organization = id;
            if (organization) {
                this._organization = organization;
            }
        }

        setItemId(id: number) {
            this.item = id;
        }

        setItem(item: any) {
            this._item = item;
            this.setItemId(item.id)
        }
    
        setId(id: string) {
            // only use newly created data (before we sending it to the server)
            // therefore for security reasons we only allow to passing string (dummy id)
            this.id = id;
        }
}