import { Component, OnInit } from '@angular/core';
import { GeneralSettings } from 'src/app/models/generalSettings.model';
import { GeneralSettingsService } from 'src/app/services/generalSettings.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

/** General Settings */
@AutoUnsub()
@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {
  generalSettings: GeneralSettings

  genSettings$;
  genSettingsUpdate$;

  constructor(
    private generalSettingsService: GeneralSettingsService
  ) {
    this.genSettings$ = this.generalSettingsService.get().subscribe(res => {
     this.generalSettings = res;
    });
  }

  ngOnInit() {
  }

  save() {
    this.genSettingsUpdate$ = this.generalSettingsService.update(this.generalSettings).subscribe(res => this.generalSettings);
  }
}
