export class User {
    id: number = null;
    email: string = null;
    firstName: string = null;
    lastName: string = null;
    language: string = null;
    isActive: boolean = false;
    isWorker: boolean = true;
    admin: boolean = false;
    verified: boolean = true; // when the user is created it's email is verified
    color: string = null;
    status: string = null;
    jobTitle: string = null;
    access: any = null;

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.email  = data.email;
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.language = data.language;
            this.isActive = data.isActive;
            this.isWorker = data.isWorker;
            this.admin = data.admin;
            this.verified = data.verified;
            this.color = data.color;
            this.status = data.status;
            this.jobTitle = data.jobTitle;

            if (data.access) {
                this.access = data.access;
            }
        }  
    }

    get fullName() {
        return this.lastName + ' ' + this.firstName;
    }
  }

export class SimpleUser {
    id: Number = null;
    email: String = null;
    firstName: String = null;
    lastName: String = null;
    language: String = null;
    color: String = null;
    jobTitle: string = null;

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.email  = data.email;
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.language = data.language;
            this.color = data.color;
            this.jobTitle = data.jobTitle;
        }  
    }

    get fullName() {
        return this.lastName + ' ' + this.firstName;
    }
  }