<ng-container *ngIf="action">
    <div class="section">
        <div class="header">
            <div class="title"><span> {{action.name}} </span></div>
        </div>
        <div class="w300">
            <app-task-status-selector [status]="action.status" (onChange)="statusChange($event)"></app-task-status-selector>
        </div>
    </div>

    <ng-container *ngIf="!action.taskCreated && action._actionTemplate.taskType">
        <div class="text-center">
            <button mat-flat-button color="primary" (click)="createTask()" matTooltip="Feladat Létrehozása a Lépés Sablon alapján">
                <mat-icon>add</mat-icon> Feladat Létrhozása
            </button>
        </div>
    </ng-container>

    <ng-container *ngIf="action.taskCreated && task">
        <div class="section">    
            <div class="header">
                <div class="title"><span>Feladat</span></div>
            </div>
            <div class="padding-10-0 helper-text">
                <app-task-type-container [type]="action._actionTemplate.taskType"></app-task-type-container>
            </div>
            <h6 class="link" (click)="goToTask()">{{task.name}}</h6>
            <p class="helper-text">{{task.description | truncate: 12}}</p>


            <table class="table table-condensed wAuto">
                <tr>
                    <td>Azonosító</td>
                    <td><span class="ref-number">{{task.refNumber}}</span></td>
                </tr>
                <tr>
                    <td>Fontosság</td>
                    <td><app-importance-container [importance]="task.importance"></app-importance-container></td>
                </tr>
                <tr>
                    <td>Felelős</td>
                    <td>
                        <ng-container *ngIf="task.assignee">
                            <app-avatar [user]="task._assignee"></app-avatar>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td>Cég</td>
                    <td>
                        <ng-container *ngIf="task.organization">
                            <app-bottom-organisation [data]="task.organization"></app-bottom-organisation>
                            <span>{{task._organization.name}}</span>
                        </ng-container>
                    </td>
                </tr>
            </table>
            <div *ngIf="task.open">
                <button mat-mini-fab  color="primary" (click)="openTaskEdit()" matTooltip="Szerkeszés">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </div>
    </ng-container>

    <div class="section">    
        <app-events [action]="action"></app-events>
    </div>
</ng-container>


