import { Component, Input, OnInit } from '@angular/core';
import { Template } from 'src/app/models/template.model';

@Component({
  selector: 'app-icon-template-type',
  templateUrl: './icon-template-type.component.html',
  styleUrls: ['./icon-template-type.component.scss']
})
export class IconTemplateTypeComponent implements OnInit {
  @Input() template: Template;

  constructor() { }

  ngOnInit() {
  }

}
