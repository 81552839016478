import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EnumActionStatus } from 'src/app/enums/actionStatus.enum';
import { Action } from 'src/app/models/projects/action.model';
import { cloneDeep } from 'lodash-es'; 

import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';

import { Project } from 'src/app/models/projects/project.model';
import { ProjectService } from 'src/app/services/projects/project.service';
import { DlgEditProjectComponent } from 'src/app/shared/dialogs/edit/dlg-edit-project/dlg-edit-project.component';
import { BottomActionDetailComponent } from 'src/app/shared/bottomSheets/bottom-action/bottom-action-detail/bottom-action-detail.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild('tree') tree;
  @Input() project: Project;
  @Output() onChangeProject: EventEmitter<Project> = new EventEmitter();
  @Output() onChangeActions: EventEmitter<Action[]> = new EventEmitter();
  selectedAction: Action = null;

  treeControl = new NestedTreeControl<Action>(node => node?.children);
  dataSource = new MatTreeNestedDataSource<Action>();
  hasChild = (_: number, node: Action) => !!node?.children && node?.children.length > 0;

  projUpdate$;
  dlgProjEdit1$;
  dlgProjEdit2$;
  bottomAction$;

  constructor(
    private projectService: ProjectService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
  ) {
    
  }

  ngOnInit() {
    this.setActionTreeData();
  }

  
  ngAfterViewInit() {
    this.openAllActionInTree();
   }

  setActionTreeData() {
    let actionTree = this.projectService.getActionTree(this.project.actions);
    // mat-tree expect an array
    this.dataSource.data = (actionTree) ? [actionTree] : null;
  }

  setState(state) {
    if (state == 'close') {
      this.project.open = false;
    } else {
      this.project.open = true;
    }

    this.projUpdate$ = this.projectService.update(this.project).subscribe(res => {
      this.project = res;

      this.onChangeProject.emit(this.project);
    })
  }

  actionChanged(action) {
    // update the action on the project
    for (let i = 0; i < this.project.actions.length; i++) {
      if (this.project.actions[i].id == action.id) { 
        this.project.actions[i].status = action.status;
      }
    };

    // to kickstart the lifecyle
    this.project.actions = cloneDeep(this.project.actions);

    this.onChangeActions.emit(this.project.actions);

    this.onChangeProject.emit(this.project);
  }

  edit() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "600px";
      
    dialogConfig.data = {
      project: this.project
    };

    const dialogRef = this.dialog.open(DlgEditProjectComponent, dialogConfig);

    this.dlgProjEdit1$ = dialogRef.componentInstance.onChangeProject.subscribe(project => {
      this.project = project;
    });

    this.dlgProjEdit2$ = dialogRef.componentInstance.onChangeActions.subscribe(actions => {
      // the actions are changed
      // it is possible that an action is deleted and we have to notify the Task about it
      this.project.actions = actions;
      
      // sort out the tree
      this.setActionTreeData();
      this.openAllActionInTree();

      this.onChangeActions.emit(actions);
    });
  }

  addFirstAction() {
    // to help open the edit dialog
    this.edit();
  }

  openAllActionInTree() {
 // opena all node when start
    // hack to make it work:  https://github.com/angular/components/issues/12469
    setTimeout(() => {
      if (this.dataSource.data) {
        this.treeControl.dataNodes = this.dataSource.data;
        this.tree.treeControl.expandAll();
      }

    }, 200)
  }

  openBottomAction(actionId) {
      // find the action
      let selectedAction = this.project.actions.find(a => a.id == actionId);

      let bottomSheetRef = this.bottomSheet.open(BottomActionDetailComponent, {data: selectedAction});
      this.bottomAction$ = bottomSheetRef.instance.onChange.subscribe((action) => {
        this.actionChanged(action);
      });  
  }
}
