import { Pipe, PipeTransform } from '@angular/core';

// from a datetime string keep only the ISO date part
//2019-10-07T09:43:31Z => 2019-10-07
@Pipe({name: 'dateDate'})
export class DateDate implements PipeTransform {
  transform(dt: Date): string {
    if (!dt) return null;
    let newStr: string = dt.toLocaleDateString()
    return newStr;
  }
}