import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dlg-confirm',
  templateUrl: './dlg-confirm.component.html',
  styleUrls: ['./dlg-confirm.component.scss']
})
export class DlgConfirmComponent implements OnInit {
  result: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DlgConfirmComponent>
  ){}

  ngOnInit() {}

  onNo() {
    this.result = false;
    this.dialogRef.close(false);
  }

  onYes() {
    this.result = true;
    this.dialogRef.close(true);
  }

}
