import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { TemplateSectionVersion } from 'src/app/models/template.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { TemplateService } from 'src/app/services/template.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-dlg-template-section-versions',
  templateUrl: './dlg-template-section-versions.component.html',
  styleUrls: ['./dlg-template-section-versions.component.scss']
})
export class DlgTemplateSectionVersionsComponent extends BaseEntity implements OnInit {
  onSelect = new EventEmitter();
  sectionId: number;
  templateSectionVersions: TemplateSectionVersion[] = [];

  tempSecGet$;
  tempSecDel$;
  tempSecUpdate$;

  constructor(
    private templateService: TemplateService,
    private modelFactory: ModelFactory,
    public dialogRef: MatDialogRef<DlgTemplateSectionVersionsComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super();

    this.sectionId = data.sectionId;

    this.fetch();
  }
  
  ngOnInit() {}

  fetch() {
    // based on the snippet enum fetch the stored snippets
    this.tempSecGet$ = this.templateService.getTemplateSectionVersions(this.sectionId).subscribe(res => {
      this.templateSectionVersions = res;
    })
  }

  add() {
    let newTemplateSectionVersion = this.modelFactory.getNewModel(TemplateSectionVersion);
    newTemplateSectionVersion.section = this.sectionId;
    newTemplateSectionVersion.id = this.getNextId();
    // not saved yet only save if at least one field is provided
    this.templateSectionVersions.push(newTemplateSectionVersion);
  }

  delete(version) {
    if (typeof version.id === 'string') {
      this.templateSectionVersions = this.templateSectionVersions.filter(v => v.id != version.id);
    } else {
      this.tempSecDel$ = this.templateService.deleteTempalateSectionVersion(version).subscribe(() => {
        this.templateSectionVersions = this.templateSectionVersions.filter(v => v.id != version.id);
      });
    }
    
  }

  save(version) {
    debugger;
    if (typeof version.id === 'string') {
      // create
      this.create(version);
    } else {
      this.update(version);
    }
  }

  create(version) {
    this.templateService.createTemplateSectionVersion(version).subscribe(res => {
        // remove and add the saved version
        this.templateSectionVersions = this.templateSectionVersions.filter(t => t.id != version.id);
        this.templateSectionVersions.push(res);
    });
  }

  update(version) {
    this.tempSecUpdate$ = this.templateService.updateTemplateSectionVersion(version).subscribe(res => {
      // replace with the saved version
      this.templateSectionVersions.forEach(v => {
        if (v.id === version.id) { v = res; }
      });
    });
  }

  updateTitleHu(content, version) {
    version.titleHu = content;
    this.save(version);
  }

  updateBodyHu(content, version) {
    version.bodyHu = content;
    this.save(version);
  }

  updateTitleEn(content, version) {
    version.titleEn = content;
    this.save(version);
  }

  updateBodyEn(content, version) {
    version.bodyEn = content;
    this.save(version);
  }

  select(version) {
    // select the version
    this.onSelect.emit(version);
    this.dialogRef.close();
  }
}
