import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { People } from 'src/app/models/people.model';
import { PeopleService } from 'src/app/services/people.service';
import { DlgEditOrganisationComponent } from 'src/app/shared/dialogs/edit/dlg-edit-organisation/dlg-edit-organisation.component';
import { Organisation } from 'src/app/models/organisation';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-page-person',
  templateUrl: './page-person.component.html',
  styleUrls: ['./page-person.component.scss']
})
export class PagePersonComponent implements OnInit {
  people: People = null;
  organisation: Organisation = null;

  peopleGet$;
  dlgEditOrg$;
  orgGet$;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private peopleService: PeopleService,
    private dialog: MatDialog,
    private organisationService: OrganisationService,
    private modelFactory: ModelFactory
  ) {
    this.people = this.modelFactory.getNewModel(People);
  }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id');

    
    if (id) {
      this.peopleGet$ = this.peopleService.get(id).subscribe(res => {
        this.people = res;

        this.loadOrganisation();
      });
    }
  }

  created(entity) {
    this.people = entity;
     //  navigate to the page
     this.router.navigate(['./'],  {
      queryParams: {id: this.people.id}, 
      relativeTo: this.route  
    });
  }

  deleted(event) {
       //  navigate to the page
       this.router.navigate(['../../persons'],  {
        relativeTo: this.route  
      });
  }

  updated(event) {
    //
  }

  createOrganisationDlg() {
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.data = null;

    const dialogRef = this.dialog.open(DlgEditOrganisationComponent, dialogConfig);

    this.dlgEditOrg$ = dialogRef.afterClosed().subscribe(organization => {
      this.people.organization = organization.id;
      this.people._organization = organization;
    });
  }

  loadOrganisation() {
    if (!this.people.organization) {
      return;
    }
    
    this.orgGet$ = this.organisationService.get(this.people.organization).subscribe(organisation => {
      this.organisation = organisation;
    });
  }

  openOrganisation() {
    if (!this.organisation) {
      return
    }

    //  navigate to the page
    this.router.navigate(['../../organisations/organisation'], {
      queryParams: {id: this.organisation.id}, 
      relativeTo: this.route  
    });
  }

}
