<div class="project-importance-bar">
    <div class="levels">
        <ng-container *ngFor="let importance of importances; let i = index">
            <div class="level level-{{i + 1}}"></div>
        </ng-container>
        <ng-container *ngFor="let empty of diffFromTotal; let i = index">
            <div class="level level-empty"></div>
        </ng-container>
    </div>
</div>

