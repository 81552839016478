export class Company {
     id: number;
     name: string;
     domain: string;
     taxNumber: string;

     logo: any;
     pdfHeader: any;
     stamp: any

     email: string = null;
     phone: string = null;
    
     addressCity: string = null;
     addressLine1: string = null;
     addressLine2: string = null;
     addressPostcode: string = null;

     timezone: string;
     language: string;
     
     created?: Date = null;
     updated?: Date = null;
     creator?: {id: number, color: string, email: string, firstName: string, lastName: string} = null;
     lastModifier?:  {id: number, color: string, email: string, firstName: string, lastName: string} = null;
 

    constructor(data) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.domain = data.domain;
            this.taxNumber = data.taxNumber;
            
            this.logo = data.logo;
            this.pdfHeader = data.pdfHeader;
            this.stamp = data.stamp;
            
            this.timezone = data.timezone;
            this.language = data.language;

            this.email = data.email;
            this.phone = data.phone;
            
            this.addressCity = data.addressCity;
            this.addressLine1 = data.addressLine1;
            this.addressLine2 = data.addressLine2;
            this.addressPostcode = data.addressPostcode;
            
            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }
    }
}