import { Component, Input, OnInit } from '@angular/core';
import { EnumLanguage } from 'src/app/enums/languages.enum';
import { EnumTemplateCategory } from 'src/app/enums/templates/templateCategory.enum';
import { Task } from 'src/app/models/tasks/task.model';
import { TemplateSection } from 'src/app/models/template.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { NotificationService } from 'src/app/services/notification';
import { TaskPdfService } from 'src/app/services/tasks/taskPdf.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-default-section-delivery-pdf',
  templateUrl: './default-section-delivery-pdf.component.html',
  styleUrls: ['./default-section-delivery-pdf.component.scss']
})
export class DefaultSectionDeliveryPdfComponent implements OnInit {
  @Input() task: Task;

  TEMPLATE_CATEGORY = EnumTemplateCategory.TaskCustomerDeliver;

  // pdf list vs create view
  createPdfView = false;
  isPdfGenerated = false;  // set to true if the pdf generated

  // this is the only section in the pdf
  section: TemplateSection;
  sections: TemplateSection[] = [];

  taskPdfCreate$;

  constructor(
    private taskPdfService: TaskPdfService,
    private modelFactory: ModelFactory,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.createSection();
  }

  createSection() {
    this.section = this.modelFactory.getNewModel(TemplateSection);
    this.section.defaultSection = this.TEMPLATE_CATEGORY;
    this.section.defaultConfig = {
      task: this.task.id,
      comments: null,
      partnerRefNumber: null,
    }
  }

  generate() {
    let data = {
      fileName: (this.task.language == EnumLanguage.En) ? 'delivery_note' : 'szallitolevel',
      sections: [this.section]
    };

    let payload = {
      taskId: this.task.id,
      data: data,
      templateCategory: this.TEMPLATE_CATEGORY
    }

    this.taskPdfCreate$ = this.taskPdfService.create(payload).subscribe(res => {
      this.notificationService.success();
    });
  }

  updatePartnerSelect(ref_number) {
    this.section.defaultConfig['partnerRefNumber'] = ref_number;
  }

}
