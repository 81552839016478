import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { GeneralSettings } from '../models/generalSettings.model';
import { map, tap } from 'rxjs/operators';
import { NotificationService } from './notification';
import { BehaviorSubject, Observable, of } from 'rxjs';

/**
 * This BehavioSubject Pattern creates a singleton service in a sense if the data is grabed
 * we don't have to grab it again.
 * - at the same time if the data is updated we can get the updated data 
 */

@Injectable({
    providedIn: 'root'
})
export class GeneralSettingsService {
    baseUrl = environment.baseUrl;

    URL = '/accounts/general-settings/';

    private generalSettingsObs$: BehaviorSubject<GeneralSettings> = new BehaviorSubject<GeneralSettings>(null);
    public generalSettings$: Observable<any>;

    constructor(
        private httpClient: HttpClient,
        private notificationService: NotificationService
     ) {
        this.generalSettings$ = this.generalSettingsObs$.asObservable();
    }

    // entry point to get the data (don't forget to subscribe!)
    get() {
        if (!this.generalSettingsObs$.value) {
            // first time we grab it
            this.fetch().subscribe();
        }
        
        return this.generalSettings$;
    }

    fetch() {
        let url = this.baseUrl  + this.URL;
        return this.httpClient.get(url).pipe(
            // return a list but we currently only one makes sense
            map(res => new GeneralSettings(res)),
            tap(res => { this.generalSettingsObs$.next(res) })
        )
    }

    update(payload) {
        let url = this.baseUrl + this.URL;
        return this.httpClient.put(url, payload).pipe(
            // return a list but we currently only one makes sense
            map(res => new GeneralSettings(res)),
            tap(res => { 
                this.notificationService.updated();
                // send data if somebody interested
                this.generalSettingsObs$.next(res);
            })
        )
    }

}