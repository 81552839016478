<ul class="breadcrumb"> 
    <li>Kategóriák</li>  
</ul>

<div class="flex"> 
    <button (click)="lookup()" mat-mini-fab color="primary" [disabled]="txtSearch === ''">
        <mat-icon matSuffix>search</mat-icon>
    </button>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Keresés</mat-label>
            <input [(ngModel)]="txtSearch" matInput placeholder="?" (keyup.enter)="lookup()">
            <mat-icon  class="pointer" matTooltip="Keresés Törlése" (click)="noSearch()" matSuffix>close</mat-icon>
        </mat-form-field>
    </div>
    <div class="full text-right">
        <span class="padding-l-10">
            <button mat-mini-fab  color="accent" [routerLink]="['./category']"  matTooltip="Új Gyártó"> 
                <mat-icon>add</mat-icon>
            </button>
        </span>
    </div> 
</div>

<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Kategória</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="link" (click)="open(row)" matTooltip="{{row.nameEn}}">{{row.nameHu}} </span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="parent">
        <mat-header-cell *matHeaderCellDef>Szülő Kategória</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.parent">
                <span matTooltip="{{row._parent.nameEn}}">{{row._parent.nameHu}}</span>
                <mat-icon class="pointer" (click)="open(row._parent)">play_arrow</mat-icon>
            </ng-container>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>


<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
></mat-paginator>

