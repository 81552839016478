import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AttributeGroup } from 'src/app/models/attributeGroup.model';
import { AttributeGroupService } from 'src/app/services/attributeGroup.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-page-attribute-groups',
  templateUrl: './page-attribute-groups.component.html',
  styleUrls: ['./page-attribute-groups.component.scss']
})
export class PageAttributeGroupsComponent implements OnInit {
  attributeGroup: AttributeGroup = null;

  attGroupList$;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private attributGroupService: AttributeGroupService,
    private modelFactory: ModelFactory
  ) { }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id')

    if (id) {
      this.attGroupList$ = this.attributGroupService.get(id).subscribe(i => {
        this.attributeGroup = i;
      });

    } else {
      this.attributeGroup = this.modelFactory.getNewModel(AttributeGroup);
    }
  }

  created(event) {

  }

  updated(event) {

  }

  deleted(event) {

  }
}
