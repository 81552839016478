
<div class="flex">
    <div>
        <mat-form-field appearance="outline" class="margin-r-10 w100">
            <mat-label>Jahr</mat-label>
            <mat-select name="ev" [ngModel]="ev"  (selectionChange)="setYear($event)">
                <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="outline" class="margin-r-10 w100">
            <mat-label>Warschein.</mat-label>
            <mat-select name="esely" [ngModel]="esely"  (selectionChange)="setEsely($event)">
                <mat-option [value]="100">=100%</mat-option>
                <mat-option [value]="90">≥90%</mat-option>
                <mat-option [value]="80">≥80%</mat-option>
                <mat-option [value]="70">≥70%</mat-option>
                <mat-option [value]="60">≥60%</mat-option>
                <mat-option [value]="50">≥50%</mat-option>
                <mat-option [value]="0">≥0%</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="outline" class="margin-r-10 w100">
            <mat-label>Active/Passive.</mat-label>
            <mat-select name="active" [ngModel]="active"  (selectionChange)="setActive($event)">
                <mat-option [value]="true">Active</mat-option>
                <mat-option [value]="false">Passive</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <mat-form-field appearance="outline" class="w200">
        <mat-label>Gemein %</mat-label>
        <input type="number" matInput  pattern="^[1-9][0-9]?$|^100$" name="Gemein" [(ngModel)]="newGemeinPerc" autocomplete="off">
    </mat-form-field>
    <div class="margin-l-10">
        <button mat-mini-fab  color="primary" (click)="filter()"> 
            <mat-icon>filter_alt</mat-icon>
        </button>
    </div>
</div>


<ng-container *ngIf="projectForecasts.length > 0">
    <div><span class="badge badge-warning">Éves összegzés</span></div>
    <div class="table-responsive">
    <table class="table table-bordered wAuto">
        <thead>
            <th>Negyedév</th>
            <th>VK</th>
            <th>Gemeinkosten</th>
            <th>Provision</th>
            <th>Gewinn ICH</th>
            <th>Gewinn Brutto</th>
        </thead>
        <tbody>
            <tr *ngFor="let row of calculatedTotals | keyvalue" [ngClass]="(row.key == 'total') ? 'text-bold' : ''">
                <td [ngClass]="(row.key == 'total') ? 'text-capital' : ''">{{row.key}}</td>
                <td>
                    <app-currency-container [value]="row.value.vk_eladas" [currency]="'eur'"></app-currency-container>
                </td>
                <td>
                    <app-currency-container [value]="row.value.gemeinKosten" [currency]="'eur'"></app-currency-container>
                </td>
                <td>
                    <app-currency-container [value]="row.value.provision" [currency]="'eur'"></app-currency-container>
                </td>
                <td>
                    <app-currency-container [isColor]="true" [value]="row.value.gewinnAuf" [currency]="'eur'"></app-currency-container>
                     &nbsp;<span class="helper-text" *ngIf="row.value.gewinnAuf">({{row.value.gewinnAuf/row.value.vk_eladas*100 | formatPercent}})</span>
                </td>
                <td>
                    <app-currency-container [isColor]="true" [value]="row.value.gewinnBrutto" [currency]="'eur'"></app-currency-container>
                    &nbsp;<span class="helper-text" *ngIf="row.value.gewinnBrutto">({{row.value.gewinnBrutto/row.value.vk_eladas*100 | formatPercent}})</span>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
</ng-container>

<div><span class="badge badge-warning">Részletek</span></div>
    <div class="table-responsive" *ngIf="projectForecasts.length > 0; else tmpNoResult">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Negyedév</th>
                    <th>Projekt</th>
                    <th>EK</th>
                    <th>Tile</th>
                    <th>Inbetr</th>
                    <th>Transport</th>
                    <th>Schulung</th>
                    <th>Garantie</th>
                    <th>VK</th>
                    <th>Gemein.K.</th>
                    <th>Provision</th>
                    <th>Gewinn.Auf.<br />ICH</th>
                    <th>Gewinn.Auf.<br/>Brutto</th>
                    <th>Warschein.</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let p of projectForecasts">
                    <td>{{p.ev}}/{{p.negyedev}}
                        <br>
                        <app-static-true-false-icon [value]="p.active"></app-static-true-false-icon>
                    </td>
                    <td class="w200" matTooltip="{{p._project.name}}">
                        <a href="" [routerLink]="['/app/projects/project']" [queryParams]="{id: p.project}">
                            {{p._project.name | truncate:4}}
                        </a>
                    </td>
                    <td>
                        <app-currency-container [value]="p.ek_vetel" [currency]="'eur'"></app-currency-container>
                    </td>
                    <td>
                        <div *ngFor="let part of p.parts" matTooltip="{{part._product.nameHu}}">
                            <app-currency-container [value]="part.cost" [currency]="'eur'"></app-currency-container>
                        </div>
                    </td>
                    <td>
                        <app-currency-container [value]="p.inbetr" [currency]="'eur'"></app-currency-container>
                    </td>
                    <td>
                        <app-currency-container [value]="p.transport" [currency]="'eur'"></app-currency-container>
                    </td>
                    <td>
                        <app-currency-container [value]="p.schulung" [currency]="'eur'"></app-currency-container>
                    </td>
                    <td>
                        <app-currency-container [value]="p.garancia" [currency]="'eur'"></app-currency-container>
                    </td>
                    <td>
                        <app-currency-container [value]="p.vk_eladas" [currency]="'eur'"></app-currency-container>
                    </td>
                    <td>
                        <app-currency-container [value]="p.gemeinKosten" [currency]="'eur'"></app-currency-container>
                        <br /><span class="helper-text">({{p.gemeinpercent | formatPercent}})</span>
                    </td>
                    <td>
                        <app-currency-container [value]="p.provision" [currency]="'eur'"></app-currency-container>
                        <br /><span class="helper-text">({{p.vorabgewinn | formatPercent}})</span>
                    </td>
                    <td>
                        <app-currency-container [isColor]="true" [value]="p.gewinnAuf" [currency]="'eur'"></app-currency-container>
                        <br /><span class="helper-text">({{p.gweinnAufPerc | formatPercent}})</span>
                    </td>
                    <td>
                        <app-currency-container [isColor]="true" [value]="p.gewinnBrutto" [currency]="'eur'"></app-currency-container>
                        <br /><span class="helper-text">({{p.gewinnBurttoPerc | formatPercent}})</span>
                    </td>
                    <td>
                        <span class="badge badge-warning">{{p.esely | formatPercent}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <ng-template #tmpNoResult>
        <span  class="helper-text"><mat-icon>not_interested</mat-icon><br>Nincs Találat</span>
    </ng-template>
