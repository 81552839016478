import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EnumModule } from 'src/app/enums/modules.enum';
import { Organisation } from 'src/app/models/organisation';
import { DlgSearchOrganisationComponent } from 'src/app/shared/dialogs/search/dlg-search-organisation/dlg-search-organisation.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-org-selector',
  templateUrl: './org-selector.component.html',
  styleUrls: ['./org-selector.component.scss']
})
export class OrgSelectorComponent implements OnInit {
  @Input() org: Organisation;
  // we are searching for a client or a vendor
  @Input() isVendor: boolean = false;

  @Output() onChange: EventEmitter<Organisation> = new EventEmitter();

  name: string = '';

  dlgOrgSearch$;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    if (this.org) {
      this.name = this.org.name;
    }
  }

  openOrgSearchDlg() {
    const dialogConfig = new MatDialogConfig();
    
    // current company name
    let name = (this.org) ? this.org.name : '';
    
    dialogConfig.data = {
      name: name,
      isVendor: this.isVendor
    };

    const dialogRef = this.dialog.open(DlgSearchOrganisationComponent, dialogConfig);

    this.dlgOrgSearch$ = dialogRef.afterClosed().subscribe(organisation => {
      this.org = organisation;
 
      this.name = '';
      if (this.org) {
        this.name = this.org.name;
      }

      this.onChange.emit(this.org);
    });
  }

}
