import { Component, OnInit, Inject, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageItemComponent } from 'src/app/items/page-item/page-item.component';
import { Item } from 'src/app/models/item.model';
import { ItemService } from 'src/app/services/item.service';
import { cloneDeep } from 'lodash-es';
import { DlgEditItemComponent } from '../../edit/dlg-edit-item/dlg-edit-item.component';
import { EnumProductType } from 'src/app/enums/productType.enum';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';
import { Organisation } from 'src/app/models/organisation';
import { DlgOrgItemComponent } from '../dlg-org-item/dlg-org-item.component';
import { OrganisationService } from 'src/app/services/organisation.service';

@AutoUnsub()
@Component({
  selector: 'app-dlg-search-item',
  templateUrl: './dlg-search-item.component.html',
  styleUrls: ['./dlg-search-item.component.scss']
})
export class DlgSearchItemComponent implements OnInit {
  @ViewChild('focus', {static: true}) focus:any;

  txtSearch: string = '';
  productType = EnumProductType.Machine
  disableTypeSelection: boolean = false
  options = [];
  item: Item = new Item(null);

  // if org is passed we are search by Org in OrgItem table instead of the Item table
  org: Organisation = null;
  // has to fetch the org
  orgId: number;
  // if true we are searching by org (contorl the component)
  searchByOrg: boolean = false;
 
  onSave = new EventEmitter();

  list$;
  dlgItemEdit$;

  constructor(
    private service: ItemService,
    private dialog: MatDialog,
    private orgService: OrganisationService,
    public dialogRef: MatDialogRef<DlgSearchItemComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data.item) {
      this.item =  cloneDeep(data.item);
      this.txtSearch = this.item._product.nameHu
    }

    if (data.disableTypeSelection) {
      this.disableTypeSelection = data.disableTypeSelection;
    }

    if (data.productType) {
      this.productType = data.productType;
    }

    if (data.org) {
      // if you already have the org open the Item search for the org as well
      this.org == data.org;
      this.searchByOrg = true;
      this.openOrgItemSelector();
    } else if (data.orgId) {
      this.searchByOrg = true;
      this.orgId = data.orgId;
    } else if (data.searchByOrg) {
      // if you want to search by org
      this.searchByOrg = data.searchByOrg
    }
  }

  fetchOrg(orgId) {
    this.orgService.get(orgId).subscribe(res => {
      this.setOrg(res);
    })
  }

  ngOnInit() {
    if (this.orgId) {
      this.fetchOrg(this.orgId);
    }
  }

  search() {
    if (this.txtSearch.length < 3) {
      // only search for more than 2 charachters
      return
    }

    this._doSearch();
  }

  _doSearch() {
    let options = {params: {
      productType: this.productType,
      search: this.txtSearch,
      limit: 50
    }};

    this.list$ = this.service.list(options).subscribe(data => {
      this.options = data.results;

      //this.focus.nativeElement.focus();
     });
  }

  select(entity) {
    this.item = entity;
    this.txtSearch = entity._product.nameHu;
  }

  delete() {
    this.txtSearch = '';
    this.item = null;
  }

  save() {
      this.onSave.emit(this.item)
      this.dialogRef.close();
  }

  openItemCreateDlg() {
    let dialogConfig = new MatDialogConfig(); 
    
    let item = new Item(null)
    
    dialogConfig.data = {
      item: item,
      preventItemCreation: true,
    };

    const dialogRef2 = this.dialog.open(DlgEditItemComponent, dialogConfig);

    this.dlgItemEdit$ = dialogRef2.componentInstance.onChange.subscribe(item => {
      if (item) {
        this.item = item;
        this.txtSearch = item._product.nameHu;
      }
    });
  }

  changeType(event) {
    this.productType = event.value
    
    setTimeout(() => {
      this.search();
    }, 0)
  }

  setOrg(org: Organisation) {
    // set the organisation for the component
    this.org = org;
    // open a dlg to select items from the org
    this.openOrgItemSelector();
  }

  openOrgItemSelector() {
    // allow to select form the org itmes of the org
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '400px';
      
    dialogConfig.data = {
      organizastion: this.org,
    };

    const dialogRef = this.dialog.open(DlgOrgItemComponent, dialogConfig);

    dialogRef.componentInstance.onSelect.subscribe(orgItem => {
      this.item = orgItem._item;
      // close the dlg we got the item
      this.save();
    });
  }

  resetData() {
    this.item = null;
    this.org = null;
  }

}
