import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Utils } from '../shared/utils';
import { Audit } from '../models/audit.model';


@Injectable({
    providedIn: 'root'
  })
export class AuditService {
    baseUrl = environment.baseUrl;

    URL_LIST = '/logs/';

    // list items
    dataSource$ = new BehaviorSubject<any[]>([])
    data = this.dataSource$.asObservable();

    constructor(
        private http: HttpClient
        ) {}

    list(options=null) {
        // @params: obj of params(filter by email)
        // No pagination!
        let url = this.baseUrl + this.URL_LIST;
        
        let params = {}
        if (options.params) {
            params = options.params;
        }

        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.map(i => new Audit(i));
                return res;
            })
        )
    }

    get(id) {
        let url = this.baseUrl + this.URL_LIST + id + '/';
        return this.http.get<Audit>(url).pipe(
            map(res => new Audit(res))
        )
    }
}