<div class="flex">
    <h1 class="full" mat-dialog-title>Verziók</h1>
    <span [mat-dialog-close]>
        <mat-icon class="text-red">close</mat-icon>
    </span>
</div>
<div mat-dialog-content style="clear: both">
    <ng-container *ngIf="templateSectionVersions.length > 0">
        <mat-accordion>
            <ng-container *ngFor="let version of templateSectionVersions">
            <form>
                <mat-expansion-panel class="bg-light-grey" [expanded]="version?.expanded">
                    
                    <mat-expansion-panel-header>
                        <mat-panel-title class="flex">
                            <button mat-button class="mat-success" (click)="select(version)">Kiválaszt</button>
                            <div class="padding-l-20">
                                <ng-container *ngIf="version.titleHu; containerBodyHu">{{version.titleHu | stripHtml | truncate:10}}</ng-container>
                                <ng-template #containerBodyHu>{{version.bodyHu | stripHtml | truncate:10}}</ng-template>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <form #f="ngForm">
                        <mat-tab-group animationDuration="0ms">
                            <mat-tab label="Magyar">
                                <div class="margin-10-0">
                                    <app-html-container [content]="version.titleHu" (onChange)="updateTitleHu($event, version)"></app-html-container>
                                </div>
                                <div>
                                    <app-html-container [content]="version.bodyHu" (onChange)="updateBodyHu($event, version)"></app-html-container>
                                </div>
                            </mat-tab>
                            <mat-tab label="Angol">
                                <div class="margin-10-0">
                                    <app-html-container [content]="version.titleEn" (onChange)="updateTitleEn($event, version)"></app-html-container>
                                </div>
                                <div>
                                    <app-html-container [content]="version.bodyEn" (onChange)="updateBodyEn($event, version)"></app-html-container>
                                </div>
                            </mat-tab>
                        </mat-tab-group>       
                    </form> 
                    <button mat-icon-button  color="warn"  matTooltip="Eltávolítás" (confirm-click)="delete(version)" confirm>
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-expansion-panel>
            </form>
            </ng-container>
        </mat-accordion>
    </ng-container>
    <div class="padding-10">
        <button mat-mini-fab color="accent" (click)="add()" matTooltip="Új verzió létrhozása">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>