<ng-container *ngIf="taskProducts.length === 0; else tmpProducts">
    <span class="text-red">Feladathoz nincs termék kiválasztva</span>
</ng-container>
<ng-template #tmpProducts>
<p>
    <app-html-container [content]="pdfSection.title" (onChange)="pdfSection.title=$event"></app-html-container>
</p>
<div class="padding-10-0">
    <ul>
        <li *ngFor="let taskProduct of taskProducts">
            <ng-container *ngIf="language == 'en'; else tmpProductHu">
                {{ taskProduct._product.nameEn}}
            </ng-container>
            <ng-template #tmpProductHu>
                {{ taskProduct._product.nameHu}}
            </ng-template>
        </li>
    </ul>
</div>
</ng-template>

