<div class="login-page">
    <mat-card class="login-card">
      <p class="padding-10-0">
        <img src="assets/logo/ich-logo-small.png" alt="IC-Hungary">
      </p>
        <mat-card-content>
          <form class="form" (keydown)="keyDownFunction($event)">
            <table cellspacing="0">
              <tr>
                <td>
                  <mat-form-field class="example-full-width">
                  <input matInput placeholder="Email" [(ngModel)]="username" name="username" required>
                  </mat-form-field>
                </td>
              </tr>
              <tr>
              <td><mat-form-field class="example-full-width">
                <input matInput placeholder="Jelszó" [(ngModel)]="password" type="password" name="password" required>
              </mat-form-field></td>
            </tr></table>
          </form>
        </mat-card-content>
        <mat-card-actions>
          <div class="flex">
            <div class="full">
              <button type="submit" mat-raised-button (click)="login()" color="primary"  [disabled]="!username || !password">Belepés</button>
            </div> 
            <div>
              <button mat-button [routerLink]="['./../request-forgotten-password']" color="secondary" matTooltip="Elfeljetetted a jelszavad?">Új jelszó</button>
            </div>
          </div>
        </mat-card-actions>
      </mat-card>
</div>

