import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Attribute } from 'src/app/models/attribute.model';
import { AttributeService } from 'src/app/services/attribute.service';
import { DlgSearchAttributeGroupComponent } from 'src/app/shared/dialogs/search/dlg-search-attribute-group/dlg-search-attribute-group.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-attribute',
  templateUrl: './attribute.component.html',
  styleUrls: ['./attribute.component.scss']
})
export class AttributeComponent implements OnInit {
  @Input() attribute: Attribute;

  @Output() eventCreated: EventEmitter<Attribute> = new EventEmitter();
  @Output() eventUpdated: EventEmitter<Attribute> = new EventEmitter();
  @Output() eventDeleted: EventEmitter<any> = new EventEmitter();

  dlgAttr$;
  attrDelete$;
  attrUpdate$;
  attrCreate$;

  constructor(
    private attributeService: AttributeService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  save() {
    (this.attribute.id) ? this.update() : this.create();
  }

  create() {
    this.attrCreate$ = this.attributeService.create(this.attribute).subscribe(res => {
      this.attribute = res;
      this.eventCreated.emit(this.attribute);
    })
  }

  update() {
    this.attrUpdate$ = this.attributeService.update(this.attribute).subscribe(res => {
      this.attribute = res;
      this.eventUpdated.emit(this.attribute);
    })
  }

  delete() {
      this.attrDelete$ = this.attributeService.delete(this.attribute).subscribe(() => {
        this.eventDeleted.emit()
      });
    }

  openAttributeGroupSearchDlg() {
    const dialogConfig = new MatDialogConfig();
    
    let name = (this.attribute.group) ? this.attribute._group.nameHu : '';
    
    dialogConfig.data = {
      name: name
    };

    const dialogRef = this.dialog.open(DlgSearchAttributeGroupComponent, dialogConfig);

    this.dlgAttr$ = dialogRef.afterClosed().subscribe(res => {
      if (res) {
          this.attribute.group = res.id;
          this.attribute._group = res;
      } else if (res == '') {
        this.attribute.group = null;
        this.attribute._group = null;
      }
    });
  }

}
