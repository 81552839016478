<mat-dialog-content> 
    <ng-container *ngIf="taskEvent.done; else eventInProgress">
        <mat-tab-group [(selectedIndex)]="showTabIndex">
            <mat-tab label="Részletek">
                <ng-container *ngTemplateOutlet="tabDetails"></ng-container>
            </mat-tab>
            <mat-tab label="Lezárás">
                <ng-container *ngTemplateOutlet="tabClose"></ng-container>
            </mat-tab>
        </mat-tab-group>
    </ng-container>

    <ng-template #eventInProgress>
        <ng-container *ngTemplateOutlet="tabDetails"></ng-container>
    </ng-template>

    <div class="padding-10-0">
        <mat-checkbox name="done"  [(ngModel)]="taskEvent.done">Lezárva</mat-checkbox>
    </div>

    <div class="flex padding-20-0">
        <div class="full">
            <button [disabled]="!taskEvent.name" matTooltip="Mentés"  mat-mini-fab  color="primary" (click)="save()">
                <mat-icon>save</mat-icon>
            </button>
        </div>
        <div *ngIf="(taskEvent.id | typeof) === 'number'">
            <button mat-icon-button  color="warn" (confirm-click)="delete()" confirm>
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
</mat-dialog-content> 



<ng-template #tabDetails>
    <form #f="ngForm">   
        <mat-form-field appearance="outline">
            <mat-label>
                <ng-container [ngSwitch]="task.taskType">
                    <ng-container *ngSwitchCase="enumsTaskTypes.TaskCustomerDeliver">Név</ng-container>
                    <ng-container *ngSwitchDefault>Tennivalók</ng-container>
                </ng-container>
            </mat-label>
            <input name="description" matInput [(ngModel)]="taskEvent.name" required [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete">
                <ng-container  *ngIf="task.taskType == enumsTaskTypes.TaskActivity">
                    <mat-option *ngFor="let option of activityTypes" [value]="option">
                        {{option}}
                      </mat-option>
                </ng-container>
              </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>
                <ng-container [ngSwitch]="task.taskType">
                    <ng-container *ngSwitchCase="enumsTaskTypes.TaskCustomerDeliver">Leírás</ng-container>
                    <ng-container *ngSwitchCase="enumsTaskTypes.TaskService">Hiba Leírás</ng-container>
                    <ng-container *ngSwitchDefault>Probléma Leírása</ng-container>
                </ng-container>
               
            </mat-label>
            <textarea name="problem" matInput [(ngModel)]="taskEvent.problem" rows="3"></textarea>
        </mat-form-field>
        <p>
            <mat-label class="helper-text">Felelős</mat-label>
            <app-user-selector [user]="taskEvent._assignee" (onChange)="setAssignedUser($event)"></app-user-selector>
        </p>
        <div class="helper-text">Tervezett Időpont</div>
        <app-datetime-range-selector [start]="taskEvent.start" [end]="taskEvent.end" [dateOnly]="dayEvent" (onChange)="setPlannedStartEnd($event)"></app-datetime-range-selector>
    </form>
</ng-template>

<ng-template #tabClose>
    <div class="flex">
        <div class="helper-text full">Tényleges Időpont</div>
        <span class="link text-tiny" (click)="setPlannedForActual()" matTooltip="Tervezett időpoint beallítása a Tényleges időponthoz">Tervezett</span>
    </div>
    
    <app-datetime-range-selector [start]="taskEvent.actualStart" [end]="taskEvent.actualEnd" [dateOnly]="dayEvent" (onChange)="setActualStartEnd($event)"></app-datetime-range-selector>
    
    <ng-container [ngSwitch]="task.taskType">
        <ng-container *ngSwitchCase="enumsTaskTypes.TaskCustomerDeliver"></ng-container>
        <ng-container *ngSwitchDefault>
            <mat-form-field appearance="outline">
                <mat-label>Szünet</mat-label>
                <mat-select [(value)]="taskEvent.pause">
                    <mat-option [value]="0">Nem volt</mat-option>
                    <mat-option [value]="900">15 perc</mat-option>
                    <mat-option [value]="1800">30 perc</mat-option>
                    <mat-option [value]="2700">45 perc</mat-option>
                    <mat-option [value]="3600">1 óra</mat-option>
                    <mat-option [value]="4500">1 óra 15 perc</mat-option>
                    <mat-option [value]="5400">1 óra 30 perc</mat-option>
                    <mat-option [value]="6300">1 óra 45 perc</mat-option>
                    <mat-option [value]="7200">2 óra</mat-option>
                </mat-select>
            </mat-form-field>
        
        </ng-container>
    </ng-container>
   
    <mat-form-field appearance="outline">
        <mat-label>
            <ng-container [ngSwitch]="task.taskType">
                <ng-container *ngSwitchCase="enumsTaskTypes.TaskCustomerDeliver">Záró Megjegyzés</ng-container>
                <ng-container *ngSwitchCase="enumsTaskTypes.TaskService">Elvégzett Feladat Leíráss</ng-container>
                <ng-container *ngSwitchDefault>Probléma Megoldása</ng-container>
            </ng-container>
            
        </mat-label>
        <textarea name="description" matInput [(ngModel)]="taskEvent.comment" rows="3"></textarea>
    </mat-form-field>
</ng-template>
