import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from './notification';
import { Utils } from '../shared/utils';
import { UserAccess } from '../models/userAccess.model';
import { EnumModule } from '../enums/modules.enum';

@Injectable({
    providedIn: 'root'
  })
export class UserAccessService {
    baseUrl = environment.baseUrl;

    URL_LIST = '/accounts/users/accesses/';

    // list items
    dataSource$ = new BehaviorSubject<any[]>([])
    data = this.dataSource$.asObservable();


     // list of configurable Modules (ADMIN and BASE MOSULES are Excluded)
     MODULES = [
        EnumModule.ProjectPlanning, 
        EnumModule.Projects,
        EnumModule.Templateing,
        EnumModule.TaskServices,
        EnumModule.TaskClients,
        EnumModule.TaskVendors,
        EnumModule.Inventory,
    ]

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {}

    getModules() {
        return this.MODULES;
    }

    list(options=null) {
        // @params: obj of params
        // @url: full url with params (next and prev for pagination)
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL_LIST;
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }

        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(i => new UserAccess(i));
                return res;
            })
        )
    }

    get(id) {
        let url = this.baseUrl + this.URL_LIST + id + '/';
        return this.http.get<UserAccess>(url).pipe(
            map(res => new UserAccess(res))
        )
    }

    create(payload) {
        let url = this.baseUrl + this.URL_LIST;
        return this.http.post(url, payload).pipe(
            map(result => new UserAccess(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    update(payload) {
        let url = this.baseUrl + this.URL_LIST + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new UserAccess(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    delete(payload) {
        let url = this.baseUrl + this.URL_LIST + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }
}