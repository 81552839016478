<ng-container *ngIf="options">
    <form>
        <mat-form-field>
            <mat-icon class="pointer text-red"  (click)="delete()" matSuffix matTooltip="Jelenlegi Törölése">delete</mat-icon>
            <mat-label>Válassz</mat-label>
            <mat-select>
                <mat-option (click)="select(option)" *ngFor="let option of options" [value]="option">
                    {{option.lastName}} {{option.firstName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</ng-container>

