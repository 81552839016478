import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttributeGroup } from 'src/app/models/attributeGroup.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';

@Component({
  selector: 'app-dlg-edit-attribute-group',
  templateUrl: './dlg-edit-attribute-group.component.html',
  styleUrls: ['./dlg-edit-attribute-group.component.scss']
})
export class DlgEditAttributeGroupComponent implements OnInit {
  attributeGroup: AttributeGroup = null;

  constructor(
    private modelFactory: ModelFactory,
    public dialogRef: MatDialogRef<DlgEditAttributeGroupComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.attributeGroup = this.modelFactory.getNewModel(AttributeGroup);
    if (data) {
      this.attributeGroup = data;
    }
  }

  ngOnInit() {
  }

  created(event) {
    this.closeDlg(event)
  }

  updated(event) {
    this.closeDlg(event);
  }

  deleted(event) {
    this.closeDlg(event);
  }

  closeDlg(event) {
    this.dialogRef.close(event);
  }

}
