<form class="" #f="ngForm"> 
    <div class="flex flex-right">
        <mat-checkbox name="isvendor" [(ngModel)]="organisation.isVendor">Beszállító</mat-checkbox>
    </div>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Cég</mat-label>
            <input matInput name="name" [(ngModel)]="organisation.name" autocomplete="off" required>
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Iparág</mat-label>
            <input matInput name="industry" [(ngModel)]="organisation.industry" autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Adó Szám</mat-label>
            <input matInput name="taxNumber" [(ngModel)]="organisation.taxNumber" autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline"> 
            <mat-label>Telefon</mat-label>
            <input matInput name="telefon" [(ngModel)]="organisation.phone" autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput name="email" [(ngModel)]="organisation.email" autocomplete="off">
        </mat-form-field>
    </p>
    <p  class="padding-10-0">
        <mat-label class="helper-text">Alapértelmezett Kommunikáció Nyelve</mat-label>
        <app-language-selector [language]="organisation.language" (onChange)="organisation.language=$event"></app-language-selector>
    </p>
    <hr>
    <div class="badge">Cím</div>
    <div class="flex flex-wrap flex-space-evenly">
        <p class="padding-l-20">
            <app-country-selector [country]="organisation.addressCountry" (eventSelect)="setCountry($event)"></app-country-selector>
        </p>
        <p class="padding-l-20">
            <mat-form-field appearance="outline">
                <mat-label>Város</mat-label>
                <input matInput name="city" [(ngModel)]="organisation.addressCity" autocomplete="off">
            </mat-form-field>
        </p>
        <p class="padding-l-20">
            <mat-form-field appearance="outline">
                <mat-label>Utca (1)</mat-label>
                <input matInput name="cim1" [(ngModel)]="organisation.addressLine1" autocomplete="off">
            </mat-form-field>
        </p>
        <p class="padding-l-20">
            <mat-form-field appearance="outline">
                <mat-label>Utca (2)</mat-label>
                <input matInput name="cim2" [(ngModel)]="organisation.addressLine2" autocomplete="off">
            </mat-form-field>
        </p>
        <p class="padding-l-20">
            <mat-form-field appearance="outline">
                <mat-label>Iranyítószám</mat-label>
                <input matInput name="postcode" [(ngModel)]="organisation.addressPostcode" autocomplete="off">
            </mat-form-field>
        </p>      
    </div>
   <div>
        <div class="badge">Szervízes 1</div>
        <app-user-selector [user]="organisation._assignedUser1" (onChange)="setAssignedUser1($event)"></app-user-selector>
    </div>
    <div>
        <div class="badge">Szervízes 2</div>
        <app-user-selector [user]="organisation._assignedUser2" (onChange)="setAssignedUser2($event)"></app-user-selector>
    </div>
   <hr>
    <div class="flex padding-10-0">
        <span class="padding-l-20">
            <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
        <ng-container *ngIf="organisation.id">
            <span class="padding-l-20">
                <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                    <mat-icon>delete</mat-icon>
                </button>
            </span>
            <span class="padding-l-20">
                <app-quick-log-info [data]="organisation"></app-quick-log-info>
            </span>
        </ng-container>
    </div>

</form>
