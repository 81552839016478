import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnumCurrency } from 'src/app/enums/currencies.enum';

@Component({
  selector: 'app-currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss']
})
export class CurrencySelectorComponent implements OnInit {
  @Input() currency: EnumCurrency; // selected currency

  @Output() onChange: EventEmitter<EnumCurrency> = new EventEmitter();

  enumCurrency = EnumCurrency;

  constructor() { }

  ngOnInit() {
  }

  currencyChanged() {
    this.onChange.emit(this.currency);
  }


}
