import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { NotificationService } from './notification';
import { EnumFileUploadType } from '../enums/fileUploadType.enum'
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
  })
export class FileUploadService {
    baseUrl = environment.baseUrl;

    paths = {};
    
    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {
            this.paths[EnumFileUploadType.CompanyPdfHeader] = '/accounts/company/upload/pdf-header/';
            this.paths[EnumFileUploadType.CompanyLogo] = '/accounts/company/upload/logo/';
            this.paths[EnumFileUploadType.CompanyStamp] = '/accounts/company/upload/stamp/';
            this.paths[EnumFileUploadType.ProfileSignature] = '/accounts/profiles/upload/signature/';
            this.paths[EnumFileUploadType.ProductImage] = '/products/images/upload/';
            this.paths[EnumFileUploadType.ProductDocument] = '/products/documents/upload/';
            this.paths[EnumFileUploadType.TaskDoc] = '/tasks/docs/upload/';
        }

    upload(formData: FormData, type: EnumFileUploadType, instance?: any) {
        /**
         * forData: form data with the file
         * type: endpoint
         * instance: if instance passed add the instance id to the path
         */
        let path = this.paths[type];
        
        let url = this.baseUrl + path;

        if (instance) {
            url += instance.id + '/';
        }

        // TODO: this header thing looks do not applied - check if possible to delete
        let headers = new HttpHeaders;
        headers = headers.set('Content-Type', 'multipart/form-data');
        headers = headers.set('Accept', 'application/json');

        let file: any = formData.get('file');
        let fileName = file.name;
        let contentDispositionHeader = `form-data; filename="${fileName}"`;
        headers = headers.append('Content-Disposition', contentDispositionHeader);

        headers = headers.append('Access-Control-Allow-Headers', 'content-disposition, accept, accept-encoding, authorization, content-type, dnt, origin, user-agent, x-csrftoken, x-requested-with');
        
        // instance exist, PUT
        return this.http.put(url, formData).pipe(
            map(result => result),
            tap(res => { this.notificationService.updated(); })
        )
    
       
    }

    download(uri): Observable<Blob> {
        return this.http.get(uri, {responseType: 'blob' });
    }


}