
  <!--loader-->
  <app-loader></app-loader>
  <!-- Page-->
  <mat-drawer-container autosize *ngIf="loggedIn; else notLoggedIn">
      <mat-drawer #drawer class="sidenav" mode="over" opened="false">
        <ng-container *ngIf="user">
          <app-sidemenu (clicked)="drawer.close()"></app-sidemenu>
        </ng-container>
      </mat-drawer>
      <div>
        <mat-toolbar [style.background-color]="'#f7f7f7'">
          <mat-toolbar-row>
              <button color="primary" id="btn-menu-toggle"  mat-mini-fab (click)="drawer.toggle()">
                 <ng-container *ngIf="drawer.opened; else tmpMenuClosed">
                    <mat-icon>format_indent_decrease</mat-icon>
                 </ng-container>
                 <ng-template #tmpMenuClosed>
                  <mat-icon>format_indent_increase</mat-icon>
                 </ng-template>
                
              </button>
              <span class="margin-l-20 padding-l-30"><ng-container *ngIf="company">{{company.name}}</ng-container></span>
              <span class="spacer"></span>

            <!--Create New -->
            <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon class="text-green">add_circle_outline</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                  <ng-container *ngIf="hasAccessProjects">
                    <button mat-menu-item routerLink="/app/projects"  [queryParams]="{create: 'true'}">
                      <mat-icon>device_hub</mat-icon>
                      <span >Új Projekt</span> 
                    </button>
                    
                    <hr>
                  </ng-container>

                  <ng-container *ngIf="hasAccessTaskServices">
                    <button mat-menu-item routerLink="/app/tasks/services"  [queryParams]="{create: 'true'}">
                      <mat-icon>construction</mat-icon>
                      <span >Új Szerviz</span> 
                    </button>
                    <button mat-menu-item routerLink="/app/tasks/activites"  [queryParams]="{create: 'true'}">
                      <mat-icon>accessibility</mat-icon>
                      <span >Új Tevékenységek</span> 
                    </button>
                  </ng-container>

                  <ng-container *ngIf="hasAccessTaskClients">
                    <button mat-menu-item routerLink="/app/tasks/customer-interests"  [queryParams]="{create: 'true'}">
                      <mat-icon>3p</mat-icon>
                      <span >Új Ajánlatkérés Vevőtől</span> 
                    </button>
                  </ng-container>
                  
                  <ng-container *ngIf="hasAccessTaskVendors">
                  <button mat-menu-item routerLink="/app/tasks/vendor-interests"  [queryParams]="{create: 'true'}">
                      <mat-icon>supervised_user_circle</mat-icon>
                      <span >Új Ajánlatkérés a Beszállítótól</span> 
                    </button>
                  </ng-container>
                  
                  <ng-container *ngIf="hasAccessInvetnory">
                    <button mat-menu-item routerLink="/app/inventory"  [queryParams]="{create: 'true'}">
                      <mat-icon>vertical_align_bottom</mat-icon>
                      <span >Bevételezés a Raktárba</span> 
                    </button>
                  </ng-container>
                  <hr>

                  <button mat-menu-item routerLink="/app/contacts/organisations/organisation">
                    <mat-icon>storefront</mat-icon>
                    <span >Új Cég</span> 
                  </button>
                  <button mat-menu-item routerLink="/app/contacts/persons/person">
                    <mat-icon>assignment_ind</mat-icon>
                    <span>Új Kontakt</span> 
                  </button>
              </mat-menu>

              <span class="spacer-to-right"></span>

              <ng-container *ngIf="exchange && exchange.rate">
                <button mat-flat-button [matMenuTriggerFor]="tmpExhange" class="margin-r-10 d-none d-lg-block" style="font-size: 12px;">
                    1€ = {{exchange.rate}} Ft <mat-icon class="text-yellow">arrow_drop_down_circle</mat-icon>
                </button>
                <mat-menu #tmpExhange>
                  <div (click)="$event.stopPropagation();" class="padding-20">
                    <div class="text-tiny text-light-grey padding-5 text-center">Valutaváltó</div>
                    <mat-form-field appearance="outline">
                      <mat-label>EUR</mat-label>
                      <input matInput name="eur" [(ngModel)]="valueEur" (keyup)="updateValueHuf()" autocomplete="off">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <mat-label>HUF</mat-label>
                      <input matInput name="huf" [(ngModel)]="valueHuf" (keyup)="updateValueEur()"  autocomplete="off" >
                    </mat-form-field>
                    <div class="bg-light-grey p-10 m-10-0">
                      <div class="text-center text-blue">{{valueEur | eur}}</div>
                      <div class="text-center text-blue">{{valueHuf | huf}}</div>
                    </div>
                    
                  </div>

                  <div class="text-center helper-text text-small">{{exchange.date | dateDate }}</div>
                  
                </mat-menu>
              </ng-container>

              <span class="margin-r-10 d-none d-lg-block">
                <input (change)="setToday()" id="headerTodayInput" type="date" value="{{today}}">
              </span>
              
              <span *ngIf="user" class="d-none d-lg-block">{{user.firstName}}</span>
              <button mat-icon-button (click)="logout()">
                <mat-icon color="warn">exit_to_app</mat-icon>
              </button>   
          </mat-toolbar-row>
        </mat-toolbar>
        <div class="page-container container-fluid">
          <router-outlet></router-outlet>
        </div>
      </div>
</mat-drawer-container>
  <!--end:page-->

  <!--login-->
<ng-template #notLoggedIn class="page-container container-fluid">
  <router-outlet></router-outlet>
</ng-template>





      