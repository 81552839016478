<div class="padding-10-0 text-center">Tulajdonság Csoport Kersés</div>
<div class="flex">
    <form class="full">
        <mat-form-field appearance="outline">
            <mat-icon class="pointer text-red"  (click)="delete()" matSuffix matTooltip="Töröl">delete</mat-icon>
            <input matInput name="txtSearch" (keyup)="search()" [(ngModel)]="txtSearch" placeholder="Keresés..."  [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
            <mat-option (click)="select(option)" *ngFor="let option of options" [value]="option">
                {{option.nameHu}}
            </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form>
    <div class="padding-l-10">
        <button mat-mini-fab  color="accent" matTooltip="Tulajdonság Csoport létrhozás" (click)="openCreateDlg()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>

