<div mat-dialog-title >
    <div class="flex">
        <div class="full text-small">
            Raktári Termék Keresés
        </div>
        <div>
            <button mat-mini-fab  color="accent" (click)="openItemCreateDlg()"  matTooltip="új Raktári Termék">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
</div>

<div mat-dialog-content>
<form class="padding-10-0">

            <div class="text-tiny text-center link m-10-0">
                <span *ngIf="!searchByOrg" (click)="searchByOrg=true">Keresés Cég Szerint</span>
                <span *ngIf="searchByOrg" (click)="searchByOrg=false">Keresés Raktári Termék Szerint</span>
                &rarr;
            </div>
           
    
            <ng-container *ngIf="searchByOrg; else tmpSearchByItem">
                <!-- search in the OrgItem by Org -->
                <div class="text-tiny text-light-grey">Keresés Cég Szerint</div>
                <app-org-selector [org]="org" (onChange)="setOrg($event)"></app-org-selector>
            </ng-container>

            <ng-template #tmpSearchByItem>
                <!-- search item be item-->
                <div class="padding-10">
                    <mat-radio-group (change)="changeType($event)" [disabled]="disableTypeSelection">
                        <mat-radio-button [checked]="productType === 'machine'" value="machine">Gép</mat-radio-button>
                        <mat-radio-button [checked]="productType === 'part'" value="part">Alkatrész</mat-radio-button>
                        <mat-radio-button [checked]="productType === 'service'" value="service">Szolgáltatás</mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-form-field appearance="outline">
                    <mat-icon class="pointer text-red"   (click)="delete()" matSuffix matTooltip="Töröl">close</mat-icon>
                    <input matInput name="txtSearch" (keyup)="search()" [(ngModel)]="txtSearch" placeholder="Keresés (3+ karaktersz)"  [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                    <mat-option (click)="select(option)" *ngFor="let option of options" [value]="option">
                        <div class="flex">
                            <app-icon-item-type [itemType]="option._product.type"></app-icon-item-type>
                            <div>     
                                <span matTooltip="{{option._product.nameHu}}">{{option._product.nameHu | truncate:3}}</span>
                                &nbsp;
                                <!--only show for items with serial number becuase in this case it is relevant becuase only one exist (for Parts it makes no sense)-->
                                <span *ngIf="option.serialNumber"  matTooltip="{{option._organization.name}}" class="text-yellow text-small">{{option._organization.name | truncate:2}}</span>
                                <div class="text-secondary text-small">
                                    {{option._product.articleNumber}} {{option.serialNumber}}
                                </div>      
                            </div>
                        </div>
                    </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-template>

    
    
    

    <ng-container *ngIf="item && (item.id | typeof) == 'number'">
        <div class="alert-info p-10 m-10-0">
            <div class="text-right text-red text-small">
                    <mat-icon class="pointer" (click)="resetData()" matTooltip="Újrakezdés">close</mat-icon>
            </div>
            <div class="text-yellow">{{item._organization.name}}</div>
            <app-item-container [item]="item"></app-item-container>
        </div>
       
    </ng-container>

    <div class="flex padding-l-10">
        <div class="full">
            <button mat-mini-fab  color="primary" (click)="save()"  matTooltip="Kiválaszt">
                <mat-icon>done</mat-icon>
            </button>
        </div>
    </div>
</form>
</div>

