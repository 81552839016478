import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Item } from 'src/app/models/item.model';
import { SetNewPasswordComponent } from 'src/app/public/set-new-password/set-new-password.component';
import { DlgSearchItemComponent } from 'src/app/shared/dialogs/search/dlg-search-item/dlg-search-item.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-item-selector',
  templateUrl: './item-selector.component.html',
  styleUrls: ['./item-selector.component.scss']
})
export class ItemSelectorComponent implements OnInit, OnChanges {
  @Input() item: Item;

  @Output() onChange: EventEmitter<Item> = new EventEmitter();

  name = '';

  dlgItemSearch$;

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
   this.setName();
  }

  setName() {
    if (this.item) {
      this.name = this.item._product.nameHu;

      if (this.item._product.articleNumber) {
        this.name += ' - ' + this.item._product.articleNumber;
      }

      if (this.item.serialNumber) {
        this.name += ' - ' + this.item.serialNumber;
      }
    } else {
      this.name = '';
    }
  }

  openSearchDlg() {
    const dialogConfig = new MatDialogConfig();
    
    dialogConfig.data = {
      txtSearch: this.name,
    };

    const dialogRef = this.dialog.open(DlgSearchItemComponent, dialogConfig);

    this.dlgItemSearch$ = dialogRef.componentInstance.onSave.subscribe(item => {
      this.item = item;
      this.setName();
      this.onChange.emit(this.item);
      dialogRef.close()
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.setName();
  }

}
