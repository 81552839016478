import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from './../notification';
import { ProjectActionPlan } from '../../models/projects/projectActionPlan.model';
import { Utils } from '../../shared/utils';
import { ListResponse } from '../../interfaces/httpResponse.interface';


@Injectable({
    providedIn: 'root'
  })
export class PlanningProjectActionsService {
    baseUrl = environment.baseUrl;

    URL_PROJECT_ACTIONS_PLAN = '/project-plans/project-actions/';

    // list items
    dataSource$ = new BehaviorSubject<any[]>([])
    data = this.dataSource$.asObservable();

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {}

    list(projectPlanId) {
        // list all Actions Plans for a project paln
        // - projectPlanId is mandatory
        if (!projectPlanId) {
            throw 'Only by Project'
        }

        let params = {project: projectPlanId}

        let url = this.baseUrl + this.URL_PROJECT_ACTIONS_PLAN;

        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res = res.map(i => new ProjectActionPlan(i));
                return res;
            })
        )
    }

    save(projectId, payload) {
        // save the list of action plans
        let url = this.baseUrl + this.URL_PROJECT_ACTIONS_PLAN + '?projectId=' + projectId;
        return this.http.post<any>(url, payload).pipe(
            map(res => {
                res = res.map(i => new ProjectActionPlan(i));
                return res;
            }),
            tap(res => { this.notificationService.created(); })
        )
    }

    get() {
        throw 'Not Implemented!';
     }
}