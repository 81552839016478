import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Organisation } from 'src/app/models/organisation';
import { People } from 'src/app/models/people.model';
import { DlgSearchPeopleComponent } from 'src/app/shared/dialogs/search/dlg-search-people/dlg-search-people.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-people-selector',
  templateUrl: './people-selector.component.html',
  styleUrls: ['./people-selector.component.scss']
})
export class PeopleSelectorComponent implements OnInit, OnChanges {
  @Input() org: Organisation;
  @Input() people: People;

  name: string = '';

  @Output() onChange: EventEmitter<People> = new EventEmitter();

  dlgPeopleSearch$;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
   this.setName();
  }

  ngOnChanges(changes: SimpleChanges): void {
     this.setName();
  }

  setName() {
    if (this.people) {
      this.name = this.people.fullName;
    }
  }

  openSearchDlg() {
    const dialogConfig = new MatDialogConfig();
    
    // current company name
    let name = (this.people) ? this.people.fullName : '';
    
    dialogConfig.data = {
      txtSearch: name,
      organisation: this.org
    };

    const dialogRef = this.dialog.open(DlgSearchPeopleComponent, dialogConfig);

    this.dlgPeopleSearch$ = dialogRef.componentInstance.onChange.subscribe(people => {
      this.name = people.fullName;
      this.people = people;
      this.onChange.emit(this.people);
    })
  }

}
