import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../services/company.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { Company } from '../../models/company.model';
import { FileUploadService } from '../../services/fileUpload.service';
import { EnumFileUploadType } from '../../enums/fileUploadType.enum';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  company: Company = null;

  comp$;
  compUpdate$;
  upload$;

  constructor(
    private companyService: CompanyService,
    private fileUploadService: FileUploadService,
    private modelFactory: ModelFactory
  ){
    this.company = modelFactory.getNewModel(Company)
  }

  ngOnInit() {
    this.comp$ = this.companyService.load().subscribe((res: Company) => {
      this.company = res;
    })
  }

  update() {
    this.compUpdate$ = this.companyService.update(this.company).subscribe(company => {
      this.company = company;
    })
  }

  onLogoUpload(formData) {
    this.upload$ = this.fileUploadService.upload(formData, EnumFileUploadType.CompanyLogo, this.company).subscribe(res => {
      this.ngOnInit();
    });
  }

  onPdfHeaderUpload(formData) {
    this.upload$ = this.fileUploadService.upload(formData, EnumFileUploadType.CompanyPdfHeader, this.company).subscribe(res => {
      this.ngOnInit();
    });
  }

  onStampUpload(formData) {
    this.upload$ = this.fileUploadService.upload(formData, EnumFileUploadType.CompanyStamp, this.company).subscribe(res => {
      this.ngOnInit();
    });
  }

}
