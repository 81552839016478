/** Created for the Customer Offer - simple list of the products */

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-default-config-product-list',
  templateUrl: './default-config-product-list.component.html',
  styleUrls: ['./default-config-product-list.component.scss']
})
export class DefaultConfigProductListComponent implements OnInit {
  @Input() defaultConfig: any;

  // default text before the list of the products
  // add the text which is best fit for the template type
  titleHu: string;
  titleEn: string;

  constructor() { }

  ngOnInit(): void {
    if (this.defaultConfig?.titleHu) {
      this.titleHu = this.defaultConfig.titleHu;
    }

    if (this.defaultConfig?.titleEn) {
      this.titleEn = this.defaultConfig.titleEn;
    }
  }

  setTitleHu(titleHu) {
    this.titleHu = titleHu;
    this.defaultConfig['titleHu'] = titleHu;
  }

  setTitleEn(titleEn) {
    this.titleEn = titleEn;
    this.defaultConfig['titleEn'] = titleEn;
  }

}
