import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseTaskEntity } from 'src/app/common/baseClasses/baseTaskEntity';
import { ProjectService } from 'src/app/services/projects/project.service';
import { SecurityService } from 'src/app/services/security.service';
import { TaskService } from 'src/app/services/tasks/task.service';
import { TaskProductService } from 'src/app/services/tasks/taskProduct.service';


@Component({
  selector: 'app-task-customer-handover',
  templateUrl: './task-customer-handover.component.html',
  styleUrls: ['./task-customer-handover.component.scss']
})
export class TaskCustomerHandoverComponent  extends BaseTaskEntity implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private _taskService: TaskService,
    private _projectService: ProjectService,
    private _taskProductService: TaskProductService,
    private _ss: SecurityService
  ) {
    super(_taskService, _taskProductService, _projectService, _ss);
  }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id');

    if (id) {
      this.fetchTaskById(id);
    }
  }

}
