import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseList } from 'src/app/common/baseClasses/baseList';
import { AttributeService } from 'src/app/services/attribute.service';
import { UrlParamService } from 'src/app/services/urlParam.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['./attributes.component.scss'],
  providers: [UrlParamService]
})
export class AttributesComponent extends BaseList implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['nameHu', 'group'];

  attr$;

  constructor(
    private attributeService: AttributeService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService

  ) {
    super();

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit() {
    let defaultParams = {}

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      }
      
      this.attr$ = this.attributeService.list(options).subscribe(data => {
        this.setListResponseData(data);
       });
    });
  }

  open(entity) {
    this.router.navigate(['attribute'],  {
      queryParams: {id: entity.id}, 
      relativeTo: this.route  
    });
  }

  lookup() {
    this.doSearch();
  }

}
