import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { cloneDeep } from 'lodash-es';
import { Project } from 'src/app/models/projects/project.model';
import { ProjectForecast } from 'src/app/models/projects/projectForecast.model';
import { TaskProduct } from 'src/app/models/tasks/taskProduct.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { ProjectForecastService } from 'src/app/services/projects/projectForecast.service';
import { DlgEditProjectForecastComponent } from 'src/app/shared/dialogs/edit/dlg-edit-project-forecast/dlg-edit-project-forecast.component';
import { DlgSearchProductComponent } from 'src/app/shared/dialogs/search/dlg-search-product/dlg-search-product.component';
import { DlgSearchProjectProductsComponent } from 'src/app/shared/dialogs/search/dlg-search-project-products/dlg-search-project-products.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-project-forecast',
  templateUrl: './project-forecast.component.html',
  styleUrls: ['./project-forecast.component.scss']
})
export class ProjectForecastComponent implements OnInit {
  @Input() project: Project

  porjectForecasts: ProjectForecast[] = [];

  list$;
  dlgProjSearch$;
  dlgForeEdit$;
  foreDel$;
  dlgProdSearch$;
  foreCreate$;

  constructor(
    private projectForecastService: ProjectForecastService,
    private modelFactory: ModelFactory,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    let params = {project: this.project.id, limit: 1000};

    this.list$ = this.projectForecastService.list({params}).subscribe(resp => {
      this.porjectForecasts = resp.results;
    })
  }

  openProjectProductSearch() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.disableClose =  true
    dialogConfig.data = {
      project: this.project
    };

    const dialogRef = this.dialog.open(DlgSearchProjectProductsComponent, dialogConfig);

    this.dlgProjSearch$ = dialogRef.componentInstance.onSelect.subscribe((taskProducts) => { 
      taskProducts.forEach(tP => {
        let projectForecast = this.createProjectForcast(tP)
        this.projectForecastService.create(projectForecast).subscribe(res => {
          this.porjectForecasts.push(res);
        });
      });
    });
  }

  createProjectForcast(taskProduct:TaskProduct) {
    let projectForecast = this.modelFactory.getNewModel(ProjectForecast);
    projectForecast.product = taskProduct._product.id;
    projectForecast._product = taskProduct._product;
    projectForecast.project = this.project.id;
    projectForecast._project = this.project;
    projectForecast.active = false;

    if (taskProduct.costPrice) {
      projectForecast.ek_vetel = taskProduct.costPrice
    }

    if (taskProduct.sellingPrice) {
      projectForecast.vk_eladas = taskProduct.sellingPrice
    }
    
    return projectForecast;
  }

  openProjectForecastEdit(projectForecast) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '450px';
    dialogConfig.disableClose =  true
    dialogConfig.data = {
      projectForecast: projectForecast,
    };

    const dialogRef = this.dialog.open(DlgEditProjectForecastComponent, dialogConfig);

    this.dlgForeEdit$ = dialogRef.componentInstance.onChange.subscribe((_projectForecast) => {
      if (!_projectForecast) {
        // deleted - remove it
        this.porjectForecasts = this.porjectForecasts.filter(pf => pf.id != projectForecast);
      }
      
      this.porjectForecasts.forEach(f => this.projectForecastService.updateCalcualtedFields(f));
    });
  }

  edit(p) {
    this.openProjectForecastEdit(p);
  }

  delete(p) {
    this.foreDel$ = this.projectForecastService.delete(p).subscribe(res => {
      this.porjectForecasts = this.porjectForecasts.filter(pf => pf.id != p.id);
    
    })
  }

  openProductSearchDlg() {
    const dialogConfig = new MatDialogConfig();
    
    dialogConfig.data = {
      name: ''
    };

    const dialogRef = this.dialog.open(DlgSearchProductComponent, dialogConfig);

    this.dlgProdSearch$ = dialogRef.componentInstance.onSelected.subscribe(product => {
      if (product) {
        let taskProduct = this.modelFactory.getNewModel(TaskProduct);
        taskProduct._product = product;
        taskProduct.product = product.id;

        let projectForecast = this.createProjectForcast(taskProduct);
      
        this.foreCreate$ = this.projectForecastService.create(projectForecast).subscribe(res => {
          this.porjectForecasts.push(res);
        });
      }
    });
  }

}
