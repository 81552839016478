export class TaskCustomerDelivery {
    task: number = null;
    _task: any = null;

    fromAddressCountry: string = 'hungary';
    fromAddressPostcode: string = null;
    fromAddressCity: string = null;
    fromAddressLine: string = null;

    toAddressCountry: string = 'hungary';
    toAddressPostcode: string = null;
    toAddressCity: string = null;
    toAddressLine: string = null;

    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.task = data.task;
            if (data._task) {
                this._task = data._task;
            }
            
            this.fromAddressCountry = data.fromAddressCountry;
            this.fromAddressPostcode = data.fromAddressPostcode;
            this.fromAddressCity = data.fromAddressCity;
            this.fromAddressLine = data.fromAddressLine;

            this.toAddressCountry = data.toAddressCountry;
            this.toAddressPostcode = data.toAddressPostcode;
            this.toAddressCity = data.toAddressCity;
            this.toAddressLine = data.toAddressLine;

            if (data.creator) {
                this.creator = data.creator;
            }
           
            if (data.lastModifier) {
                this.lastModifier = data.lastModifier;
            }
           
            if (data.created) {
                this.created = new Date(data.created);
            }
            
            if (data.updated) {
                this.updated = new Date(data.updated);
            }
        }  
    }
  }
