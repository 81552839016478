<form #f="ngForm">
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Név - HU</mat-label>
            <input matInput name="nameHu" [(ngModel)]="attribute.nameHu" required autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Név - EN</mat-label>
            <input matInput name="nameEn" [(ngModel)]="attribute.nameEn" required autocomplete="off">
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Tulajdonság Csoport</mat-label>
            <ng-container *ngIf="attribute.group; else noGroup">
                <input matInput name="group" disabled [(ngModel)]="attribute._group.nameHu" autocomplete="off" required>
            </ng-container>
            <ng-template #noGroup>
                <input matInput name="group" readonly [(ngModel)]="attribute.group" autocomplete="off" required>
            </ng-template>
            <mat-icon class="pointer" (click)="openAttributeGroupSearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>
        </mat-form-field>
    </p>
    <p>
        <span class="padding-20-0">
            <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
        <ng-container *ngIf="attribute.id">
            <span class="padding-20-0 padding-l-10">
                <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                    <mat-icon>delete</mat-icon>
                </button>
            </span>
            <span class="padding-l-10 padding-10-0">
                <app-quick-log-info [data]="attribute"></app-quick-log-info>           
            </span>
        </ng-container>
    </p>
</form>

