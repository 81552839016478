<form #f="ngForm">
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Árucikk Csoport</mat-label>
            <ng-container *ngIf="item.product; else noProduct">
                <input matInput name="product" disabled [(ngModel)]="item._product.nameHu" autocomplete="off" required>
            </ng-container>
            <ng-template #noProduct>
                <input matInput name="product" readonly [(ngModel)]="item.product" autocomplete="off" required>
            </ng-template>
            <mat-icon class="pointer" (click)="openProductSearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>
            <app-icon-item-type  *ngIf="item.product" matSuffix [itemType]=item._product.type></app-icon-item-type>
        </mat-form-field>
    </p>
    <p *ngIf="item.product && item._product.type != 'service'">
        <mat-form-field appearance="outline">
            <mat-label>Sorozat Szám</mat-label>
            <input matInput name="serialNumber" [(ngModel)]="item.serialNumber"  autocomplete="off" [required]="item.product && item._product.type == 'machine'">
        </mat-form-field>
        
    </p>
    <p *ngIf="item.product && item._product.type != 'service'">
        <mat-form-field appearance="outline">
            <mat-label>Polc szám</mat-label>
            <input matInput name="location" [(ngModel)]="item.location"  autocomplete="off">
        </mat-form-field>
    </p>
    <p *ngIf="item.product && item._product.type == 'machine'">
        <mat-form-field appearance="outline">
            <mat-label>Gyártási Év</mat-label>
            <input matInput name="yearOfManufacture" [(ngModel)]="item.yearOfManufacture"  autocomplete="off">
        </mat-form-field>
    </p>
    <p *ngIf="item.product && item._product.type == 'machine'">
        <mat-form-field appearance="outline">
            <mat-label>Üzemóra</mat-label>
            <input matInput name="runningHours" [(ngModel)]="item.runningHours"  autocomplete="off">
        </mat-form-field> 
    </p>
    <p *ngIf="item.product && item._product.type != 'service'">
        <mat-form-field appearance="outline">
            <mat-label>Minimum Raktár  Készlet</mat-label>
            <input type="number" pattern="^[0-9]\d*$" matInput name="alertStockLevel" [(ngModel)]="item.alertStockLevel"  autocomplete="off">
            <span matSuffix class="helper-text">{{item._product.unitHu}}</span>
            <mat-icon matPrefix  matTooltip="Amennyiben a Termék raktáron lévő mennyisége kivételt követően lecsökken az itt megadott mennyiségre vagy az alá a rendszer figyelmeztető emailt küld.">info</mat-icon>
        </mat-form-field>
        
    </p>
    <p class="padding-20-0">
        <span class="padding-20-0">
            <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
        <ng-container *ngIf="item.id">
            <span class="padding-20-0 padding-l-10">
                <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                    <mat-icon>delete</mat-icon>
                </button>
            </span>
            <span class="padding-l-10 padding-10-0">
                <app-quick-log-info [data]="item"></app-quick-log-info>           
            </span>
        </ng-container>
    </p>
</form>
