import { Organisation } from './organisation';
import { Product } from './product.model'

export class Item {
    id: number = null;
    product: number = null;
    _product?: any = null;
    serialNumber: string = null;
    location: string = null;
    alertStockLevel: number = null;
    yearOfManufacture: number = null;
    runningHours: number = null;

    _organization: any;
    
    migrated?: boolean = false;
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.product  = data.product;
            this.serialNumber = data.serialNumber;
            this.alertStockLevel = data.alertStockLevel;
            this.location = data.location;
            this.yearOfManufacture = data.yearOfManufacture;
            this.runningHours = data.runningHours;

            if ('_product' in data) {
                this._product = new Product(data._product);
            }

            if ('_organization' in data) {
                this._organization = new Organisation(data._organization);
            }

            this.migrated = data.migrated;
            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);

        }  
    }
  }
