<p class="padding-10-0">
    Bevezető szöveg mielött a termék lista beillsztésre kerül.
    <mat-icon matTooltip="Példa: Árajánlatkérésére hivatkozva a következő gépekről küldjük ezúton ajánlatunkat:">info</mat-icon>
</p>

<div class="helper-text">Magyar</div>
<app-html-container [content]="titleHu" (onChange)="setTitleHu($event)"></app-html-container>

<div class="helper-text">Angol</div>
<app-html-container [content]="titleEn" (onChange)="setTitleEn($event)"></app-html-container>


