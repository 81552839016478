import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseList } from 'src/app/common/baseClasses/baseList';
import { UrlParamService } from 'src/app/services/urlParam.service';
import { AttributeGroupService } from 'src/app/services/attributeGroup.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-attribute-groups',
  templateUrl: './attribute-groups.component.html',
  styleUrls: ['./attribute-groups.component.scss'],
  providers: [UrlParamService]
})
export class AttributeGroupsComponent  extends BaseList implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['nameHu', 'nameEn'];

  attList$;

  constructor(
    private attributeService: AttributeGroupService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService
  ) {
    super();

    
    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit() {
    let defaultParams = {}

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      }
      
      this.attList$ = this.attributeService.list(options).subscribe(data => {
        this.setListResponseData(data);
       });
    });
  }

  open(entity) {
    this.router.navigate(['group'],  {
      queryParams: {id: entity.id}, 
      relativeTo: this.route  
    });
  }

  lookup() {
    this.doSearch();
  }

}
