<div class="text-center">Biztos?</div>
<hr>
<div class="flex">
    <div class="full">
        <button class="mat-button-icon mat-success" (click)="onYes()" mat-fab >
            <mat-icon>done</mat-icon>
        </button>
    </div>
    <div> 
        <button class="mat-button-icon mat-danger" (click)="onNo()" mat-fab color="warm">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

