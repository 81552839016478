<mat-form-field appearance="outline">
    <mat-label>Státusz</mat-label>
    <mat-select name="status"  [(ngModel)]="status"  (selectionChange)="update()">
        <ng-container *ngIf="showAll">
            <mat-option [value]="">Összes</mat-option>
        </ng-container>
        <mat-option [value]="enumTaskStatus.NotStarted">
            Nem kezdődőtt el
        </mat-option>
        <mat-option [value]="enumTaskStatus.InProgress">
            Folyamatban
        </mat-option>
        <mat-option [value]="enumTaskStatus.Blocked">
            Elakadt
        </mat-option>
        <mat-option [value]="enumTaskStatus.Done">
            Befejeződőtt
        </mat-option>
        <mat-option [value]="enumTaskStatus.Skipped">
            Átugorva
        </mat-option>
    </mat-select>
    <!-- yes, the enum is the calss name :( -->
    <mat-icon matPrefix [ngClass]="status" *ngIf="status">brightness_1</mat-icon>
</mat-form-field>
