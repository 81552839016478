export class TaskEvent {
    id: number = null;

    name: string = null; // name of the activity if the activity type is other
    problem: string // descirption of the problem
    comment: string; // solution of the problem

    done: boolean = false;
    
    task: number = null;
    _task: any = null;

    project: number = null;

    assignee: number = null;
    _assignee: any = null;

    start: Date = null; // planned start time of the activity
    end: Date = null;  // planned end time of the activity

    actualStart: Date = null; // actual start time of the activity
    actualEnd: Date = null;  // actual end time of the activity

    pause: number = 0;  // break during the event

    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.name = data.name;
            this.problem = data.problem;
            this.comment = data.comment;
            this.done = data.done;
            this.pause = data.pause;
            this.project = data.project;

            this.assignee = data.assignee;
            if (data._assignee) {
                this._assignee = data._assignee;
            }

            if (data.start) {
                this.start = new Date(data.start);
            }

            if (data.end) {
                this.end = new Date(data.end);
            }

            if (data.actualStart) {
                this.actualStart = new Date(data.actualStart);
            }

            if (data.actualEnd) {
                this.actualEnd = new Date(data.actualEnd);
            }
           
            this.task = data.task;
            if ('_task' in data) {
                this._task = data._task;
            }

            if (data.creator) {
                this.creator = data.creator;
            }
           
            if (data.lastModifier) {
                this.lastModifier = data.lastModifier;
            }
           
            if (data.created) {
                this.created = new Date(data.created);
            }
            
            if (data.updated) {
                this.updated = new Date(data.updated);
            }
        }  
    }
  }
