
<app-default-price-table [defaultConfig]="pdfSection.defaultConfig" (onChange)="updateShowBrutto()"></app-default-price-table>

<p class="padding-10-0">
    <app-html-container [content]="pdfSection.title" (onChange)="pdfSection.title = $event"></app-html-container>
</p>


<table class="table">
    <thead>
        <tr>
            <th *ngFor="let col of data.headers">{{col}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let productData of data.products">
            <td>{{productData.name}}</td>
            <ng-container *ngIf="productData.active; else tmpInactive">
                <td>
                    <app-currency-container [value]="productData.unitPrice" [currency]="currency"></app-currency-container>
                </td>
                <td>{{productData.amount}}</td>
                <td>
                    <span  [ngClass]="(productData.discountPrice) ? 'text-line-through' : ''">
                        <app-currency-container [value]=" productData.netPrice" [currency]="currency"></app-currency-container>
                    </span>
                    <span class="w150 m-l-20">
                        <input  placeholder="Kevezményes ár" type="number" name="discount" (focusout)="calculateData()" [(ngModel)]="productData.discountPrice">
                    </span>          
                </td>
                <ng-container *ngIf="pdfSection.defaultConfig?.includeBrutto">
                    <td>
                        <app-currency-container [value]="productData.vat" [currency]="currency"></app-currency-container>
                    </td>
                    <td>
                        <app-currency-container [value]="productData.grossPrice" [currency]="currency"></app-currency-container>
                    </td>
                </ng-container>
            </ng-container>
            <ng-template #tmpInactive>
                <td colspan="99" class="helper-text">Minta Termék</td>
            </ng-template>
        </tr>
    </tbody>
    <tfoot>
            <tr>
                <td colspan="3" class="text-bold">
                    {{data.total.name}}
                </td>
                <td class="text-bold">
                    <app-currency-container [value]="data.total.netPrice" [currency]="currency"></app-currency-container>
                </td>
                <ng-container *ngIf="pdfSection.defaultConfig?.includeBrutto">
                    <td class="text-bold">
                        <app-currency-container [value]="data.total.vat" [currency]="currency"></app-currency-container>
                    </td>
                    <td class="text-bold">
                        <app-currency-container [value]="data.total.grossPrice" [currency]="currency"></app-currency-container>
                    </td>
                </ng-container>
            </tr>
    </tfoot>
</table>
