<div class="quick-log">
    <div class="text-center header"><mat-icon>policy</mat-icon></div>
    <table class="table">
        <tr *ngIf='data.updated'>
            <td class="text-secondary w100">Frissítve</td>
            <td  *ngIf='data.updated'>
                <div>{{data.updated | dateTime}}</div>
                <div *ngIf='data.lastModifier'> 
                <app-avatar [user]="data.lastModifier"></app-avatar>
                </div>
            </td>
        </tr>
        <tr >
            <td class="text-secondary w100">Létrhozva</td>
            <td>
                <div *ngIf='data.created'>{{data.created | dateTime}}</div>
                <div *ngIf='data.creator'>
                    <app-avatar [user]="data.creator"></app-avatar>
                </div>
            </td>
        </tr>
    </table>

    <ng-container *ngIf="isAdmin">
        <ng-container *ngIf="audits; else auditNotLoaded">
            <div class="table-responsive">
            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th>Mit?</th>
                        <th>Mikor?</th>
                        <th>Ki?</th>
                        <th>Részletek</th>
                    </tr>  
                </thead>
            
                    <ng-container *ngFor="let audit of audits">
                        <tr>
                            <td>
                                <ng-container *ngIf="audit.operation === 'create'">
                                    <span class="badge badge-success">Létrhozva</span>
                                </ng-container>
                                <ng-container *ngIf="audit.operation === 'update'">
                                    <span class="badge badge-warning">Frissitve</span>
                                </ng-container>
                                <ng-container *ngIf="audit.operation === 'delete'">
                                    <span class="badge badge-danger">Törölve</span>
                                </ng-container>
                            </td>
                            <td>{{audit.when | dateTime}}</td>
                            <td>{{audit.who_email}}</td>
                            <td>
                                <mat-accordion>
                                    <mat-expansion-panel>
                                      <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <span>Részletek</span>
                                        </mat-panel-title>
                                      </mat-expansion-panel-header>
                                            <table>
                                                <tr>
                                                    <th>Data</th>
                                                    <th>Régi</th>
                                                    <th>Új</th>
                                                </tr>
                                                <ng-container *ngFor="let field of audit.changes">
                                                    <tr>
                                                        <td>{{field.field}}</td>
                                                        <td>{{field.from}}</td>
                                                        <td>{{field.to}}</td>
                                                    </tr>
                                                </ng-container>
                                            </table>
                                    </mat-expansion-panel>
                                </mat-accordion>    
                            </td>
                        </tr>
                    </ng-container>
            </table>
            </div>
        </ng-container>

        <ng-template #auditNotLoaded>
            <tr>
                <td colspan="4">
                    <button mat-flat-button  color="primary" (click)="loadAudit()">
                        Log Információ
                    </button>
                </td>
            </tr>
        </ng-template>
    </ng-container>
    
</div>
