<ul class="breadcrumb"> 
    <li>Műszaki Adatok</li>  
</ul>


<div class="flex block-md"> 
    <div class="flex">
        <button (click)="lookup()" mat-mini-fab color="primary" [disabled]="txtSearch === ''">
            <mat-icon matSuffix>search</mat-icon>
        </button>
        <div class="padding-l-10">
            <mat-form-field appearance="standard">
                <mat-label>Keresés</mat-label>
                <input [(ngModel)]="txtSearch" matInput placeholder="?" (keyup.enter)="lookup()">
                <mat-icon  class="pointer" matTooltip="Keresés Törlése" (click)="noSearch()" matSuffix>close</mat-icon>
            </mat-form-field>
        </div>
    </div>

    <div class="full text-right margin-5">
        <span class="padding-5">
            <button mat-stroked-button  color="accent" [routerLink]="['/app/attributes/groups']"  matTooltip="Csoportok létrhozása Műszaki Adatokhoz"> 
                Műszaki Adatok Csoportok
            </button>
        </span>
        <span class="padding-5">
            <button mat-mini-fab  color="accent" [routerLink]="['attribute']"> 
                <mat-icon>add</mat-icon>
            </button>
        </span>
    </div> 
</div>


<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="nameHu">
        <mat-header-cell *matHeaderCellDef>Műszaki Adat</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="link">{{row.nameHu}}</span> 
            <span class="text-secondary text-small margin-l-10">({{row.nameEn}})</span>
        </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="group">
        <mat-header-cell *matHeaderCellDef>Csoport</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span *ngIf="row.group">{{row._group.nameHu}}</span>
            <span class="text-small text-secondary margin-l-10" *ngIf="row.group">({{row._group.nameEn}})</span>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns" class="pointer" (click)="open(row)"></mat-row>

</mat-table>


<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
></mat-paginator>

