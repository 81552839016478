
<ul class="breadcrumb"> 
    <li>Árucikk Csoport</li>  
</ul>

<div class="flex"> 
    <button (click)="lookup()" mat-mini-fab color="primary" [disabled]="txtSearch === ''">
        <mat-icon matSuffix>search</mat-icon>
    </button>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Keresés</mat-label>
            <input [(ngModel)]="txtSearch" matInput placeholder="?" (keyup.enter)="lookup()">
            <mat-icon  class="pointer" matTooltip="Keresés Törlése" (click)="noSearch()" matSuffix>close</mat-icon>
        </mat-form-field>
    </div>
    <div class="padding-l-10">
        <mat-radio-group (change)="changeType($event)">
            <mat-radio-button [checked]="type === 'machine'" value="machine">Gép</mat-radio-button>
            <mat-radio-button [checked]="type === 'part'" value="part">Alkatrész</mat-radio-button>
            <mat-radio-button [checked]="type === 'service'" value="service">Szolgáltatás</mat-radio-button>
          </mat-radio-group>
    </div>
    <div class="full text-right">
        <span class="padding-l-10">
            <button mat-mini-fab  color="accent" [routerLink]="['product']"  matTooltip="Új  Árucikk Csoport"> 
                <mat-icon>add</mat-icon>
            </button>
        </span>
    </div> 
</div>

<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="manufacturer">
        <mat-header-cell *matHeaderCellDef>Gyártó</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row._manufacturer">
                <ng-container *ngIf="row._manufacturer.imageUrl; else txtManufacturer">
                    <img [src]="row._manufacturer.imageUrl" loading="lazy">
                </ng-container>
                <ng-template #txtManufacturer>
                    <span class="text-secondary cut-off" matTooltip="{{row._manufacturer.name}}">{{row._manufacturer.name}}</span>
                </ng-template>
            </ng-container>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="nameHu">
        <mat-header-cell *matHeaderCellDef>Termék</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <a href="#"  class="link"  routerLink="/app/products/product"  [queryParams]="{id: row.id}">
                <app-product-container [product]="row"></app-product-container>
            </a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef>Kategória</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.category">
                <span class="cut-off">{{row._category.nameHu}}</span>
            </ng-container>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns" class="pointer"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
    [showFirstLastButtons]="true"
></mat-paginator>
