import { Pipe, PipeTransform } from '@angular/core';

// from a date string create a date object
// use with other pipes to format the date as you wish
@Pipe({name: 'strToDate'})
export class StrToDate implements PipeTransform {
  transform(dt_string: string): Date {
    if (!dt_string) return null;
    
    let dt = new Date(dt_string);
    
    return dt;
  }
}