export class Inventory {
    id: number = null;
    stock: number = null;
    price: number = null;
    item: number = null;
    _item: any = null;
    foreignItem: boolean = false;
    
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.stock = data.stock;
            this.price = data.price;
            this.foreignItem = data.foreignItem;

            this.item = data.item;
            if (data._item) {
                this._item = data._item;
            }

            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }  
    }
  }

  export class InventoryTransaction {
    id: number = null;
    quantity: number = null;
    price: number = null;
    item: number = null;
    _item: any = null;
    direction: string = null;
    reason: string = null;
    foreignItem: boolean = false;

    organization: number;
    _organization: any;

    builtin: number;
    _builtin: any;
    
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.quantity = data.quantity;
            this.price = data.price;
            this.direction = data.direction;
            this.reason = data.reason;
            this.foreignItem = data.foreignItem;

            this.item = data.item;
            if (data._item) {
                this._item = data._item;
            }

            this.organization = data.organization;
            if (data._organization) {
                this._organization = data._organization;
            }

            this.builtin = data.builtin;
            if (data._builtin) {
                this._builtin = data._builtin;
            }

            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
            this.created = new Date(data.created);
            this.updated = new Date(data.updated);
        }  
    }
  }