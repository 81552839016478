import { Component, Input, OnInit } from '@angular/core';
import { EnumFileUploadType } from 'src/app/enums/fileUploadType.enum';
import { Product, ProductImage } from 'src/app/models/product.model';
import { FileUploadService } from 'src/app/services/fileUpload.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { ProductService } from 'src/app/services/product.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.scss']
})
export class ProductImagesComponent implements OnInit {
  @Input() product: Product;
  productImages: ProductImage[];

  imgGet$;
  imgUpdate$;
  imgDel$;
  upload$;
  imgCreate$;

  constructor(
    private productService: ProductService,
    private fileUploadService: FileUploadService,
    private modelFacotry: ModelFactory
  ) { }

  ngOnInit() {
    this.fetch();
  }

  fetch() {
    this.imgGet$ = this.productService.getProductImages(this.product.id).subscribe(res => {
      this.productImages = res;
    })
  }

  setDefault(productImage) {
    productImage.default = true;
    
    this.imgUpdate$ = this.productService.updateProductImage(productImage).subscribe(res => {
      // unset every picture default to false
      this.productImages.forEach(pI => pI.default = false);
     
      this.productImages.forEach(pI => {
        if (pI.id == res.id) {
          pI.default = true;
        }
      })
    });
  }

  delete(productImage) {
    this.imgDel$ = this.productService.deleteProductImage(productImage).subscribe(() => {
      this.productImages = this.productImages.filter(pI => pI.id != productImage.id);
    });
  }

  upload(formData) {
    // create the productimage and upload the file
    let productImage = this.modelFacotry.getNewModel(ProductImage);
    productImage.product = this.product.id;
    productImage._product = this.product;

    //create the product image
    this.imgCreate$ = this.productService.createProductImage(productImage).subscribe(res => {
      this.upload$ = this.fileUploadService.upload(formData, EnumFileUploadType.ProductImage, res).subscribe(res => {
        this.ngOnInit();
      });
    });
  }

}
