import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-item-type',
  templateUrl: './icon-item-type.component.html',
  styleUrls: ['./icon-item-type.component.scss']
})
export class IconItemTypeComponent implements OnInit {
  @Input() itemType: string;
  
  constructor() { }

  ngOnInit() {
  }

}
