
<ng-container *ngIf="item; else tmpItemNotFound">
    <a href="#" class="link"  routerLink="/app/items/item"  [queryParams]="{id: item.id}">
        <app-icon-item-type [itemType]="item._product.type"></app-icon-item-type>
    </a>
    {{item._product.nameHu | truncate: 5}}
    <span class="badge badge-info" *ngIf="item._product.articleNumber" matTooltip="Cikkszám">{{item._product.articleNumber}}</span>&nbsp;
    <span class="badge badge-warning" *ngIf="item.serialNumber" matTooltip="Sorozatszám">{{item.serialNumber}}</span>
    <div class="helper-text" *ngIf="showLocation && item.location">Polc szám {{item.location}}</div>
</ng-container>

<ng-template #tmpItemNotFound>
    <span class="text-red">Raktári termék nem találhato</span>
</ng-template>

