import { Component, OnInit, Input } from '@angular/core';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { UserAccess } from 'src/app/models/userAccess.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { UserAccessService } from 'src/app/services/userAccess.service';

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.scss']
})
export class UserAccessComponent  extends BaseEntity implements OnInit {
  @Input() userId: number;

  userAccesses: UserAccess[];

  modules = [];
  // list of moudules where access is not set yet
  availableModuels = null;

  // reset the Module Select to the firt one
  defaultSelect = '?';


  constructor(
    private userAccessService: UserAccessService,
    private modelFactory: ModelFactory
  ) {
    super()
  }

  ngOnInit(): void {
    this.fetchUserAccess();
    this.modules = this.userAccessService.getModules();
  }

  fetchUserAccess() {
    let params = {
      user: this.userId,
      limit: 1000
    }
    this.userAccessService.list({params}).subscribe(res => {
      this.userAccesses = res.results;

       // set the list of modules which are not already in use
       this.updateAvailableModuels();
    })
  }

  updateAvailableModuels() {
    // the selected list of Moduels changed -> update available modules for the dropdown

    //get a fresh list
    this.availableModuels = JSON.parse(JSON.stringify(this.modules));

    this.userAccesses.forEach(ua => {
      if (this.availableModuels.indexOf(ua.module) > -1) {
        // get rid of from the list
        this.availableModuels.splice(this.availableModuels.indexOf(ua.module), 1);
      }
    });
  }

  save(userAccess) {
    if (typeof userAccess.id === 'string') {
      // create
      this.create(userAccess);
    } else {
      this.update(userAccess);
    }
  }

  create(userAccess) {
    this.userAccessService.create(userAccess).subscribe(res => {
      // fitler out the dummy email
      this.userAccesses = this.userAccesses.filter(e => e.module != res.module);
      // add the newly created to the list
      this.userAccesses.push(res)
    })
  }

  update(userAccess) {
    this.userAccessService.update(userAccess).subscribe()
  }

  add($event) {
    // add new element to the list

    if (!$event.value) {
      return;
    }

    let userAccess = this.modelFactory.getNewModel(UserAccess)
    userAccess.user = this.userId;
    userAccess.module = $event.value;
    userAccess.level = 0;
    userAccess.id = this.getNextId();
    
    this.userAccesses.push(userAccess);

    this.updateAvailableModuels()
  }

  delete(roleAccess) {
    if (typeof roleAccess.id === 'string') {
      // delete only from the list
      this.userAccesses = this.userAccesses.filter(ra => ra.module !== roleAccess.module);
      this.updateAvailableModuels();
    } else {
      // delete from the server
      this.userAccessService.delete(roleAccess).subscribe(res => {
        this.userAccesses = this.userAccesses.filter(ra => ra.id !== roleAccess.id);
        this.updateAvailableModuels();
      });
    }
  }

}
