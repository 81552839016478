<app-file-upload (onFileSelect)="upload($event)"></app-file-upload>

<div class="flex flex-wrap">
    <div *ngFor="let productImage of productImages" style="width: 300px; margin-right: 20px;">
        <a target="_blank" href="{{productImage.file}}">
            <img class="img-thumbnail" src="{{productImage.file}}"
            [ngStyle]="{'border': (productImage.default) ? '4px solid orange' : '1px solid #ffffff'}">
        </a>  
        <div class="flex padding-20-0">
            <div class="full">
                <button mat-stroked-button color="primary" [disabled]="productImage.default"  (click)="setDefault(productImage)"  matTooltip="Beállítás fő képként" *ngIf="!productImage.default; else default">
                    Fő kép
                </button>
                <ng-template #default><span class="badge badge-warning">Fő kép</span></ng-template>
            </div>
            <button mat-mini-fab  class="mat-fab mat-danger" matTooltip="Kép törlése"  (confirm-click)="delete(productImage)" confirm>
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        
    </div>
</div>