<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Sablonok</a></li> 
    <ng-container *ngIf="template">
        <li>{{template.name}}</li>  
    </ng-container> 
</ul>

<mat-tab-group animationDuration="0ms">
    <mat-tab label="Sablon">
        <ng-template *ngTemplateOutlet="tmpl"></ng-template>
    </mat-tab>
    <mat-tab label="Felépítés" *ngIf="template.id">
        <ng-template matTabContent>
            <ng-template matTabContent *ngTemplateOutlet="tmplSections"></ng-template>
        </ng-template>  
    </mat-tab>
</mat-tab-group>


<!--template-->  
<ng-template #tmpl>
<form #f="ngForm">
    <div class="section">
    <div class="header flex">
        <div class="full">
            <div class="title"><span>Sablon</span></div>
        </div>   
    </div>

  
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Név</mat-label>
                <input matInput name="name" [(ngModel)]="template.name"  autocomplete="off" required>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Leírás</mat-label>
                <textarea rows=3 matInput name="description" [(ngModel)]="template.description"  autocomplete="off"></textarea>
            </mat-form-field>
        </p>
        <p>
            <app-template-category-selector 
            [category]="template.category" 
            [disabled]="(this.template.id) ? true : false"
            [required]="(this.template.id) ? false : true"
            (onSelect)="template.category=$event"></app-template-category-selector>
        </p> 
        <p *ngIf="(template.id | typeof) != 'number'; else textType">
            <span class="margin-r-10">Pdf vagy Email Sablon?</span>
            <mat-radio-group required (change)="changeType($event)">
                <mat-radio-button [checked]="template.type == 'pdf'" value="pdf">Pdf</mat-radio-button>
                <mat-radio-button [checked]="template.type == 'email'" value="email">Email</mat-radio-button>
            </mat-radio-group>
        </p>
        <ng-template #textType>
            <p>
               <span>Típus</span>: <app-icon-template-type [template]='template'></app-icon-template-type>
            </p>  
        </ng-template>
    </div>

    <div class="section" *ngIf="template.type == 'pdf'">
        <div class="header flex">
            <div class="full">
                <div class="title"><span>Genrárált PDF Fájlnév</span></div>
            </div>   
        </div>
        <div class="helper-text padding-10-0">A fájl nevek csak betűket (ékezet nélkül) és számokat tartalmazhat, valamint alulvonást (_)
        </div>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Fájl Név Magyarul</mat-label>
                <input matInput name="fileNameHu" [(ngModel)]="template.fileNameHu" pattern="[a-zA-Z0-9_]+" autocomplete="off" required>
                <mat-icon matSuffix matTooltip="A generált pdf fájlnév ami a generálás során kiegészülhet a dátummal és egydi azonosítóval">info</mat-icon>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Fájl Név Angolul</mat-label>
                <input matInput name="fileNameEn" [(ngModel)]="template.fileNameEn" pattern="[a-zA-Z0-9_]+" autocomplete="off" required>
            </mat-form-field>
        </p>
    </div>
        
    <p class="flex padding-10-0">
        <span class="padding-l-10">
            <button mat-mini-fab  color="primary" (click)="save()"  [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
        <span class="padding-l-10">
            <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                <mat-icon>delete</mat-icon>
            </button>
        </span>
        <span class="padding-l-10">
            <app-quick-log-info [data]="template"></app-quick-log-info>
        </span>
    </p>              
</form>
</ng-template> 


<!--sections-->
<ng-template #tmplSections>
    <div class="flex flex-right">
      <span class="padding-10">
        <button mat-mini-fab  color="primary" (click)="saveSections()" matTooltip="Mentés">
            <mat-icon>save</mat-icon>
        </button>
      </span>
    </div>

    <ng-container *ngIf="sections.length > 0">
        <mat-accordion>
            <ng-container *ngFor="let section of sections; let first = first; let last = last">
                <mat-expansion-panel>
                <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="text-help margin-r-10">{{section.order | orderNumber}}</span>
                    <span class="margin-r-10">
                        <ng-container *ngIf="!first">
                            <mat-icon class="cursor text-green" (click)="moveUp(section)" click-stop-propagation>arrow_circle_up</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="!last">
                            <mat-icon class="cursor text-red" (click)="moveDown(section)" click-stop-propagation>arrow_circle_down</mat-icon>
                        </ng-container>
                    </span>
                    <ng-container *ngIf="!section.defaultSection">
                        <span *ngIf="section.titleHu" [innerHtml]="section.titleHu|stripHtml"></span>
                    </ng-container>
                    <span *ngIf="section.defaultSection == 'pdf_header'">Fejléc (partner és saját adatok)</span>
                    <span *ngIf="section.defaultSection == 'product_list'">Termék Lista</span>
                    <span *ngIf="section.defaultSection == 'price_table'">Ár Táblázat</span>
                    <span *ngIf="section.defaultSection == 'products_marketing'">Termékek Bemutatása</span>
                    <span *ngIf="section.defaultSection == 'signiture_and_stamp'">Elköszönás és Aláíras</span>
                    <span *ngIf="section.defaultSection == 'product_table'">Termék Táblázat</span>
                    <span *ngIf="section.defaultSection == 'invoice_and_delivery_address'">Számlázási and szállítási címek</span>
                    <span *ngIf="section.defaultSection == 'vat_number'">Saját adószám</span>
                    <span *ngIf="section.defaultSection == 'customer_confirmation'">Visszaigazolás</span>
                </mat-panel-title>
                </mat-expansion-panel-header>
                    <p>
                        <!-- Default Section-->
                        <ng-container *ngIf="section.defaultSection; else customSection">
                            <ng-container  *ngIf="section.defaultSection == 'signiture_and_stamp'">
                                <app-default-config-signature [defaultConfig]="section.defaultConfig"></app-default-config-signature>
                            </ng-container>
                            <ng-container  *ngIf="section.defaultSection == 'product_list'">
                                <app-default-config-product-list [defaultConfig]="section.defaultConfig"></app-default-config-product-list>
                            </ng-container>
                            <ng-container  *ngIf="section.defaultSection == 'products_marketing'">
                                <app-default-config-marketing [defaultConfig]="section.defaultConfig"></app-default-config-marketing>
                            </ng-container>
                            <ng-container  *ngIf="section.defaultSection == 'price_table'">
                                <app-default-price-table [defaultConfig]="section.defaultConfig"></app-default-price-table>
                            </ng-container>
                            <ng-container  *ngIf="section.defaultSection == 'product_table'">
                                <app-default-config-product-table [defaultConfig]="section.defaultConfig"></app-default-config-product-table>
                            </ng-container>
                            <button mat-icon-button  color="warn"  matTooltip="Eltávolítás" (confirm-click)="deleteSection(section)" confirm>
                                <mat-icon>delete</mat-icon>
                            </button>
                        </ng-container >
                        
                        <!-- Custom Section -->
                        <ng-template #customSection section>
                            <form #f="ngForm">
                                <mat-tab-group animationDuration="0ms">
                                    <mat-tab label="Magyar">
                                        <div [ngClass]="(section.columns) ? 'container' : ''">
                                            <div  [ngClass]="(section.columns)? 'row' : ''">      
                                                <div [ngClass]="(section.columns)?'col-6':'padding-10-0'">
                                                    <app-html-container [content]="section.titleHu" (onChange)="updateSectionTitleHu($event, section)"></app-html-container>
                                                </div>
                                                <div  [ngClass]="(section.columns)?'col-6':''">
                                                    <app-html-container [content]="section.bodyHu" (onChange)="updateSectionBodyHu($event, section)"></app-html-container>
                                                </div>   
                                            </div>
                                        </div>
                                    </mat-tab>
                                    <mat-tab label="Angol">
                                        <div [ngClass]="(section.columns) ? 'container' : ''">
                                            <div  [ngClass]="(section.columns)? 'row' : ''">      
                                                <div [ngClass]="(section.columns)?'col-6':'padding-10-0'">
                                                    <app-html-container [content]="section.titleEn" (onChange)="updateSectionTitleEn($event, section)"></app-html-container>
                                                </div>
                                                <div  [ngClass]="(section.columns)?'col-6':''">
                                                    <app-html-container [content]="section.bodyEn" (onChange)="updateSectionBodyEn($event, section)"></app-html-container>
                                                </div>   
                                            </div>
                                        </div>
                                    </mat-tab>
                                </mat-tab-group>       
                                <div class="flex">
                                    <span class="full">
                                        <mat-checkbox name="columns" [(ngModel)]="section.columns" [disabled]="template.type == 'email'">
                                            Elrendezés 2 oszlopba 
                                            <mat-icon matTooltip="Alap esetben a Cím és a Leírás egymás alatt helyezkedik el, de ha ez a lehetőség ki van választva akkor 2 oszlopban lesz elrendezve. Email sablon esetén ez a funkció nem alkalmazhó.">info</mat-icon>
                                        </mat-checkbox>
                                    </span>
                                    <button mat-icon-button  color="warn"  matTooltip="Eltávolítás" (confirm-click)="deleteSection(section)" confirm>
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </form> 
                        </ng-template>
                    <p>
                </mat-expansion-panel>

            </ng-container>
        </mat-accordion>
    </ng-container>

    <div class="padding-10">
        <button class="mat-button-icon mat-success" (click)="createNewSection()" mat-mini-fab matTooltip="Új Bekezdés Hozzáadása">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</ng-template>