import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectPlan } from 'src/app/models/projects/projectPlan.model';
import { ProjectType } from 'src/app/models/projects/projectType.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { PlanningProjectService } from 'src/app/services/projects/planningProject.service';
import { ProjectTypeService } from 'src/app/services/projects/projectType.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-project-plan',
  templateUrl: './project-plan.component.html',
  styleUrls: ['./project-plan.component.scss']
})
export class ProjectPlanComponent implements OnInit {
  projectPlan: ProjectPlan = null;
  projectTypes: ProjectType[] = null;
  editMode: boolean = false;

  planGet$;
  planCreate$;
  planUpdate$;
  planDelete$;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modelFactory: ModelFactory,
    private planningProjectService: PlanningProjectService,
    private projectTypeService: ProjectTypeService
  ) {
    this.projectPlan = this.modelFactory.getNewModel(ProjectPlan);

    this.fetchProjectTypes();
  }

  fetchProjectTypes() {
    let params = {
      limit: 1000,
      context: 'projectplanning'
    }

    this.projectTypeService.list({params}).subscribe(types => {
      this.projectTypes = types.results;
    })
  }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id');

    if (id) {
      this.planGet$ = this.planningProjectService.get(id).subscribe(i => {
        this.projectPlan = i;
      });
    }
  }

  save() {
    (this.projectPlan.id) ? this.update() : this.create();
  }

  create() {
    this.planCreate$ = this.planningProjectService.create(this.projectPlan).subscribe( projectPlan => {
      this.projectPlan = projectPlan;
      this.editMode = true;
    })
  }

  update() {
    this.planUpdate$ = this.planningProjectService.update(this.projectPlan).subscribe(projectPlan => {
      this.projectPlan = projectPlan;
    })
  }

  delete() {
    this.planDelete$ = this.planningProjectService.delete(this.projectPlan).subscribe(() => {
       this.router.navigate(['../../project-plans'],  {
        relativeTo: this.route  
      });
    });
  }

  changeStatus(event) {
    this.projectPlan.archived = (event.value == 'archived') ? true : false;
  }

  changeProjectType(projectType) {
    this.projectPlan.project_type = projectType.id;
    this.projectPlan._projectType = projectType;
  }

}
