export class ProjectForecast {
    id: number = null;
    project: number = null;
    _project: any = null;

    product: number = null;
    _product: any = null;

    active: boolean = true;

    ek_vetel: number = null;
    vk_eladas: number = null;
    inbetr: number = null;
    transport: number = null;
    schulung: number = null;
    garancia: number = null;
    ev: number = null;
    negyedev: number = null;
    gemeinpercent: number = null;
    esely: number = null;
    vorabgewinn: number = null;

    parts: any[] = [];

    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 
    // Calcualted fields
    sumKosten: number = 0; //ek_vetel + transport + sum parts
    gemeinKosten: number = 0; // (vk_eladas * gemeinpercent)  + Schulunk + Inbetr + Garancia
    provision: number = 0; // (==voragbewin ) vk_eladas*vorabgewinn perc
    gewinnAuf: number = 0; // vk_eladas - Sum Kosten - Sum Gemainkosten - vorabgewin
    gewinnAufPec: number = 0; //(gewinnAuf / vk_eladas) * 100
    gewinnBrutto: number = 0; //  vk_eladas - Sum Kosten
    gewinnBruttoPerc: number = 0; // (gewinnBrutto/ vk_eladas) *100

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            
            this.active = data.active;

            this.ek_vetel = data.ek_vetel;
            this.vk_eladas = data.vk_eladas;
            this.inbetr = data.inbetr;
            this.transport = data.transport;
            this.schulung = data.schulung;
            this.garancia = data.garancia;
            this.ev = data.ev;
            this.negyedev = data.negyedev;
            this.gemeinpercent = data.gemeinpercent;
            this.esely = data.esely;
            this.vorabgewinn = data.vorabgewinn;
         
            this.product = data.product;
            if ('_product' in data) {
                this._product = data._product;
            }
           
            this.project = data.project;
            if ('_project' in data) {
                this._project = data._project;
            }

            
            if (data.parts.length > 0) {
                data.parts.forEach(_p => {
                    let p = new ProjectForecastPart(_p);
                    this.parts.push(p);
                });
            }
            
            if (data.creator) {
                this.creator = data.creator;
            }
           
            if (data.lastModifier) {
                this.lastModifier = data.lastModifier;
            }
           
            if (data.created) {
                this.created = new Date(data.created);
            }
            
            if (data.updated) {
                this.updated = new Date(data.updated);
            }

        }  
    }
  }


  export class ProjectForecastPart {
    id: number = null;

    forecast: number = null;
    _forecast: any = null;

    product: number = null;
    _product: any = null;

    cost: number = 0;

    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.cost = data.cost;
         
            this.product = data.product;
            if ('_product' in data) {
                this._product = data._product;
            }
           
            this.forecast = data.forecast;
            if ('_forecast' in data) {
                this._forecast = data._forecast;
            }
            
            if (data.creator) {
                this.creator = data.creator;
            }
           
            if (data.lastModifier) {
                this.lastModifier = data.lastModifier;
            }
           
            if (data.created) {
                this.created = new Date(data.created);
            }
            
            if (data.updated) {
                this.updated = new Date(data.updated);
            }

        }  
    }
  }
