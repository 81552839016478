export class GeneralSettings {
    id: number;
    defaultVat: number;
    backupEmail: string;
    notificationEmail: string;
    
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;

   constructor(data) {
       if (data) {
           this.id = data.id;
           this.defaultVat = data.defaultVat;
           this.backupEmail = data.backupEmail;
           this.notificationEmail = data.notificationEmail;
           
           this.creator = data.creator;
           this.lastModifier = data.lastModifier;
           this.created = new Date(data.created);
           this.updated = new Date(data.updated);
       }
   }
}