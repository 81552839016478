import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlParamService } from '../services/urlParam.service';
import { BaseList } from '../common/baseClasses/baseList';
import { Template } from '../models/template.model';
import { TemplateService } from '../services/template.service';
import { EnumTemplateType } from '../enums/templates/templateType.enum';
import { EnumTemplateCategory } from '../enums/templates/templateCategory.enum';
import { AutoUnsub } from '../utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
  providers: [UrlParamService]
})
export class TemplatesComponent extends BaseList implements OnInit  {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['name', 'category', 'type'];

  enumTemplateType = EnumTemplateType;

  // pdf vs. email type
  type = EnumTemplateType.Pdf;
  category:string = null;

  templates: Template[] = null;

  tempList$;

  constructor(
    private templateService: TemplateService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService
  ) {
    super()

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit() {
    let defaultParams = {type: this.type}

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      };

      this.tempList$ = this.templateService.list(options).subscribe(data => {
        this.setListResponseData(data);
       });
    })
  }

  open(entity) {
    this.router.navigate(['template'],  {
      queryParams: {id: entity.id}, 
      relativeTo: this.route  
    });
  }

  changeType(event) {
    let param = {type: null}; 
    this.type = null;
    
    if (event.value) {
      this.type = event.value;
      param = {type: this.type};   
    }
 
    this.urlParamService.update(param);
    this.urlParamService.set();
  }

  changeCategory(category) {
    let param = {category: null}; 
    this.category = null;
    
    if (category) {
      this.category = category;
      param = {category: this.category};   
    }
 
    this.urlParamService.update(param);
    this.urlParamService.set();
  }

}
