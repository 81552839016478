import { Component, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EnumTaskTypes } from 'src/app/enums/tasks.enums';
import { Task } from 'src/app/models/tasks/task.model';
import { TaskEvent } from 'src/app/models/tasks/taskEvent.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { TaskEventService } from 'src/app/services/tasks/taskEvent.service';
import { BottomTaskEventSearchComponent } from 'src/app/shared/bottomSheets/bottom-task-event-search/bottom-task-event-search.component';
import { DlgEditTaskEventComponent } from 'src/app/shared/dialogs/edit/tasks/dlg-edit-task-event/dlg-edit-task-event.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-task-events',
  templateUrl: './task-events.component.html',
  styleUrls: ['./task-events.component.scss']
})
export class TaskEventsComponent implements OnInit {
  @Input() task: Task;

  taskEvents: TaskEvent[] = null;

  enumsTaskTypes = EnumTaskTypes;

  onlyOneEvent: boolean = false; // only one event can be in the list, for a few type of tasks

  list$;
  dlgTaskEdit$;
  dlgTaskEdit2$;
  eventUpdate$;

  constructor(
    private taskEventService: TaskEventService,
    private modelFactory: ModelFactory,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
  ) { }

  ngOnInit(): void {
    if (this.task.taskType == EnumTaskTypes.TaskCustomerDeliver) {
      this.onlyOneEvent = true;
    }

    let params = {
      limit: 1000,
      task: this.task.id
    };
    
    this.list$ = this.taskEventService.list({params}).subscribe(res => {
      this.taskEvents = res.results;
    })
  }

  update(taskEvent) {
    this.openEdtiTaskEventDlg(taskEvent);
  }

  create() {
    let taskEvent = this.modelFactory.getNewModel(TaskEvent);

    if (this.task.taskType == EnumTaskTypes.TaskCustomerDeliver) {
      taskEvent.name = 'Kiszállítás: ';
      if (this.task.organization) {
        taskEvent.name += this.task._organization.name;
      }
    }

    taskEvent.task = this.task.id;
    taskEvent._task = this.task;
    this.openEdtiTaskEventDlg(taskEvent);
  }

  openEdtiTaskEventDlg(taskEvent) {
    const dialogConfig = new MatDialogConfig();
        
    dialogConfig.data = {
      taskEvent: taskEvent
    };

    const dialogRef = this.dialog.open(DlgEditTaskEventComponent, dialogConfig);
    this.dlgTaskEdit$ = dialogRef.componentInstance.onChange.subscribe(taskEvent => {
      // add the new task event to the list
      let ts = this.taskEvents.find(ts => ts.id === taskEvent.id);
      if (!ts) this.taskEvents.push(taskEvent);
    });

    this.dlgTaskEdit2$ = dialogRef.componentInstance.onDelete.subscribe(taskEvent => {
      this.taskEvents = this.taskEvents.filter(ts => ts.id != taskEvent.id);
    });
  }


  doneChange(taskEvent) {
    if (taskEvent.done) {
      // task is done open the dialog to finish the the details
      this.openEdtiTaskEventDlg(taskEvent);
    } else {
      // task is not done, remove every related field data
      taskEvent.actualStart = null;
      taskEvent.actualEnd = null;
      taskEvent.pause = 0;
      taskEvent.comment = null;

      this.eventUpdate$ = this.taskEventService.update(taskEvent).subscribe(res => {
        let _taskEvent = this.taskEvents.find(te => te.id === taskEvent.id);
        _taskEvent.done = taskEvent.done; 
      })
    }
  }

  searchInEvents() {
    let bottomSheetRef = this.bottomSheet.open(BottomTaskEventSearchComponent, {});
  }

}
