<div class="section">
    <div class="header flex">
        <div class="title full"><span>Lépések</span></div>
        <div>
            <button mat-stroked-button color="secondary" (click)="openActionPlanCreate()" matTooltip="Eddig nem létező lépés létrhozása">
                <mat-icon>add</mat-icon> Új Lépés Létrehozása
            </button>
        </div>
    </div>

    <ng-container *ngIf="actions">
        <app-project-actions-tree [actions]="actions" [isPlan]="true"></app-project-actions-tree>
    </ng-container>

    <div style="min-height: 150px;" *ngIf="dataSource.data; else tmpAddFirstActionPlan">
        <mat-tree  #tree [dataSource]="dataSource" [treeControl]="treeControl" class="action-tree">
            <!-- This is the tree node template for leaf nodes -->
            <!-- There is inline padding applied to this node using styles.
              This padding value depends on the mat-icon-button width. -->
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                <div class="action flex" *ngIf="node">
                    <div class="full">
                        <span matTooltip="{{node.name}}">{{node._actionTemplate.name | truncate:6}}</span>
                        <span *ngIf="node._actionTemplate.taskType" class="text-green text-small" matTooltip="Hozzárendelt Feladat típus">
                            &nbsp;&#x2794;&nbsp;
                            <app-task-type-container [type]="node._actionTemplate.taskType"></app-task-type-container>
                        </span>
                    </div>
                    <mat-icon class="text-red pointer" (click)="deleteAction(node)" matTooltip="Lépés törlése">delete</mat-icon>
                    <mat-icon *ngIf="node.parent && node._parent.parent" class="text-grey pointer" (click)="moveLeftAction(node)" matTooltip="Mozgatás oldalra">subdirectory_arrow_left</mat-icon>
                    <mat-icon *ngIf="node.parent" class="text-yellow pointer" (click)="moveUpAction(node)" matTooltip="Mozgatás felfele">arrow_upward</mat-icon>
                    <mat-icon class="text-green pointer" (click)="addChildAction(node)" matTooltip="Következő lépés hozzáadása">add</mat-icon>
                </div>  
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <div class="mat-tree-node" *ngIf="node">
                  <button mat-icon-button matTreeNodeToggle>
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                  </button>
                  <div class="flex action">
                    <div class="full">
                        <span matTooltip="{{node.name}}">{{node._actionTemplate.name | truncate:6}}</span>
                        <span *ngIf="node._actionTemplate.taskType" class="text-green text-small" matTooltip="Hozzárendelt Feladat típus">
                            &nbsp;&#x2794;&nbsp;
                            <app-task-type-container [type]="node._actionTemplate.taskType"></app-task-type-container>
                        </span>
                    </div>
                    <mat-icon *ngIf="node.parent && node._parent.parent" class="text-grey pointer" (click)="moveLeftAction(node)" matTooltip="Mozgatás oldalra">subdirectory_arrow_left</mat-icon>
                    <mat-icon *ngIf="node.parent" class="text-yellow pointer" (click)="moveUpAction(node)" matTooltip="Mozgatás felfele">arrow_upward</mat-icon>
                    <mat-icon class="text-green pointer" (click)="addChildAction(node)" matTooltip="Következő lépés hozzáadása">add</mat-icon>
                </div>
                 
                </div>
                <!-- There is inline padding applied to this div using styles.
                    This padding value depends on the mat-icon-button width.  -->
                <div [class.action-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                  <ng-container matTreeNodeOutlet></ng-container>
              </div>
            </mat-nested-tree-node>
          </mat-tree>
    </div>

    <ng-template #tmpAddFirstActionPlan>
        <button mat-flat-button color="accent" (click)="addFirstAction()" matTooltip="Lépés létrhozása">
            <mat-icon>add</mat-icon> Első Lépés
        </button>
    </ng-template>

    <div class="padding-20-0">
        <button mat-mini-fab color="primary" (click)="save()" matTooltip="Mentés">
            <mat-icon>save</mat-icon>
        </button>
    </div>
</div>
