import { Component, Input, OnInit } from '@angular/core';
import { EnumFileUploadType } from 'src/app/enums/fileUploadType.enum';
import { Product, ProductDocument } from 'src/app/models/product.model';
import { FileUploadService } from 'src/app/services/fileUpload.service';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { ProductService } from 'src/app/services/product.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-product-documents',
  templateUrl: './product-documents.component.html',
  styleUrls: ['./product-documents.component.scss']
})
export class ProductDocumentsComponent implements OnInit {
  @Input() product: Product;
  productDocuments: ProductDocument[];

  docGet$;
  imgDel$;
  docCreate$;
  upload$;

  constructor(
    private productService: ProductService,
    private fileUploadService: FileUploadService,
    private modelFacotry: ModelFactory
  ) { }

  ngOnInit() {
    this.fetch();
  }

  fetch() {
    this.docGet$ = this.productService.getProductDocuments(this.product.id).subscribe(res => {
      this.productDocuments = res;
    })
  }

  delete(productDocument) {
    this.imgDel$ = this.productService.deleteProductImage(productDocument).subscribe(() => {
      this.productDocuments = this.productDocuments.filter(pD => pD.id != productDocument.id);
    });
  }

  upload(formData) {
    // create the productimage and upload the file
    let productDocument = this.modelFacotry.getNewModel(ProductDocument);
    productDocument.product = this.product.id;
    productDocument._product = this.product;

    //create the product image
    this.docCreate$ = this.productService.createProductDocument(productDocument).subscribe(res => {
      this.upload$ = this.fileUploadService.upload(formData, EnumFileUploadType.ProductDocument, res).subscribe(res => {
        this.ngOnInit();
      });
    });
  }


}
