<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Projekt Típus</a></li> 
    <ng-container *ngIf="projectType.name">
        <li>{{projectType.name}}</li>  
    </ng-container> 
</ul>
  
<div class="section">
    <div class="header flex">
        <div class="full">
            <div class="title"><span>Projekt Típus</span></div>
        </div>   
    </div>
        <form #f="ngForm">
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Név</mat-label>
                    <input matInput name="nev" [(ngModel)]="projectType.name"  autocomplete="off" required>
                </mat-form-field>
                
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Leírás</mat-label>
                    <textarea rows=6 matInput name="description" [(ngModel)]="projectType.description"  autocomplete="off"></textarea>
                </mat-form-field>
                
            </p>
            <p>
                <input matInput name="color cursor" type="color" [(ngModel)]="projectType.color" [value]="projectType.color"  autocomplete="off">
            </p>
            <p class="padding-20-0">
                <mat-radio-group (change)="changeStatus($event)" >
                    <mat-radio-button  [checked]="!projectType.archived" value="active">Aktív</mat-radio-button>
                    <mat-radio-button [checked]="projectType.archived" value="archived">Archivált</mat-radio-button>
                </mat-radio-group>
            </p>
            <p class="padding-20-0">
                <span class="padding-20-0">
                    <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                        <mat-icon>save</mat-icon>
                    </button>
                </span>
                <ng-container *ngIf="projectType.id">
                    <span class="padding-20-0 padding-l-10">
                        <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                            <mat-icon>delete</mat-icon>
                        </button>
                    </span>
                    <span class="padding-l-10 padding-10-0">
                        <app-quick-log-info [data]="projectType"></app-quick-log-info>           
                    </span>
                </ng-container>
            </p>
        </form>
</div>

