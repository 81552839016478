import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnumLanguage } from 'src/app/enums/languages.enum';
import { EnumCurrency } from 'src/app/enums/currencies.enum';
import { TaskProduct } from 'src/app/models/tasks/taskProduct.model';
import { CurrencyUtil } from 'src/app/utils/currency.util';
import { ExchangeService } from 'src/app/services/exchange.service';
import { GeneralSettings } from 'src/app/models/generalSettings.model';
import { GeneralSettingsService } from 'src/app/services/generalSettings.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-default-section-price-table',
  templateUrl: './default-section-price-table.component.html',
  styleUrls: ['./default-section-price-table.component.scss']
})
export class DefaultSectionPriceTableComponent implements OnInit {
  @Input() taskProducts: TaskProduct[];
  @Input() language: EnumLanguage | string = EnumLanguage.Hu;
  @Input() currency: EnumCurrency | string = EnumCurrency.EUR;
  @Input() pdfSection: any; // pdf section (for every change update the data)

  // return the generated html markup of the table
  @Output() onChange: EventEmitter<string> = new EventEmitter();
  // contains the product meta data (object), this is the object where the values are updated and based on the html generated
  productsData = null;
  totalData = null;

  enumCurrency = EnumCurrency;

  // holds the data of the tabel
  data = {
    headers: null,
    products: [],
    total: {
      name: null,
      amount: null,
      netPrice: null,
      vat: null,
      grossPrice: null
    },
  }
  
  COLUMNS = [
    {id: 'product', hu: 'Termék', en: 'Product'},
    {id: 'unitPrice', hu: 'Nettó egység ár', en: 'Net unit price'},
    {id:'amount', hu: 'Darab', en: 'Amount'},
    {id:'netPrice', hu: 'Nettó ár', en: 'Net price'}
  ]

  GROSS_COLUMN = [
    {id: 'vat', hu: 'Áfa', en: 'VAT'},
    {id: 'grossPrice', hu: 'Bruttó ár', en: 'Gross Price'}
  ];
  
  // current exchange rage between eur and huf
  exchangeRate = null;

  generalSettings: GeneralSettings;

  genSetGet$;
  exhGet$;

  constructor(
    private exchangeService: ExchangeService,
    private generalSettingService: GeneralSettingsService
  ) {
  }

  ngOnInit() {
    // first have to grab the VAT
    this.genSetGet$ = this.generalSettingService.get().subscribe(generalSettings => {
      if (generalSettings && !generalSettings.defaultVat) alert('Nincs alapértelmezett Áfa beállítva!');

      this.generalSettings = generalSettings;
       
      this.doStart();
    });
  }

  doStart() {
    this.data.headers = this.getHeaders();

    this.pdfSection.defaultConfig['currency'] = this.currency;

    // if HUF we have to make a conversation straight
    if (this.currency == 'huf' && !this.exchangeRate) {
     this.exhGet$ = this.exchangeService.get().subscribe(res => {
       this.exchangeRate = res.rate;
       this.buildProducts();
     })
   } else {
     this.buildProducts();
   }
  }

  getHeaders() {
    this.COLUMNS = this.COLUMNS.filter(c => c.id != 'grossPrice');
    this.COLUMNS = this.COLUMNS.filter(c => c.id != 'vat');
    if (this.pdfSection.defaultConfig?.includeBrutto) {
      this.COLUMNS = [...this.COLUMNS, ...this.GROSS_COLUMN];
    }

    let headers = [];
    
    this.COLUMNS.forEach(col => {
      let header = col['hu'];
      if (this.language == 'en') {
        header = col['en']
      }

      if (col.id === 'vat' && this.generalSettings && this.generalSettings.defaultVat) {
        // add perc value to header
        header += ' (' + this.generalSettings.defaultVat + '%)';
      }

      headers.push(header);
    })

    return headers;
  }

  buildProducts() {
    let data = [];

    // if huf we add the extra multiplier to the proudcts
    let conversionRate = (this.currency == 'huf') ? this.exchangeRate : 1;

    this.taskProducts.forEach(taskProduct => {
      let d:any = {
        taskProductId: taskProduct.id,
        active: taskProduct.active,
        type: taskProduct._product.type,
        name: null,
        amount: 0,
        unitPrice: 0,
        netPrice: 0,
        discountPrice: null,
        conversionRate: conversionRate, // we have to send back the HUF/EUR exhange rate to help to calcualte the new unitprice if there is a discount
        vat: 0,
        grossPrice: 0
      };

      // name
      if (this.language == EnumLanguage.En) {
        d.name = taskProduct._product.nameEn;
      } else {
        d.name = taskProduct._product.nameHu
      }

      // amount
      if (d.active) {
        d.amount = taskProduct.amount;

        // unit price
        d.unitPrice = taskProduct.sellingPrice * conversionRate;
  
        // net price
        d.netPrice = null;

        // gross price 
        d.grossPrice = null; 
      }

      data.push(d);
     
    });

    this.data.products = data;

    this.calculateData();
   
  }

  calculateData() {
    // calcualte the fields which are depend on other fields

    if (!this.generalSettings) return;

    this.data.products.forEach(p => {
        p.netPrice =  (p.unitPrice === null) ? null : p.amount * p.unitPrice;

        if (typeof p.discountPrice === 'number' && p.discountPrice >= 0) {
          p.vat = (p.discountPrice * this.generalSettings.defaultVat) / 100;
          p.grossPrice = p.discountPrice + p.vat;
        } else {
          p.vat = (p.netPrice * this.generalSettings.defaultVat) / 100;
          p.grossPrice = p.netPrice + p.vat;
        }
    });

     // total 
     this.data.total = {
      name: (this.language === 'hu') ? 'Összesen' : 'Total',
      amount: null,
      netPrice: null,
      vat: null,
      grossPrice: null
    }

    // values
    this.data.total['amount'] = this.data.products.reduce((a, b) => a + b.amount, 0);
    this.data.total['vat'] = this.data.products.reduce((a, b) => a + b.vat, 0);
    this.data.total['grossPrice'] = this.data.products.reduce((a, b) => a + b.grossPrice, 0);

    let totalNetPrice = 0;
    this.data.products.forEach(p => {
      if (typeof p.discountPrice === 'number' && p.discountPrice >= 0) {
        totalNetPrice += p.discountPrice;
      } else {
        totalNetPrice += p.netPrice;
      }
    })

    this.data.total['netPrice'] = totalNetPrice;

    this.pdfSection.defaultConfig['data'] = this.data;
  }

  updateShowBrutto() {
    this.doStart()
  }
}
