<ul class="breadcrumb"> 
    <li>Projekt Típusok</li>  
</ul>

<div class="flex"> 
    <button (click)="doSearch()" mat-mini-fab color="primary" [disabled]="txtSearch === ''">
        <mat-icon matSuffix>search</mat-icon>
    </button>
    <div class="padding-l-10">
        <mat-form-field appearance="standard">
            <mat-label>Keresés</mat-label>
            <input [(ngModel)]="txtSearch" matInput placeholder="?" (keyup.enter)="doSearch()">
            <mat-icon  class="pointer" matTooltip="Keresés Törlése" (click)="noSearch()" matSuffix>close</mat-icon>
        </mat-form-field>
    </div>
    <div class="padding-l-10">
        <mat-radio-group (change)="changeStatus($event)">
            <mat-radio-button [checked]="!archived" value="active">Active</mat-radio-button>
            <mat-radio-button [checked]="archived" value="archived" matTooltip="Nem válsztható ki Projektekhez">Archivált</mat-radio-button>
          </mat-radio-group>
    </div>
    
    <div class="full text-right">
        <span class="padding-l-10">
            <button mat-mini-fab  color="accent" [routerLink]="['type']"  matTooltip="Új Típus Létrehozása"> 
                <mat-icon>add</mat-icon>
            </button>
        </span>
    </div> 
</div>

<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Név</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <span class="color-dot" [style.backgroundColor]="row.color"></span>
            <span class="link" (click)="open(row)">{{row.name}} </span>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
></mat-paginator>


