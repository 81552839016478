import { Component, Input, Output, OnInit } from '@angular/core';
import { Category } from 'src/app/models/category.model';
import { CategoryService } from 'src/app/services/category.service';
import { DlgSearchCategoryComponent } from 'src/app/shared/dialogs/search/dlg-search-category/dlg-search-category.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EventEmitter } from '@angular/core';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent extends BaseEntity implements OnInit {
  @Input() category: Category;
  @Input() isDlg: false;

  @Output() eventCreated: EventEmitter<Category> = new EventEmitter();
  @Output() eventUpdated: EventEmitter<Category> = new EventEmitter();
  @Output() eventDeleted: EventEmitter<any> = new EventEmitter();

  catCreate$;
  catUpdate$;
  catDelete$;
  dlgCat$;
  
  constructor(
    private categoryService: CategoryService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() { }

  save() {
    (this.category.id) ? this.update() : this.create();
  }

  create() {
    this.catCreate$ = this.categoryService.create(this.category).subscribe(c => {
      this.category = c;
      this.eventCreated.emit(this.category);
    })
  }

  update() {
    this.catUpdate$ = this.categoryService.update(this.category).subscribe(c => {
      this.category = c;
      this.eventUpdated.emit(this.category);
    })
  }

  delete() {
      this.catDelete$ = this.categoryService.delete(this.category).subscribe(() => {
        this.eventDeleted.emit()
      });
    }

    openCategorySearchDlg() {
      const dialogConfig = new MatDialogConfig();
      
      let name = (this.category.parent) ? this.category._parent.name : '';
      
      dialogConfig.data = {
        name: name
      };
  
      const dialogRef = this.dialog.open(DlgSearchCategoryComponent, dialogConfig);
  
      this.dlgCat$ = dialogRef.afterClosed().subscribe(parent => {
        if (parent) {
            // update the origanistion for the people
            this.category.parent = parent.id;
            // update the organisation details 
            this.category._parent = parent;
        } else if (parent == '') {
          // if empty string set back we delete the organisation
          this.category.parent = null;
          this.category._parent = null;
        }
      });
    }

}
