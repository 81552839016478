import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-template-category-selector',
  templateUrl: './template-category-selector.component.html',
  styleUrls: ['./template-category-selector.component.scss']
})
export class TemplateCategorySelectorComponent implements OnInit {
  // if want to preselect a cateogry
  @Input() category: string;
  @Input() disabled: false;
  @Input() required: false;

  @Output() onSelect: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  select(event) {
    this.category = event.value
    this.onSelect.emit(this.category);
  }

}
