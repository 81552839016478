/**
 * 
 */
 import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { EnumModule } from "src/app/enums/modules.enum";
import { EnumTaskTypes } from "src/app/enums/tasks.enums";
import { User } from "src/app/models/user";
import { SecurityService } from "src/app/services/security.service";
import { DlgEditTaskComponent } from "src/app/shared/dialogs/edit/tasks/dlg-edit-task/dlg-edit-task.component";
import { AutoUnsub } from "src/app/utils/autoUnsubscribe";
import { TaskUtil } from "src/app/utils/task.util";
import { BaseEntity } from "./baseEntity";
 import { BaseList } from "./baseList";

@AutoUnsub()
export abstract class BaseTaskList extends BaseList {
    taskType: EnumTaskTypes;
    dialog: MatDialog;
    ss: SecurityService;

    // common filters for the tasks
    //textSearch: string = ''
    state: boolean = true;
    importance = null;
    status = null;
    assignee: User = null;

    dlgEditTask$;

    // access
    hasAccessProjects = false;

    constructor(
        taskType:EnumTaskTypes,
        dialog: MatDialog,
        ss: SecurityService
        ) {
        super();

        if (taskType) {
          // nothing for all tasks
          this.taskType = taskType;
        }
        
        this.dialog = dialog;

        this.ss = ss;

        this.hasAccessProjects = this.ss.hasAccess(EnumModule.Projects);
    }

    openTaskEdit() {
        const dialogConfig = new MatDialogConfig();
    
        dialogConfig.data = {
          taskType: this.taskType,
        };
    
        dialogConfig.width = '450px';
    
        const dialogRef = this.dialog.open(DlgEditTaskComponent, dialogConfig);
    
        this.dlgEditTask$ = dialogRef.componentInstance.onChange.subscribe(task => {
          this.openEntity(task);
        });
      }
      
      openEntity(entity) {
        let path: string;
        if (this.taskType) {
          path = TaskUtil.getTaskPath(this.taskType);
        } else {
          // for all tasks list
          path = TaskUtil.getTaskPath(entity.taskType);
        }
        
        this.router.navigate([path],  {
          queryParams: {id: entity.id}, 
        });
      }

      openProject(entity) {
        this.router.navigate(['/app/projects/project/'],  {
          queryParams: {id: entity._action._project.id}, 
          relativeTo: this.route  
        });
      }
    
      changeSearch(txtSearch) {
        if (txtSearch.length == 0) {
          this.noSearch();
          return;
        }
    
        this.txtSearch = txtSearch;
    
        this.doSearch();
      }
    
      changeState(state) {
        this.resetFilters();
    
        this.state = state;
        
        let param = {open: this.state};   
        this.urlParamService.update(param);
        this.urlParamService.set();
      }
    
      changeImportance(importance) {
        this.resetFilters();
    
        let param = {importance: null}; 
        
        this.importance = null;
        
        if (importance) {
          this.importance = importance;
          param = {importance: +importance};   
        }
     
        this.urlParamService.update(param);
        this.urlParamService.set();
      }
    
      changeUser(user) {
        this.resetFilters();
    
        let param = {assignee: null}; 
        
        this.assignee = null;
        
        if (user) {
          this.assignee = user;
          param = {assignee: this.assignee.id};   
        }
     
        this.urlParamService.update(param);
        this.urlParamService.set();
      }
    
      changeStatus(status) {
        this.resetFilters();
        let param = {status: null}; 
        this.status = null;
        
        if (status) {
          this.status = status;
          param = {status: this.status};
        }
     
        this.urlParamService.update(param);
        this.urlParamService.set();
      }


}