<mat-form-field appearance="outline">
    <mat-label>Ország</mat-label>
    <mat-select [value]="country" (selectionChange)="selected($event.value)">
        <mat-option [value]="enumsCountry.Albania">Albánia</mat-option>
        <mat-option [value]="enumsCountry.Andorra">Andorra</mat-option>
        <mat-option [value]="enumsCountry.Austria">Ausztria</mat-option>
        <mat-option [value]="enumsCountry.Belarus">Fehéroroszország</mat-option>
        <mat-option [value]="enumsCountry.Belgium">Belgium</mat-option>
        <mat-option [value]="enumsCountry.Bosnia">Bosznia</mat-option>
        <mat-option [value]="enumsCountry.Bulgaria">Bulgária</mat-option>
        <mat-option [value]="enumsCountry.Croatia">Horvátország</mat-option>
        <mat-option [value]="enumsCountry.CzechRepublic">Cseh Köztársaság</mat-option>
        <mat-option [value]="enumsCountry.Denmark">Dánia</mat-option>
        <mat-option [value]="enumsCountry.Estonia">Észtország</mat-option>
        <mat-option [value]="enumsCountry.Finland">Finnország</mat-option>
        <mat-option [value]="enumsCountry.France">Franciaország</mat-option>
        <mat-option [value]="enumsCountry.Germany">Németország</mat-option>
        <mat-option [value]="enumsCountry.Greece">Görögország</mat-option>
        <mat-option [value]="enumsCountry.Hungary">Magyarország</mat-option>
        <mat-option [value]="enumsCountry.Iceland">Izland</mat-option>
        <mat-option [value]="enumsCountry.Ireland">Írország</mat-option>
        <mat-option [value]="enumsCountry.Italy">Olaszország</mat-option>
        <mat-option [value]="enumsCountry.Latvia">Lettország</mat-option>
        <mat-option [value]="enumsCountry.Liechtenstein">Liechtenstein</mat-option>
        <mat-option [value]="enumsCountry.Lithuania">Litvánia</mat-option>
        <mat-option [value]="enumsCountry.Luxembourg">Luxemburg</mat-option>
        <mat-option [value]="enumsCountry.Malta">Málta</mat-option>
        <mat-option [value]="enumsCountry.Moldova">Moldova</mat-option>
        <mat-option [value]="enumsCountry.Monaco">Monaco</mat-option>
        <mat-option [value]="enumsCountry.Montenegro">Montenegró</mat-option>
        <mat-option [value]="enumsCountry.Netherlands">Hollandia</mat-option>
        <mat-option [value]="enumsCountry.Macedonia">Macedónia</mat-option>
        <mat-option [value]="enumsCountry.Norway">Norvégia</mat-option>
        <mat-option [value]="enumsCountry.Poland">Lengyelország</mat-option>
        <mat-option [value]="enumsCountry.Portugal">Portugália</mat-option>
        <mat-option [value]="enumsCountry.Romania">Románia</mat-option>
        <mat-option [value]="enumsCountry.Russia">Oroszország</mat-option>
        <mat-option [value]="enumsCountry.SanMarino">San Marino</mat-option>
        <mat-option [value]="enumsCountry.Serbia">Szerbia</mat-option>
        <mat-option [value]="enumsCountry.Slovenia">Szlovákia</mat-option>
        <mat-option [value]="enumsCountry.Slovenia">Szlovénia</mat-option>
        <mat-option [value]="enumsCountry.Spain">Spanyolország</mat-option>
        <mat-option [value]="enumsCountry.Sweden">Svédország</mat-option>
        <mat-option [value]="enumsCountry.Switzerland">Svájc</mat-option>
        <mat-option [value]="enumsCountry.Ukraine">Ukrajna</mat-option>
        <mat-option [value]="enumsCountry.UnitedKingdom">Egyesült Királyság</mat-option>
    </mat-select>
</mat-form-field>
