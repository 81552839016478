import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product } from 'src/app/models/product.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { DlgEditCategoryComponent } from '../dlg-edit-category/dlg-edit-category.component';

@Component({
  selector: 'app-dlg-edit-product',
  templateUrl: './dlg-edit-product.component.html',
  styleUrls: ['./dlg-edit-product.component.scss']
})
export class DlgEditProductComponent implements OnInit {
  product: Product = null;
  preventItemCreation = false;

  @Output() onChange: EventEmitter<Product> = new EventEmitter;

  constructor(
    private modelFactory: ModelFactory,
    public dialogRef: MatDialogRef<DlgEditCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.product = this.modelFactory.getNewModel(Product);

    if (data?.product) {
      this.product = data;
    }

    if (data?.preventItemCreation) {
      this.preventItemCreation = data.preventItemCreation;
    }
  }

  ngOnInit() {}

  created(product) {
    this.onChange.emit(product);
    this.closeDlg();
  }

  updated(product) {
    this.onChange.emit(product);
    this.closeDlg();
  }

  deleted(event) {
    this.onChange.emit(null);
    this.closeDlg();
  }

  closeDlg() {
    this.dialogRef.close();
  }

}

