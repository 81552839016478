<div class="section">
    <div class="header">
        <div class="title full">
            <span>Termékek</span>
        </div>
        <div *ngIf="task.action">
            <button mat-flat-button color="accent" (click)="openProjectProductSearch()">
                <mat-icon>search</mat-icon> Projekt Termék Listák
            </button>
        </div>
    </div>

 <div class="table-responsive">   
<table class="table table-bordered" style="width: 98%;">  
    <thead>
        <tr>
            <th>Termék</th>
            <th>Mennyiség</th>
            <th *ngIf="readCostPrice || editCostPrice">Beszerzési Egységár</th>
            <th *ngIf="readSellingPrice || editSellingPrice">Értékesítési Egységár</th>
            <ng-container *ngIf="hasAccessInventory">
                <th *ngIf="inventoryIn">Bevételezés</th>
                <th *ngIf="inventoryOut">Kivételezés</th>
                <th>Raktár Készlet</th>
            </ng-container>
            <th>&nbsp;</th>
        </tr>
    </thead>
    <ng-container *ngFor="let p of taskProducts">
       
        <ng-container *ngIf="!p.active; else tmpNormalTr">
           <tr class="bg-light-grey">
               <td>
                    <div class="helper-text">Minta Raktári Termék</div>
                    <app-item-container [item]="p._item" [showLocation]="true"></app-item-container>
               </td>
               <td colspan="99" class="text-right">
                    <button mat-icon-button  color="warn"  matTooltip="Törlés" (confirm-click)="delete(p)" confirm>
                        <mat-icon>delete</mat-icon>
                    </button>
                    <span class="padding-l-10">
                        <button mat-icon-button  color="accent"  matTooltip="Termékhez kapcsolódó Kiajánlható Árucikk Csoportok hozzáadása a listához" (click)="addRelatedProduct(p)">
                            <mat-icon>low_priority</mat-icon> 
                        </button>
                    </span>
                </td>
           </tr>
       </ng-container>

       <ng-template #tmpNormalTr>
        <tr [ngClass]="(type === 'item' && !p._item) ? 'bg-error' : ''">
            <td>
                <div *ngIf="p.item; else tmpProduct">
                    <app-item-container [item]="p._item" [showLocation]="true"></app-item-container>
                    <div class="helper-text" matTooltip="Raktári Termék"><mat-icon>precision_manufacturing</mat-icon></div>
                </div>
                <ng-template #tmpProduct>
                    <app-product-container [product]="p._product"></app-product-container>
                    <div class="helper-text" matTooltip="Árucikk Csoport"><mat-icon>coffee_maker</mat-icon></div>
                </ng-template>

                <div *ngIf="type === 'item' && !p._item" >
                        <button (click)="connectToItem(p)" mat-flat-button  color="warn"  matTooltip=" Egy Raktári Terméket létre kell hozni az alábbi Árucikk Csoportból!">
                            Raktári Termékké Alakítás
                        </button>
                </div>
            </td>
            <td>
                <mat-form-field  appearance="outline" class="w100">
                    <mat-label class="capitalize">
                        <ng-container *ngIf="p._product.unitHu; else tmpDefaultUnit">{{p._product.unitHu}}</ng-container>
                        <ng-template #tmpDefaultUnit>Darab</ng-template>
                    </mat-label>
                    <input type="number" pattern="^[0-9]\d*$" matInput [(ngModel)]="p.amount" required [disabled]="p.inventory_transaction">
                </mat-form-field>
            </td>
            <td *ngIf="readCostPrice || editCostPrice">
                <ng-container *ngIf="editCostPrice; else tmpReadCostPrice">
                    <div class="flex">
                        <mat-form-field  appearance="outline" class="w200">
                            <mat-label class="capitalize">Beszerzési</mat-label>
                            <input type="number" pattern="^\d*\.?\d{2}$" matInput [(ngModel)]="p.costPrice"  [disabled]="p.inventory_transaction">
                            <mat-icon matPrefix>euro</mat-icon>
                        </mat-form-field>
                    </div>
                </ng-container>   
                <ng-template #tmpReadCostPrice>
                    <app-currency-container [value]="p.costPrice" [currency]="'eur'"></app-currency-container> 
                </ng-template> 
            </td>
            <td *ngIf="readSellingPrice || editSellingPrice">
                <ng-container *ngIf="editSellingPrice; else tmpReadSellingPrice">
                    <div class="flex">
                        <mat-form-field  appearance="outline" class="w200">
                            <mat-label class="capitalize">Értékesítési</mat-label>
                            <input type="number" pattern="^\d*\.?\d{2}$" matInput [(ngModel)]="p.sellingPrice">
                            <mat-icon matPrefix>euro</mat-icon>
                            <mat-hint>
                                <span *ngIf="p.costPrice" class="link" matTooltip="Értékesítési ár a Beszerzési Ár alapján [árrés 1,6]"
                                    (click)="p.sellingPrice=taskUtil.getAutoOfferPrice(p.costPrice)">
                                    BE Ár: €{{taskUtil.getAutoOfferPrice(p.costPrice)}}
                                </span>
                                <br *ngIf="p.costPrice">
                                <span *ngIf="p.stock?.length > 0" class="link" matTooltip="Értékesítési ár a Raktákészket alapján [árrés 1,6]"
                                    (click)="p.sellingPrice=taskUtil.getAutoOfferPrice(p.stock[0].price)">
                                    RK Ár: €{{taskUtil.getAutoOfferPrice(p.stock[0].price)}}
                                </span>
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </ng-container>   
                <ng-template #tmpReadSellingPrice>
                    <app-currency-container [value]="p.sellingPrice" [currency]="'eur'"></app-currency-container> 
                </ng-template> 
            </td>
            <ng-container *ngIf="hasAccessInventory">
                <td *ngIf="inventoryIn">
                    <ng-container *ngIf="p.inventory_transaction; else tmpInventoryIn">
                        <div class="flex">
                            <app-static-true-false-icon [value]="true"></app-static-true-false-icon> 
                            <a href="#" class="link"  routerLink="/app/inventory/transactions"  [queryParams]="{id: p.inventory_transaction}">Betéve</a>
                        </div>
                        <div class="helper-text">{{p._inventoryTransaction.created | date:'longDate'}}</div>
                        <app-avatar [user]="p._inventoryTransaction.creator"></app-avatar>
                    </ng-container>
                    <ng-template #tmpInventoryIn>
                        <button mat-mini-fab color="accent"  [disabled]="p.amount < 1"  matTooltip="Bevételezés" (click)="openInventoryTransaction('in', p)"> 
                            <mat-icon>vertical_align_bottom</mat-icon>
                        </button>
                    </ng-template>
                </td>
                <td *ngIf="inventoryOut">
                    <ng-container *ngIf="p.inventory_transaction; else tmpInventoryOut">
                        <div class="flex">
                            <app-static-true-false-icon [value]="true"></app-static-true-false-icon> 
                            <a href="#" class="link"  routerLink="/app/inventory/transactions"  [queryParams]="{id: p.inventory_transaction}">Kivéve</a>
                        </div>
                        <div class="helper-text">{{p._inventoryTransaction.created | date:'longDate'}}</div>
                        <app-avatar [user]="p._inventoryTransaction.creator"></app-avatar>
                    </ng-container>
                    <ng-template #tmpInventoryOut>
                        <button mat-mini-fab color="warn"  [disabled]="p.amount < 1"  matTooltip="Kivételezés" (click)="openInventoryTransaction('out', p)"> 
                            <mat-icon>vertical_align_top</mat-icon>
                        </button>
                    </ng-template>
                </td>
                <td>
                    <ng-container *ngIf="p.stock && p.stock.length > 0; else tmpNotInStock">
                        <ng-container *ngFor="let stock of p.stock">
                            <div>
                                <span class="text-bold text-green">{{stock.stock}}</span>&nbsp;
                                <span class="helper-text">{{p._product.unitHu}}</span>
                            </div>
                            <app-currency-container [value]="+stock.price"></app-currency-container>
                        </ng-container>
                    </ng-container>
                    <ng-template #tmpNotInStock>
                        <mat-icon class="text-red" matTooltip="Nincs Raktáron">not_interested</mat-icon>
                    </ng-template>
                </td>
            </ng-container>
            <td>
                <div class="flex flex-right">
                    <span class="padding-l-10" *ngIf="!p.inventory_transaction">
                        <button mat-mini-fab  color="primary" [disabled]="p.amount < 1" (click)="update(p)" matTooltip="Mentés">
                            <mat-icon>save</mat-icon>
                        </button>
                    </span>
                    <ng-container *ngIf="p.id">
                        <span class="padding-l-10" *ngIf="!p.inventory_transaction">
                            <button mat-icon-button  color="warn"  matTooltip="Törlés" (confirm-click)="delete(p)" confirm>
                                <mat-icon>delete</mat-icon>
                            </button>
                        </span>
                        <span class="padding-l-10">
                            <button mat-icon-button  color="accent"  matTooltip="Kapcsolódó Kiajánlható Árucikk Csoportok hozzáadása a listához" (click)="addRelatedProduct(p)">
                                <mat-icon>low_priority</mat-icon> 
                            </button>
                        </span>
                    </ng-container>
                </div>   
            </td>
        </tr>
        </ng-template>

    </ng-container>
</table>
</div>

<div *ngIf="totalSellingPrice > 0" class="text-right helper-text">
    Teljes Értékesítési Ár: {{totalSellingPrice | eur}}
</div>

<div class="flex">
    <div class="full">
        <ng-container *ngIf="showOrgItemSelector()">
            <span class="margin-r-20">
              <button mat-mini-fab  color="primary"  (click)="openOrgItemSelector()" matTooltip="Cégnél lévő Termék hozzáadása">
                <mat-icon>storefront</mat-icon>
              </button>
            </span>
        </ng-container>
        <span class="padding-5">
            <button class="margin-5" mat-flat-button color="accent" (click)="openSearchMachineDlg()" matToolitp="Új Gép Hozzáadása">
                <mat-icon>add</mat-icon> Gép
            </button>
        </span>
        <span class="padding-5">
            <button class="margin-5" mat-flat-button color="accent" (click)="openSearchPartDlg()" matToolitp="Új Alkatrész Hozzáadása">
                <mat-icon>add</mat-icon> Alkatrész
            </button>
        </span>
        <span class="padding-5">
            <button class="margin-5" mat-flat-button color="accent" (click)="openSearchServiceDlg()" matToolitp="Új Szolgáltatás Hozzáadása">
                <mat-icon>add</mat-icon> Szolg.
            </button>
        </span>
    </div>
    <div class="flex" *ngIf="showInactiveCreateButton()">
        <mat-icon matTooltip="Gép hozzáadása amivel az alkatrészek kompatibilisek. A gép szokásos adatai nem lesznek szerkeszthetőek. A Feladat szempontjából a Gép inactive lesz.">info</mat-icon>
        <span  class="link" (click)="openSearchInactiveDlg()">Minta Gép hozzáadása</span>
    </div>
</div>
  

</div>