import { Component, OnInit } from '@angular/core';
import { groupBy } from 'lodash-es';
import { ProjectForecast } from 'src/app/models/projects/projectForecast.model';
import { ProjectForecastService } from 'src/app/services/projects/projectForecast.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.scss']
})
export class ForecastComponent implements OnInit {
  projectForecasts: ProjectForecast[] = [];

  years: number[] = [];

  // object which holds the data to render the total
  calculatedTotals: any;

  // filters
  ev: number = new Date().getFullYear();
  esely: number = 0;
  active: boolean = true;

  // if it is set update the gemain percentage for all the data and recalcualte everything
  newGemeinPerc: number = null;

  forecast$;

  constructor(
    private projectForecastService: ProjectForecastService
  ) { }

  ngOnInit(): void {
    this.setYearsForDropdown();

    let params = {
      active: true,
      esely: 0,
      ev: new Date().getFullYear()
    }
    this.fetch(params);
  }

  fetch(params) {
    this.forecast$ = this.projectForecastService.report(params).subscribe(res => {
      this.projectForecasts = res;

      if (this.newGemeinPerc) {
        // update the new gemain perc and recalculate the fields
        this.projectForecasts.forEach(p => {
          p.gemeinpercent=this.newGemeinPerc;
          this.projectForecastService.updateCalcualtedFields(p)
        });
      }

      this.calculateTotals();
    })
  }

  filter() {
    let params = {
      active: this.active,
      esely: this.esely,
      ev: this.ev
    }

    this.fetch(params);
  }

  setYearsForDropdown() {
    // create a list of year from the last year
    let year = new Date().getFullYear() - 1;

    for (var i = 0; i < 3; i++) {
        this.years.push(year + i);
    }
  }

  setYear(event) {
    this.ev = event.value;
  }

  setEsely(event) {
    this.esely = event.value
  }

  setActive(event) {
    this.active = event.value;
  }

  calculateTotals() {
    let groupedByQuarter = groupBy(this.projectForecasts, 'negyedev');

    let totals = {
      1: { vk_eladas: 0, gemeinKosten: 0, provision: 0, gewinnAuf: 0, gewinnBrutto: 0},
      2: { vk_eladas: 0, gemeinKosten: 0, provision: 0, gewinnAuf: 0, gewinnBrutto: 0},
      3: { vk_eladas: 0, gemeinKosten: 0, provision: 0, gewinnAuf: 0, gewinnBrutto: 0},
      4: { vk_eladas: 0, gemeinKosten: 0, provision: 0, gewinnAuf: 0, gewinnBrutto: 0},
      total: { vk_eladas: 0, gemeinKosten: 0, provision: 0, gewinnAuf: 0, gewinnBrutto: 0},
    }

    for (const quarter in groupedByQuarter) {
      let total = totals[quarter];

      for (let p of groupedByQuarter[quarter]) {
        // quarter
        total['vk_eladas'] += p.vk_eladas;
        total['gemeinKosten'] += p.gemeinKosten;
        total['provision'] += p.provision;
        total['gewinnAuf'] += p.gewinnAuf;
        total['gewinnBrutto'] += p.gewinnBrutto;
        // totals
        totals['total']['vk_eladas'] += p.vk_eladas;
        totals['total']['gemeinKosten'] += p.gemeinKosten;
        totals['total']['provision'] += p.provision;
        totals['total']['gewinnAuf'] += p.gewinnAuf;
        totals['total']['gewinnBrutto'] += p.gewinnBrutto;
      }
    }

    this.calculatedTotals = totals;
  }

}
