import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default-section-products-marketing',
  templateUrl: './default-section-products-marketing.component.html',
  styleUrls: ['./default-section-products-marketing.component.scss']
})
export class DefaultSectionProductsMarketingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
