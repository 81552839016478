<div class="text-center padding-20-0">
    Cég - Termék Kapcsolat
</div>
<form #f="ngForm">
    <p>
        <mat-form-field appearance="outline">
            <mat-label>Cég</mat-label>
            <mat-icon matSuffix matTooltip="Módisíthatod a Céget amelyiknél a Termék van">info</mat-icon>
            <ng-container *ngIf="orgItem.organization; else noOrg">
                <input matInput name="organisation" disabled [(ngModel)]="orgItem._organization.name"  autocomplete="off">
            </ng-container>
            <ng-template #noOrg>
                <input matInput name="organisation" disabled [(ngModel)]="orgItem.organization" autocomplete="off">
            </ng-template>
            <mat-icon class="pointer" (click)="openOrgSearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>
        </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="outline">
            <mat-label>Termék</mat-label>
            <mat-icon class="pointer" (click)="openItemSearchDlg()" matPrefix matTooltip="Szerkesztés">edit</mat-icon>
            <mat-icon matSuffix matTooltip="Módisíthatod melyik Termék tartozik a Céghez">info</mat-icon>
            <ng-container *ngIf="orgItem.item; else noItem">
                <input matInput name="item" disabled [(ngModel)]="orgItem._item._product.nameHu"  autocomplete="off">
            </ng-container>
            <ng-template #noItem>
                <input matInput name="item" disabled [(ngModel)]="orgItem.item" autocomplete="off">
            </ng-template>
        </mat-form-field>
    </p>

    <p>
        <span class="padding-l-10">
            <button mat-mini-fab  color="primary" (click)="save()" [disabled]="f.invalid" matTooltip="Mentés">
                <mat-icon>save</mat-icon>
            </button>
        </span>
        <ng-container *ngIf="orgItem.id">
            <span class="padding-l-10">
                <button mat-mini-fab  color="warn"  matTooltip="Törlés" (confirm-click)="delete()" confirm>
                    <mat-icon>delete</mat-icon>
                </button>
            </span>
        </ng-container>   
    </p>
</form> 