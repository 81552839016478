import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { ActionPlan } from 'src/app/models/projects/actionPlan.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { PlanningActionService } from 'src/app/services/projects/planningAction.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-page-action-plans',
  templateUrl: './page-action-plans.component.html',
  styleUrls: ['./page-action-plans.component.scss']
})
export class PageActionPlansComponent extends BaseEntity implements OnInit {
  actionPlan: ActionPlan = null;

  planGet$;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modelFactory: ModelFactory,
    private actionPlanService: PlanningActionService
  ) {
    super();
    
    this.actionPlan = this.modelFactory.getNewModel(ActionPlan)
  }

  ngOnInit() {
    let id = + this.route.snapshot.queryParamMap.get('id')

    if (id) {
      this.planGet$ = this.actionPlanService.get(id).subscribe(i => {
        this.actionPlan = i;
      });
    }
  }

  created(entity) {
    this.actionPlan = entity;
     //  navigate to the page
     this.router.navigate(['./'],  {
      queryParams: {id: this.actionPlan.id}, 
      relativeTo: this.route  
    });
  }

  deleted(event) {
       //  navigate to the page
       this.router.navigate(['../../actions'],  {
        relativeTo: this.route  
      });
  }

  updated(event) {
    //
  }

}
