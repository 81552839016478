<div class="flex flex-right">
        <button mat-mini-fab color="accent" (click)="add()" matTooltip="Új Cég Hozzáadás">
            <mat-icon>add</mat-icon>
        </button>
</div>
<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="org">
        <mat-header-cell *matHeaderCellDef>Cég</mat-header-cell>
        <mat-cell *matCellDef="let row">
            
            <app-quick-log-info [data]="row" class="margin-r-10"></app-quick-log-info>
            <span class="link" (click)="openOrg(row._organization.id)">{{row._organization.name}}</span>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator #paginator
    [length]="count"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPaginatorClick($event)"
    [hidePageSize]="true"
></mat-paginator>

