import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { EnumTaskTypes } from 'src/app/enums/tasks.enums';
import { ActionPlan } from 'src/app/models/projects/actionPlan.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { PlanningActionService } from 'src/app/services/projects/planningAction.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-action-plan',
  templateUrl: './action-plan.component.html',
  styleUrls: ['./action-plan.component.scss']
})
export class ActionPlanComponent extends BaseEntity implements OnInit {
  @Input() actionPlan: ActionPlan;

  editMode: boolean = false;
  enumTaskTypes: any = EnumTaskTypes;

  @Output() eventCreated: EventEmitter<ActionPlan> = new EventEmitter();
  @Output() eventDeleted: EventEmitter<ActionPlan> = new EventEmitter();
  @Output() eventUpdated: EventEmitter<ActionPlan> = new EventEmitter();

  palnCreate$;
  planUpdate$;
  planDel$;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modelFactory: ModelFactory,
    private planningActionService: PlanningActionService
  ) {
    super();
  }

  ngOnInit() {}

  save() {
    (this.actionPlan.id) ? this.update() : this.create();
  }

  create() {
    this.palnCreate$ = this.planningActionService.create(this.actionPlan).subscribe(res => {
      this.actionPlan = res;
      this.editMode = true;

      this.eventCreated.emit(this.actionPlan);
    })
  }

  update() {
    this.planUpdate$ = this.planningActionService.update(this.actionPlan).subscribe(res => {
      this.actionPlan = res;
      
      this.eventUpdated.emit(this.actionPlan);
    })
  }

  delete() {
    this.planDel$ = this.planningActionService.delete(this.actionPlan).subscribe(() => {
      this.router.navigate(['../'], {
        relativeTo: this.route
      });

      this.eventDeleted.emit();
    });
  }

  changeStatus(event) {
    this.actionPlan.archived = (event.value == 'archived') ? true : false;
  }

}
