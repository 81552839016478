import { validateHorizontalPosition } from '@angular/cdk/overlay';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { create } from 'lodash-es';
import { Product, ProductRelatedOffers } from 'src/app/models/product.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { ProductService } from 'src/app/services/product.service';
import { DlgSearchProductComponent } from 'src/app/shared/dialogs/search/dlg-search-product/dlg-search-product.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-product-related-offer',
  templateUrl: './product-related-offer.component.html',
  styleUrls: ['./product-related-offer.component.scss']
})
export class ProductRelatedOfferComponent implements OnInit {
  @Input() product: Product;
  offers: ProductRelatedOffers[] = [];

  relGet$;
  dlgProdSearch$;
  relCreate$;
  relDel$;

  constructor(
    private modelFactory: ModelFactory,
    private productService: ProductService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.fetch();
  }

  fetch() {
    this.relGet$ = this.productService.getRelatedOffers(this.product.id). subscribe(res => {
      this.offers = res;
    })
  }

  openProductSearchDlg() {
    const dialogConfig = new MatDialogConfig();
    
    let name = (this.product) ? this.product.nameHu : '';
    
    dialogConfig.data = {
      name: name
    };

    const dialogRef = this.dialog.open(DlgSearchProductComponent, dialogConfig);

    this.dlgProdSearch$ = dialogRef.componentInstance.onSelected.subscribe(offer => {
      if (offer) {
       this.create(offer);
      }
    });
  }

  create(offer) {
    let relatedOffer = this.modelFactory.getNewModel(ProductRelatedOffers);
    relatedOffer.product = this.product.id;
    relatedOffer.offer = offer.id;

    if (!this.validate(relatedOffer)) {
      return;
    }
    
    this.relCreate$ = this.productService.createRelatedOffer(relatedOffer).subscribe(res => {
      this.offers.push(res);
    });
  }

  validate(relatedOffer) {
    // validate if the offer product is already in the list
    let isNewOffer = true;

    this.offers.forEach(o => {
      if (o.offer === relatedOffer.offer) {
        isNewOffer = false;
      }
    });

    return isNewOffer
  }

  delete(offer) {
    this.relDel$ = this.productService.deleteRelatedOffer(offer).subscribe(res => {
      this.offers = this.offers.filter(o => o.id != offer.id);
    });
  }

}
