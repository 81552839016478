import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Product } from 'src/app/models/product.model';
import { Manufacturer } from 'src/app/models/manufacturers.model';
import { Category } from 'src/app/models/category.model';
import { ProductService } from 'src/app/services/product.service';
import { DlgSearchManufacturerComponent } from 'src/app/shared/dialogs/search/dlg-search-manufacturer/dlg-search-manufacturer.component';
import { DlgSearchCategoryComponent } from 'src/app/shared/dialogs/search/dlg-search-category/dlg-search-category.component';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { EnumProductType } from 'src/app/enums/productType.enum';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent extends BaseEntity implements OnInit {
  @Input() product: Product;
  @Input() isDlg: boolean = false;
  // use if want to switch of Item creation at Product creation
  @Input() preventItemCreation = false;

  manufacturer: Manufacturer = null;
  category: Category = null;

  @Output() eventCreated: EventEmitter<Product> = new EventEmitter();
  @Output() eventDeleted: EventEmitter<Product> = new EventEmitter();
  @Output() eventUpdated: EventEmitter<Product> = new EventEmitter();

  prodCreate$;
  prodUdpate$;
  prodDelete$;
  dlgManSearch$;
  dlgCatSearch$;

  constructor(
    private productService: ProductService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {}

  save() {
    (this.product.id) ? this.update() : this.create();
  }

  create() {
    if (this.preventItemCreation) {
        this.product.automaticItemCreation = false;
    }

    this.prodCreate$ = this.productService.create(this.product).subscribe(res => {
      this.product = res;
      this.eventCreated.emit(this.product);
    })
  }

  update() {
    this.prodUdpate$ = this.productService.update(this.product).subscribe(res => {
      this.product = res;

      this.eventUpdated.emit(this.product);
    })
  }

  delete() {
    this.prodDelete$ = this.productService.delete(this.product).subscribe(() => {
        this.eventDeleted.emit();
    });
  }

  openManufacturerSearchDlg() {
    const dialogConfig = new MatDialogConfig();
      
    let name = (this.product.manufacturer) ? this.product._manufacturer.name : '';
    
    dialogConfig.data = {
      name: name,
      autoFocus: true
    };

    const dialogRef = this.dialog.open(DlgSearchManufacturerComponent, dialogConfig);

    this.dlgManSearch$ = dialogRef.afterClosed().subscribe(manufacturer => {
      if (manufacturer) {
          this.product.manufacturer = manufacturer.id;
          this.product._manufacturer = manufacturer;
      } else if (manufacturer == '') {
        this.product.manufacturer = null;
          this.product._manufacturer = null;
      }
    });
  }

  openCategorySearchDlg() {
    const dialogConfig = new MatDialogConfig();
    
    let name = (this.product.category) ? this.product._category.nameHu : '';
    
    dialogConfig.data = {
      name: name,
      autoFocus: true
    };

    const dialogRef = this.dialog.open(DlgSearchCategoryComponent, dialogConfig);

    this.dlgCatSearch$ = dialogRef.afterClosed().subscribe(category => {
      if (category) {
          this.product.category = category.id;
          this.product._category = category;
      } else if (category == '') {
        this.product.category = null;
        this.product._category = null;
      }
    });
  }

  changeType(event) {
    this.product.type = event.value;

    if (this.product.type == EnumProductType.Machine) {
      this.product.automaticItemCreation = false;
    } else {
      this.product.automaticItemCreation = true;
    }
  }
}

