export enum EnumTaskActivity {
    Procurement = 'procurement', //  anyagbeszerzes
    Meeting = 'meeting', //  megbeszeles
    Warehousing = 'warehousing', //  raktártakarítás, rendezés
    MachineMovement = 'machine_movement', //  gépmozgatás
    Exhibition = 'exhibition', //  vásár, kiállítás
    Course = 'course', //  tanfolyam
    CustomerVisit = 'customer_visit', //  vevőlátogatás
    TelephoneMarketing = 'telephone_marketing', //  telefonmarketing
    Office = 'office', //  iroda
    Other = 'other', //  egyeb
  }