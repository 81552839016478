import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ProjectPlan } from 'src/app/models/projects/projectPlan.model';
import { ProjectActionPlan } from 'src/app/models/projects/projectActionPlan.model';
import { BaseEntity } from '../../../common/baseClasses/baseEntity';
import { PlanningProjectActionsService } from 'src/app/services/projects/planningProjectActions.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { ActionPlan } from 'src/app/models/projects/actionPlan.model';
import { DlgEditPlanActionComponent } from 'src/app/shared/dialogs/edit/dlg-edit-plan-action/dlg-edit-plan-action.component';
import { DlgSearchPlanActionComponent } from 'src/app/shared/dialogs/search/dlg-search-plan-action/dlg-search-plan-action.component';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ProjectService } from 'src/app/services/projects/project.service';
import { cloneDeep } from 'lodash-es';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

/**
 * View manage list of Actions, send it back in one batch to the backend
 */

@AutoUnsub()
@Component({
  selector: 'app-project-actions-plan',
  templateUrl: './project-actions-plan.component.html',
  styleUrls: ['./project-actions-plan.component.scss']
})
export class ProjectActionsPlanComponent extends BaseEntity implements OnInit {
  @Input() projectPlan: ProjectPlan;

  actions: ProjectActionPlan[] = [];

  @ViewChild('tree') tree;

  treeControl = new NestedTreeControl<ProjectActionPlan>(node => node?.children);
  dataSource = new MatTreeNestedDataSource<ProjectActionPlan>();
  hasChild = (_: number, node: ProjectActionPlan) => !!node?.children && node.children.length > 0;

  actPlanList$;
  dlgactPlanSearch$;
  dlgActPlanEdit$;
  actPlanSave$;

  constructor(
    private modelFacotry: ModelFactory,
    private projectActionPlanService: PlanningProjectActionsService,
    private dialog: MatDialog,
    private projectService: ProjectService
  ) {
    super()
  }

  ngOnInit() {
    // list action for Project
    this.actPlanList$ = this.projectActionPlanService.list(this.projectPlan.id).subscribe(resp => {
      this.actions = resp;
      this.setTreeData();
    })
  }

  setTreeData() {
    let actionTree = this.projectService.getActionTree(this.actions);
    // mat-tree expect an array
    this.dataSource.data = (actionTree) ? [actionTree] : null;

    this.openAllAction();
  }

  openAllAction() {
    // opena all node when start
    // hack to make it work:  https://github.com/angular/components/issues/12469
    if (this.actions.length <= 1) {
      // only open if more than one action
      return;
    }

    setTimeout(() => {
      this.treeControl.dataNodes = this.dataSource.data;
      this.tree.treeControl.expandAll();
    }, 300)

  }

  moveUpAction(_newParent) {
    // get the original actions
    let newParent =  this.actions.find(a => a.id == _newParent.id);
    let originalParent =  this.actions.find(a => a.id == newParent.parent);

    // set parent's parent  id on this for the new parent
    newParent.parent = originalParent.parent;
    newParent._parent = cloneDeep(originalParent._parent); // copied by reference and we swapt them...

    // set the new parent for the original parent
    originalParent.parent = newParent.id;
    originalParent._parent = cloneDeep(newParent); // copied by reference and we swapt them...


    this.setTreeData();
  }

  moveLeftAction(_action) {
    /** move the action to the same lavel as the parent action, 
     * if no parent action stick it to the root
     */
    let action =  this.actions.find(a => a.id == _action.id);

    if (!action.parent) {
      return
    }

    let parentAction = this.actions.find(a => a.id == action.parent);

    if (parentAction.parent) {
      action.parent = parentAction.parent;
      action._parent = parentAction._parent;
    }

    this.setTreeData();
  }

  addChildAction(action) {
    // add new child action to the selected action
    this.openActionPlanSearch(action);
  }

  addFirstAction() {
     // add new child action to the selected action
     this.openActionPlanSearch(null);
  }

  deleteAction(action) {
    this.actions = this.actions.filter(a => a.id != action.id);
    
    this.setTreeData();
  }

  openActionPlanSearch(parentAction) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = null;

    const dialogRef = this.dialog.open(DlgSearchPlanActionComponent, dialogConfig);
    
    this.dlgactPlanSearch$ = dialogRef.componentInstance.onClose.subscribe(action => {
      if (!action) return;

      this.createProjectActionPlan(action, parentAction)
    });
  }

  openActionPlanCreate() {
    const dialogConfig = new MatDialogConfig();

    let action = this.modelFacotry.getNewModel(ActionPlan);
    dialogConfig.data = action;
    dialogConfig.width = '500px';

    const dialogRef = this.dialog.open(DlgEditPlanActionComponent, dialogConfig);
    
    this.dlgActPlanEdit$ = dialogRef.componentInstance.onChange.subscribe(action => {
        // nothing to do, we cannot create a project action paln from this and stick it to the tree
    });
  }

  createProjectActionPlan(action, parentAction) {
    // based on an Action create a Project Action Plan
    let projectActionPlan = this.modelFacotry.getNewModel(ProjectActionPlan);
    projectActionPlan.setId(this.getNextId())
    projectActionPlan.setProject(this.projectPlan);
    projectActionPlan.setAction(action);

    // add parent
    if (parentAction) {
      projectActionPlan.parent = parentAction.id;
      projectActionPlan._parent = parentAction;
    }

    this.actions.push(projectActionPlan);

    this.save();
  }

  save() {
    this.actPlanSave$ = this.projectActionPlanService.save(this.projectPlan.id, this.actions).subscribe(actions => {
      this.actions = actions;
      this.setTreeData();
    })
  }

  delete(action) {
    // becuse order number always keep synced and one Action could be in the list mulitple times
    // the best if we delete it based on order (and not ID)
    this.actions = this.actions.filter(a => a.id != action.id);
  }
}
