import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";

/**
 * Only create new models with this facotry!!!
 * - populate the creator property to work with the security
 */

@Injectable({
    providedIn: 'root'
})
export class ModelFactory {
    constructor(
        private authService: AuthService
    ){ }

    getNewModel(model){
       const instance = new model();
       instance.creator = this.authService.user;
       // at creation the assignee and creator are the same
       instance.assignee = this.authService.user.id;
       instance._assignee = this.authService.user;
       return instance;
    }
}