/**
 * Complication that the input field are excpect string, this is way we have to mock around"
 */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-datetime-range-selector',
  templateUrl: './datetime-range-selector.component.html',
  styleUrls: ['./datetime-range-selector.component.scss']
})
export class DatetimeRangeSelectorComponent implements OnInit, OnChanges {
  @Input() start: Date;
  @Input() end: Date;
  @Input() dateOnly: boolean = false; // only a date count hide the rest but calcualte it at the background

  // calculated from the start (date)
  date: Date;

  strDate: string;
  strStartTime: string;
  strEndTime: string;

  constructor() { }

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
   this.setData();
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.setData();
  }

  setData() {
    if (this.start) {
      this.date = this.start;
      this.strStartTime = this.getTimeStr(this.start);
      this.strDate = this.start.toISOString().slice(0, 10);
    }

    if (this.end) {
      this.strEndTime = this.getTimeStr(this.end);
    }
  }

  change() {
    if (!this.dateOnly) {
      if (!this.strStartTime || !this.strEndTime || !this.strDate) return;
    }
  

    let data = this.getReturnData();

    if (this.dateOnly) {
      // only care about the day set first and last second of the day
      this.strStartTime = this.getTimeStr(this.start);
      this.strEndTime = this.getTimeStr(this.end);
      this.strDate = this.getTimeStr(this.date);
    }

    this.onChange.emit(data);
  }

  getTimeStr(dt) {
    if (!dt) return null;

    let hours = dt.getHours();
    let minutes = dt.getMinutes();

    hours = (hours < 10) ? '0' + hours : hours;
    minutes = (minutes < 10) ? '0' + minutes : minutes;  

    return hours+':'+minutes;
  }


  getReturnData() {
    this.date = new Date(this.strDate);

    if (this.dateOnly) {
      this.start = new Date(this.date.setHours(0,0,0,0));
      this.end =  new Date(this.date.setHours(23,59,59,999));

      return {
        start: this.start,
        end: this.end,
        date: this.date
      }
    }

    // return an object wih the start end end time
    let startBits = this.strStartTime.split(':')
    let endBits = this.strEndTime.split(':')

    this.start = new Date(this.date);
    this.start.setHours(+startBits[0], +startBits[1]);

    this.end = new Date(this.date);
    this.end.setHours(+endBits[0], +endBits[1]);

    if (this.end < this.start) {
      this.end = null;
      this.strEndTime = null;
      alert('Befejezés korábban mint a Kezdet!');
    }

    return {
      start: this.start,
      end: this.end,
      date: this.date
    }
  }

  setNow() {
    // date selection based on the start date (assumed start and end on the same day)
    this.start = new Date();
    this.strStartTime = this.getTimeStr(this.start);
    this.date = this.start;

    // 2 hours from now
    this.end = new Date(new Date().setHours(this.start.getHours() + 2));

    if (this.start.getDay() != this.end.getDay()){
      // end date is the next day set it today last minute
      this.end = new Date(new Date().setHours(23, 59));
    }

    this.strEndTime = this.getTimeStr(this.end);

    this.strDate = this.start.toISOString().slice(0, 10);

    this.strStartTime = this.getTimeStr(this.start);
    this.strEndTime = this.getTimeStr(this.end);

    let data = this.getReturnData();
    this.onChange.emit(data);
  }
}
