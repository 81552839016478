import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { EnumActionStatus } from 'src/app/enums/actionStatus.enum';
import { Action } from 'src/app/models/projects/action.model';
import { Task } from 'src/app/models/tasks/task.model';
import { ActionService } from 'src/app/services/projects/action.service';
import { TaskService } from 'src/app/services/tasks/task.service';
import { DlgEditTaskComponent } from 'src/app/shared/dialogs/edit/tasks/dlg-edit-task/dlg-edit-task.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';
import { TaskUtil } from 'src/app/utils/task.util';

@AutoUnsub()
@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent extends BaseEntity implements OnInit {
  @Input() action: Action;
  @Output() onChange: EventEmitter<Action> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onChangeTask: EventEmitter<Task> = new EventEmitter();

  task: Task;

  enumActionStatus = EnumActionStatus;

  actUpdate$;
  taskList$;
  dlgTaskEdit$;
  taskCreate$;

  constructor(
    private actionService: ActionService,
    private taskService: TaskService,
    private router: Router,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    if(this.action.taskCreated) {
      this.fetchTask();
    }
  }

  statusChange(status) {
    this.action.status = status
    this.actUpdate$ = this.actionService.update(this.action).subscribe();

    this.onChange.emit(this.action);
  }

  fetchTask() {
    let params = {
      action: this.action.id
    }

    this.taskList$ = this.taskService.list({params}).subscribe(res => {
        if (res.results.length > 0) {
          this.task = res.results[0];
        }
    });
  }

  goToTask() {
    let fullPath = TaskUtil.getTaskPath(this.task.taskType);

    // ugly hack to ensure the the components recreated with the new task id even when 
    // it is the same page
    this.router.navigate(['/']).then(() => { 
      this.router.navigate([fullPath],  {
        queryParams: {id: this.task.id}
      })

      this.onClose.emit();
     })
  }

  openTaskEdit() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      task: this.task
    };

    dialogConfig.width = '450px';
    dialogConfig.disableClose =  true;

    const dialogRef = this.dialog.open(DlgEditTaskComponent, dialogConfig);

    this.dlgTaskEdit$ = dialogRef.componentInstance.onChange.subscribe(task => {
      this.task = task;
      this.action.status = this.task.status;

      if (this.task.action != this.action.id) {
        // it looks the task is assigned to a different Action, lets remove it
        this.task = null;
      }

      this.onChangeTask.emit(this.task);
      this.onChange.emit(this.action);
    }); 
  }

  createTask() {
    this.taskCreate$ = this.actionService.createTask(this.action).subscribe(res => {
      this.action.taskCreated = res.action.taskCreated;
      this.task = res.task;
    });

  }

}
