<div class="sidemenu">
  <button color="primary" mat-button routerLink="/app/dash" (click)="clicked()">
      <mat-icon>emoji_food_beverage</mat-icon>Főoldal
    </button>

    <button *ngIf="projects" color="primary" mat-button routerLink="/app/projects" (click)="clicked()">
      <mat-icon>device_hub</mat-icon> Projektek
    </button>


    <!---------------Inventory--------------->
    <ng-container *ngIf="inventory">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class='text-center'>Raktár</div>
            </mat-panel-title>
            </mat-expansion-panel-header>
              <!--content-->
              <button color="primary" mat-button routerLink="/app/inventory" (click)="clicked()">
                <mat-icon>inventory_2</mat-icon> Raktár Készlet
              </button>
              <button color="primary" mat-button routerLink="/app/inventory/transactions" (click)="clicked()">
                <mat-icon>move_down</mat-icon> Raktár Mozgások
              </button>
              <!--end content -->
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>


  <!---------------Tasks--------------->
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class='text-center'>Feladatok</div>
        </mat-panel-title>
        </mat-expansion-panel-header>
          <!--content-->
        <button *ngIf="taskAny" color="primary" mat-button routerLink="/app/tasks/all" (click)="clicked()">
            <span class="text-blue text-small">Összes Feladat</span>
        </button>
        <button *ngIf="taskClients" color="primary" mat-button routerLink="/app/tasks/customer-interests" (click)="clicked()">
            <mat-icon>3p</mat-icon><span>Ajánlatkérés Vevőtől</span>
        </button>
        <button *ngIf="taskVendors" color="primary" mat-button routerLink="/app/tasks/vendor-interests" (click)="clicked()">
          <mat-icon>supervised_user_circle</mat-icon><span>Ajánlatkérés Beszállítótól</span>
        </button>
        <button *ngIf="taskClients" color="primary" mat-button routerLink="/app/tasks/customer-offers" (click)="clicked()">
          <mat-icon>subject</mat-icon><span>Ajánlat a Vevőnek</span>
        </button>
        <button *ngIf="taskClients" color="primary" mat-button routerLink="/app/tasks/customer-orders" (click)="clicked()">
          <mat-icon>attach_money</mat-icon><span>Megrendelés a Vevőtől</span>
        </button>
        <button *ngIf="taskClients" color="primary" mat-button routerLink="/app/tasks/customer-confirmations" (click)="clicked()">
          <mat-icon>thumb_up_alt</mat-icon><span>Visszaigazolás a Vevőnek</span>
        </button>
        <button *ngIf="taskVendors" color="primary" mat-button routerLink="/app/tasks/vendor-orders" (click)="clicked()">
          <mat-icon>how_to_vote</mat-icon><span>Megrendelés a Beszállítótól</span>
        </button>
        <button *ngIf="inventory" color="primary" mat-button routerLink="/app/tasks/inventories-in" (click)="clicked()">
          <mat-icon>vertical_align_bottom</mat-icon><span>Bevételezés a Raktárba</span>
        </button>
        <button *ngIf="inventory" color="primary" mat-button routerLink="/app/tasks/inventories-out" (click)="clicked()">
          <mat-icon>vertical_align_top</mat-icon><span>Kivételezés a Raktárból</span>
        </button>
        <button *ngIf="taskClients" color="primary" mat-button routerLink="/app/tasks/customer-deliveries" (click)="clicked()">
          <mat-icon>person_pin_circle</mat-icon><span>Kiszállítás a Vevőnek</span>
        </button>
        <button *ngIf="taskClients" color="primary" mat-button routerLink="/app/tasks/customer-handovers" (click)="clicked()">
          <mat-icon>real_estate_agent</mat-icon><span>Átadás/Átvétel</span>
        </button>

        <button *ngIf="taskServices" color="primary" mat-button routerLink="/app/tasks/services" (click)="clicked()">
          <mat-icon>construction</mat-icon> Szervízek
        </button>

        <button *ngIf="taskServices" color="primary" mat-button routerLink="/app/tasks/activites" (click)="clicked()">
          <mat-icon>accessibility</mat-icon> Tevékenységek
        </button>
          <!--end content -->
    </mat-expansion-panel>
  </mat-accordion>

    <!---------------Service MGMT--------->
    <ng-container *ngIf="hasAccessToEventPlanning">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class='text-center'>Szervíz és Tennivalók</div>
            </mat-panel-title>
            </mat-expansion-panel-header>
              <!--content-->
              <button color="primary" mat-button routerLink="/app/services/management" (click)="clicked()">
                <mat-icon>event</mat-icon> Események
              </button>
              <button color="primary" mat-button (click)="searchInTaskEvents()">
                <mat-icon>pageview</mat-icon> Keresés as adatokban
              </button>
              <!--end content -->
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>

  <!---------------Contacts--------------->
  <ng-container *ngIf="contacts">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class='text-center'>Telefonkönyv</div>
          </mat-panel-title>
          </mat-expansion-panel-header>
            <!--content-->
            <button color="primary" mat-button routerLink="/app/contacts/organisations" (click)="clicked()">
              <mat-icon>storefront</mat-icon> Cégek
            </button>
            <button color="primary" mat-button routerLink="/app/contacts/persons" (click)="clicked()">
              <mat-icon>assignment_ind</mat-icon> Kontaktok
            </button>
            <!--end content -->
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
  
  <!---------------Items--------------->
    <ng-container *ngIf="items">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class='text-center'>Termékek</div>
            </mat-panel-title>
            </mat-expansion-panel-header>
              <!--content-->
              <button color="primary" mat-button routerLink="/app/items" (click)="clicked()">
                <mat-icon>precision_manufacturing</mat-icon>Raktári Termékek
              </button>
              <hr>
              <button color="primary" mat-button routerLink="/app/products" (click)="clicked()">
                <mat-icon>coffee_maker</mat-icon> Árucikk Csoport
              </button>
              <button color="primary" mat-button routerLink="/app/manufacturers" (click)="clicked()">
                <mat-icon>domain</mat-icon> Gyártók
              </button>
              <button color="primary" mat-button routerLink="/app/categories" (click)="clicked()">
                <mat-icon>view_agenda</mat-icon> Kategóriák
              </button>
              <button color="primary" mat-button routerLink="app/attributes/attributes" (click)="clicked()">
                <mat-icon>table_view</mat-icon> Műszaki adatok
              </button>
              <!--end content -->
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  
  
    <!---------------Project Templates--------------->
    <ng-container *ngIf="projectPlanning">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class='text-center'>Projekt Sablon Beállítások</div>
            </mat-panel-title>
            </mat-expansion-panel-header>
              <!--content-->
              <button color="primary" mat-button routerLink="/app/project-plans" (click)="clicked()">
                <mat-icon>pattern</mat-icon> Projekt Sablon
                </button>
                <button color="primary" mat-button routerLink="/app/project-plans/actions" (click)="clicked()">
                  <mat-icon>adjust</mat-icon> Projekt Lépés Sablon
              </button>
              <button color="primary" mat-button routerLink="/app/project-plans/types" (click)="clicked()">
                <mat-icon>label</mat-icon> Projekt Típusok
            </button>
              <!--end content -->
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>


    <!---------------Email is Pdf subloniok--------------->
    <ng-container *ngIf="admin">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class='text-center'>PDF és Email Beállítások</div>
            </mat-panel-title>
            </mat-expansion-panel-header>
              <!--content-->
              <button color="primary" mat-button routerLink="/app/templates" (click)="clicked()">
                  <mat-icon>receipt_long</mat-icon> Sablonok
              </button>
              <!--end content -->
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>


    <!---------------Config Section--------------->
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class='text-center'>Felhasználói fiókom</div>
            </mat-panel-title>
            </mat-expansion-panel-header>
              <!--content Own Profile-->
              <button color="primary" mat-button routerLink="/app/my-profile" (click)="clicked()">
                <mat-icon>sentiment_satisfied_alt</mat-icon> Profilom
              </button>
        </mat-expansion-panel>
      </mat-accordion>

       <!---------------ADMIN--------------->
       <mat-accordion *ngIf="admin">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class='text-center'>Admin</div>
            </mat-panel-title>
            </mat-expansion-panel-header>
              <!--ADMIN Content-->
              <ng-container>
                <button color="primary" mat-button routerLink="/app/accounts/users" (click)="clicked()">
                  <mat-icon>account_circle</mat-icon> Felhasználók
                </button>
                <button color="primary" mat-button routerLink="/app/accounts/company" (click)="clicked()">
                    <mat-icon>home_work</mat-icon> Szervezet
                </button>
                <button color="primary" mat-button routerLink="/app/accounts/configuration" (click)="clicked()">
                  <mat-icon>tune</mat-icon> Konfiguració
                </button>
                <button color="primary" mat-button routerLink="/app/accounts/email-logs" (click)="clicked()">
                  <mat-icon>alternate_email</mat-icon> Email Logs
                </button>
                 <!---------------Reports--------------->
                  <mat-accordion>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <div class='text-center'>Riportok</div>
                        </mat-panel-title>
                        </mat-expansion-panel-header>
                          <!--content-->
                          <button color="primary" mat-button routerLink="/app/reports/forecast" (click)="clicked()">
                              <mat-icon>leaderboard</mat-icon> Vorkalkulation
                          </button>
                          <!--end content -->
                    </mat-expansion-panel>
                  </mat-accordion>
              </ng-container>
              <!--end admin content -->
        </mat-expansion-panel>
      </mat-accordion>

</div>