import { Component, Input, OnInit } from '@angular/core';
import { Organisation } from 'src/app/models/organisation';
import { People } from 'src/app/models/people.model';
import { Task } from 'src/app/models/tasks/task.model';
import { PeopleService } from 'src/app/services/people.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-default-section-pdf-header',
  templateUrl: './default-section-pdf-header.component.html',
  styleUrls: ['./default-section-pdf-header.component.scss']
})
export class DefaultSectionPdfHeaderComponent implements OnInit {
  @Input() defaultConfig: any // pdfData section, update the cofnig data
  @Input() task: Task;

  org: Organisation;
  recipient: People;

  peopleGet$;

  constructor(
    private peopleService: PeopleService
  ) { }

  ngOnInit() {
    if (this.task.organization) {
      this.org = this.task._organization;
    }

    this.initRecipient();

    if (!this.defaultConfig?.partnerRefNumber) {
      this.defaultConfig.partnerRefNumber = null;
    }
  }

  initRecipient() {
    // if the recipient is set on the config we can load the People to pass in to the the selecotr
    if (!this.defaultConfig?.recipient) {
      return;
    }
  
    this.peopleGet$ = this.peopleService.get(this.defaultConfig.recipient.id).subscribe(people => {this.recipient = people})
  }

  setRecipient(people) {
    if (!people) {
      this.defaultConfig['recipient'] = null;
      return
    }
    this.defaultConfig['recipient'] = {
      id: people.id,
      firstName: people.firstName,
      lastName: people.lastName,
      email: people.email
    };
  }

  updatePartnerSelect(partnerRefNumber) {
    this.defaultConfig.partnerRefNumber = partnerRefNumber;
  }

}
