import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Output() onFileSelect = new EventEmitter<any>();

  uploadForm: UntypedFormGroup; 

  fileToUpload: File = null;

  constructor() { }

  ngOnInit() {
  }

  onFileDropped(files: any) {
    if (files.length > 0) {
      this.fileToUpload = files.item(0);

      this.fileUpload();
    }
  }

  handleFileInput(files: any) {
    if (files.length > 0) {
      this.fileToUpload = files.item(0);

      this.fileUpload();
    }
  }

  fileUpload() {
    let formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);

    this.onFileSelect.emit(formData);
  }
}
