<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Raktári Termékek</a></li> 
    <ng-container *ngIf="item.product">
        <li>{{item._product.nameHu}} {{item._product.articleNumber}} {{item.serialNumber}}</li>  
    </ng-container> 
</ul>


<div class="flex padding-10-0">
    <div class="full">
        <ng-container *ngIf="item.product">
            <h4><app-product-container [product]="item._product"></app-product-container></h4>
            {{item.serialNumber}} 
        </ng-container>
    </div>
    <!--<div class="padding-r-20">
        <button mat-stroked-button color="primary" (click)="qrCodeDownload()">
            <mat-icon matTooltip="QR Kód Létrehozása">download</mat-icon> QR Kód
        </button>
    </div>-->
    <button mat-stroked-button color="primary" (click)="createProductDlg()">Árucikk Csoport Létrehozása</button>
</div>

<div class="section">
    <div class="header">
        <div class="title">
            <span>Raktári Termék</span>
            <mat-icon matTooltip="Migrált adat" class="m-l-10 text-yellow" *ngIf="item && item.migrated">move_down</mat-icon>
        </div>
    </div>
    <app-item 
    (eventCreated)="created($event)"
    (eventDeleted)="deleted($event)" 
    (eventUpdated)="updated($event)"
    [item]="item"></app-item>
</div>

<ng-container *ngIf="item.id">
    <div class="section">
        <div class="header">
            <div class="title"><span>Beépített Termékek</span></div>
        </div>
        <app-item-built-into [item]="item"></app-item-built-into>
    </div>
    
    <div class="section">
        <div class="header">
            <div class="title"><span>Cégek a Termékkel</span></div>
        </div>
        <app-item-org [pageItem]="item"></app-item-org>
    </div>
</ng-container>

