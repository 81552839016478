import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseList } from 'src/app/common/baseClasses/baseList';
import { Item } from 'src/app/models/item.model';
import { OrganisationItem } from 'src/app/models/organisation';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { UrlParamService } from 'src/app/services/urlParam.service';
import { DlgEditOrganisationItemComponent } from 'src/app/shared/dialogs/edit/dlg-edit-organisation-item/dlg-edit-organisation-item.component';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-item-org',
  templateUrl: './item-org.component.html',
  styleUrls: ['./item-org.component.scss'],
  providers: [UrlParamService]
})
export class ItemOrgComponent extends BaseList implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['org'];

  @Input() pageItem: Item;
  orgItems: OrganisationItem[] = null;

  itemList$;
  dlgItemEdit$;

  constructor(
    private organisationService: OrganisationService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService,
    private modelFactory: ModelFactory,
    private dialog: MatDialog,
  ) {
    super()

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit() {
    this.fetch();
  }

  fetch() {
    let defaultParams = {item: this.pageItem.id}

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        if (key === 'id') {
          // when render list table response ignore key, cannot be relevant
          // it could happen if the list presponse is not the only componenet on the page
          continue;
        }

        this[key] = options.params[key];
      }

      this.itemList$ = this.organisationService.listItems(options).subscribe(data => {
        this.setListResponseData(data);
      })
    })
  }

  openOrg(orgId: number) {
    this.router.navigate(['../../contacts/organisations/organisation'],  {
      queryParams: {id: orgId}, 
      relativeTo: this.route  
    });
  }

  add() {
    let orgItem = this.modelFactory.getNewModel(OrganisationItem);
    orgItem.setItem(this.pageItem);
    this.editOrgItemDlg(orgItem);
  }

  editOrgItemDlg(orgItem) {
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.data = {orgItem: orgItem};

    const dialogRef = this.dialog.open(DlgEditOrganisationItemComponent, dialogConfig);

    this.dlgItemEdit$ = dialogRef.afterClosed().subscribe(orgItem => {
      this.fetch()
    });
  }


}
