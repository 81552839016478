<ng-container *ngIf="(value | typeof) === 'number'">
    <ng-container *ngIf="isColor; else tmpNormal">
        <span style="white-space: nowrap;" [ngClass]="(value > 0) ? 'text-green' : (value < 0) ? 'text-red' : '' ">{{formattedValue}}</span>
    </ng-container>
    
    <ng-template #tmpNormal>
        <span style="white-space: nowrap;"> {{formattedValue}}</span>  
    </ng-template>
</ng-container>

