<mat-tab-group animationDuration="0ms">
    <mat-tab label="Legújabb Változások">
        <app-audits></app-audits>
    </mat-tab>
    <mat-tab label="Saját Események/Tennivalók" *ngIf="hasAccessServices">
        <ng-template matTabContent>
            <app-my-task-events></app-my-task-events>
        </ng-template>
    </mat-tab>
    <mat-tab label="Saját Feladatok">
        <ng-template matTabContent>
            <app-my-tasks></app-my-tasks>
        </ng-template>
    </mat-tab>
  </mat-tab-group>