
export class DateUtil {
    static startOfDay(date: Date) {
        // return the first moment of the day
        let start = new Date(date);
        start.setUTCHours(0,0,0,0);
        return start
    }
    
    static endOfDay(date: Date) {
        // return the last moment of the day
        let end = new Date(date);
        end.setUTCHours(23,59,59,999);
        return end
    }
}
