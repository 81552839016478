import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from './notification';
import { Utils } from '../shared/utils';
import { Template, TemplateSection, TemplateSectionVersion } from '../models/template.model';


@Injectable({
    providedIn: 'root'
  })
export class TemplateService {
    baseUrl = environment.baseUrl;

    URL_TEMPLATES = '/templates';
    URL_SECTIONS = '/templates/sections';
    URL_VERSIONS = '/templates/sections/versions'

    // list items
    dataSource$ = new BehaviorSubject<any[]>([])
    data = this.dataSource$.asObservable();

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {}

    list(options=null) {
        // list all the contacts
        // @params: obj of params
        // @url: full url with params (next and prev for pagination)
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent (paginated) we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL_TEMPLATES + '/';
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }

        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(i => new Template(i));
                return res;
            })
        )
    }

    get(id) {
        let url = this.baseUrl + this.URL_TEMPLATES + '/' + id + '/';
        return this.http.get<Template>(url).pipe(
            map(res => new Template(res))
        )
    }

    create(payload) {
        let url = this.baseUrl + this.URL_TEMPLATES  + '/';
        return this.http.post(url, payload).pipe(
            map(result => new Template(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    update(payload) {
        let url = this.baseUrl + this.URL_TEMPLATES + '/' + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new Template(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    delete(payload) {
        let url = this.baseUrl + this.URL_TEMPLATES + '/' + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

    // section

    getTemplateSections(templateId, context=null) {
        // no pagination for the endpoint
        let url = this.baseUrl + this.URL_SECTIONS + '/';

        let params = {
            template: templateId
        }

        if (context) {
            params['context'] = context;
        }

        return this.http.get<any>(url, {params: params}).pipe(
            map(res => {
                res = res.map(i => new TemplateSection(i));
                return res;
            })
        )
    }

    saveTemplateSections(payload) {
        // only POST allowed to send all the sections at once to the backend
        // - expected response is a list of sections
        let url = this.baseUrl + this.URL_SECTIONS + '/';;
        return this.http.post<any>(url, payload).pipe(
            map(res => {
                res = res.map(i => new TemplateSection(i));
                return res;
            }),
            tap(res => { this.notificationService.created(); })
        )
    }

    // versions

    getTemplateSectionVersions(sectionId) {
        // no pagination for the endpoint
        let url = this.baseUrl + this.URL_VERSIONS + '/';

        let params = {
            section: sectionId
        }

        return this.http.get<any>(url, {params: params}).pipe(
            map(res => {
                res = res.map(i => new TemplateSectionVersion(i));
                return res;
            })
        )
    }

    createTemplateSectionVersion(payload) {
        let url = this.baseUrl + this.URL_VERSIONS  + '/';
        return this.http.post(url, payload).pipe(
            map(result => new TemplateSectionVersion(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    updateTemplateSectionVersion(payload) {
        let url = this.baseUrl + this.URL_VERSIONS + '/' + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new TemplateSectionVersion(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    deleteTempalateSectionVersion(payload) {
        let url = this.baseUrl + this.URL_VERSIONS + '/' + payload.id + '/';
        return this.http.delete(url, payload).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

}