<ul class="breadcrumb"> 
    <li><a [routerLink]="['../']">Ajánlatkérés Beszállítótól</a></li> 
    <ng-container *ngIf="task">
        <li>{{task.name}}</li>  
    </ng-container> 
</ul>

<ng-container>
    <mat-tab-group  animationDuration="0ms" dynamicHeight (selectedTabChange)="onTabClick($event)">
        <mat-tab label="Feladat">
            <ng-container *ngTemplateOutlet="taskDetails"></ng-container>
        </mat-tab>
        <mat-tab label="Ajánlatkérések">
            <ng-template matTabContent>
              <ng-container *ngIf="createPdfView; else tmpListPdf">
                <div class="padding-10-0">
                    <button mat-flat-button color="primary" (click)="backToPdfList()">
                        <mat-icon>arrow_back</mat-icon>
                        Vissza a Generált Ajánlatkérésekhez
                    </button>
                </div>
                <ng-container *ngTemplateOutlet="generatePDf"></ng-container>
              </ng-container>
              <ng-template #tmpListPdf>
                  <div class="padding-10-0">
                      <button mat-flat-button color="primary" (click)="createPdfView=!createPdfView">
                          <mat-icon>add</mat-icon>
                          Új Ajánlatkérés Generálás
                        </button>
                  </div>
                <app-task-pdfs *ngIf="task" [task]="task" [templateCategory]="TEMPLATE_CATEGORY" (onCreate)="createPdfFromPdf($event)"></app-task-pdfs> 
              </ng-template>
            </ng-template>
        </mat-tab>
        <mat-tab label="Jegyzetek">
            <ng-template matTabContent>
                <ng-container *ngTemplateOutlet="taskNotes"></ng-container>
            </ng-template>
        </mat-tab>
        <ng-container *ngIf="hasAccessProjects">
            <mat-tab label="Projekt" *ngIf="task && task.action;">
                <ng-template matTabContent>
                    <ng-container *ngTemplateOutlet="projectDetails"></ng-container>
                </ng-template>
            </mat-tab>
        </ng-container>
    </mat-tab-group>
</ng-container>


<ng-template #taskDetails>
    <ng-container *ngIf="task">
        <app-task-detail [task]="task" (onChange)="taskUpdated($event)"></app-task-detail>
    </ng-container>

    <ng-container *ngIf="task">
        <app-task-products [task]="task" [type]="'product'" [searchByOrg]="true"></app-task-products>
    </ng-container>

    <ng-container *ngIf="task">
        <app-task-docs [task]="task"></app-task-docs>
    </ng-container>
</ng-template>


<ng-template #projectDetails>
    <ng-container *ngIf="project">
        <app-project-details [project]="project" (onChange)="projectUpdated($event)"></app-project-details>
    </ng-container>   
</ng-template>


<ng-template #taskNotes matTabContent >
    <app-task-notes [task]="task"></app-task-notes>
</ng-template>


<ng-template #generatePDf>
    <ng-container *ngIf="isPdfGenerated">
        <div class="alert alert-success">Pdf generalálása befeleződött. Ha viassza mész a lista oldalra, a frissítés gomb megnyomása után, a pdf megfog jelenni</div>
    </ng-container> 

    <app-template-selector *ngIf="!editPdf"
        [category]="TEMPLATE_CATEGORY"
        [type]="enumTemplateType.Pdf"
        (eventTemplate)="setTemplate($event)"
        (eventSections)="setTemplateSections($event)">
    </app-template-selector>

    <app-template-builder 
        [products]="products"
        [template]="template"
        [templateSections]="templateSections"
        [language]="language"
        [isLanguageOption]="false"
        [task]="task"
        (onGeneratePdf)="generateOfferPdf($event)"
        (onReset)="resetPdfGeneration()">
    </app-template-builder>
</ng-template>


