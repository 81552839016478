import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { UsersService } from 'src/app/services/users.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-default-config-signature',
  templateUrl: './default-config-signature.component.html',
  styleUrls: ['./default-config-signature.component.scss']
})
export class DefaultConfigSignatureComponent implements OnInit {
  @Input() defaultConfig: any;

  sender: User;
  includeStamp = false;
  includeSignature = false;

  userGet$;

  constructor(
    private userService: UsersService
  ) { }

  ngOnInit() {
    // if the recipient is set on the config we can load the People to pass in to the the selecotr
    if (!this.defaultConfig?.sender) return;
    this.userGet$ = this.userService.get(this.defaultConfig.sender.id).subscribe(user => {this.sender = user})
    
    if (this.defaultConfig?.includeSignature) {
      this.includeSignature = this.defaultConfig.includeSignature;
    }

    if (this.defaultConfig?.includeStamp) {
      this.includeStamp = this.defaultConfig.includeStamp;
    }
  }

  setSender(user) {
    if (!user) {
      this.defaultConfig['sender'] = null;
      return;
    }

    this.defaultConfig['sender'] = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email
    };
  }

  setIncludeStamp() {
    this.includeStamp = !this.includeStamp;
    this.defaultConfig['includeStamp'] = this.includeStamp;
  }

  setIncludeSignature() {
    this.includeSignature = !this.includeSignature;
    this.defaultConfig['includeSignature'] = this.includeSignature;
  }

}
