import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AttributeGroup } from 'src/app/models/attributeGroup.model';
import { AttributeGroupService } from 'src/app/services/attributeGroup.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-attribute-group',
  templateUrl: './attribute-group.component.html',
  styleUrls: ['./attribute-group.component.scss']
})
export class AttributeGroupComponent implements OnInit {
  @Input() attributeGroup: AttributeGroup;

  @Output() eventCreated: EventEmitter<AttributeGroup> = new EventEmitter();
  @Output() eventUpdated: EventEmitter<AttributeGroup> = new EventEmitter();
  @Output() eventDeleted: EventEmitter<any> = new EventEmitter();
  
  attrGroup$;
  attrGroup1$;
  attrGroup2$;

  constructor(
    private attributeGroupService: AttributeGroupService
  ) { }

  ngOnInit() {
  }

  save() {
    (this.attributeGroup.id) ? this.update() : this.create();
  }

  create() {
    this.attrGroup$ = this.attributeGroupService.create(this.attributeGroup).subscribe(ag => {
      this.attributeGroup = ag;
      this.eventCreated.emit(this.attributeGroup);
    })
  }

  update() {
    this.attrGroup1$ = this.attributeGroupService.update(this.attributeGroup).subscribe(c => {
      this.attributeGroup = c;
      this.eventUpdated.emit(this.attributeGroup);
    })
  }

  delete() {
    this.attrGroup2$ =this.attributeGroupService.delete(this.attributeGroup).subscribe(() => {
        this.eventDeleted.emit()
      });
    }

}
