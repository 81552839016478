import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Organisation, OrganisationAddress, OrganisationEmail, OrganisationItem, OrganisationPhone } from '../models/organisation';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationService } from './notification';
import { Utils } from '../shared/utils';
import { ListResponse } from '../interfaces/httpResponse.interface';

@Injectable({
    providedIn: 'root'
  })
export class OrganisationService {
    baseUrl = environment.baseUrl;

    URL_ORGANISATION = '/organisations/';
    URL_ORGANISATION_EMAIL = '/organisations/emails/';
    URL_ORGANISATION_PHONE = '/organisations/phones/';
    URL_ORGANISATION_ADDRESS = '/organisations/addresses/';
    URL_ORGANISATION_ITEMS = '/organisations/items/';

    // list of the organisations
    dataSource$ = new BehaviorSubject<any[]>([])
    data = this.dataSource$.asObservable();

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService
        ) {}

    list(options=null) {
        // list all the organisations
        // params: obj of params
        // url: full url with params (next and prev for pagination)
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL_ORGANISATION;
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }
        
        return this.http.get<any>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(org => new Organisation(org));
                return res;
            })
        )
    }

    get(id) {
        // get organisation by id
        let url = this.baseUrl + this.URL_ORGANISATION + id + '/';
        return this.http.get<Organisation>(url).pipe(
            map(res => new Organisation(res))
        )
    }

    create(payload) {
        // create organisation
        let url = this.baseUrl + this.URL_ORGANISATION;
        return this.http.post(url, payload).pipe(
            map(result => new Organisation(result)),
            tap(res => { this.notificationService.created(); })
        )
    }


    update(payload) {
        // update organisation
        let url = this.baseUrl + this.URL_ORGANISATION + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(res => new Organisation(res)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    delete(payload) {
        // update Organisation Email
        let url = this.baseUrl + this.URL_ORGANISATION + payload.id + '/';
        return this.http.delete(url).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

    // EMAILS

    listEmails(params=null) {
        // list organisation's email

        if (params) {
            params = Utils.getParams(params);
        }

        let url = this.baseUrl + this.URL_ORGANISATION_EMAIL;
        
        return this.http.get<ListResponse>(url, {params}).pipe(
            map(res => res.results.map(data => new OrganisationEmail(data)))
        )
    }

    createEmail(payload) {
        // create Organisation Email
        let url = this.baseUrl + this.URL_ORGANISATION_EMAIL;
        return this.http.post(url, payload).pipe(
            map(result => new OrganisationEmail(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    updateEmail(payload) {
        // update Organisation Email
        let url = this.baseUrl + this.URL_ORGANISATION_EMAIL + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(result => new OrganisationEmail(result)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    deleteEmail(emailId) {
        // update Organisation Email
        let url = this.baseUrl + this.URL_ORGANISATION_EMAIL + emailId + '/';
        return this.http.delete(url).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

     // Phones

     listPhones(params=null) {
        // list organisation's phones

        if (params) {
            params = Utils.getParams(params);
        }

        let url = this.baseUrl + this.URL_ORGANISATION_PHONE;
        
        return this.http.get<ListResponse>(url, {params}).pipe(
            map(res => res.results.map(data => new OrganisationPhone(data)))
        )
    }

    createPhone(payload) {
        // create Organisation Phone
        let url = this.baseUrl + this.URL_ORGANISATION_PHONE;
        return this.http.post(url, payload).pipe(
            map(result => new OrganisationPhone(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    updatePhone(payload) {
        // update Organisation Phone
        let url = this.baseUrl + this.URL_ORGANISATION_PHONE + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(result => new OrganisationPhone(result)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    deletePhone(emailId) {
        // update Organisation Phone
        let url = this.baseUrl + this.URL_ORGANISATION_PHONE + emailId + '/';
        return this.http.delete(url).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

    // Addresses

    listAddresses(params=null) {
        // list organisation's addresses
        if (params) {
            params = Utils.getParams(params);
        }

        let url = this.baseUrl + this.URL_ORGANISATION_ADDRESS;
        
        return this.http.get<ListResponse>(url, {params}).pipe(
            map(res => res.results.map(data => new OrganisationAddress(data)))
        )
    }

    createAddress(payload) {
        // create Organisation Address
        let url = this.baseUrl + this.URL_ORGANISATION_ADDRESS;
        return this.http.post(url, payload).pipe(
            map(result => new OrganisationAddress(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    updateAddress(payload) {
        // update Organisation Address
        let url = this.baseUrl + this.URL_ORGANISATION_ADDRESS + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(result => new OrganisationAddress(result)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    deleteAddress(id) {
        // update Organisation Address
        let url = this.baseUrl + this.URL_ORGANISATION_ADDRESS + id + '/';
        return this.http.delete(url).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }

     // Items

     listItems(options=null) {
        // list all the organisations
        // params: obj of params
        // url: full url with params (next and prev for pagination)
        let url = null;
        let params = null;
        
        // set options
        let base = {url: null, params: null}
        if (options) {
            options = {...base, ...options};
        } else {
            options = base;
        }

        if (options.url) {
            // if url sent we don't care about params just grab the data by the url
           url = options.url; 
        } else {
            url = this.baseUrl + this.URL_ORGANISATION_ITEMS;
            if (options.params) {
                params = Utils.getParams(options.params);
            }
        }

        return this.http.get<ListResponse>(url, {params}).pipe(
            map(res => {
                res.results = res.results.map(data => new OrganisationItem(data));
                return res;
            })
        )
    }
  
    createItem(payload) {
        // add a new Item to a Organisation
        let url = this.baseUrl + this.URL_ORGANISATION_ITEMS;
        return this.http.post(url, payload).pipe(
            map(result => new OrganisationItem(result)),
            tap(res => { this.notificationService.created(); })
        )
    }

    updateItem(payload) {
        // change Item or Organisation for connection
        let url = this.baseUrl + this.URL_ORGANISATION_ITEMS + payload.id + '/';
        return this.http.put(url, payload).pipe(
            map(result => new OrganisationItem(result)),
            tap(res => { this.notificationService.updated(); })
        )
    }

    deleteItem(payload) {
        // delete Item and Organisation connection
        let url = this.baseUrl + this.URL_ORGANISATION_ITEMS + payload.id + '/';
        return this.http.delete(url).pipe(
            tap(res => { this.notificationService.deleted(); })
        )
    }
}