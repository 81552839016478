import { Component, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { OrganisationService } from 'src/app/services/organisation.service';
import { environment } from '../../../environments/environment';
import { UrlParamService } from '../../services/urlParam.service';
import { BaseList } from 'src/app/common/baseClasses/baseList';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';


@AutoUnsub()
@Component({
  selector: 'app-organisations',
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.scss'],
  providers: [UrlParamService]
})
export class OrganisationsComponent extends BaseList implements OnInit  {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // filter
  isVendor: boolean = false;

  displayedColumns = ['name', 'industry', 'updated'];

  orgs$

  constructor(
    private organisationService: OrganisationService,
    public router: Router,
    public route: ActivatedRoute,
    public urlParamService: UrlParamService
  ){
    super()

    // set data for the base service
    this.setUrlParamService(this.urlParamService);
    this.setActivatedRoute(this.route);
  }

  ngOnInit() {
    let defaultParams = {isVendor: this.isVendor}

    this.getInitialOptions(defaultParams, (options) => {
      // if the default page setup changed based on the url we have to set them
      for (const key in options.params) {
        this[key] = options.params[key];
      }
      
      this.orgs$ = this.organisationService.list(options).subscribe(data => {
        this.setListResponseData(data);
       });
    })
  }

  open(organisation) {
    this.router.navigate(['organisation'],  {
      queryParams: {id: organisation.id}, 
      relativeTo: this.route  
    });
  }

  lookup() {
    this.doSearch();
  }

  filterVendors() {
    this.txtSearch = ''; 
    this.pageIndex = 0;
    
    this.urlParamService.firstPage();
    this.urlParamService.update({isVendor: this.isVendor})
    this.urlParamService.set();
  }
}
