export class TaskDoc {
    id: number = null;
    task: number = null;
    _task: any = null;
    project: number = null;

    docRefNumber: string = null; // partner ref number in the doc
    description: string = null;  // desc of the doc
    file: any;


    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;
 

    constructor(data: any) {
       if (data) {
            this.id = data.id;
            this.project = data.project;
            this.docRefNumber = data.docRefNumber;
            this.description = data.description;
            this.file = data.file;
            
            this.task = data.task;
            if (data._task) {
                this._task = data._task;
            }

            this.project = data.project;

            
            if (data.creator) {
                this.creator = data.creator;
            }
           
            if (data.lastModifier) {
                this.lastModifier = data.lastModifier;
            }
           
            if (data.created) {
                this.created = new Date(data.created);
            }
            
            if (data.updated) {
                this.updated = new Date(data.updated);
            }
        }  
    }
  }
