import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Product, ProductAttribute } from 'src/app/models/product.model';
import { ModelFactory } from 'src/app/services/modelFactory.service';
import { ProductService } from 'src/app/services/product.service';
import { DlgSearchAttributeComponent } from 'src/app/shared/dialogs/search/dlg-search-attribute/dlg-search-attribute.component';
import { BaseEntity } from 'src/app/common/baseClasses/baseEntity';
import { DlgSearchAttributeGroupComponent } from 'src/app/shared/dialogs/search/dlg-search-attribute-group/dlg-search-attribute-group.component';
import { AttributeGroupService } from 'src/app/services/attributeGroup.service';
import { AttributeService } from 'src/app/services/attribute.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-product-attributes',
  templateUrl: './product-attributes.component.html',
  styleUrls: ['./product-attributes.component.scss']
})
export class ProductAttributesComponent extends BaseEntity implements OnInit {
  @Input() product: Product;
  productAttributes: ProductAttribute[] = [];
  groupedProductAttributes: any;

  attrGet$;
  dlgAttrSearch$;
  dlgAttrGSearch$;
  attrCreate$;
  attrUpdate$;
  attrList$;
  attrDelete$;
 
  constructor(
    private modelFactory: ModelFactory,
    private productService: ProductService,
    private attributeService: AttributeService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.fetchProductAttributes();
  }

  fetchProductAttributes() {
    this.attrGet$ = this.productService.getProductAttributes(this.product.id).subscribe(res => {
      this.productAttributes = res;
      this.groupAttributes();
    })
  }

  groupAttributes() {
    /// group attributes by attribute gorups
    const groups = this.productAttributes.reduce((groups, productAttribute) => {
      // creating a default attribute strucutre: {group:.... attributes:[]}
      const group = (groups[productAttribute._attribute.group] || {attributes: [], group: productAttribute._attribute._group});
      group['attributes'].push(productAttribute);
      groups[productAttribute._attribute.group] = group;
      return groups;
    }, {});

    this.groupedProductAttributes = groups;
  
  }

  openAttributeSearchDlg() {
    const dialogConfig = new MatDialogConfig();
      
    let name = '';
    
    dialogConfig.data = {
      name: name
    };

    const dialogRef = this.dialog.open(DlgSearchAttributeComponent, dialogConfig);

    this.dlgAttrSearch$ = dialogRef.afterClosed().subscribe(attribute => {
      if (attribute) {
         this.createProductAttribute(attribute);
      }
    });
  }

  openAttributeGroupSearchDlg() {
    const dialogConfig = new MatDialogConfig();
      
    let name = '';
    
    dialogConfig.data = {
      name: name
    };

    const dialogRef = this.dialog.open(DlgSearchAttributeGroupComponent, dialogConfig);

    this.dlgAttrGSearch$ = dialogRef.afterClosed().subscribe(attributeGroup => {
     // grab all the attributes and create all productAttributes from that
     this.createProductAttributesFromGroup(attributeGroup);
     this.groupAttributes();
    });
  }

  createProductAttribute(attribute) {
    let productAttribute = this.modelFactory.getNewModel(ProductAttribute);
    productAttribute.attribute = attribute.id;
    productAttribute._attribute = attribute;
    productAttribute.product = this.product.id;
    productAttribute._product = this.product;

    productAttribute.id = this.getNextId();

    this.addAttributeToProduct(productAttribute);
    this.groupAttributes();
  }

  addAttributeToProduct(productAttribute) {
    // add attribute to the list if not already there
    let _productAttribute = this.productAttributes.find(pA => {
      return pA.attribute == productAttribute.attribute;
    })

    if (_productAttribute) {
      // we found the attribute is already
      return;
    }

    this.productAttributes.push(productAttribute);
  }

  save(productAttribute) {
    (typeof productAttribute.id === 'number') ? this.update(productAttribute) : this.create(productAttribute);
  }

  create(productAttribute) {
    this.attrCreate$ = this.productService.createProductAttribute(productAttribute).subscribe(res => {
      this.productAttributes = this.productAttributes.filter(pA => pA.id != productAttribute.id);
      this.productAttributes.push(res);
      this.groupAttributes();
    });
  }

  update(productAttribute) {
    this.attrUpdate$ = this.productService.updateProductAttribute(productAttribute).subscribe(res => {
      this.productAttributes = this.productAttributes.filter(pA => pA.id != productAttribute.id);
      this.productAttributes.push(res);
      this.groupAttributes();
    });
  }

  createProductAttributesFromGroup(attributeGroup) {
    let params = {
        group: attributeGroup.id
    };
    
    this.attrList$ = this.attributeService.list({params}).subscribe(res => {
        res.results.forEach(attribute => {
            this.createProductAttribute(attribute);
        });
    });

    this.groupAttributes();
  }

  delete(productAttribute) {
    this.attrDelete$ = this.productService.deleteProductAttribute(productAttribute).subscribe(res => {
        this.productAttributes = this.productAttributes.filter(pA => pA.id !=productAttribute.id);
        this.groupAttributes();
    });
  }

}
