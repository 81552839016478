import { SimpleUser } from './user';

export class UserAccess {
    id: number = null;
    user: number;
    _user: SimpleUser =  new SimpleUser(null);
    module: string;
    level: number;
    
    created?: Date = null;
    updated?: Date = null;
    creator?: {id: number, color: String, email: String, firstName: String, lastName: String} = null;
    lastModifier?:  {id: number, color: String, email: String, firstName: String, lastName: String} = null;

   constructor(data) {
       if (data) {
            this.id = data.id;
            this.module = data.module;
            this.level = data.level;

            this.user = data.user;
            if (data.user) {
                this._user = new SimpleUser(data._user);
            }
       
            this.updated = new Date(data.updated);
            this.created =  new Date(data.created);
            this.creator = data.creator;
            this.lastModifier = data.lastModifier;
       }
   }
}