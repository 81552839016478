import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, RoutesRecognized, CanActivateChild  } from '@angular/router';
import { Observable } from "rxjs";
import { EnumNotificationLevel } from "../enums/notificationLevel.enum";
import { EnumNotificationType } from "../enums/notificationType.enum";
import { AuthService } from './auth.service';
import { NotificationService } from "./notification";

/**
 * Check if the User has access to the Module
 * - auth guard has to be called first
 */

@Injectable({
    providedIn: 'root'
  })
  export class ModuleGuard implements CanActivateChild {
  
    constructor(
      private authService: AuthService,
      private notificationService: NotificationService, 
      private router: Router) { }
  
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      // check the module access based on the user access level
      let module = route.data.module;

      if (module == 'open') {
        // it is an open page like dashboard
        return true;
      }

      if (!this.authService.user) {
        // we still load the user
        return false
      }
      
      if (this.authService.user.admin) {
        // admin
        return true;
      } else {
        if (this.authService.accessLevel.hasOwnProperty(module)) {
          if (this.authService.accessLevel[module].access == 0) {
            this.notificationService.notify(EnumNotificationType.SecurityModuleNoAccess, EnumNotificationLevel.Error);
          }

          return this.authService.accessLevel[module].access;
        }

        // module not found
        return false;
      }
    }
  }