import { Component, Input, OnInit } from '@angular/core';
import { EnumCountry } from 'src/app/enums/countries.enums';
import { Company } from 'src/app/models/company.model';
import { CompanyService } from 'src/app/services/company.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-default-config-invoice-and-delivery-address',
  templateUrl: './default-config-invoice-and-delivery-address.component.html',
  styleUrls: ['./default-config-invoice-and-delivery-address.component.scss']
})
export class DefaultConfigInvoiceAndDeliveryAddressComponent implements OnInit {
  @Input() defaultConfig: any;

  company: Company;

  enumCountry = EnumCountry;

  compLoad$;

  constructor(
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.compLoad$ = this.companyService.load().subscribe(res => {
      this.company = res;

      this.populateAddresses();
    });
  }

  populateAddresses() {
    this.defaultConfig['invoiceCountry'] =  EnumCountry.Hungary;
    this.defaultConfig['invoicePostcode'] = this.company.addressPostcode;
    this.defaultConfig['invoiceCity'] = this.company.addressCity;
    
    this.defaultConfig['invoiceLine'] = this.company.addressLine1;
    if (this.company.addressLine2) {
      this.defaultConfig['invoiceLine'] += ' ' + this.company.addressLine2;
    }

    this.defaultConfig['deliveryCountry'] = EnumCountry.Hungary;
    this.defaultConfig['deliveryPostcode'] = this.company.addressPostcode;
    this.defaultConfig['deliveryCity'] = this.company.addressCity;
    
    this.defaultConfig['deliveryLine'] = this.company.addressLine1;
    if (this.company.addressLine2) {
      this.defaultConfig['deliveryLine'] += ' ' + this.company.addressLine2;
    }

  }

}
