import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { DlgSearchOrgContactComponent } from '../search/dlg-search-org-contact/dlg-search-org-contact.component';
import { OrganisationService } from 'src/app/services/organisation.service';
import { Utils } from 'src/app/shared/utils';
import { EnumTemplateType } from 'src/app/enums/templates/templateType.enum';
import { EnumTemplateCategory } from 'src/app/enums/templates/templateCategory.enum';
import { Template, TemplateSection } from 'src/app/models/template.model';
import { EnumLanguage } from 'src/app/enums/languages.enum';
import { TaskPdf } from 'src/app/models/tasks/taskPdf.model';
import { EmailService } from 'src/app/services/email.service';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { AutoUnsub } from 'src/app/utils/autoUnsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-email-sending',
  templateUrl: './email-sending.component.html',
  styleUrls: ['./email-sending.component.scss']
})
export class EmailSendingComponent implements OnInit {
  isTemplateBased: boolean = true;
  EnumTemplateType = EnumTemplateType;
  EnumTemplateCategory = EnumTemplateCategory;
  language: EnumLanguage | string = 'hu';
  templateCategory: EnumTemplateCategory | string;

  template: Template = null;
  templateSections: TemplateSection[] = [];

  // generated html to pass for the html editor
  // after we got the sections we can generate it...
  body: string = '';

  onSent = new EventEmitter();

  sender: string;
  to: string[] = [];
  cc: string[] = [];
  bcc: string[] = [];
  subject: string;
  files: any[] =  []; // user uploaded attachments (actual files)
  sendByMyself: boolean = false;

  user: User | any; // logged in user


  // email sending was intiated from a generated PDF
  taskPdf: TaskPdf;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  removable = true;
  addOnBlur = true;

  // we need the org id only becuse we want to load all the email address which is related to the company
  orgId: number;

  showCC = false;
  showBCC = false;

  authGet$;
  orgGet$;
  dlgOrgSearch$;
  send$;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<EmailSendingComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private organisationService: OrganisationService,
    private emailService: EmailService,
    private authService: AuthService
  ) {
    this.orgId = data.org;
    this.language = data.language;
    this.templateCategory = data.templateCategory;
    this.taskPdf = data.taskPdf

    if (this.taskPdf?._task?.organization) {
      this.orgId = this.taskPdf._task.organization;
    }

    this.authGet$ = this.authService.user$.subscribe(user => {
      // get the logged in user
      this.user = user;
    })
  }

  ngOnInit() {
  
  }

  addTo(email) {
    this._add(email, this.to);
  }

  addCC(email) {
    this._add(email, this.cc);
  }

  addBcc(email) {
    this._add(email, this.bcc);
  }

  addTypedTo(event) {
    const value = (event.value || '').trim();
    if (value) {
     this._add(value, this.to);
    }

    // Clear the input value
    event.input.value = null;
  }

  addTypedCC(event) {
    const value = (event.value || '').trim();
    if (value) {
     this._add(value, this.cc);
    }

    // Clear the input value
    event.input.value = null;
  }

  addTypedBCC(event) {
    const value = (event.value || '').trim();
    if (value) {
     this._add(value, this.bcc);
    }

    // Clear the input value
    event.input.value = null;
  }

  removeTo(email) {
    this._remove(email, this.to)
  }


  removeCC(email) {
    this._remove(email, this.cc)
  }

  removeBcc(email) {
    this._remove(email, this.bcc)
  }

  _add(email, list) {
    if (!Utils.validateEmail(email)) {
      alert('Hibás Email');
      return;
    }

    if (list.indexOf(email) === -1) {
      list.push(email);
    } 
  }

  _remove(email, list) {
    let index = list.indexOf(email);
    if (index !== -1) {
      list.splice(list.indexOf(email), 1);
    }
  }

  openSearchOrgContactDialog(recOrBcc) {
      this.orgGet$ = this.organisationService.get(this.orgId).subscribe(org => {
          this._openSearchOrgContactDialog(org, recOrBcc);
      })
  }

  _openSearchOrgContactDialog(org, recOrBcc) {
    // open the dialog to select contact information of the pdf
    const dialogConfig = new MatDialogConfig();
        
    dialogConfig.data = {
      organisation: org,
      type: 'email'
    };

    const dialogRef = this.dialog.open(DlgSearchOrgContactComponent, dialogConfig);

    this.dlgOrgSearch$ = dialogRef.componentInstance.onSelect.subscribe(email => {
      if (recOrBcc === 'bcc') {
        this.addBcc(email);
      } else if (recOrBcc == 'cc') {
        this.addCC(email);
      } else {
        this.addTo(email);
      }
      
    })
  }

  toggleTemplateBased() {
    setTimeout(() => {
      this.isTemplateBased = !this.isTemplateBased;
    }, 0)
  }

  setTemplate(template) {
      this.template = template   
  }

  setTemplateSections(sections) {
      this.templateSections = sections;    
  }

  generate(pdfData, stepper) {
    // generate the sections for the email and go the to next step
    // @pdfData is a odd name but remind what is the strucutre
    this.generateHtmlData(pdfData);

    this.goForward(stepper);
  }

  resetPdfGeneration() {
    /*this.isPdfGenerated = false;
    this.templateSections = [];

    if (this.templateSelectorComp) {
      this.templateSelectorComp.unset();
    }*/
  }

goBack(stepper: MatStepper){
    stepper.previous();
}

goForward(stepper: MatStepper){
    stepper.next();
}

generateHtmlData(pdfData) {
  // the generated html is going to go into an email -> inline css is fine even required
  let body = '';

  pdfData.sections.forEach(section => {
    let htmlSection = '';

    if (section.title && section.title.length > 0) {
      htmlSection += '' + section.title + '';
    }
   
    if (section.body && section.body.length > 0) {
      htmlSection += '' + section.body + '';
    }

    body += '<div style="margin-bottom: 15px">' + htmlSection + '</div>';
  });

  this.body = body;
}

manuallyEdited(content) {
  // the email was manually edited in the dialog
  this.body = content;
}

continueWithoutTemplate(stepper) {
  // if the continue without tmp button clicked on the first
  this.body = "";

  this.goForward(stepper);
}

addAttachement(formData) {
  this.files.push(formData.get('file'))
}

sendEmail() {
  let payload = {
    to: this.to.join(),
    cc: this.cc.join(),
    bcc: this.bcc.join(),
    subject: this.subject,
    body: this.body,
    attachments: this.files,
    sendByMyself: Utils.isTruthy(this.sendByMyself)
  }

  if (this.taskPdf) {
    payload['taskPdf'] = this.taskPdf.id;
  }

  this.send$ = this.emailService.send(payload).subscribe(() => {
      this.onSent.emit();
      this.dialogRef.close();
  });

}

removeFile(file) {
  this.files = this.files.filter(f => f.name !=file.name)
}

}
