<ul class="breadcrumb"> 
    <li>Konfiguráció</li>  
</ul>

<ng-container *ngIf="generalSettings">

<div class="flex padding-10-0">
    <div class="full"></div>
    <button mat-mini-fab  color="primary" (click)="save()"  [disabled]="f.invalid" matTooltip="Mentés">
        <mat-icon>save</mat-icon>
    </button>
</div>
<form  #f="ngForm">

    <div class="section">
        <div class="header">
            <div class="title">
                <span>ÁFA</span>
            </div>
        </div>
        <div>
            <p class="helper-text padding-10-0">Alapértelmezett ÁFA kulcs amit a rendszer használ ha szükséges. A konkrét kalkulácóknál az érték módosítható.</p>
            <mat-form-field appearance="outline">
                <mat-label>Alap ÁFA</mat-label>
                <input matInput type="number" [minlength]="1" [maxlength]="100" name="defaultVat" [(ngModel)]="generalSettings.defaultVat" autocomplete="off">
                <mat-icon matSuffix>percent</mat-icon>
            </mat-form-field>
        </div>
    </div>

    <div class="section">
        <div class="header">
            <div class="title">
                <span>Értesítítő Email Cím</span>
            </div>
        </div>
        <div>
            <p class="helper-text">A rendszer az alábbi emailcímre fogja kiküldeni az általános értesítéseket a rendszerből.</p>
            <p class="helper-text text-small padding-10-0">Például: minimum raktár készlet értesítés</p>
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput type="email" name="notificationEmail" [(ngModel)]="generalSettings.notificationEmail" autocomplete="off">
            </mat-form-field>
        </div>
    </div>

    <div class="section">
        <div class="header">
            <div class="title">
                <span>Biztonsági Email Cím</span>
            </div>
        </div>
        <div>
            <p class="helper-text">Az alábbi emailcím hozzá lesz adva mint titkos másolat (BCC) minden egyes emailhez amit a rendszer kiküld.</p>
            <p class="helper-text padding-10-0">Javasolt létrehozni egy külön emailcímet erre a célra és ellenőrizni amikor szükség van rá.</p>
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput type="email" name="backupEmail" [(ngModel)]="generalSettings.backupEmail" autocomplete="off">
            </mat-form-field>
        </div>
    </div>
</form>

</ng-container>
