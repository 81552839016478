import { Attribute } from '@angular/compiler';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModelFactory } from 'src/app/services/modelFactory.service';

@Component({
  selector: 'app-dlg-edit-attribute',
  templateUrl: './dlg-edit-attribute.component.html',
  styleUrls: ['./dlg-edit-attribute.component.scss']
})
export class DlgEditAttributeComponent implements OnInit {
  attribute: Attribute = null;

  constructor(
    private modelFactory: ModelFactory,
    public dialogRef: MatDialogRef<DlgEditAttributeComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.attribute = this.modelFactory.getNewModel(Attribute);
    if (data) {
      this.attribute = data;
    }
  }

  ngOnInit() {
  }

  created(event) {
    this.closeDlg(event)
  }

  updated(event) {
    this.closeDlg(event);
  }

  deleted(event) {
    this.closeDlg(event);
  }

  closeDlg(event) {
    this.dialogRef.close(event);
  }

}
